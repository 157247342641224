import React from "react";
import { Fragment } from "react";
import { Container, Row, Col } from "reactstrap";
import StatisticsCard from "../subcomponents/dashboard/statisticsCard";
import Graph from "../../assets/images/dashboard/Graph.png";
import img from "../../assets/images/dashboard/Image.png";
import img1 from "../../assets/images/dashboard/Image1.png";
import img2 from "../../assets/images/dashboard/Image2.png";
import { useSelector } from "react-redux";
import assessmentStatus from "../../utilities/Constants";

let statistics = [];

export default function StatsComponent(props) {
	

	const roles = useSelector((state) => state.inviteCandidateReducer.roles);
	const candidateTests = useSelector(
		(state) => state.inviteCandidateReducer.candidateTests,
	);

	const assessmentSessionStats = useSelector(state => state.candidateTesting.assessmentSessionsStats);

	
	var shortlistRatio = 0;

	if(assessmentSessionStats.shortlisted > 0){
		shortlistRatio = ((assessmentSessionStats.shortlisted / assessmentSessionStats.total) * 100);
	}
	
	const openPositions = [];
	const previousPositions = [];
	const testTaken = [];
	const testPending = [];
	const testPassed = [];

	if (props.candidate) {
		candidateTests.map((test) => {
			if (
				test.status === assessmentStatus.UNDER_EVALUATION ||
				test.status === assessmentStatus.NOT_CONSIDERED ||
				test.status === assessmentStatus.FINISHED ||
				test.status === assessmentStatus.SHORTLISTED
			) {
				testTaken.push({
					...test,
				});
			}
			if (
				test.status === assessmentStatus.INITIALIZED ||
				test.status === assessmentStatus.IN_PROGRESS
			) {
				testPending.push({
					...test,
				});
			}
			if (test.status === assessmentStatus.SHORTLISTED) {
				testPassed.push({
					...test,
				});
			}
		});
	} else {
		roles.map((role) => {
			if (role.is_active) {
				openPositions.push({
					title: role.name,
					id: role.id,
				});
			} else {
				previousPositions.push({
					title: role.name,
					id: role.id,
				});
			}
		});
	}

	if (props.candidate) {
		statistics = [
			{
				title: "Strong Skills",
				start: 0,
				end: 100,
				image: Graph,
			},
			{
				title: "Tests Taken",
				start: 0,
				end: testTaken.length,
				image: img,
			},
			{
				title: "Tests Passed",
				start: 0,
				end: testPassed.length,
				image: img1,
			},
			{
				title: "Tests Pending",
				start: 0,
				end: testPending.length,
				image: img2,
			},
		];
	} else {
		statistics = [
			{ title: "Shortlist Ratio", start: 0, end: shortlistRatio, image: Graph },
			{ title: "Pending Tests", start: 0, end: assessmentSessionStats.pending, image: img },
			{ title: "Shortlisted Applicants", start: 0, end: assessmentSessionStats.shortlisted, image: img1 },
			{
				title: "Open Positions",
				start: 0,
				end: openPositions.length,
				image: img2,
			},
		];
	}

	return (
		<Fragment>
			<div className='d-flex'>
				<span className='b2'>Statistics</span>
			</div>
			<Container className='d-flex justify-content-center align-items-center pl-0 pr-0 no-gutters'>
				<Row
					className='d-flex justify-content-center align-items-center pr-0'
					style={{ paddingTop: "28px" }}>
					{statistics.map((stats, key) => {
						return (
							<Col key={key} style={{ paddingBottom: "20px" }} xs='auto'>
								<StatisticsCard
									graph={key === 0 ? true : false}
									title={stats.title}
									start={stats.start}
									end={stats.end}
									image={stats.image}
								/>
							</Col>
						);
					})}
				</Row>
			</Container>
		</Fragment>
	);
}
