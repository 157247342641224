import React, { useState } from "react";
import {ReactComponent as Helpicon} from "../../assets/icons/basic/exclamation-circle.svg";

export default function HelpIcon(props){

	const [isOpen, setIsOpen] = useState(false);

	const handleState = () => {
		setIsOpen(!isOpen);

		if(props.onMouseOver){
			props.onMouseOver();
		}
	};

	return(
		<div onMouseOver={handleState} style={{marginTop: props.forRefrance ? "-10px" : null }} >
			<Helpicon/> 
		</div>
		
	);
}

