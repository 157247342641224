import React from "react";
import Logo from "../../assets/icons/rnr-logo/1.svg";
import Profile from "../subcomponents/Profile_btn";

export default function Menu(props){
	return (
		<div className="onboarding_menu d-flex justify-content-between align-items-center">
			<div className="logo_container">
				<img className="logo" src={Logo}/>
			</div>
			<div style={{alignSelf:"stretch"}}>
				<Profile dashboard={props.dashboard} onboarding suspended={props.suspended}/>
			</div>
		</div>
	);
}
