import React from "react";
import { useSelector } from "react-redux";

export default function QualificationPointsCard() {
	const previousTestRightPanel = useSelector(
		(state) => state.candidateTesting.previousTestRightPanel,
	);
	const qualificationScores = useSelector(
		(state) => state.candidateTesting.qualificationScores,
	);

	return (
		<div
			style={{
				width: "100%",
				height: "178px",
				borderRadius: "10px",
				padding: "10px",
				backgroundColor: "white",
			}}>
			<div className="d-flex">
				<span className='b2'>Qualification</span>
			</div>
			<div
				style={{
					padding: "10px 10px 0px 0px",
					borderBottom: "1px solid #C8CBCD",
				}}>
				{qualificationScores.map((qualification, index) => {
					return (
						<div key={index} className='d-flex justify-content-between align-items-center'>
							<span className='description'>{qualification.tp_name}</span>
							<div>
								<span
									className='p1'
									style={{
										color:
											qualification.tp_score > 6 ||
											qualification.tp_score === 10
												? "#00D69A"
												: "#F4C20D",
									}}>
									{qualification.tp_score}
								</span>
							</div>
						</div>
					);
				})}
			</div>
			<div className='d-flex justify-content-between align-items-center'>
				<span className='description'>Score Acquired</span>
				<div>
					<span
						className='headline2'
						style={{
							color:
								previousTestRightPanel?.score > 6 ||
								previousTestRightPanel?.score === 10
									? "#00D69A"
									: "#F4C20D",
						}}>
						{previousTestRightPanel?.score}
					</span>
				</div>
			</div>
		</div>
	);
}
