import React, { useState, useEffect } from "react";
import { Fragment } from "react";
import RoleTemplateCard from "./RoleTemplateCard";
import { useDispatch, useSelector } from "react-redux";
// import dotIcon from "../../assets/icons/basic/dot.png";
import { ReactComponent as Filter } from "../../assets/icons/basic/filter.svg";
import { ReactComponent as CrossIcon } from "../../assets/icons/basic/x.svg";
import * as setupRoleActions from "../../redux/actions/onboarding/SetupRoles";

import RoleFilterModal from "./RoleFilterModal";

export default function SearchAndSelectRole(props) {


	// eslint-disable-next-line no-unused-vars
	const [allRoles, setAllRoles] = useState(props.roles);

	const [isModalOpen, setIsModalOpen] = useState(false);
	const [searchInput, setSearchInput] = useState("");
	const [matchedRoles, setmatchedRoles] = useState();
	const [filteredRoles, setfilteredRoles] = useState([]);
	const dispatch = useDispatch()
	const categoryMappings = useSelector((state) => state.setupRoles.categoryMappings)

	const Convert = (roles)=>{
		
		const arr = [];

		for(let i=0;i<roles.length;i++){
			for(let j=0;j<allRoles.length;j++){
				if(roles[i] ==allRoles[j].id){
					arr.push(j);
				}
			}
		}

		return arr;
	}; 
	const [selectedRoles, setselectedRoles] = useState(Convert(props.selectedRolesIds));

	// eslint-disable-next-line no-unused-vars
	const [fillter, setFillter] = useState({
		category: []
	});


	const setmatchedRolesToDefault = () => {
		var tempArr = [];
		allRoles.map((_, index) => {
			tempArr = [...tempArr, index];
		});
		setmatchedRoles(tempArr);
	};

	// eslint-disable-next-line no-unused-vars
	const [suggestions, setSuggestions] = useState({
		category: props.categorySugestions,
		// charityArea: props.charityAreasSugestions,
		// impactFactor: props.impactFactorsSugestions
	});

	const addSelectedRole = (roleIndex) => {
		setSearchInput("");
		document.getElementById("select-charity-search-input").value = "";
		if (!selectedRoles.includes(roleIndex)) {
			// console.log(roleIndex, "index");
			setselectedRoles([...selectedRoles, roleIndex]);
			// console.log(roleIndex, selectedRoles, "index");
		}
		// console.log(selectedRoles, "selected charities");
	};

	const removeSelectedRole = (roleIndex) => {
		setselectedRoles(selectedRoles.filter(item => item !== roleIndex));
		// console.log(selectedRoles.length, "array after remove");
	};

	useEffect(() => {
		// setmatchedRoles([]);
		var dummyArr = [];
		if (searchInput != "") {
			// console.log(matchedRoles, "Matched Charities");
			matchedRoles.map((roleIndex) => {
				if (allRoles[roleIndex].title.toLowerCase().includes(searchInput.toLowerCase())) {
					dummyArr = [...dummyArr, roleIndex];
				}
			});
			setmatchedRoles(dummyArr);
		}
		else {
			if (filteredRoles.length <= 0) {
				setmatchedRolesToDefault();
			}
			else {
				setmatchedRoles(filteredRoles);
			}
		}
	}, [searchInput]);

	useEffect(() => {
		// console.log(filteredRoles, "filltered Roles");
	}, [filteredRoles]);

	useEffect(() => {
		props.getSelectedRoles(allRoles[selectedRoles[0]]);
	}, [selectedRoles]);

	useEffect(() => {
		// console.log(fillter, "u[dated filterfiltered");
		var tempStateArr = [];
		var tempCharityArr = [];
		var tempImpacyArr = [];
		var comonArr = [];

		const selectedCatRoleIds = categoryMappings.filter(catMap => fillter.category.some(cat => cat.id == catMap.category_type_id)).map(catMap => catMap.role_template_id)
		tempStateArr = selectedCatRoleIds.map(roleId => {
			return allRoles.findIndex(role => role.id == roleId)
		}).filter(arr => arr >= 0)

		// eslint-disable-next-line no-unused-vars
		if (tempStateArr.length > 0 && tempCharityArr.length <= 0 && tempImpacyArr.length <= 0) {
			comonArr = tempStateArr;
		}
		else if (tempStateArr.length <= 0 && tempCharityArr.length > 0 && tempImpacyArr.length <= 0) {
			comonArr = tempCharityArr;
		}
		else if (tempStateArr.length <= 0 && tempCharityArr.length <= 0 && tempImpacyArr.length > 0) {
			comonArr = tempImpacyArr;
		}
		else if (tempStateArr.length > 0 && tempCharityArr.length > 0 && tempImpacyArr.length <= 0) {
			allRoles.map((_, index) => {
				if (tempStateArr.includes(index) && tempCharityArr.includes(index)) {
					comonArr = [...comonArr, index];
				}
			});
		}
		else if (tempStateArr.length > 0 && tempCharityArr.length <= 0 && tempImpacyArr.length > 0) {
			allRoles.map((_, index) => {
				if (tempStateArr.includes(index) && tempImpacyArr.includes(index)) {
					comonArr = [...comonArr, index];
				}
			});
		}
		else if (tempStateArr.length <= 0 && tempCharityArr.length > 0 && tempImpacyArr.length > 0) {
			allRoles.map((_, index) => {
				if (tempCharityArr.includes(index) && tempImpacyArr.includes(index)) {
					comonArr = [...comonArr, index];
				}
			});
		}
		else {
			allRoles.map((_, index) => {
				if (tempStateArr.includes(index) && tempCharityArr.includes(index) && tempImpacyArr.includes(index)) {
					comonArr = [...comonArr, index];
				}
			});
		}

		// console.log(tempStateArr, tempCharityArr, tempImpacyArr, comonArr, "temp ");
		setfilteredRoles(comonArr);
		if (tempStateArr.length <= 0 && tempCharityArr.length <= 0 && tempImpacyArr.length <= 0) {
			setmatchedRolesToDefault();
		}
		else {
			setmatchedRoles(comonArr);
		}

	}, [fillter]);

	useEffect(() => {
		dispatch(setupRoleActions.getCategoryMappings())
	}, [])

	const updateFillter = (updatedFilter) => {
		setFillter({category: updatedFilter});
		// console.log(updatedFilter, "filtered data");
	};

	const handleModal = (modalStatus) => {
		// console.log(modalStatus, "mmodal");
		setIsModalOpen(modalStatus);
	};
	return (
		<Fragment>

			<RoleFilterModal
				updateFillter={updateFillter}
				isOpen={isModalOpen}
				fillter={fillter}
				suggestions={suggestions}
				setIsOpen={handleModal}
			/>

			<div
				className="w-100 search-bar-card"
				style={props.style}>
				<div className="w-100 search-bar-card-header ">
					<input
						id="select-charity-search-input"
						className="basic-input"
						placeholder="Search for Role Templates"
						style={{ padding: "14px 20px" }}
						onChange={(e) => setSearchInput(e.target.value)}
					/>
					<Filter className="pointer" onClick={() => setIsModalOpen(true)} />
				</div>
				{console.log("matchedroles are " ,allRoles)}
				<div
					className="searched-charity-list-container"
					style={{ padding: "0px 20px" }}>

					{
						matchedRoles && matchedRoles
							.map((roleIndex, index) => {
								return (
									<Fragment key={index}>
										{
											!selectedRoles.includes(roleIndex) &&
										<div
											id="roleTemplateIdForCypress"
											className="pointer"
											style={(index + 1) !== matchedRoles.length ? { padding: "6px 0px", borderBottom: "1px solid #C8CBCD" } : { padding: "6px 0px" }}
											onClick={selectedRoles.length < 1 ? () => addSelectedRole(roleIndex) : () => { }}
										>
											<div className="d-flex align-items-center">
												
												<span className="description" style={{ marginLeft: "0px" }}>{allRoles[roleIndex].title}</span>
												{/* <img style={{ marginLeft: "5px" }} src={dotIcon} /> */}
												{/* <span className="description" style={{ marginLeft: "5px" }}>{charities[roleIndex].impact}</span> */}
											</div>
										</div>
										}
									</Fragment>
								);
							})
					}

				</div>
			</div >
			<div>
				{
					selectedRoles &&
					selectedRoles.map((roleIndex, index) => {
						return (
							<div id={roleIndex} key={index}>
								{
									allRoles[roleIndex] &&
									<div style={{ position: "relative" }}>
										<CrossIcon
											className="pointer"
											style={{
												position: "absolute",
												right: "20px",
												top: "10px"
											}}
											onClick={() => removeSelectedRole(roleIndex)} />
										{
											<RoleTemplateCard
												title={allRoles[roleIndex].title}
												description={`${allRoles[roleIndex].state} - ${allRoles[roleIndex].charityArea}`}
												impact={allRoles[roleIndex].impact}
												details={allRoles[roleIndex].details} />
										}

									</div>

								}
							</div>
						);
					})
				}
			</div>
		</Fragment >
	);
}
