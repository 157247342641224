import React from "react";
import SearchInput from "../subcomponents/dashboard/SearchInput";
import Notifications from "../../components/dashboard/Notifications";
import SelectValue from "../subcomponents/SelectValue";
import DashboardAlert from "../../components/subcomponents/DashboardAlert";
import { useSelector } from "react-redux";

export default function MidLayerHeader(props) {

	const form = useSelector(state => state.billing.invoiceForm);

	const getSubscriptions = useSelector(state => state.user.isTenantSubscribed);

	// const thresholdCost = getSubscriptions?.data[0]?.alert_threshold;
	// const upcomingCost = form.total_cost;
	// const status = getSubscriptions?.data[0]?.status;
	// console.log(getSubscriptions);

	return (
		<div>
			{props.mobile ? (
				<div>
					<div
						className='d-flex justify-content-between w-100'
						style={{ padding: "0px 0px 30px 0px" }}>
						<div style={{ paddingRight: "5px" }}>
							<span className='headline1'>{props.label}</span>
						</div>

						<div className='d-flex flex-column '>
							<div className='d-flex ' style={{ paddingBottom: "15px" }}>
								<div style={{ paddingRight: "10px" }}>
									<SearchInput />
								</div>
								<div>
									<Notifications mobile={props.mobile} />
								</div>
							</div>
						</div>
					</div>
					<div
						style={{ marginTop: "-25px", paddingBottom: "20px" }}
						className='d-flex '>
						{props.hasClients && (
							<div className='w-100'>
								<SelectValue
									mobile={props.mobile}
									agency
									agencySetup
									options={props.options}
									updateSelections={(e) => props.onSelect(e)}
								/>
							</div>
						)}
					</div>
				</div>
			) : (
				<div>
					<div
						className='d-flex justify-content-between w-100'
						style={{ padding: "0px 0px 30px 0px" }}>
						<div>
							<span className={props.mobile ? "headline2" : "headline1"}>
								{props.label}
							</span>
						</div>

						<div className='d-flex'>
							<div>
								{props.hasClients && (
									<div>
										<SelectValue
											agency
											agencySetup
											options={props.options}
											updateSelections={(e) => props.onSelect(e)}
										/>
									</div>
								)}
							</div>
							<div className='d-flex ' style={{ paddingBottom: "15px" }}>
								<div style={{ paddingRight: "30px" }}>
									<SearchInput />
								</div>
								<div>
									<Notifications mobile={props.mobile} />
								</div>
							</div>
						</div>
					</div>
					{ props.candidate &&
						<div style={{marginTop:"-20px", paddingBottom:"15px"}}>
							{getSubscriptions?.data[0]?.alert_threshold && (form.total_cost >= getSubscriptions?.data[0]?.alert_threshold) &&
							<DashboardAlert
								alert="Your upcoming cost is more than your defined cost."
							/>
							}
							<div className="mt-3">
								{
									getSubscriptions?.data[0]?.status === "past_due" &&
							<DashboardAlert
								alert="Your payment was unsuccessful after 3 attempts. Please Click here to retry or to update your payment method, otherwise your account will be locked after a 30 day
								period. "
							/>

								}
							</div>

						</div>
					}
					
				</div>
			)}
		</div>
	);
}
