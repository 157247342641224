import React, { useEffect, useState } from "react";
import CheckMark from "../../../assets/icons/onboarding/checkmark.png";
import BlankPicture from "../../../assets/icons/Userpic/userprofile.svg";


export default function SwitcherProfile(props) {
	const [selected, setIsSelected] = useState(false);

	useEffect(() => {
		setIsSelected(props.selected);
	}, [props.selected]);

	return (
		<div
			className='d-flex align-items-center justify-content-between pointer'
			style={{ paddingBottom: "8px" }}
			onClick={props.onClick}>
			<div>
				<img
					src={props.avatar || BlankPicture}
					alt=''
					style={{
						width: "20px",
						height: "20px",
						marginRight: "10px",
						borderRadius: (props.avatar === null) ? "0%" :"50%",
					}}
				/>
				<span className='description'>{props.name}</span>
			</div>
			{selected && (
				<div
					className={
						props.loading ? "spinner-border spinner-border-sm text-info" : ""
					}>
					{!props.loading && (
						<img
							src={CheckMark}
							alt=''
							style={{ width: "14px", height: "12px" }}
						/>
					)}
				</div>
			)}
		</div>
	);
}
