import React from "react";
import Scroll from "react-scroll";

var Element = Scroll.Element;

export default function Section(props){
	return(
		<Element className="section-content" name={props.name} style={props.style}>
			<img
				src={props.image}
				alt=""
				style={{width:"38.02vw", height:"50.43vh"}}
			/>
		</Element>
	);
}