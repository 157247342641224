/* eslint-disable no-mixed-spaces-and-tabs */
/* eslint-disable indent */
import React, { useState, useEffect } from "react";
import Input from "../Input";
import { ReactComponent as Add } from "../../../assets/icons/basic/add.svg";
import { ReactComponent as Tick } from "../../../assets/icons/basic/tick.svg";
import { Fragment } from "react";
import Dropdown from "./Dropdown";
import ImageUploader from "./ImageUploader";
import { useSelector } from "react-redux";
// import { handleChange } from "../../../redux/actions/onboarding/companySetup";

export default function AddRow(props) {
	const loading = useSelector((state) => state.clients.addClientsLoading);

	const [row, setRow] = useState([]);

	const handleValue = (event, index, action, inputType, imageFile) => {
		if (!loading) {
			var dummyRow = row;
			if (dummyRow === []) {
				if (inputType === "image") {
					dummyRow.push(event);
					dummyRow.push(imageFile);
				} else {
					dummyRow.push(event.target.value);
				}
				// setstate(event.target.value);
				// console.log("123321");
			} else {
				if (inputType === "select") {
					dummyRow[index] = event;
					// console.log("here");
				} else if(inputType === "multi-select") {
					const tempArray = [];
					let alreadyExists = false;

					if(dummyRow[index]?.length > 0) {
						dummyRow[index].map((o) => {
							if(o.id === event.id) {
								alreadyExists = true;
							} else {
								tempArray.push(o);
							}
						});
		
						if(!alreadyExists) {
							tempArray.push(event);
						}
					} else {
						tempArray.push(event);
					}

					dummyRow[index] = tempArray;
				}
				 else if (inputType === "image") {
					dummyRow[index] = event;
					if (dummyRow[index - 1]) {
						dummyRow[index] = imageFile;
					} else {
						dummyRow[index - 1] = imageFile;
					}
				} else {
					if (dummyRow[index] === undefined) {
						// console.log("hererer");
						dummyRow[index] = "" + event.target.value;
					} else {
						if (action === 2) {
							// console.log("123321");
							if (row.length > 0) {
								document.getElementById(`index${index}`).value =
									event.target.value;
								dummyRow[index] = document.getElementById(
									`index${index}`,
								).value;
							}
							// console.log("edited ho gya")
						} else {
							dummyRow[index] = event.target.value;
						}
					}
				}
			}
			setRow(dummyRow);
		}
	};

	const selectedImage = (image) => {
		handleValue(
			image.image,
			image.imageFor,
			props.action,
			"image",
			image.imageFile,
		);
	};

	const addRow = () => {
		console.log("row", row);
		if (row.length > 0 && row.length === props.rowLength) {
			props.updateTable(0, row);
			Array.from({ length: row.length }).map((_, index) => {
				if (index > 0) {
					if (document.getElementById(`index${index}`).value) {
						document.getElementById(`index${index}`).value = "";
					} else {
						// console.log(document.getElementById(`index${index}`));
						document.getElementById(`index${index}`).innerHTML = "";
						// console.log(document.getElementById(`index${index}`));
					}
				}
			});
			setRow([]);
		} else {
			alert("Please fill all fields");
		}
	};

	useEffect(() => {
		if (props.rowData !== undefined) {
			setRow(props.rowData);
			// console.log(props.rowData, "rowww");
			// console.log(row, "rowww");
			Array.from({ length: props.rowData.length }).map((_, index) => {
				// console.log(props.rowData[index]);
				// console.log(props.rowData[index]);
				if (index > 0) {
					// console.log(props.rowData[index], "rowww");
					if (props.rowData[index] instanceof Object) {
						document.getElementById(`index${index}`).innerHTML =
							props.rowData[index].option;

						if(Array.isArray(props.rowData[index])) {
							Array.from({length: props.rowData[index].length}).map((__, i) => {
								if(document.getElementById(`index${index}`).innerHTML != "undefined") {
									document.getElementById(`index${index}`).innerHTML +=
									props.rowData[index][i].option + " ";
								} else {
									document.getElementById(`index${index}`).innerHTML =
									props.rowData[index][i].option + ", ";
								}
							});
						}
					} else {
						document.getElementById(`index${index}`).value =
							props.rowData[index];
					}
				}
			});
		}
	}, [props.rowData]);

	useEffect(() => {
		// console.log(row);
	}, [row]);

	return (
		<div className='outer-row w-100'>
			<div className='add-new-row w-100'>
				{Array.from({ length: props.rowLength + 1 }).map((_, index) => {
					return (
						<Fragment key={index}>
							{index > 0 && (
								<div
									className={
										"row-cell d-flex " +
										`${`justify-content-${
											props.alignText ??
											`${
												index === 1
													? "center "
													: index === props.rowLength
													? "end "
													: "center "
											} `
										}`}`
									}
									style={{
										paddingLeft: "20px",
										paddingRight: "5px",
										width: `${100 / props.rowLength}%`,
									}}>
									{props.rowLength !== index ? (
										<Fragment>
											{
												props.activeSelectInput !== null &&
												props.activeSelectInput.find(
													(select) => select.col === index,
												) ? (
													<div
														className={`w-100 d-flex + ${`justify-content-${
															props.alignText ? props.alignText : "center "
														}`}`}>
														{/* <Input id={`index${index}`} className={`b4 d-flex ${!props.alignText && `${index === 1 && " row-margin-left "}`}`} tableInput onChange={e => handleValue(e, index, props.action)} /> */}
														<Dropdown
															id={`index${index}`}
															className='tabel-dropdown'
															options={
																props.activeSelectInput.find(
																	(select) => select.col === index,
																).data
															}
															onSelect={(e) => {
																handleValue(e, index, props.action, "select");
															}}
														/>
													</div>
												) : props.activeMultiSelectInput !== null &&
												  props.activeMultiSelectInput.find(
														(select) => select.col === index,
												  ) ? (
													<div
														className={`w-100 d-flex + ${`justify-content-${
															props.alignText ? props.alignText : "center "
														}`}`}>
														{/* <Input id={`index${index}`} className={`b4 d-flex ${!props.alignText && `${index === 1 && " row-margin-left "}`}`} tableInput onChange={e => handleValue(e, index, props.action)} /> */}
														<Dropdown
															id={`index${index}`}
															className='tabel-dropdown'
															options={
																props.activeMultiSelectInput.find(
																	(select) => select.col === index,
																).data
															}
															onSelect={(e) => {
																handleValue(e, index, props.action, "multi-select");
															}}
															multiSelected={props.rowData?.length > 3 ? props.rowData[index] : []}
															row={row}
															varient='multi-select'
														/>
													</div>
												) : (
													<Fragment>
														{props.activeImageInput !== null &&
														props.activeImageInput.find(
															(select) => select.col === index,
														) ? (
															<ImageUploader
																id={`index${index}`}
																image={
																	props.activeImageInput.find(
																		(select) => select.col === index,
																	).data
																}
																getImage={selectedImage}
																imageFor={index}
																row={row}
															/>
														) : (
															<Input
																id={`index${index}`}
																className={`description d-flex  ${
																	!props.alignText &&
																	`${index === 1 && " justify-content-center "}`
																}`}
																tableInput
																onChange={(e) =>
																	handleValue(e, index, props.action, "input")
																}
															/>
														)}
													</Fragment>
												)
												// console.log(props.activeSelectInput)
											}
										</Fragment>
									) : (
										<Fragment>
											{props.action === 2 ? (
												<div
													onClick={!loading ? props.onClick : null}
													className={`d-flex ${
														!props.alignText && " row-margin-right "
													}`}>
													<Tick className='table-icons' />
												</div>
											) : (
												<div
													onClick={!loading ? addRow : null}
													className={`d-flex ${
														!props.alignText && " row-margin-right "
													}`}>
													<Add className='table-icons' />
												</div>
											)}
										</Fragment>
									)}
								</div>
							)}
						</Fragment>
					);
				})}
			</div>
		</div>
	);
}
