import React, { useEffect, useState } from "react";
import CandidateRightPanel from "../../components/dashboard/CandidateRightPanel";
import DashboardMenu from "../../components/dashboard/DashboardMenu";
import RecruiterDashboardMenue from "../../components/dashboard/RecruiterDashboardMenue";
import CandidateDashboardMenu from "../../components/dashboard/CandidateDashboard/CandidateDashboardMenu";
import Dashboard from "../pages/Dashboard";
import { Route, Switch, useRouteMatch, useHistory } from "react-router-dom";
import Positions from "../pages/Positions";
import CompanySettings from "../pages/Settings/CompanySettings";
import AgencySettings from "../pages/Settings/AgencySettings";
import TeamSettings from "../pages/Settings/TeamSettings";
import BillingSettings from "../pages/Settings/Billing/BillingSettings";
import RightBackground from "../../assets/images/dashboard/Candidatebackground.png";
import Background from "../../assets/images/dashboard/Glass.png";
import MidLayerHeader from "../../components/dashboard/MidLayerHeader";
import Integrations from "../pages/Integrations";
import CandidateDetails from "../../components/dashboard/CandidateDetails/CandidateDetails";
import SubscriptionPlan from "../pages/Settings/Billing/SubscriptionPlan";
import AddCard from "../pages/Settings/Billing/AddCard";
import EditCard from "../pages/Settings/Billing/EditCard";
import CreateNewPosition from "../pages/CreateNewPosition/CreateNewPosition";
import UserSettings from "../pages/UserSettings";
import { Fragment } from "react";
import Modal from "../../components/subcomponents/dashboard/Modal";
import { useSelector, useDispatch } from "react-redux";
import * as addClientActions from "../../redux/actions/onboarding/Clients";
import * as clientFilterActions from "../../redux/actions/Dashboard/ClientFilter.actions";
import InterviewDetails from "../../components/dashboard/CandidateDashboard/InterviewDetails";
import CandidateInterview from "../../components/dashboard/CandidateDashboard/CandidateInterview";
import PositionComponent from "../../components/dashboard/PositionComponent";
import InterviewSummaryCard from "../../components/dashboard/InterviewSummaryCard";
import * as candidateTestingActions from "../../redux/actions/Candidate/candidate-testing.actions";
// import QuestionFeedbackModal from "../../components/subcomponents/dashboard/QuestionFeedbackModal";

export default function DashboardPageContainer() {
	const dispatch = useDispatch();

	const history = useHistory();

	const user = useSelector((state) => state.user.user);
	const hasClients = useSelector((state) => state.clients.hasClients);
	const allClients = useSelector((state) => state.clients.allClients);

	const allClientsForMidlayerHeader = [{}];
	allClients.map((client) => {
		allClientsForMidlayerHeader.push({
			label: client.name,
			value: client.id,
			icon: client.logo,
		});
	});

	const handleClientFilterSelected = (client) => {
		if (client?.value) {
			dispatch(clientFilterActions.clientFilterSelected(client));
		} else {
			dispatch(clientFilterActions.clientFilterRemoved());
		}
	};

	const [selectedPage, setSelectedPage] = useState({
		pageIndex: 0,
		pageLabel: "Dashboard",
	});
	const [isOpen, setIsOpen] = useState(false);
	const [candidateWidth, setCandidateWidth] = useState("0px");
	const [rightPanelWidth, setRightPanelWidth] = useState("0px");
	const [isExtended, setIsExtended] = useState(false);
	const [isSelected, setIsSelected] = useState(false);
	const [isRightExtended, setIsRightExtended] = useState(false);
	const [hideMenu, setHideMenu] = useState(false);
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [isCandidateDetails, setIsCandidateDetails] = useState(false);
	const [isInterview, setIsInterview] = useState(false);
	const [isTestDetails, setIsTestDetails] = useState(false);
	const [testCompleted, setIsTestCompleted] = useState(false);

	const match = useRouteMatch();

	const onClick = () => {
		setIsOpen(!isOpen);
		setHideMenu(false);
		setIsTestDetails(true);
		setIsCandidateDetails(false);
	};

	const handleCompleted = () => {
		setIsTestCompleted(true);
	};

	const onPreviousClick = () => {
		setIsOpen(!isOpen);
		setHideMenu(false);
		setIsTestDetails(false);
		setIsCandidateDetails(true);
		setIsInterview(false);
	};

	const extendLeft = () => {
		if (isExtended) {
			setCandidateWidth("500px");
		} else {
			setCandidateWidth("829px");
		}
		setIsExtended(!isExtended);
	};

	const extendRightPanel = () => {
		if (isRightExtended) {
			setRightPanelWidth("500px");
		} else {
			setRightPanelWidth("829px");
		}
		setIsRightExtended(!isRightExtended);
	};

	const closeRightPanel = () => {
		dispatch(candidateTestingActions.resetPreviousTestRightPanel());

		setIsSelected(false);
	};

	const hideRightPanel = () => {
		dispatch(candidateTestingActions.resetPreviousTestRightPanel());

		setIsSelected(true);
		setRightPanelWidth("500px");
		setIsCandidateDetails(false);
	};

	const closeComponent = () => {
		setIsOpen(false);
		setIsExtended(false);

		closeRightPanel();
	};

	const switchMenu = () => {
		dispatch(candidateTestingActions.resetPreviousTestRightPanel());

		setIsExtended(false);
		setIsRightExtended(false);
		setIsSelected(false);
		setIsOpen(false);
	};

	useEffect(() => {
		if (!isOpen) {
			setCandidateWidth("0px");
			setIsExtended(false);
		} else {
			setCandidateWidth("500px");
		}
	}, [isOpen]);

	useEffect(() => {
		if (hasClients && user.tenantId) {
			dispatch(addClientActions.getAllClients());
		}
	}, [dispatch, hasClients, user]);

	const getPageNo = (pageNo, label, hasSubMenu) => {
		if (hasSubMenu) {
			//
		} else {
			setSelectedPage({
				pageIndex: pageNo,
				pageLabel: label,
			});
		}
	};

	const handleClick = () => {
		setHideMenu(true);
		setIsOpen(true);
	};

	const handleModal = () => {
		setIsModalOpen(true);
	};

	const handleRowClick = () => {
		setIsSelected(true);
		setRightPanelWidth("500px");
		setIsCandidateDetails(true);
	};

	const handleSettingsClick = () => {
		setHideMenu(true);
		setIsSelected(false);
	};

	const handleStart = () => {
		setIsOpen(true);
		history.push(`${match.path}/candidate-interview`);
		setIsInterview(true);
	};

	const handleInterviewSummary = () => {
		setIsOpen(false);
		history.push(`${match.path}/interview-summary`);
	};

	const handleIntegration = () => {
		console.log("clicked");
	};

	if (user?.tenantId === 0) {
		return (
			<div className='dashboard-layout'>
				<div
					className='dashboard-menu'
					style={{ width: `${isOpen ? "100px" : "226px"}` }}>
					<CandidateDashboardMenu
						onClick={switchMenu}
						getSelectedItem={getPageNo}
						isOpen={isOpen}
						onSettingsClick={handleSettingsClick}
					/>
				</div>

				<div
					className='dashboard-right-panel'
					style={{
						width: "511px",
						display: `${isSelected ? "none" : "block"}`,
						padding:
							selectedPage.pageLabel === "Dashboard" ? "30px" : "30px 40px",
					}}>
					{!isInterview && (
						<MidLayerHeader
							label={selectedPage.pageLabel}
							options={allClientsForMidlayerHeader}
							onSelect={(e) => handleClientFilterSelected(e)}
							mobile={false}
							candidate={false}
						/>
					)}

					<Switch>
						<Route exact path={`${match.path}`}>
							<Dashboard
								onClickStart={handleStart}
								candidate
								onClick={onClick}
								selected={isOpen}
								onHandleClick={handleClick}
								onSettingsClick={handleSettingsClick}
								onPreviousClick={onPreviousClick}
								setIsInterview={setIsInterview}
								setIsOpen={setIsOpen}
								mobile={false}
							/>
						</Route>
						<Route exact path={`${match.path}/candidate-positions`}>
							{/* <InterviewSummaryCard
								onSubmit={() => history.push(`${match.path}/`)}
							/> */}
							{/* <QuestionFeedbackModal/> */}
							<PositionComponent
								onClickStart={handleStart}
								candidate
								onClick={onClick}
								selected={isOpen}
								onPreviousClick={onPreviousClick}
							/>
						</Route>
						<Route exact path={`${match.path}/user-settings`}>
							<UserSettings />
						</Route>
						<Route exact path={`${match.path}/candidate-interview`}>
							<CandidateInterview
								onComplete={handleCompleted}
								welcome={handleStart}
								handleInterviewSummary={handleInterviewSummary}
								mobile={false}
							/>
						</Route>
						<Route exact path={`${match.path}/interview-summary`}>
							<InterviewSummaryCard history={history} match={match}/>
						</Route>
						<Route path='*' exact={true}>
							<Dashboard
								onClickStart={handleStart}
								candidate
								onClick={onClick}
								selected={isOpen}
								onHandleClick={handleClick}
								onSettingsClick={handleSettingsClick}
								onPreviousClick={onPreviousClick}
								setIsInterview={setIsInterview}
								setIsOpen={setIsOpen}
								mobile={false}
							/>
						</Route>
					</Switch>
				</div>
				<div
					className='dashboard-candidate-panel'
					style={{
						width: candidateWidth,
						flexGrow: `${isSelected ? "1" : "0"}`,
						backgroundImage: `${
							isSelected ? `url(${Background})` : `url(${RightBackground})`
						}`,
						display: `${isOpen ? (hideMenu ? "none" : "block") : "none"}`,
					}}>
					{isInterview ? (
						<InterviewDetails
							completed={testCompleted}
							onClick={handleInterviewSummary}
						/>
					) : (
						<CandidateDetails
							candidate
							onClickStart={handleStart}
							onCross={closeComponent}
							onClick={extendLeft}
							isExtended={isExtended}
							testDetails={isTestDetails}
							tableClick={isCandidateDetails}
						/>
					)}
				</div>
			</div>
		);
	} else {
		return (
			<div className='dashboard-layout'>
				{(user.roleId == 14) ? 
					<div
						className='dashboard-menu'
						style={{ width: `${isOpen ? "100px" : "226px"}` }}>
						<RecruiterDashboardMenue
							onClick={switchMenu}
							getSelectedItem={getPageNo}
							isOpen={isOpen}
							onSettingsClick={handleSettingsClick}
						/>
					</div> :
					<div
						className='dashboard-menu'
						style={{ width: `${isOpen ? "100px" : "226px"}` }}>
						<DashboardMenu
							onClick={switchMenu}
							getSelectedItem={getPageNo}
							isOpen={isOpen}
							onSettingsClick={handleSettingsClick}
						/>
					</div>
				}
				<Fragment>
					{isModalOpen && <Modal onClick={() => setIsModalOpen(false)} />}
					<div
						className='dashboard-right-panel'
						style={{
							width: "511px",
							display: `${isSelected ? "none" : "block"}`,
							padding:
								selectedPage.pageLabel === "Dashboard" ? "30px" : "30px 40px",
						}}>
						<MidLayerHeader
							hasClients={hasClients}
							label={selectedPage.pageLabel}
							options={allClientsForMidlayerHeader}
							onSelect={(e) => handleClientFilterSelected(e)}
							mobile={false}
							candidate

						/>

						<Switch>
							<Route exact path={`${match.path}`}>
								<Dashboard
									onClick={onClick}
									selected={isOpen}
									onHandleClick={handleClick}
									onSettingsClick={handleSettingsClick}
								/>
							</Route>
							<Route exact path={`${match.path}/positions`}>
								<Positions
									onClick={onClick}
									selected={isOpen}
									onHandleClick={handleClick}
								/>
							</Route>
							{console.log("user at dashboard is" , user.roleId)}
							{(user.roleId !== 14) && <>
								<Route path={`${match.path}/settings/company`}>
									<CompanySettings />
								</Route>
								<Route path={`${match.path}/settings/agency`}>
									<AgencySettings />
								</Route>
								<Route path={`${match.path}/settings/team`}>
									<TeamSettings />
								</Route>
								<Route exact path={`${match.path}/settings/billing`}>
									<BillingSettings />
								</Route>
								<Route path={`${match.path}/settings/billing/subscription`}>
									<SubscriptionPlan />
								</Route>
								<Route path={`${match.path}/settings/billing/add-card`}>
									<AddCard />
								</Route>
								<Route path={`${match.path}/settings/billing/edit-card`}>
									<EditCard />
								</Route>
								<Route path={`${match.path}/create-new-position`}>
									<CreateNewPosition />
								</Route>
								<Route path={`${match.path}/positions/create-new-position`}>
									<CreateNewPosition />
								</Route>
								<Route exact path={`${match.path}/integrations`}>
									{/* <ReferenceCheckScreen /> */}
									<Integrations onClick={handleIntegration} />
								</Route>
							</>}
							<Route path={`${match.path}/create-new-position`}>
								<CreateNewPosition />
							</Route>
							<Route path={`${match.path}/positions/create-new-position`}>
								<CreateNewPosition />
							</Route>
							
							{/* <Route exact path={`${match.path}/oauth`}>
								<OAuthScreen />
							</Route> */}
							<Route exact path={`${match.path}/user-settings`}>
								<UserSettings />
							</Route>
							<Route path='*' exact={true}>
								<Dashboard
									onClick={onClick}
									selected={isOpen}
									onHandleClick={handleClick}
									onSettingsClick={handleSettingsClick}
								/>
							</Route>
						</Switch>
					</div>
					<div
						className='dashboard-candidate-panel'
						style={{
							width: candidateWidth,
							flexGrow: `${isSelected ? "1" : "0"}`,
							backgroundImage: `${
								isSelected ? `url(${Background})` : `url(${RightBackground})`
							}`,
							display: `${isOpen ? (hideMenu ? "none" : "block") : "none"}`,
						}}>
						<CandidateRightPanel
							onCross={closeComponent}
							onClick={extendLeft}
							isExtended={isExtended}
							documentClick={hideRightPanel}
							selected={isSelected}
							onhandleClick={handleModal}
							onRowClick={handleRowClick}
							mobile={false}
							web
						/>
					</div>
				</Fragment>
				<div
					className='dashboard-extended-panel'
					style={{
						width: rightPanelWidth,
						display: `${isSelected ? "block" : "none"}`,
					}}>
					<CandidateDetails
						onCross={closeRightPanel}
						onClick={extendRightPanel}
						isExtended={isRightExtended}
						tableClick={isCandidateDetails}
						onAllCross={closeComponent}
						testDetails={false}
					/>
				</div>
			</div>
		);
	}
}
