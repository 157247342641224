import React from "react";

export default function RoleTemplateCard(props) {
	return (
		<div className='role-template-card' style={props.style}>
			<div
				className='d-flex justify-content-between w-100'
				style={{ padding: "10px 15px" }}>
				<span className='headline3'>{props.title || "Title Not Found"}</span>
			</div>
			<div className='d-flex' style={{ padding: "0px 12px" }}>
				<span className='description' style={{ color: "#3E454D" }}>
					{props.details || "Details Not Found"}
				</span>
			</div>
		</div>
	);
}
