import React from "react";
import Tick from "../assets/icons/custom_checkbox/tick.png";

function CheckMark() {
	return (
		<>
			<img src={Tick} width='9.3px' height='6.7px' />
		</>
	);
}

function CustomCheckBox(props) {
	const bg = props.value === true ? "#32c3e0" : "#c8cbcd";
	let myclass = "custom_checkbox d-flex align-items-center";
	if (props.className) {
		myclass += " ";
		myclass += props.className;
	}

	return (
		<div className={myclass}>
			<div
				style={{
					background: bg,
					width: props.mobile ? "35px" : "20px",
					height: props.mobile ? "25px" : "20px",
				}}
				className='checkbox'
				onClick={props.toggle}>
				<CheckMark value={props.value} />
			</div>
			<div className='checkbox_text text-justify'>
				<span className={`pl-2 ${props.textClass}`}>{props.label}</span>
			</div>
		</div>
	);
}

export default CustomCheckBox;
