import React, { useEffect } from "react";
import Button from "../../../subcomponents/Button";
import TraitCard from "../../../subcomponents/onboarding/TraitCard";
import IMG1 from "../../../../assets/images/onboarding/personality/1.png";
import IMG2 from "../../../../assets/images/onboarding/personality/2.png";
import IMG3 from "../../../../assets/images/onboarding/personality/3.png";
import IMG4 from "../../../../assets/images/onboarding/personality/4.png";
import IMG5 from "../../../../assets/images/onboarding/personality/5.png";
import IMG6 from "../../../../assets/images/onboarding/personality/6.png";
import IMG7 from "../../../../assets/images/onboarding/personality/7.png";
import IMG8 from "../../../../assets/images/onboarding/personality/8.png";
import IMG9 from "../../../../assets/images/onboarding/personality/9.png";
import IMG10 from "../../../../assets/images/onboarding/personality/10.png";
import IMG11 from "../../../../assets/images/onboarding/personality/11.png";
import IMG12 from "../../../../assets/images/onboarding/personality/12.png";
import IMG13 from "../../../../assets/images/onboarding/personality/13.png";
import IMG14 from "../../../../assets/images/onboarding/personality/14.png";
import IMG15 from "../../../../assets/images/onboarding/personality/15.png";
import IMG16 from "../../../../assets/images/onboarding/personality/16.png";
import { Fragment } from "react";
import HelpIconComponent from "../../../HelpIconComponent";
import Checkbox from "../../../subcomponents/Checkbox";
import { useSelector, useDispatch } from "react-redux";
import * as setupRoleActions from "../../../../redux/actions/onboarding/SetupRoles";
import { useHistory } from "react-router-dom";

const personalities = [
	{
		id: 1,
		category: "Analysts",
		image: IMG1,
		name: "Architect",
		type: "INJT-A/ INTJ-T",
		description: "Imaginative and strategic thinker, with a plan of everything",
	},
	{
		id: 2,
		category: "Analysts",
		image: IMG2,
		name: "Logician",
		type: "INTP-A/ INTP-T",
		description: "Innovative inventors with an unquenchable thirst for knowledge.",
	},
	{
		id: 3,
		category: "Analysts",
		image: IMG3,
		name: "Commander",
		type: "ENTJ-A/ ENTJ-T",
		description: "Bold,imaginative and strong-willed leaders,always finding a way - or making one.",
	},
	{
		id: 4,
		category: "Analysts",
		image: IMG4,
		name: "Debator",
		type: "ENTP-A/ ENTP-T",
		description: "Smart and curious thinkers who cannot resist an intellectual challenge.",
	},
	{
		id: 5,
		category: "Diplomats",
		image: IMG5,
		name: "Advocate",
		type: "INFJ-A/ INFJ-T",
		description: "Quiet and mystical, yet very inspiring and tireless idealists.",
	},
	{
		id: 6,
		category: "Diplomats",
		image: IMG6,
		name: "Mediator",
		type: "INFP-A/ INFP-T",
		description: "Poetic, kind and altruistic people, always eager to help a good cause.",
	},
	{
		id: 7,
		category: "Diplomats",
		image: IMG7,
		name: "Protagonist",
		type: "ENFJ-A/ ENFJ-T",
		description: "Charismatic and inspiring leaders, able to mesmerize their listeners.",
	},
	{
		id: 8,
		category: "Diplomats",
		image: IMG8,
		name: "Campaigner",
		type: "ENFP-A/ ENFP-T",
		description: "Enthusiastic, creative and sociable free spirits, who can always find a reason to smile.",
	},

	{
		id: 9,
		category: "Sentinels",
		image: IMG9,
		name: "Logistician",
		type: "ISTJ-A/ ISTJ-T",
		description: "Practical and fact-minded individuals, whose reliability cannot be doubted.",
	},
	{
		id: 10,
		category: "Sentinels",
		image: IMG10,
		name: "Defender",
		type: "ISFJ-A/ ISFJ-T",
		description: "Very dedicated and warm protectors, always ready to defend their loved ones.",
	},
	{
		id: 11,
		category: "Sentinels",
		image: IMG11,
		name: "Executive",
		type: "ESTJ-A/ ESTJ-T",
		description: "Excellent administrators, unsurpassed at managing things - or people.",
	},
	{
		id: 12,
		category: "Sentinels",
		image: IMG12,
		name: "Consul",
		type: "ESFJ-A/ ESFJ-T",
		description: "Extraordinary caring, social and popular people, always eager to help.",
	},
	{
		id: 13,
		category: "Explorers",
		image: IMG13,
		name: "Virtuoso",
		type: "ISTP-A/ ISTP-T",
		description: "Bold and practical experimenters, masters of all kinds of tools.",
	},
	{
		id: 14,
		category: "Explorers",
		image: IMG14,
		name: "Adventurer",
		type: "ISFP-A/ ISFP-T",
		description: "Flexible and charming artists, always ready to explore and experience something new.",
	},
	{
		id: 15,
		category: "Explorers",
		image: IMG15,
		name: "Enterpreneur",
		type: "ESTP-A/ ESTP-T",
		description: "Smart, energetic and very perceptive people, who truly enjoy living on the edge.",
	},
	{
		id: 16,
		category: "Explorers",
		image: IMG16,
		name: "Entertainer",
		type: "ESFP-A/ ESFP-T",
		description: "Spontaneous, energetic and enthusiastic people - life is never boring around them.",
	},
];

const getPersonalitiesByCategory = () => {
	const sortByCategory = (arr) => {
		return arr.sort(function (a, b) {
			if (a.category < b.category) {
				return -1;
			}
			if (a.category > b.category) {
				return 1;
			}
			return 0;
		});
	};

	const plist = sortByCategory(personalities);
	const sections = [[]];
	let sectionIndex = 0;
	let current_category = plist[0].category;
	plist.forEach((element) => {
		if (element.category !== current_category) {
			current_category = element.category;
			sectionIndex++;
			sections[sectionIndex] = [];
		}
		sections[sectionIndex].push(element);
	});

	return sections;
};

export default function Personality(props) {
	const dispatch = useDispatch();

	const history = useHistory();

	const form = useSelector(
		(state) => state.setupRoles.setupRolePersonalityForm,
	);
	const { personalityMappings } = useSelector(
		(state) => state.setupRoles.setupRoleQualificationForm,
	);
	const isPersonalitySubmitted = useSelector(
		(state) => state.setupRoles.isPersonalitySubmitted,
	);

	const selectedPersonalities = [];
	form.sections.map((section) => {
		section.map((data) => {
			form.selectedPersonalityIds.map((selectedPersonalityId) => {
				if (data.id === selectedPersonalityId) {
					selectedPersonalities.push(data);
				}
			});
		});
	});

	form.selectedPersonalityIds.sort();

	useEffect(() => {
		if ((!props.completed && !isPersonalitySubmitted) || props.criteria) {
			dispatch(setupRoleActions.setSelectedPersonalityIds(personalityMappings));
			dispatch(
				setupRoleActions.setPersonalitiesSections(getPersonalitiesByCategory()),
			);
		}
	}, [dispatch, personalityMappings]);
	const handleTogglePersnalityIsChecked = () => {
		return dispatch(setupRoleActions.togglePersonalityIsChecked() );
	};


	const handleSubmit = () => {
		
		if(form.isChecked){
			if (
				form.selectedPersonalityIds.length >= 1 &&
				form.selectedPersonalityIds.length <= 4
			) {
				dispatch(setupRoleActions.submitPersonalityForm());
				if (props.dashboard) {
					dispatch(setupRoleActions.personalityCompleted());
					history.push("/dashboard/create-new-position/reference-checks");
				} else {
					props.history.push(`/onboarding/setupRoles/referenceChecks`);
				}
			} else {
				alert("You need to select atleast 1 and maximum 4 cards");
			}
		} else {
			dispatch(setupRoleActions.submitPersonalityForm());
			
			if (props.dashboard) {
				dispatch(setupRoleActions.personalityCompleted());
				history.push("/dashboard/create-new-position/reference-checks");
			} else {
				props.history.push(`/onboarding/setupRoles/referenceChecks`);
			}
			

		}
	};
	const handleClick = (selected, id) => {
		!selected
			? dispatch(setupRoleActions.addSelectedPersonalityId(id))
			: dispatch(setupRoleActions.removeSelectedPersonalityId(id));
	};

	return (
		<div>
			{props.completed ? (
				<Fragment>
					<div className='d-flex' style={{ marginBottom: "10px" }}>
						<span className='headline3'>Personality Assesment </span>
					</div>
					<div>
						<div className='container'>
							<div className='row no-gutters justify-content-start'>
								{selectedPersonalities.map((selectedPersonality, index) => (
									<div
										key={index}
										className='col-xs-3'
										style={{ paddingRight: "10px" }}>
										<TraitCard completed>
											<img
												src={selectedPersonality.image}
												style={{ marginBottom: "15px" }}
											/>
											<div>{selectedPersonality.name}</div>
											<div className='headline3'>
												{selectedPersonality.type}
											</div>
										</TraitCard>
									</div>
								))}
							</div>
						</div>
					</div>
				</Fragment>
			) : (
				<Fragment>
					<div className='d-flex justify-content-between'>
						<div style={{ marginBottom: "20px" }} className='headline1'>
							Setup Role
						</div>
						<div>
							<HelpIconComponent />
						</div>
					</div>
					<div style={{ marginBottom: "10px" }}>
						<span className='headline3'>Personality Assesment </span>
						{form.isChecked ?
							<span
								className='description'
								style={{ color: "#96999C", paddingBottom: "20px" }}>
							(Please select atleast 4 card)
							</span> : null }
						
					</div>
					<Checkbox
						style={{ marginBottom: "30px" }}
						value={form.isChecked}
						toggle={() => handleTogglePersnalityIsChecked()}
						className='p2'
						label='Would you like to do personality assessments for this role?'
					/>

					{form.isChecked ? 
						<div> 
							{form.sections.map((section, i) => {
								return (
									<div key={i}>
										<div className='container no-gutters pr-0 pl-0'>
											<div
												className='headline3'
												style={{ marginBottom: "10px", marginTop: "10px" }}>
												{section[0].category}
											</div>
											<div className='row justify-content-center'>
												{section.map((data, j) => (
													<div key={j} className='col-xs-3'>
														<TraitCard
															onClick={(e) => handleClick(e, data.id)}
															selected={
																form.selectedPersonalityIds.find(
																	(p) => p === data.id,
																)
																	? true
																	: null
															}>
															<img
																src={data.image}
																style={{ marginBottom: "15px" }}
															/>
															<div>{data.name}</div>
															<div className='headline3'>{data.type}</div>
															<div className='description hide'>
																{data.description}
															</div>
														</TraitCard>
													</div>
												))}
											</div>
										</div>
									</div>
								);
							})}

					

							<div style={{ height: "30px" }} />
							<div className='d-flex w100 justify-content-center'>
								<Button
									rightArrow
									label='Next'
									textClass='b2'
									className='button-large'
									style={{ padding: "6.5px 69px" }}
									onClick={handleSubmit}
								/>
							</div> </div>
						: <Button
							whiteButton
							className='white-button-sm'
							label='Skip'
							onClick={handleSubmit}
						/>
					}
			
				</Fragment>
			)}
		</div>
	);
}
