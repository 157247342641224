import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import SearchIcon from "../../../assets/icons/Search/search.svg";
import * as inviteCandidateActions from "../../../redux/actions/onboarding/inviteCandidate";

export default function SearchInput(props) {
	const dispatch = useDispatch();

	const search = useSelector((state) => state.inviteCandidateReducer.search);
	const user = useSelector((state) => state.user.user);

	const handleSearch = (event) => {
		if (user.tenantId !== 0) {
			if (event.keyCode === 13 && search) {
				dispatch(inviteCandidateActions.getRoles(search));
			}
		}
	};

	const handleChange = (event) => {
		if (user.tenantId !== 0) {
			dispatch(inviteCandidateActions.handleChange(event.target));
		}
	};

	useEffect(() => {
		if (!search && user.tenantId !== 0) {
			dispatch(inviteCandidateActions.getRoles());
		}
	}, [dispatch, search, user]);

	return (
		<div className='search-box' style={props.style}>
			{
				props.candidateSearch ? 
					<>
						<input
							placeholder='Search'
							className='search-input'
							name='search'
							value={props.search}
							onKeyUp={props.onKeyUp}
							onChange={props.onChange}
						/>
						<img src={SearchIcon} alt='' />
					</>
					:

					<>
						<input
							placeholder='Search'
							className='search-input'
							name='search'
							value={search}
							onKeyUp={(e) => handleSearch(e)}
							onChange={(e) => handleChange(e)}
						/>
						<img src={SearchIcon} alt='' />
					</>
			}
		</div>
	);
}
