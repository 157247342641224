import React from "react";
import PricingScreenMobile from "./PricingScreenMobile";
import PricingScreenWeb from "./PricingScreenWeb";
import { useSelector} from "react-redux";
import gif from "../../assets/gifs/rnr-loading.gif";


export default function PricingScreen() {
	const userProfile = useSelector((state) => state.formsStatus.userProfile);
	// console.log("isOnboarding at pricing" , userProfile.is_onboarding) ;

	const isLoader = useSelector(state => state.user.isLoader);
	// console.log("isLoader at pricing is " , isLoader);
	
	// console.log(" at pricing  ");
	return (
		<div>
			{!userProfile.is_onboarding ?
				<>
					{isLoader ? 
						<div
							className='d-flex align-items-center justify-content-center'
							style={{
								backgroundColor: "rgb(253, 253, 253)",
								minHeight: "100vh",
							}}>
							<img src={gif} width='50%' height='50%' />
						</div>
						:
						<>
							<div className="SignIn-DesktopView">
								<PricingScreenWeb/>
							</div>
							<div className="SignIn-MobileView">
								<PricingScreenMobile/>
							</div>
						</>
					}
				</>
				:
				<>
					<div className="SignIn-DesktopView">
						<PricingScreenWeb/>
					</div>
					<div className="SignIn-MobileView">
						<PricingScreenMobile/>
					</div>
				</>
			}
            
		
		</div>
	);
}
