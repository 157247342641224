import React from "react";
import Form from "../../components/dashboard/Signin/Form";
import RnrLogo from "../../assets/icons/dashboard/logo.png";
import Signininfo from "../../components/dashboard/Signin/SigninInfo";
import { connect } from "react-redux";
import { useHistory } from "react-router";

function Link({ name, url }) {
	return (
		<a className='helper_link' href={url}>
			{name}
		</a>
	);
}

function SignInWeb() {

	const history = useHistory();

	return (
		<div className='signin_container'>
			<div className='main-row row row-no-gutters'>
				<div className='left_box col-xs-7 col-sm-7 col-md-7 col-lg-7 no-gutters'>
					<div className='content_wrapper d-flex flex-column justify-content-between'>
						<div
							className='d-flex justify-flex-start'
							style={{ paddingTop: "20px", paddingLeft: "80px" }}>
							<img
								onClick={() => history.push("/")}
								src={RnrLogo}
								width='237px'
								style={{ cursor: "pointer" }}
							/>
						</div>
						<div className='d-flex justify-content-center'>
							<Form />
						</div>
						<div className='links'>
							<Link name='RanknRole Inc, 2021' url='google.com' />
							<Link name='Privacy Policy' url='google.com' />
							<Link name='Terms and conditions' url='google.com' />
						</div>
					</div>
				</div>
				<div className='right_box col-xs-5 col-sm-5 col-md-5 col-lg-5 no-gutters'>
					<div className='background'></div>
					<div className='content_wrapper d-flex flex-column justify-content-between'>
						<div></div>
						<Signininfo />
						<div className='links'>
							<Link name='Info' url='google.com' />
							<Link name='Any Questions?' url='google.com' />
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

const mapStateToProps = (state) => {
	return { user: state.user };
};

export default connect(mapStateToProps, null)(SignInWeb);
