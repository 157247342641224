import { instance, secure_instance } from "../../../api/axios/axios-config";

export const SAVE_SETUP_ROLES_FORM = "[Setup Roles] SAVE_SETUP_ROLES_FORM";
export const RESET_ROLES_FORM = "[Setup Roles] RESET_ROLES_FORM";
export const GET_ROLES = "[Setup Roles] GET_ROLES";
export const GET_CATEGORY_SUGGESTIONS =
	"[Setup Roles] GET_CATEGORY_SUGGESTIONS";
export const GET_SELECTED_CATEGORY =
	"[Setup Roles] GET_SELECTED_CATEGORY";
export const GET_CATEGORY_MAPPINGS =
	"[Setup Roles] GET_CATEGORY_MAPPINGS";
export const SET_ROLES_FORM = "[Setup Roles] SET_ROLES_FORM";
export const SET_SELECTED_ROLE_ID = "[Setup Roles] SET_SELECTED_ROLE_ID";
export const SET_ERROR_ALERT = "[Setup Roles] SET_ERROR_ALERT";
export const SET_PERSONALITIES_SECTION =
	"[Setup Roles] SET_PERSONALITIES_SECTION";
export const ADD_SELECTED_PERSONALITY =
	"[Setup Roles] ADD_SELECTED_PERSONALITY";
export const REMOVE_SELECTED_PERSONALITY =
	"[Setup Roles] REMOVE_SELECTED_PERSONALITY";
export const SET_NUMBER_OF_REFERENCES =
	"[Setup Roles] SET_NUMBER_OF_REFERENCES";
export const REMOVE_NUMBER_OF_REFERENCES =
	"[Setup Roles] REMOVE_NUMBER_OF_REFERENCES";
export const TOGGLE_REFERENCE_IS_CHECKED =
	"[Setup Roles] TOGGLE_REFERENCE_IS_CHECKED";
export const TOGGLE_PERSONALITY_IS_CHECKED =
	"[Setup Roles] TOGGLE_PERSONALITY_IS_CHECKED";
export const SWITCH_AUTO_MAIL =
	"[Setup Roles] SWITCH_AUTO_MAIL";
export const GET_SPECIFIC_ROLE_TEMPLATE =
	"[Setup Roles] GET_SPECIFIC_ROLE_TEMPLATE";
export const SET_QUALIFICATION_MAPPINGS_SLIDER =
	"[Setup Roles] SET_QUALIFICATION_MAPPINGS_SLIDER";
export const SET_QUALIFICATION_MAPPINGS_SELECT =
	"[Setup Roles] SET_QUALIFICATION_MAPPINGS_SELECT";
export const ADD_QUALIFICATION_MAPPINGS_SELECT =
	"[Setup Roles] ADD_QUALIFICATION_MAPPINGS_SELECT";
export const REMOVE_QUALIFICATION_MAPPINGS_SELECT =
	"[Setup Roles] REMOVE_QUALIFICATION_MAPPINGS_SELECT";
export const SET_SELECTED_PERSONALITY_IDS =
	"[Setup Roles] SET_SELECTED_PERSONALITY_IDS";
export const SUBMIT_QUALIFICATION_FORM =
	"[Setup Roles] SUBMIT_QUALIFICATION_FORM";
export const SUBMIT_PERSONALITY_FORM = "[Setup Roles] SUBMIT_PERSONALITY_FORM";
export const SUBMIT_REFERENCE_CHECK_FORM =
	"[Setup Roles] SUBMIT_REFERENCE_CHECK_FORM";
export const ADD_SETUP_ROLE_FORM_START =
	"[Setup Roles] ADD_SETUP_ROLE_FORM_START";
export const ADD_SETUP_ROLE_FORM_END = "[Setup Roles] ADD_SETUP_ROLE_FORM_END";
export const MENU_CLICKED = "[Setup Roles] MENU_CLICKED";
export const ROLE_ALREADY_EXISTS = "[Setup Roles] ROLE_ALREADY_EXISTS";
export const DASHBOARD_ROLE_COMPLETED =
	"[Setup Roles] DASHBOARD_ROLE_COMPLETED";
export const QUALIFICATION_COMPLETED = "[Setup Roles] QUALIFICATION_COMPLETED";
export const PERSONALITY_COMPLETED = "[Setup Roles] PERSONALITY_COMPLETED";
export const REFERENCE_CHECK_COMPLETED =
	"[Setup Roles] REFERENCE_CHECK_COMPLETED";
export const GET_SPECIFIC_ROLE_TEMPLATE_FOR_RESULT =
	"[Setup Roles] GET_SPECIFIC_ROLE_TEMPLATE_FOR_RESULT";
export const GET_TEST_PACKS = "[Setup Roles] GET_TEST_PACKS";
export const ADD_SETUP_ROLE_FORM_FAILED =
	"[Setup Roles] ADD_SETUP_ROLE_FORM_FAILED";
export const SET_SETUP_ROLE_FORM_FOR_EDIT =
	"[Setup Roles] SET_SETUP_ROLE_FORM_FOR_EDIT";
export const UPDATE_SETUP_ROLE_FORM_START =
	"[Setup Roles] UPDATE_SETUP_ROLE_FORM_START";
export const UPDATE_SETUP_ROLE_FORM_END =
	"[Setup Roles] UPDATE_SETUP_ROLE_FORM_START";
export const UPDATE_SETUP_ROLE_FORM_FAILED =
	"[Setup Roles] UPDATE_SETUP_ROLE_FORM_START";
export const HANDLE_SELECT_CHANGE = "[Setup Roles] HANDLE_SELECT_CHANGE";

export const saveSetupRoleForm = (data) => {
	return {
		type: SAVE_SETUP_ROLES_FORM,
		payload: data,
	};
};

export const resetForm = () => {
	return {
		type: RESET_ROLES_FORM,
	};
};

export const getRoleTemplates = () => {
	return async (dispatch) => {
		try {
			const request = await secure_instance.request({
				url: "v1/role_templates/?qualification=yes",
				method: "Get",
			});

			dispatch({
				type: GET_ROLES,
				payload: request.data,
			});
		} catch (e) {
			//
		}
	};
};

export const getCategories = () => {
	return async (dispatch) => {
		console.log("coming to getCategories");
		try {
			const request = await instance.request({
				url: "v1/category_types",
				method: "Get",
			});

			dispatch({
				type: GET_CATEGORY_SUGGESTIONS,
				payload: request.data,
			});
		} catch (e) {
			//
		}
	};
};

export const getCategoryMappings = () => {
	return async (dispatch) => {
		console.log("coming to getCategoryMappings");
		try {
			const request = await instance.request({
				url: "v1/category_mappings",
				method: "Get",
			});

			dispatch({
				type: GET_CATEGORY_MAPPINGS,
				payload: request.data,
			});
		} catch (e) {
			//
		}
	};
};

export const setSetupRoleForm = (target) => {
	return {
		type: SET_ROLES_FORM,
		payload: target,
	};
};

export const handleSelectChange = (target) => {
	return {
		type: HANDLE_SELECT_CHANGE,
		payload: target,
	};
};

export const setErrorAlert = (data) => {
	return {
		type: SET_ERROR_ALERT,
		payload: data,
	};
};

export const setPersonalitiesSections = (personalities) => {
	return {
		type: SET_PERSONALITIES_SECTION,
		payload: personalities,
	};
};

export const addSelectedPersonalityId = (personalityId) => {
	return {
		type: ADD_SELECTED_PERSONALITY,
		payload: personalityId,
	};
};

export const removeSelectedPersonalityId = (personalityId) => {
	return {
		type: REMOVE_SELECTED_PERSONALITY,
		payload: personalityId,
	};
};

export const setNumberOfReferences = (numberOfReferences) => {
	return {
		type: SET_NUMBER_OF_REFERENCES,
		payload: numberOfReferences,
	};
};

export const removeNumberOfReferences = () => {
	return {
		type: REMOVE_NUMBER_OF_REFERENCES,
	};
};

export const toggleReferenceIsChecked = () => {
	return {
		type: TOGGLE_REFERENCE_IS_CHECKED,
	};
};
export const togglePersonalityIsChecked = () => {
	return {
		type: TOGGLE_PERSONALITY_IS_CHECKED
	};
};
export const switchAutoMail = () => {
	return {
		type: SWITCH_AUTO_MAIL ,
	};
};

export const getRoleTemplate = (selectedRoleTemplateId) => {
	return async (dispatch) => {
		try {
			const request = await secure_instance({
				url: `v1/role_templates/${selectedRoleTemplateId}`,
				method: "Get",
			});

			dispatch({
				type: GET_SPECIFIC_ROLE_TEMPLATE,
				payload: request.data,
			});
		} catch (e) {
			//
		}
	};
};

export const setQualificationMappingsSlider = (data) => {
	return {
		type: SET_QUALIFICATION_MAPPINGS_SLIDER,
		payload: data,
	};
};

export const setQualificationMappingsSelect = (data) => {
	return {
		type: SET_QUALIFICATION_MAPPINGS_SELECT,
		payload: data,
	};
};

export const addQualificationMappingsSelect = (data) => {
	return {
		type: ADD_QUALIFICATION_MAPPINGS_SELECT,
		payload: data,
	};
};

export const removeQualificationMappingsSelect = (qualification) => {
	return {
		type: REMOVE_QUALIFICATION_MAPPINGS_SELECT,
		payload: qualification,
	};
};

export const setSelectedPersonalityIds = (data) => {
	return {
		type: SET_SELECTED_PERSONALITY_IDS,
		payload: data,
	};
};

export const submitSetupRole = (data, history, dashboard ) => {
	return async (dispatch) => {
		try {
			dispatch({
				type: ADD_SETUP_ROLE_FORM_START,
			});

			const request = await secure_instance.request({
				url: "v1/positions/",
				method: "Post",
				data
			
				
			});

			dispatch({
				type: ADD_SETUP_ROLE_FORM_END,
				payload: request.data,
			});
			if (dashboard) {
				history.push("/dashboard");
			}
		} catch (e) {
			dispatch({
				type: ADD_SETUP_ROLE_FORM_FAILED,
			});
		}
	};
};

export const submitQualificationForm = () => {
	return {
		type: SUBMIT_QUALIFICATION_FORM,
	};
};

export const submitPersonalityForm = () => {
	return {
		type: SUBMIT_PERSONALITY_FORM,
	};
};

export const submitReferenceCheckForm = () => {
	return {
		type: SUBMIT_REFERENCE_CHECK_FORM,
	};
};

export const setupRolesMenuClicked = () => {
	return {
		type: MENU_CLICKED,
	};
};

export const roleAlreadyExists = () => {
	return {
		type: ROLE_ALREADY_EXISTS,
	};
};

export const dashboardSetupRoleCompleted = () => {
	return {
		type: DASHBOARD_ROLE_COMPLETED,
	};
};

export const qualificationCompleted = () => {
	return {
		type: QUALIFICATION_COMPLETED,
	};
};

export const personalityCompleted = () => {
	return {
		type: PERSONALITY_COMPLETED,
	};
};

export const referenceCheckCompleted = () => {
	return {
		type: REFERENCE_CHECK_COMPLETED,
	};
};

export const getRoleTemplateForResult = (selectedRoleTemplateId) => {
	return async (dispatch) => {
		try {
			const request = await secure_instance({
				url: `v1/role_templates/${selectedRoleTemplateId}`,
				method: "Get",
			});

			dispatch({
				type: GET_SPECIFIC_ROLE_TEMPLATE_FOR_RESULT,
				payload: request.data,
			});
		} catch (e) {
			//
		}
	};
};

export const getTestPacks = () => {
	return async (dispatch) => {
		try {
			const request = await secure_instance.request({
				url: "v1/test_packs/",
				method: "Get",
			});

			dispatch({
				type: GET_TEST_PACKS,
				payload: request.data,
			});
		} catch (e) {
			//
		}
	};
};

export const setSetupRoleFormForEdit = (data) => {
	return {

		type: SET_SETUP_ROLE_FORM_FOR_EDIT,
		payload: data,
	};
};

export const updateSetupRole = (data, history, dashboard, positionId) => {
	return async (dispatch) => {
		try {
			dispatch({
				type: UPDATE_SETUP_ROLE_FORM_START,
			});

			const request = await secure_instance.request({
				url: `v1/positions/${positionId}`,
				method: "Patch",
				data,
			});

			dispatch({
				type: UPDATE_SETUP_ROLE_FORM_END,
				payload: request.data,
			});

			if (dashboard) {
				history.push("/dashboard");
			}
		} catch (e) {
			dispatch({
				type: UPDATE_SETUP_ROLE_FORM_FAILED,
			});
		}
	};
};
