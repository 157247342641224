import React from "react";
import Features from "../../components/FeatureComponent";
import Footer from "../../components/Footer/Footer";
import FeatureHeader from "../../components/subcomponents/FeatureHeader";
import Navbar from "../../components/subcomponents/WebNavbar/Navbar";
import logo2 from "../../assets/icons/features/02.png";
import logo3 from "../../assets/icons/features/03.png";
import logo1 from "../../assets/icons/features/01.png";
import logo4 from "../../assets/icons/features/04.png";
import logo5 from "../../assets/icons/features/05.png";
import logo6 from "../../assets/icons/features/06.png";
import Background from "../../assets/images/Backgrounds/FAQs.svg";
import Background2 from "../../assets/images/Backgrounds/Background.png";
import { useHistory } from "react-router";
import HighlightedFeatures from "../../components/HighlightedFeatures";
import FAQComponent from "../../components/FAQComponent";


const features =
[
	{
		logo:logo6,
		name:"Lorum Ipsum",
		details:"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed erat nibh tristique ipsum.",
	},
	{
		logo:logo5,
		name:"Lorum Ipsum",
		details:"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed erat nibh tristique ipsum.",
	},
	{
		logo:logo3,
		name:"Lorum Ipsum",
		details:"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed erat nibh tristique ipsum.",
	},
	{
		logo:logo4,
		name:"Lorum Ipsum",
		details:"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed erat nibh tristique ipsum.",
	},
	{
		logo:logo2,
		name:"Lorum Ipsum",
		details:"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed erat nibh tristique ipsum.",
	},
	{
		logo:logo1,
		name:"Lorum Ipsum",
		details:"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed erat nibh tristique ipsum.",
	},
	{
		logo:logo6,
		name:"Lorum Ipsum",
		details:"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed erat nibh tristique ipsum.",
	},
	{
		logo:logo5,
		name:"Lorum Ipsum",
		details:"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed erat nibh tristique ipsum.",
	},
	{
		logo:logo3,
		name:"Lorum Ipsum",
		details:"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed erat nibh tristique ipsum.",
	},
	{
		logo:logo4,
		name:"Lorum Ipsum",
		details:"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed erat nibh tristique ipsum.",
	},
	{
		logo:logo2,
		name:"Lorum Ipsum",
		details:"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed erat nibh tristique ipsum.",
	},
	{
		logo:logo1,
		name:"Lorum Ipsum",
		details:"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed erat nibh tristique ipsum.",
	},

];

export default function FeaturesPage() {
	const history = useHistory();

	return (
		<div>
			<Navbar  style={{position:"absolute"}} textColor="#00B4D8"/>
			<FeatureHeader />
			<HighlightedFeatures/>
			<Features features={features} title="All Features" color="white" className="hero3" style={{backgroundImage:`url(${Background})`}}/>
			<FAQComponent features/>
			<div style={{backgroundImage:`url(${Background2})`, height:"338px", width:"100%"}} className="d-flex flex-column align-items-center">
				<div className="mt-5">
					<span className="hero3">Get Started Now</span>
				</div>
				<div className="mt-4 mb-5">
					<span className="p1">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc odio in et, lectus sit lorem id integer.</span>
				</div>
				<div style={{width:"307px", height:"68px", borderRadius:"10px", padding:"10px 60px", background: "#00B4D8", margin:"20px 0px"}} className="d-flex flex-column justify-content-center align-items-center pointer" onClick={() => history.push("/signup")}>
					<span className="b1" style={{color:"white"}}>Get Started</span>
					<span className="description" style={{color:"white"}}>10 Free Assessments on Sign up</span>
				</div>
			</div>
			<Footer/>
		</div>
	);
}
