import * as Actions from "../../actions/onboarding/teamSetup";
import { teamMemberRoles } from "../../../utilities/Constants";

const initialState = {
	accessRoles: [],
	allClients: [],
	teamSetupTable: [],
	loading: false,
	failure: false, // flag to detect failure
};

const TeamSetupReducer = (state = initialState, action) => {
	const data = action.payload;

	switch (action.type) {
		case Actions.GET_ACCESS_ROLES:
			return (state = {
				...state,
				accessRoles: teamMemberRoles,
			});

		case Actions.GET_ALL_CLIENTS:
			return (state = {
				...state,
				allClients: data,
			});

		case Actions.SET_TEAM_SETUP_TABLE:
			return (state = {
				...state,
				teamSetupTable: data,
			});

		case Actions.ADD_TEAM_MEMBER_START:
			return (state = {
				...state,
				loading: true,
			});

		case Actions.ADD_TEAM_MEMBER_FAILED:
			return (state = {
				...state,
				loading: false,
				failure: !state.failure,
			});

		case Actions.ADD_TEAM_MEMBER_END:
			return (state = {
				...state,
				loading: false,
				teamSetupTable: state.teamSetupTable.map((row) => {
					if (row[1] === data.email) {
						row[0] = data.data.user_id;
						return row;
					}
					return row;
				}),
			});

		case Actions.DELETE_TEAM_MEMBER_START:
			return (state = {
				...state,
				loading: true,
			});

		case Actions.DELETE_TEAM_MEMBER_END:
			return (state = {
				...state,
				loading: false,
			});

		case Actions.DELETE_TEAM_MEMBER_FAILED:
			return (state = {
				...state,
				loading: false,
			});

		case Actions.UPDATE_TEAM_MEMBER_START:
			return (state = {
				...state,
				loading: true,
			});

		case Actions.UPDATE_TEAM_MEMBER_END:
			return (state = {
				...state,
				loading: false,
			});

		case Actions.UPDATE_TEAM_MEMBER_FAILED:
			return (state = {
				...state,
				loading: false,
			});

		case Actions.GET_TEAM_MEMBERS:
			state.teamSetupTable = [];

			data.map((roleAssignment) => {
				state.allClients.length > 0 ?
					state.teamSetupTable.push([
						roleAssignment.user_id,
						roleAssignment.user.email,
						roleAssignment.role_id === 12
							? { id: roleAssignment.role_id, option: "Admin" }
							: roleAssignment.role_id === 14
								? { id: roleAssignment.role_id, option: "Recruiter" }
								: roleAssignment.role_id === 15
									? { id: roleAssignment.role_id, option: "Candidate" }
									: roleAssignment.role_id === 16
										? { id: roleAssignment.role_id, option: "Guest" }
										: { id: roleAssignment.role_id, option: "Public" },
						roleAssignment.role_scopes.map((roleScope) => {
							return { id: roleScope.client_id, option: state.allClients.find(c => c.id === roleScope.client_id)?.name };
						}),
					]) :
					state.teamSetupTable.push([
						roleAssignment.user_id,
						roleAssignment.user.email,
						roleAssignment.role_id === 12
							? { id: roleAssignment.role_id, option: "Admin" }
							: roleAssignment.role_id === 14
								? { id: roleAssignment.role_id, option: "Recruiter" }
								: roleAssignment.role_id === 15
									? { id: roleAssignment.role_id, option: "Candidate" }
									: roleAssignment.role_id === 16
										? { id: roleAssignment.role_id, option: "Guest" }
										: { id: roleAssignment.role_id, option: "Public" },
					]);
			});

			return (state = {
				...state,
				teamSetupTable: state.teamSetupTable,
			});

		default:
			return state;
	}
};

export default TeamSetupReducer;
