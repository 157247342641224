import React from "react";
import { useDispatch } from "react-redux";
import Circle from "../../../assets/icons/notification/Dashboard/Notifications/circle.svg";
import * as notificationActions from "../../../redux/actions/Notification/notification.actions";
import RnrLogo from "../../../assets/icons/logo/1.png";

export default function NotificationCard(props) {
	const dispatch = useDispatch();

	const handleNotificationClicked = (notification) => {
		dispatch(notificationActions.changeStatusToRead(notification._id));
	};
	return (
		<>
			<div
				className='notification-card'
				style={{
					width: props.mobile ? "calc(100vw - 10px)" : "100%",
				}}
				onClick={() => handleNotificationClicked(props.notification)}>
				{props.notification.status === "READ" ? (
					<div
						style={{
							marginInline: "10px",
							width: "11px",
							height: "11px",
						}}></div>
				) : (
					<div style={{ marginInline: "10px" }}>
						<img src={Circle} alt='' style={{ width: "8px", height: "8px" }} />
					</div>
				)}
				<div>
					<img
						src={props.image || RnrLogo}
						alt=''
						style={{ width: "45px", height: "45px", borderRadius: "50%" }}
					/>
				</div>
				<span
					style={{
						padding: "0px 10px 0px 10px",
						minWidth: "230px",
						maxWidth: "230px",
					}}
					className='description text-justify'>
					{props.description}
				</span>
				<div style={{ marginTop: "-15px" }}>
					<span style={{ paddingRight: "10px" }} className='description'>
						{props.date}
					</span>
				</div>
			</div>
		</>
	);
}
