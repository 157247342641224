import React, { useEffect, useState } from "react";
import Button from "../../subcomponents/Button";
import CandidateSummaryCard from "../../subcomponents/dashboard/CandidateSummaryCard";
import QualificationPointsCard from "../../subcomponents/dashboard/QualificationPointsCard";
import ReviewTestCard from "../../subcomponents/dashboard/ReviewTestCard";
import { useSelector, useDispatch } from "react-redux";
import * as candidateTestingActions from "../../../redux/actions/Candidate/candidate-testing.actions";
import ReferenceCard from "../../subcomponents/dashboard/ReferenceCard";
import assessmentStatus from "../../../utilities/Constants";
import BlankPicture from "../../../assets/icons/Userpic/userprofile.svg";
import share from "../../../assets/icons/basic/Share.svg";
import ShareAssessmentModal from "./ShareAssessmentModal";
import PersonalityAssessmentCard from "../../subcomponents/dashboard/PersonalityAssessmentCard";

export default function CandidateData(props) {
	const dispatch = useDispatch();
	const [isShareModal , setShareModal] = useState(false);

	const previousTestRightPanel = useSelector(
		(state) => state.candidateTesting.previousTestRightPanel,
	);
	console.log(previousTestRightPanel);

	const isPersonality = useSelector(
		(state) => state.candidateTesting.isPersonality,
	);

	const refrranceCount = useSelector(
		(state) => state.candidateTesting.refrranceCount,
	);

	const candidateUserProfile = useSelector(
		(state) => state.candidateTesting.candidateUserProfile,
	);

	const handleShareModal = () => {

		setShareModal(!isShareModal);
	};

	useEffect(() => {
		if (previousTestRightPanel?.user_id) {
			dispatch(
				candidateTestingActions.getCandidateUserProfile(
					previousTestRightPanel.user_id,
				),
			);
		}
	}, [dispatch, previousTestRightPanel]);

	useEffect(() => {
		if (previousTestRightPanel?.id) {
			dispatch(
				candidateTestingActions.getSingleAssessmentSession(
					previousTestRightPanel?.id
				),
			);
		}
	}, [dispatch, previousTestRightPanel ]);

	const handleShortlistClicked = () => {
		dispatch(
			candidateTestingActions.setCandidateStatus(
				previousTestRightPanel._id,
				assessmentStatus.SHORTLISTED,
			),
		);
	};

	const handleNotConsiderClicked = () => {
		dispatch(
			candidateTestingActions.setCandidateStatus(
				previousTestRightPanel._id,
				assessmentStatus.NOT_CONSIDERED,
			),
		);
	};

	return (
		<div>
			<div
				className='w-100 d-flex justify-content-between'
				style={{ paddingBottom: "25px" }}>
				<div
					className='d-flex align-items-center'
					style={{ marginTop: "-30px" }}>
					<img
						src={candidateUserProfile?.logo || BlankPicture}
						alt=''
						style={{ width: "75px", height: "75px", borderRadius: (candidateUserProfile?.logo) ? "50%" : "0%" }}
					/>
					<div
						className='d-flex flex-column align-items-start'
						style={{ paddingLeft: "15px" }}>
						<span className='headline2'>
							{candidateUserProfile?.firstName || "firstname" +
								" " +
								candidateUserProfile?.lastName || "lastname" }
						</span>
						<span className='description' style={{ color: "#96999C" }}>
							{props.time || "1 day"} ago
						</span>
					</div>
				</div>
				{!props.candidate &&
					!(
						previousTestRightPanel?.status ===
							assessmentStatus.NOT_CONSIDERED ||
						previousTestRightPanel?.status === assessmentStatus.SHORTLISTED
					) && (
					<div style={{ display: props.isExtended ? "flex" : "block" }}>
						<div
							style={{
								paddingBottom: "10px",
								paddingRight: props.isExtended ? "10px" : "0px",
							}}>
							<Button
								dashboardbtn
								style={{ backgroundColor: "#E0FAFF", padding: "5px 37px" }}
								textStyle={{ color: "#00D69A" }}
								label='Shortlist'
								onClick={handleShortlistClicked}
							/>
						</div>
						<div>
							<Button
								dashboardbtn
								style={{ backgroundColor: "#FFE1E1", padding: "5px 10px" }}
								textStyle={{ color: "#FC4848" }}
								label='Not Considered'
								onClick={handleNotConsiderClicked}
							/>
						</div>
					</div>
				)}

				{props.candidate && (
					<div style={{ display: props.isExtended ? "flex" : "block" }}>
						{previousTestRightPanel?.status ===
							assessmentStatus.SHORTLISTED && (
							<div
								style={{
									paddingBottom: "10px",
									paddingRight: props.isExtended ? "10px" : "0px",
								}}>
								<Button
									dashboardbtn
									style={{ backgroundColor: "#E0FAFF", padding: "5px 37px", cursor: "default" }}
									textStyle={{ color: "#00D69A" }}
									label='Shortlist'
								/>
							</div>
						)}
						{previousTestRightPanel?.status ===
							assessmentStatus.NOT_CONSIDERED && (
							<div
								style={{
									paddingBottom: "10px",
									paddingRight: props.isExtended ? "10px" : "0px",
								}}>
								<Button
									dashboardbtn
									style={{ backgroundColor: "#FFE1E1", padding: "5px 10px", cursor: "default" }}
									textStyle={{ color: "#FC4848" }}
									label='Not Considered'
								/>
							</div>
						)}
					</div>
				)}
			</div>
			{!props.candidate &&
			<div>
				<div className="d-flex align-items-center justify-content-end ">
					<div className="d-flex align-items-center justify-content-between"
						style={{

							backgroundColor:"#00B4D8" ,
							borderRadius:"50px" ,
							padding:"5px 15px",
							marginBottom:"10px",
							marginTop:"15px",
							cursor:"pointer"

						}}
						onClick={handleShareModal}
					>
						<span className="p1" style={{color:"#FFFFFF" , marginRight:"5px"}}> Share Assessment Results </span>
						<img src={share} />
					</div>
				</div>
				<div>
					{isShareModal && <ShareAssessmentModal report  setShareModal= {setShareModal}/> }

				</div>
			</div>

			}


			<div>
				<CandidateSummaryCard isExtended={props.isExtended} />
			</div>
			<div style={{ paddingTop: "30px" }}>
				<QualificationPointsCard />
			</div>
			{isPersonality && <div style={{ paddingTop: "30px" }}>
				<PersonalityAssessmentCard pers_match={previousTestRightPanel?.pers_match}/>
			</div>}
			{isPersonality && <div style={{ paddingTop: "30px" }}>
				<ReviewTestCard />
			</div>
			}
			{refrranceCount !== 0 && <div style={{ paddingTop: "30px" }}>
				<ReferenceCard  assessId = {props.candidate ? previousTestRightPanel?.id : previousTestRightPanel?._id}/>
			</div>}
		</div>
	);
}
