import React, { useEffect } from "react";
import Button from "../../subcomponents/Button";
import Input from "../../subcomponents/Input";
import RoleTemplateCard from "../../common/RoleTemplateCard";
import Qualification from "../../onboarding/screens/SetupRoles/Qualification";
import Personality from "../../onboarding/screens/SetupRoles/PersonalityAssesment";
import Reference from "../../onboarding/screens/SetupRoles/ReferenceChecks";
import { useSelector, useDispatch } from "react-redux";
import * as clientFilterActions from "../../../redux/actions/Dashboard/ClientFilter.actions";
import { useHistory } from "react-router-dom";
import { ReactComponent as Location } from "../../../assets/icons/basic/pin.svg";

export default function CriteriaDetails(props) {
	const dispatch = useDispatch();
	const history = useHistory();

	const position = useSelector(
		(state) => state.clientFilter.positionForCandidateRightPanel,
	);

	const roleTemplate = useSelector(
		(state) => state.clientFilter.roleTemplateForCriteria,
	);

	const handleEditClicked = () => {
		dispatch(clientFilterActions.isEditRole());
		props.onAllCross();
		history.push("/dashboard/create-new-position/setup-roles");
	};

	useEffect(() => {
		if (position) {
			dispatch(clientFilterActions.getRoleTemplate(position?.role_template_id));
		}
	}, [dispatch, position]);

	return (
		<div>
			<div
				className='d-flex justify-content-between align-items-center'
				style={{ marginTop: "-5px" }}>
				<div className='d-flex flex-column align-items-start'>
					<div>
						<span className='headline2'>Role Criteria</span>
					</div>
					<div
						className='d-flex align-items-start'
						style={{ paddingRight: "30px" }}>
						<span className='headline1 text-justify'>
							{position?.title || "Title"}
						</span>
					</div>
					<div className='d-flex' style={{ paddingTop: "3px" }}>
						<Location style={{ marginRight: "3px" }} />
						<span className='description'>
							{(position?.city || "city") + ", " + (position?.country || "country")}
						</span>
					</div>
				</div>
				<div>
					<Button
						dashboardbtn
						style={{ backgroundColor: "#E0FAFF" }}
						textStyle={{ color: "#00B4D8" }}
						label='Edit Settings'
						onClick={handleEditClicked}
					/>
				</div>
			</div>
			<div style={{ paddingTop: "25px" }}>
				<Input
					key={position?.id}
					label='Role Name'
					type='text'
					defaultValue={position?.name}
					readOnly
				/>
			</div>
			<div
				className='d-flex flex-column align-items-start'
				style={{ padding: "18px 0px" }}>
				<span className='p2'>Role Template</span>
				<RoleTemplateCard
					style={{ marginTop: "5px" }}
					title={roleTemplate?.name}
					details={roleTemplate?.description}
				/>
			</div>
			<Input
				type='text'
				textarea
				name='roleDetails'
				label='Role Details'
				defaultValue={position?.role_details}
				readOnly
			/>
			<div>
				<Qualification dashboard completed criteria />
			</div>
			<div>
				<Personality dashboard completed criteria />
			</div>
			<div>
				<Reference
					dashboard
					completed
					criteria
					referenceCount={position?.reference_count}
				/>
			</div>
		</div>
	);
}
