import React from "react";
import ProgressBar from "./ProgressBar";
import { ReactComponent as FaceIcon } from "../../../assets/icons/dashboard/Table/face-id.svg";
import { ReactComponent as ReferenceIcon } from "../../../assets/icons/dashboard/Table/reference-check.svg";
import { useSelector } from "react-redux";

export default function CandidateSummaryCard(props) {
	const previousTestRightPanel = useSelector(
		(state) => state.candidateTesting.previousTestRightPanel,
	);

	const correct = useSelector((state) => state.candidateTesting.correct);

	const total = useSelector(state => state.candidateTesting.total);

	return (
		<div
			className='d-flex flex-column align-items-start'
			style={{
				width: "100%",
				height: "179px",
				borderRadius: "10px",
				backgroundColor: "white",
				padding: "10px 20px",
			}}>
			<div>
				<span className='b2'>Candidate Summary</span>
			</div>
			<div className='d-flex w-100' style={{ marginTop: "18px" }}>
				<div
					className='d-flex flex-column align-items-start summary-card-right-padding'
					style={{ borderRight: "2px solid #C8CBCD" }}>
					<span className='p2'>Correct Answers</span>
					<div style={{ marginTop: "10px" }}>
						<span
							className='hero3'
							style={{
								color:
									correct > "6" ||
									correct === "10"
										? "#00D69A"
										: "#F4C20D",
							}}>
							{correct || "0"} <span className="headline1" >of {total || "0"}</span>
						</span>
					</div>
				</div>
				<div
					className={
						props.isExtended
							? "d-flex align-items-center"
							: "d-flex flex-column justify-content-between summary-card-padding"
					}>
					<div
						className={
							props.isExtended
								? "d-flex flex-column "
								: "d-flex align-items-center"
						}
						style={{
							paddingRight: props.isExtended ? "53px" : "0px",
							borderRight: props.isExtended ? " 2px solid #C8CBCD" : "none",
						}}>
						<div
							style={{
								margin: props.isExtended
									? "0px 0px 15px 0px"
									: "0px 25px 0px 0px",
							}}>
							<span style={{ color: "#3E454D" }} className='description'>
								Qualification
							</span>
						</div>
						<div style={{ height: "5px" }}>
							<ProgressBar
								barColor={ previousTestRightPanel?.score  > "6" || previousTestRightPanel?.score  === "10" ? "#00D69A": "#F4C20D"}
								completed={previousTestRightPanel?.score * 10}
								style={{ backgroundColor: previousTestRightPanel?.score > "6" || previousTestRightPanel?.score === "10" ? "#90EE90" : "#F0E68C", width: "97px" }}
							/>
						</div>
					</div>
					<div
						className={
							props.isExtended
								? "d-flex flex-column align-items-center "
								: "d-flex"
						}
						style={{
							paddingInline: props.isExtended ? "50px" : "0px",
							borderRight: props.isExtended ? " 2px solid #C8CBCD" : "none",
						}}>
						<div
							style={{
								margin: props.isExtended
									? "0px 0px 10px 0px"
									: "0px 25px 0px 0px",
							}}>
							<span style={{ color: "#3E454D" }} className='description'>
								Personality Assesment
							</span>
						</div>
						<div style={{ width: "15px", height: "15px" }}>
							<FaceIcon
								fill={
									(previousTestRightPanel?.pers_match === null || previousTestRightPanel?.pers_match === undefined) ? "#F4C20D" : previousTestRightPanel?.pers_match ? "#00D69A" : "#FC4848"
								}
							/>
						</div>
					</div>
					<div
						className={
							props.isExtended
								? "d-flex flex-column align-items-center"
								: "d-flex "
						}
						style={{
							paddingLeft: props.isExtended ? "40px" : "0px",
						}}>
						<div
							style={{
								margin: props.isExtended
									? "0px 0px 10px 0px"
									: "0px 25px 0px 0px",
								paddingRight: props.isExtended ? "10px" : "0px",
							}}>
							<span style={{ color: "#3E454D" }} className='description'>
								Reference Checks
							</span>
						</div>
						<div style={{ width: "15px", height: "15px" }}>
							<ReferenceIcon
								fill={
									previousTestRightPanel?.referral_status
										? "#00D69A"
										: "#F4C20D"
								}
							/>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
