import React, { useEffect } from "react";
import StatsComponent from "../../components/dashboard/StatsComponent";
import PositionComponent from "../../components/dashboard/PositionComponent";
import { useDispatch, useSelector } from "react-redux";
import * as setupRoleActions from "../../redux/actions/onboarding/SetupRoles";
import * as clientFilterActions from "../../redux/actions/Dashboard/ClientFilter.actions";
import * as candidateTestingActions from "../../redux/actions/Candidate/candidate-testing.actions";
import * as notificationActions from "../../redux/actions/Notification/notification.actions";

export default function Dashboard(props) {
	const dispatch = useDispatch();

	const user = useSelector((state) => state.user.user);

	useEffect(() => {
		dispatch(setupRoleActions.resetForm());
		dispatch(clientFilterActions.resetEditRole());
		dispatch(candidateTestingActions.resetCandidateTesting());
		localStorage.clear();
		if (props?.setIsInterview) {
			props.setIsInterview(false);
			props.setIsOpen(false);
		}
	}, [dispatch]);

	useEffect(() => {
		dispatch(candidateTestingActions.getAssessmentSessionsStats());
	},[dispatch]);

	useEffect(() => {
		dispatch(notificationActions.getAllNotifications());

		setInterval(() => {
			dispatch(notificationActions.getAllNotifications());
		}, 15000);
	}, [dispatch, user]);

	return (
		<div>
			<StatsComponent candidate={props.candidate} />
			<PositionComponent
				onClickStart={props.onClickStart}
				candidate={props.candidate}
				onClick={props.onClick}
				selected={props.selected}
				mobile={props.mobile}
				onHandleClick={props.onHandleClick}
				onPreviousClick={props.onPreviousClick}
			/>
		</div>
	);
}
