import React, { useState, useEffect, useRef } from "react";
import { Fragment } from "react";
import ReactRoundedImage from "react-rounded-image";

import { ReactComponent as ArrowDown } from "../../assets/icons/Arrows/chevron-bottom.svg";
import Tag from "./Tag";

/**
 * Select Component
 * @param {object} props Form Select Component
 * @param {Function} updateSelections Update Selected States which Send back the selected option To The Parent(or where you call it)
 * @param {String} varient should be either 'multi-select' or null (for single selection)
 * @param {Array} options array of string (options)
 * @param {Array} selected array of selected option. Pass it an array in which you want to save options
 * [IMPORTANT] *********** This Component accept only options as object array with three attributes label, value and image. Image attribute is optional.
*/

export default function Select(props) {
	const [isOpen, setIsOpen] = useState(false);
	const [selected, setSelected] = useState(props.selected || null);

	const [optionBoxSize, setOptionBoxSize] = useState(0);
	const selectRef = useRef();

	useEffect(() => {
		// console.log(selected, "Selected Items");
		if (typeof props.updateSelections === "function") {
			props.updateSelections(selected);
		}
	}, [selected]);

	const optionSelected = (option) => {
		if (props.varient === "multi-select") {
			setSelected([...selected, option]);
		}
		else {
			// var dummyArr = [option];
			setSelected(option);
		}
		setIsOpen(!isOpen);

	};

	const removeTag = (selectedTag) => {
		setSelected(selected.filter(item => item != selectedTag));
	};

	useEffect(() => {
		if(isOpen){
			selectRef?.current?.focus();
		}
		// else{
		// 	selectRef?.current?.blur();
		// }
	}, [isOpen]);
	

	if(props.varient === "customizeSelect"){
		return(
			<div
				className="d-flex flex-column w-100 align-items-start position-relative pointer"
				style={{ ...props.style}}
				tabIndex="0"
				onBlur={() => { setIsOpen(false); }}
				onClick={props.disable ? () => { } : () => { setIsOpen(!isOpen); selectRef?.current?.blur();}}

			>
				<span className="b3">{props.title}</span>
				<select onChange={(e) => { 
					optionSelected(e.target.value);
					if (props.onSelect) {
						props.onSelect(e.target.value);
					}
				}} 
				ref={selectRef} size={optionBoxSize} style={{zIndex:"1", top: "10px", marginTop: "0px", position: "absolute", height: (optionBoxSize === 1) ? "40px" : "", border:"none", borderRadius:(optionBoxSize === 1) ? "12px": "", backgroundColor:"white", }} className="form-select w-100 pointer" aria-label="Default select example" onFocus={() => { setOptionBoxSize(10);}} onBlur={() => {setOptionBoxSize(1);}} >
					{
						props.options && props.options.map((option, key) => {
							return (
								<option key={key}  selected={selected === option ? true : false} value={option}>{option}</option>
							);
						})
					}
				</select>
			
				
			</div>
		);
	}
	else {

	
		return (
			<div className="d-flex flex-column w-100 align-items-start position-relative"
				style={{ ...props.style, outline:"none" }}
				tabIndex="0"
				onBlur={() => { setIsOpen(false); }}
			>
				<span className="p2">{props.title}</span>
				<div className="custom-select-div"
					style={props.inputStyle}
					onClick={props.disable ? () => { } : () => setIsOpen(!isOpen)}
					>
					{
						props.varient === "multi-select"
							?
							<div>Select an option</div>
							:
							<div>
								{
									selected === null ?
										<span className="description" style={props.placeholderStyle}>{ props.placeholder || "Select Position" }</span>
										:
										<div>
											{
												props.agency
													?
													<div className="d-flex align-items-center">

														{ !props.agencySetup &&
													<>
														<span className="description">{selected.value}</span>
														<div style={{width:"4px", height:"4px", marginLeft:"10px" ,borderRadius:"50%", backgroundColor:"#00B4D8"}}>
														</div>
													</>
														}
														<div style={{marginLeft:"5px" }}>
															{selected?.icon && (
																<ReactRoundedImage
																	image={selected.icon}
																	roundedSize="0"
																	imageWidth="20"
																	imageHeight="20"
																	roundedColor="white"
																/>
															)}

														</div>
														{
															props.agencySetup && props.qualifications ?
																<span className="description" style={{ padding:"0px 5px"}}>{props.selected?.label}</span>
																: props.agencySetup ?
																	<span className="description" style={{ padding:"0px 5px"}}>{selected?.label}</span>
																	:
																	<span className="description" style={{color:"#96999C", padding:"0px 5px"}}>{selected?.label}</span>

														}
													</div>
													:
													<span className="description">{selected}</span>
											}

										</div>
								}
							</div>

					}
					{!props.disable && (<ArrowDown
						onClick={props.disable ? () => { } : () => setIsOpen(!isOpen)}
						className="pointer" />)
					}
				</div>
				{
					props.varient === "multi-select" &&
				<div className="d-flex flex-wrap" style={{ marginTop: "10px" }}>
					{
						selected && selected.map((selection, index) => {
							return (
								<div key={index} style={{ marginLeft: "5px", marginBottom: "5px" }}>
									<Tag
										title={selection}
										onClick={() => {
											removeTag(selection);
										}}
									/>
								</div>
							);
						})
					}
				</div>
				}

				{
					isOpen &&
					<div
						className="w-100 custom-select-option-container "
						style={{ position: "absolute", marginTop: "4.30em" }}>
						{
							props.options && props.options.map((option, key) => {
								return (
									<Fragment key={key}>
										{
											props.varient === "multi-select" ?
												!selected.includes(option) &&
											<div className="custom-select-option" onClick={() => {
												optionSelected(option);
												if (props.onSelect) {
													props.onSelect(option);
												}
											}}>
												<span className="description">{option}</span>
											</div>
												:
												<div className="custom-select-option" onClick={props.disable ? () => { } : () => {
													if(props.agency){
														optionSelected({value: option.value, icon: option.icon, label: option.label});
														if (props.onSelect) {
															props.onSelect(option);
														}
													}
													else{
														optionSelected(option);
														if (props.onSelect) {
															props.onSelect(option);
														}
													}
												}}>

													{
														props.agency ?
															<div className="d-flex align-items-center">
																{ !props.agencySetup &&
													<>
														<span className="description">{option}</span>
														<div style={{width:"4px", height:"4px", marginLeft:"10px" ,borderRadius:"50%", backgroundColor:"#00B4D8"}}>
														</div>
													</>

																}
																<div style={{marginLeft:"5px" }} key={key}>
																	{option.icon && (
																		<ReactRoundedImage
																			image={option.icon}
																			roundedSize="0"
																			imageWidth="20"
																			imageHeight="20"
																			roundedColor="white"
																		/>
																	)}
																</div>
																{
																	props.agencySetup ?
																		<span className="description" style={{ padding:"0px 5px"}}>{option.label}</span>
																		:
																		<span className="description" style={{color:"#96999C", padding:"0px 5px"}}>{option.label}</span>

																}

															</div>
															:
															<span className="description"><input style={{display:"none"}} name={props.name}/>{option}</span>
													}
												</div>
										}
									</Fragment>
								);
							})
						}
					</div>

				}

			</div >
		);
	}
}
