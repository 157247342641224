/* eslint-disable indent */
import React, { useState, useEffect } from "react";
import { Fragment } from "react";
import AddRow from "./Row";
import { ReactComponent as Delete } from "../../../assets/icons/basic/Delete.svg";
import { useSelector } from "react-redux";

/**
 * Tabel
 * @param {object} props Tabel
 * @param {boolean} props.isEditable if true you can use CRUD oprations on table
 * @param {array} props.headers an array of strings which can be collaction of column(label) for header
 * @param {array} props.rows an 2D array of items
 * @param {boolean} props.alignText it uses the flex property like start, center and end to align items(text)
 * @param {Function} props.updateTable a callback functions which retuens all updated data
 * @param {Function} props.updatedRow a callback functions which retuens all updated row parameters are (row , actionId) where actionId 0 is add 1 is edit 2 is delete
 * @param {Array} props.activeSelectInput pass an array of objects in which each object has a Col no. to tell where we want to use selection and the data we can use multiple select with multiple objects in the array
 * @param {Array} props.activeImageInput
 * @param {Array} props.activeMultiSelectInput
 */

export default function CustomTabel(props) {
	const loading = useSelector((state) => state.clients.addClientsLoading);

	const [rows, setRows] = useState([]);
	// const [editable, setEditable] = useState(false);
	const [activeForEdit, setActiveForEdit] = useState(null);
	const [headers, setHeaders] = useState(props.headers);

	useEffect(() => {
		if (props.isEditable === true && headers[headers.length - 1] !== "Action") {
			setHeaders([...headers, "Action"]);
		}
	}, [props.isEditable]);

	useEffect(() => {
		if (props.rows) {
			setRows(props.rows);
		} else {
			setRows(rows);
		}
	}, [props.rows, rows]);

	// const checkRow = (action, rowBeingAdded, index) => {
	const checkRow = (action, rowBeingAdded) => {
		var updatedRow = rows;

		if (action === 0) {
			updatedRow.push(rowBeingAdded);
			props.updatedRow(rowBeingAdded, 0);
		}
		if (action === 3) {
			const tempIndex = updatedRow.indexOf(rowBeingAdded);
			if (tempIndex > -1) {
				updatedRow.splice(tempIndex, 1);
				props.updatedRow(rowBeingAdded, 2);
			}
		}
		setRows([updatedRow]);
		props.updateTable(rows);
	};

	const editRow = (rowId) => {
		if (props.isEditable) {
			if (activeForEdit === null) {
				setActiveForEdit(rowId);
			} else if (rowId === activeForEdit) {
				props.updatedRow(rows[rowId], 1);
				setActiveForEdit(null);
			}
		}
	};

	return (
		<div className='w-100 d-flex flex-column align-items-start'>
		{/* {console.log(" isTabletOrMobile value at table is " , props.isTabletOrMobile && true)} */}
			<span className='b3'>{props.title}</span>
			<div style={{ marginTop: "10px" }} className='custom-table-header w-100'>
				{headers.map((header, key) => {
					return (
						<div
							key={key}
							className={
								"row-cell d-flex " +
								`${`justify-content-${
									props.alignText ??
									`${
										key === 0
											? "start "
											: key === headers.length - 1
											? "end "
											: "center "
									} `
								}`}`
							}
							style={{ width: `${100 / headers.length}%` }}>
							<span
								className={`p2 ${
									!props.alignText &&
									`${key === 0 && " row-margin-left "} ${
										key === headers.length - 1 && " row-margin-right " 
									}`
								}`}
								style={{marginRight: "6px" }}
								>
								{" "}
								{header}
							</span>
						</div>
					);
				})}
			</div>
			<div style={{ marginTop: "10px" }} className='custom-table-body w-100'>
				{props.rows &&
					props.rows.map((row, index) => {
						return (
							<div key={index} className='outer-row'>
								<div className='custom-table-row w-100'>
									{activeForEdit !== index ? (
										<Fragment>
											<div
												onClick={() => editRow(index)}
												className='d-flex'
												style={{
													width: `${
														props.isEditable
															? `${100 - 100 / row.length}`
															: "100"
													}%`,
												}}>
												{row.map((entity, innerIndex) => {
													return (
														<Fragment key={innerIndex}>
															{innerIndex > 0 && (
																<div
																	className={
																		"d-flex align-items-center " +
																		`${`justify-content-${
																			props.alignText ??
																			`${
																				innerIndex === 1 ? "start " : "center "
																			} `
																		}`}`
																	}
																	style={{
																		width: `${100 / (row.length - 1)}%`,
																	}}>
																	{entity instanceof Array ? (
																		<Fragment>
																			<span
																				className={`row-cell description d-inline-block ${
																					!props.alignText &&
																					`${
																						innerIndex === 1 &&
																						" row-margin-left "
																					}`
																				}`}
																				style={{ overflow: "hidden", width: "50%", height: "100%", textOverflow: "ellipsis", whiteSpace: "nowrap"}}
																				>
																				{entity.map((e, key) => {
																					return (
																						<span key={key} >
																							{key === 0 ? " " + e.option : ", " + e.option}
																						</span>
																					);
																				})}
																			</span>
																		</Fragment>
																	)
																	:
																	typeof entity === "string" ||
																	entity instanceof String ? (
																		<Fragment>
																			{entity.includes("blob") ||
																			entity.includes("https") ? (
																				<div
																					className={`row-cell description d-flex ${
																						!props.alignText &&
																						`${
																							innerIndex === 1 &&
																							" row-margin-left "
																						}`
																					}`}>
																					<img
																						src={entity}
																						style={{
																							height: "27px",
																							width: "27px",
																							borderRadius: "50%",
																						}}
																					/>
																				</div>
																			) :
																			
																				(<span
																					className={`row-cell description d-flex ${
																						!props.alignText &&
																						`${
																							innerIndex === 1 &&
																							" row-margin-left "
																						}`
																					}`}
																					style={{ color: "#2B3340" }}>
																					{entity}
																				</span>
																				)
																			}
																		</Fragment>
																	) : 
																	(	
																		<Fragment>
																			{(entity?.option) ? (
																				<span
																					className={`row-cell description d-flex ${
																						!props.alignText &&
																						`${
																							innerIndex === 1 &&
																							" row-margin-left "
																						}`
																					}`}>
																					{entity.option}
																				</span>
																			) : (
																				<div
																					className={`row-cell description d-flex ${
																						!props.alignText &&
																						`${
																							innerIndex === 1 &&
																							" row-margin-left "
																						}`
																					}`}>
																					{entity}
																				</div>
																			)}
																		</Fragment>
																	)}
																</div>
															)}
														</Fragment>
													);
												})}
											</div>
											{props.isEditable && (
												<div
													className={
														"row-cell b4 d-flex" +
														` justify-content-${
															props.alignText ? props.alignText : "end"
														}`
													}
													style={{ width: `${100 / row.length}%` }}>
													<Delete
														className={`${
															!props.alignText && "row-margin-right table-icons"
														}`}
														onClick={!loading ? () => checkRow(3, row) : null}
														width='16.67'
														height='16.67'
													/>
												</div>
											)}
										</Fragment>
									) : (
										<AddRow
												
											onClick={() => editRow(index)}
											action={2}
											rowData={row}
											updateTable={checkRow}
											rowLength={headers.length}
											alignText={props.alignText}
											activeImageInput={
												props.activeImageInput && props.activeImageInput
											}
											activeSelectInput={
												props.activeSelectInput && props.activeSelectInput
											}
											activeMultiSelectInput={
												props.activeMultiSelectInput &&
												props.activeMultiSelectInput
											}
										/>
									)}
								</div>
							</div>
						);
					})}
				{props.isEditable && (
					<div className='w-100' style={{ padding: "0px 10px" }}>
						<AddRow
							
							updateTable={checkRow}
							alignText={props.alignText}
							rowLength={headers.length}
							activeImageInput={
								props.activeImageInput && props.activeImageInput
							}
							activeSelectInput={
								props.activeSelectInput && props.activeSelectInput
							}
							activeMultiSelectInput={
								props.activeMultiSelectInput && props.activeMultiSelectInput
							}
						/>
					</div>
				)}
			</div>
		</div>
	);
}


