import * as Actions from "../../actions/Candidate/candidate-testing.actions";

const initialState = {
	testPack: null,
	assessmentId: null,
	getNextLoading: false,
	timeOut: false,
	answerSubmitted: false,
	timerReset: false,
	textArea: "",
	testToResume: null,
	isCandidateTestEnded: false,
	videoBlob: null,
	pendingTestRightPanel: {
		roleName: null,
		tenantName: null,
		tenantState: null,
		tenantCountry: null,
		roleDetails: null,
		test: null,
		tenantLogo: null,
	},
	candidateUserProfile: {
		firstName: "firstName",
		lastName: "lastName ",
		city: null,
		country: null,
	},
	candidateReportDetail: null ,
	autoRefMail :true ,
	candidateReferrersReport: [],
	candidateReportProfile : {
		firstName: null ,
		image: null,
		lastName: null
	},
	CandidateSummaryReport: {
		qualityTotal: null,
		qualityCorrect: null,
	},
	qualReportscores: [],
	persquestionsReport:[],

	testEnded: {
		completionRate: null,
		questionsAttempted: null,
		minutes: null,
		seconds: null,
		qualificationTotal: null,
		correctQualifications: null,
	},

	total: null,
	correct: null,
	refrranceCount: null ,
	isPersonality : true ,
	pers_match: null,
	isAutoRefEmail: true ,
	personalityData: null,
	previousTestRightPanel: null,
	testProgress: null,
	qualificationScores: [],
	personalityQuestions: [],
	assessmentSessions: [],
	isAssessmentDeleted: false,
	assessmentSessionsStats: {
		pending: 0,
		shortlisted: 0,
		total: 0,
	},
	invitedUsers: [],
	completedUserProfiles: [],
	reportId : " " ,
	reportKey:" ",
	reportLink:null ,
	assessmentStatus: null ,
	isReferenceCheck: false,
	referenceDetailsForm: {
		new_referrers: {},
		existing_referrers: [],
		loading: false,
	},
	candidateReferrers: [],
	comments: "",
	linkId : null ,
	linkStatus:null ,
	isLoading:true ,
	inviteId : null,
};

const CandidateTesting = (state = initialState, action) => {
	const data = action.payload;

	switch (action.type) {
		case Actions.GET_NEXT_QUESTION_END:
			return (state = {
				...state,
				testPack: data,
				getNextLoading: false,
				timeOut: data.duration.minutes === 0 && data.duration.seconds === 0,
				testProgress: data.test_progress,
			});

		case Actions.GET_NEXT_QUESTION_START:
			return (state = {
				...state,
				getNextLoading: true,
			});

		case Actions.GET_NEXT_QUESTION_FAILED:
			return (state = {
				...state,
				getNextLoading: false,
			});

		case Actions.SET_ASSESSMENT_ID_TO_START_TEST:
			return (state = {
				...state,
				assessmentId: data,
			});

		case Actions.SET_TIME_OUT:
			return (state = {
				...state,
				timeOut: data,
			});

		case Actions.SET_ANSWER_SUBMITTED:
			return (state = {
				...state,
				answerSubmitted: data,
			});

		case Actions.SUBMIT_ANSWER_START:
			return (state = {
				...state,
				getNextLoading: true,
			});

		case Actions.SUBMIT_ANSWER_END:
			return (state = {
				...state,
				getNextLoading: false,
				videoBlob: null,
			});

		case Actions.SUBMIT_ANSWER_FAILED:
			return (state = {
				...state,
				getNextLoading: false,
			});

		case Actions.SET_TIMER_RESET:
			return (state = {
				...state,
				timerReset: data,
			});

		case Actions.HANDLE_CHANGE_TEXT_AREA:
			return (state = {
				...state,
				textArea: data,
			});

		case Actions.RESET_TEXT_AREA:
			return (state = {
				...state,
				textArea: "",
			});

		case Actions.SET_TEST_TO_RESUME:
			return (state = {
				...state,
				testToResume: data,
			});

		case Actions.CANDIDATE_TEST_ENDED:
			localStorage.clear();
			return (state = {
				...state,
				isReferenceCheck: true,
				isCandidateTestEnded: true,
				testPack: null,
				// assessmentId: null,
				getNextLoading: false,
				timeOut: false,
				answerSubmitted: false,
				timerReset: false,
				textArea: "",
				testProgress: data.test_progress,
				testEnded: {
					...state.testEnded,
					completionRate: data.completion_rate,
					questionsAttempted: data.questions_attempted,
					minutes: data.time_taken.minutes,
					seconds: data.time_taken.seconds,
					qualificationTotal: data.qual_total,
					correctQualifications: data.qual_correct,
				},
			});

		case Actions.SET_VIDEO_BLOB:
			return (state = {
				...state,
				videoBlob: data,
			});

		case Actions.SET_PENDING_TEST_RIGHT_PANEL:
			return (state = {
				...state,
				pendingTestRightPanel: {
					...state.pendingTestRightPanel,
					roleName: data.roleName,
					tenantName: data.tenantName,
					tenantState: data.tenantState,
					tenantCountry: data.tenantCountry,
					roleDetails: data.roleDetails,
					tenantLogo: data.tenantLogo,
					test: data.test,
				},
			});

		case Actions.SET_CANDIDATE_USER_PROFILE:
			return (state = {
				...state,
				candidateUserProfile: {
					...state.candidateUserProfile,
					firstName: data.first_name,
					lastName: data.last_name,
					city: data.city,
					country: data.country,
					logo: data.image_url,
				},
			});

		case Actions.SET_PREVIOUS_TEST_RIGHT_PANEL:
			return (state = {
				...state,
				previousTestRightPanel: data,
			});
		case Actions.SET_SINGLE_ASSESSMENT_SESSION:
			return (state = {
				...state,
				qualificationScores: data.qual_scores,
				personalityQuestions: data.pers_questions,
				correct: data.qual_correct,
				total: data.qual_total,
				reportId:data.report_id ,
				refrranceCount:data.req_reference_count ,
				isPersonality:data.pers_assess_required ,
				isAutoRefEmail:data.auto_ref_email ,
				personalityData: data.personality_data
			});
			// ------ Assessment Report reducer
		case Actions.SET_ASSESSMENT_REPORT:
			return (state = {
				...state,
				candidateReportDetail : data.data ,
				candidateReferrersReport:data.data.references,
				candidateReportProfile :{
					...state.candidateReportProfile ,
					firstName: data.data.first_name,
					lastName:data.data.last_name,
					image:data.data.image_url,
				},
				CandidateSummaryReport: {
					...state.CandidateSummaryReport ,
					qualityTotal:data.data.qual_total,
					qualityCorrect:data.data.qual_correct,
				},
				qualReportscores : data.data.qual_scores,
				persquestionsReport: data.data.pers_questions,
				assessmentStatus:data.status,
				autoRefMail:data.data.auto_ref_email,
				isPersonality:data.data.pers_assess_required,
				persMatch:data.data.pers_match,
				personalityData:data.data.personality_data
			});

		case Actions.GET_ASSESSMEST_SESSIONS:
			return (state = {
				...state,
				assessmentSessions: data,
			});
		case Actions.GET_ASSESSMEST_SESSIONS_STATS:
			return (state = {
				...state,
				assessmentSessionsStats: {
					...state.assessmentSessionsStats,
					pending: data.data.pending,
					total: data.data.total,
					shortlisted: data.data.shortlisted,
				},
			});

		case Actions.SET_INVITED_USERS:
			return (state = {
				...state,
				invitedUsers: data,
			});

		case Actions.RESET_INVITED_USERS:
			return (state = {
				...state,
				invitedUsers: [],
			});

		case Actions.SET_COMPLETED_USER_PROFILES:
			return (state = {
				...state,
				completedUserProfiles: data,
			});

		case Actions.RESET_COMPLETED_USER_PROFILES:
			return (state = {
				...state,
				completedUserProfiles: [],
			});
		case Actions.SET_REPORT_URL:
			return(state = {
				...state ,
				reportKey:data._id ,
				reportLink:data.url
				
			});

		case Actions.SET_LINK_ID:
			return(state = {
				...state ,
				linkId:data.link_id
				
			});
		case Actions.SAVE_LINK_ID_PARAM:
			return(state = {
				...state ,
				inviteId:data,
				
			});
		case Actions.GET_ASSESSMEST_INVITE_VERIFICATION_START:
			return(state = {
				...state ,
				isLoading:true ,
				
			});
		case Actions.GET_ASSESSMEST_INVITE_VERIFICATION:
			return(state = {
				...state ,
				linkStatus:data.status,
				isLoading:false ,
				
			});
		case Actions.GET_ASSESSMEST_INVITE_VERIFICATION_END:
			return(state = {
				...state ,
				isLoading:false , 
				
			});

		case Actions.SET_CANDIDATE_STATUS:
			return (state = {
				...state,
				previousTestRightPanel: {
					...state.previousTestRightPanel,
					status: data.status,
				},
				assessmentSessions: state.assessmentSessions.map((assessment) => {
					if (assessment._id === data.assessmentId) {
						return {
							...assessment,
							status: data.status,
						};
					}
					return assessment;
				}),
			});

		case Actions.RESET_CANDIDATE_TESTING:
			return (state = initialState);

		case Actions.RESET_PREVIOUS_TEST_RIGHT_PANEL:
			return (state = {
				...state,
				previousTestRightPanel: null,
			});

		case Actions.SUBMIT_REFERENCE_CHECK_START:
			return (state = {
				...state,
				referenceDetailsForm: {
					...state.referenceDetailsForm,
					loading: true,
				},
			});

		case Actions.SUBMIT_REFERENCE_CHECK_END:
			return (state = {
				...state,
				referenceDetailsForm: {
					...state.referenceDetailsForm,
					loading: false,
				},
				isReferenceCheck: false,
			});

		case Actions.SUBMIT_REFERENCE_CHECK_FAILED:
			return (state = {
				...state,
				referenceDetailsForm: {
					...state.referenceDetailsForm,
					loading: false,
				},
			});

		case Actions.SET_ASSESSMENT_ID_FOR_REFERENCE_DETAILS:
			return (state = {
				...state,
				referenceDetailsForm: {
					...state.referenceDetailsForm,
					assess_id: data,
				},
			});

		case Actions.SET_NEW_REFERRERS:
			return (state = {
				...state,
				referenceDetailsForm: {
					...state.referenceDetailsForm,
					new_referrers: {
						...state.referenceDetailsForm.new_referrers,
						[data.name]: data.value,
					},
				},
			});

		case Actions.GET_CANDIDATE_REFERRERS:
			return (state = {
				...state,
				candidateReferrers: data,
			});

		case Actions.ADD_EXISTING_REFERRER:
			return (state = {
				...state,
				referenceDetailsForm: {
					...state.referenceDetailsForm,
					existing_referrers: [
						...state.referenceDetailsForm.existing_referrers,
						data,
					],
				},
			});

		case Actions.REMOVE_EXISTING_REFERRER:
			return (state = {
				...state,
				referenceDetailsForm: {
					...state.referenceDetailsForm,
					existing_referrers:
						state.referenceDetailsForm.existing_referrers.filter((ref) => {
							if (ref === data) {
								return;
							} else {
								return ref;
							}
						}),
				},
			});
		case Actions.DELETE_ASSESSMEST:
			return (state = {
				...state,
				assessmentSessions: state.assessmentSessions.filter((assessment) => {
					if (assessment._id !== data) {
						return assessment;
					}
				}),
				isAssessmentDeleted: true,
			});
		case Actions.HANDLE_COMMENTS_CHANGE:
			return (state = {
				...state,
				comments: data,
			});
		case Actions.SUBMIT_ASSESSMENT_FEEDBACK:
			return (state = {
				...state,
				assessmentId: null,
			});
		case Actions.SUBMIT_FEEDBACK:
			return (state = {
				...state,
				comments: "",
			});
		default:
			return state;
	}
};

export default CandidateTesting;
