import React, { useState } from "react";
import { ReactComponent as Logo } from "../../assets/icons/logo/Header/Header/rnr/logo.svg";
import { ReactComponent as MenuLogo } from "../../assets/icons/basic/align-text-justify.svg";
import MidLayerHeader from "../../components/dashboard/MidLayerHeader";
import { Route, useRouteMatch } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import * as clientFilterActions from "../../redux/actions/Dashboard/ClientFilter.actions";
import Switch from "react-bootstrap/esm/Switch";
import Dashboard from "../pages/Dashboard";
import DashboardMenu from "../../components/dashboard/DashboardMenu";
import Positions from "../pages/Positions";
import CompanySettings from "../pages/Settings/CompanySettings";
import AgencySettings from "../pages/Settings/AgencySettings";
import TeamSettings from "../pages/Settings/TeamSettings";
import BillingSettings from "../pages/Settings/Billing/BillingSettings";
import SubscriptionPlan from "../pages/Settings/Billing/SubscriptionPlan";
import AddCard from "../pages/Settings/Billing/AddCard";
import EditCard from "../pages/Settings/Billing/EditCard";
import CreateNewPosition from "../pages/CreateNewPosition/CreateNewPosition";
import Integrations from "../pages/Integrations";
import UserSettings from "../pages/UserSettings";
import Background from "../../assets/images/dashboard/Glass.png";
import CandidateRightPanel from "../../components/dashboard/CandidateRightPanel";
import NotificationBox from "../../components/subcomponents/dashboard/NotificationBox";
import CandidateDetails from "../../components/dashboard/CandidateDetails/CandidateDetails";
import CandidateDashboardMenu from "../../components/dashboard/CandidateDashboard/CandidateDashboardMenu";
import PositionComponent from "../../components/dashboard/PositionComponent";
import CandidateInterview from "../../components/dashboard/CandidateDashboard/CandidateInterview";
import InterviewSummaryCard from "../../components/dashboard/InterviewSummaryCard";
import InterviewDetails from "../../components/dashboard/CandidateDashboard/InterviewDetails";
import { useHistory } from "react-router-dom";
import { ReactComponent as ExclamationMark } from "../../assets/icons/basic/exclamation-circle.svg";
import { ReactComponent as CrossIcon } from "../../assets/icons/basic/x.svg";

export default function DashboardMobileContainer() {
	const match = useRouteMatch();

	const history = useHistory();
	const dispatch = useDispatch();

	const [isMenuOpen, setIsMenuOpen] = useState(false);
	const [isRightPanelOpen, setIsRightPanelOpen] = useState(false);
	const [isExtendedRightPanel, setIsExtendedRightPanel] = useState(false);
	const [isCandidateDetails, setIsCandidateDetails] = useState(false);
	const [IsInterviewStart, setIsInterviewStart] = useState(false);
	const [isTestDetails, setIsTestDetails] = useState(false);
	const [testCompleted, setIsTestCompleted] = useState(false);

	const [isCross, setIsCross] = useState(false);

	const [selectedPage, setSelectedPage] = useState({
		pageIndex: 0,
		pageLabel: "Dashboard",
	});

	const getPageNo = (pageNo, label, hasSubMenu) => {
		if (hasSubMenu) {
			//
		} else {
			setSelectedPage({
				pageIndex: pageNo,
				pageLabel: label,
			});
		}
	};
	const user = useSelector((state) => state.user.user);
	const hasClients = useSelector((state) => state.clients.hasClients);
	const allClients = useSelector((state) => state.clients.allClients);

	const allClientsForMidlayerHeader = [{}];
	allClients.map((client) => {
		allClientsForMidlayerHeader.push({
			label: client.name,
			value: client.id,
			icon: client.logo,
		});
	});

	const handleClientFilterSelected = (client) => {
		if (client?.value) {
			dispatch(clientFilterActions.clientFilterSelected(client));
		} else {
			dispatch(clientFilterActions.clientFilterRemoved());
		}
	};

	const handlePosition = () => {
		setIsRightPanelOpen(!isRightPanelOpen);
	};

	const showRightPanel = () => {
		setIsExtendedRightPanel(true);
		setIsCandidateDetails(false);
	};

	const handleRowClick = () => {
		setIsCandidateDetails(true);
		setIsExtendedRightPanel(true);
	};

	const handleMenu = () => {
		setIsMenuOpen(!isMenuOpen);
	};

	const handlePrevious = () => {
		setIsRightPanelOpen(!isRightPanelOpen);
		setIsTestDetails(false);
		setIsCandidateDetails(true);
		setIsInterviewStart(false);
	};

	const handleStart = () => {
		setIsInterviewStart(true);
		setIsRightPanelOpen(false);
		setIsTestDetails(false);
		setIsCandidateDetails(false);
		history.push(`${match.path}/candidate-interview`);
	};

	const handleTestClick = () => {
		setIsRightPanelOpen(true);
		setIsCandidateDetails(false);
		setIsInterviewStart(false);
		setIsTestDetails(true);
	};

	const openInterviewPanel = () => {
		setIsRightPanelOpen(!isRightPanelOpen);
		setIsCross(!isCross);
	};

	const handleCross = () => {
		setIsExtendedRightPanel(false);
		setIsRightPanelOpen(false);
	};

	const handleCompleted = () => {
		setIsTestCompleted(true);
	};

	if (user?.tenantId === 0) {
		return (
			<div>
				<div
					style={{
						width: isMenuOpen ? "230px" : "0px",
						left: isMenuOpen ? "0" : "-250px",

						// display:  ? "block" : "none",
					}}
					className='dashboard-mobile-menu'>
					<CandidateDashboardMenu
						onClick={handleMenu}
						getSelectedItem={getPageNo}
						onSettingsClick={handleMenu}
					/>
				</div>

				<div
					className='dashboard-mobile-right-panel'
					style={{
						width: isRightPanelOpen ? "100vw" : "0px",
						// right: isRightPanelOpen ? "0px" : "-250px",
						display: isRightPanelOpen ? "block" : "none", 
					}}>
					{IsInterviewStart ? (
						<InterviewDetails completed={testCompleted} onClick={() => {}} />
					) : (
						<CandidateDetails
							candidate
							onClickStart={handleStart}
							onCross={() => setIsRightPanelOpen(false)}
							mobile
							testDetails={isTestDetails}
							tableClick={isCandidateDetails}
						/>
					)}
				</div>

				<div
					className='d-flex justify-content-between w-100'
					style={{
						paddingBlock: "20px",
						backgroundImage: `url(${Background})`,
					}}>
					<div style={{ paddingLeft: "10px" }} onClick={handleMenu}>
						<MenuLogo style={{ width: "23px", height: "15px" }} />
					</div>
					<div style={{ paddingRight: "10px" }}>
						<Logo style={{ width: "144px", height: "22px" }} />
					</div>

					{IsInterviewStart ? (
						<div style={{ paddingRight: "10px" }} onClick={openInterviewPanel}>
							{isCross ? <CrossIcon /> : <ExclamationMark />}
						</div>
					) : (
						<div></div>
					)}
				</div>
				<div className='dashboard-mobile-layout invisible-scrollbar'>
					<div style={{ zIndex: "0" }} className='w-100'>
						{!IsInterviewStart && (
							<MidLayerHeader
								hasClients={hasClients}
								label={selectedPage.pageLabel}
								options={allClientsForMidlayerHeader}
								onSelect={(e) => handleClientFilterSelected(e)}
								mobile={true}
							/>
						)}

						<Switch>
							<Route exact path={`${match.path}`}>
								<Dashboard
									onClickStart={handleStart}
									candidate
									onClick={handleTestClick}
									selected={() => {}}
									onHandleClick={() => {}}
									onSettingsClick={() => {}}
									onPreviousClick={handlePrevious}
									mobile
								/>
							</Route>
							<Route exact path={`${match.path}/candidate-positions`}>
								
								<PositionComponent
									onClickStart={() => {}}
									candidate
									onHandleClick={handlePosition}
									onClick={() => {}}
									selected={() => {}}
									onPreviousClick={handlePrevious}
								/>
								{/* <ReferenceCheckScreen mobile /> */}
							</Route>
							<Route exact path={`${match.path}/user-settings`}>
								<UserSettings mobile />
							</Route>
							<Route exact path={`${match.path}/candidate-interview`}>
								<CandidateInterview
									mobile
									onComplete={handleCompleted}
									welcome={() => {}}
									handleInterviewSummary={() => {}}
								/>
							</Route>
							<Route exact path={`${match.path}/interview-summary`}>
								<InterviewSummaryCard
									mobile
									onCross={() => history.push(`${match.path}/`)}
								/>
							</Route>
							<Route exact path={`${match.path}/notifications`}>
								<NotificationBox mobile />
							</Route>
						</Switch>
					</div>
				</div>
			</div>
		);
	} else {
		return (
			<div>
				<div
					style={{
						width: isMenuOpen ? "230px" : "0px",
						left: isMenuOpen ? "0" : "-250px",

						// display:  ? "block" : "none",
					}}
					className='dashboard-mobile-menu'>
					<DashboardMenu
						getSelectedItem={getPageNo}
						onSettingsClick={handleMenu}
						onClick={handleMenu}
					/>
				</div>
				<div
					className='dashboard-mobile-right-panel'
					style={{
						width: isRightPanelOpen ? "100vw" : "0px",
						// right: isRightPanelOpen ? "0px" : "-250px",
						display: isRightPanelOpen ? "block" : "none",
					}}>
					<CandidateRightPanel
						mobile
						onCross={() => setIsRightPanelOpen(false)}
						onRowClick={handleRowClick}
						documentClick={showRightPanel}
						web={false}
					/>
				</div>
				<div
					className='dashboard-mobile-right-panel'
					style={{
						width: isExtendedRightPanel ? "100vw" : "0px",
						// right: isRightPanelOpen ? "0px" : "-250px",
						display: isExtendedRightPanel ? "block" : "none",
					}}>
					<CandidateDetails
						onCross={() => setIsExtendedRightPanel(false)}
						mobile
						tableClick={isCandidateDetails}
						onAllCross={handleCross}
					/>
				</div>
				<div
					className='d-flex justify-content-between w-100'
					style={{
						paddingBlock: "20px",
						backgroundImage: `url(${Background})`,
					}}>
					<div style={{ paddingLeft: "10px" }} onClick={handleMenu}>
						<MenuLogo style={{ width: "23px", height: "15px" }} />
					</div>
					<div style={{ paddingRight: "10px" }}>
						<Logo style={{ width: "144px", height: "22px" }} />
					</div>
					<div></div>
				</div>
				<div className='dashboard-mobile-layout invisible-scrollbar'>
					<div style={{ zIndex: "0" }} className='w-100'>
						<MidLayerHeader
							hasClients={hasClients}
							label={selectedPage.pageLabel}
							options={allClientsForMidlayerHeader}
							onSelect={(e) => handleClientFilterSelected(e)}
							mobile={true}
						/>

						<Switch>
							<Route exact path={`${match.path}`}>
								<Dashboard onHandleClick={() => {}} onClick={handlePosition} />
							</Route>
							<Route exact path={`${match.path}/positions`}>
								<Positions onHandleClick={() => {}} onClick={handlePosition} />
							</Route>
							<Route path={`${match.path}/settings/company`}>
								<CompanySettings />
							</Route>
							<Route path={`${match.path}/settings/agency`}>
								<AgencySettings />
							</Route>
							<Route path={`${match.path}/settings/team`}>
								<TeamSettings />
							</Route>
							<Route exact path={`${match.path}/settings/billing`}>
								<BillingSettings />
							</Route>
							<Route path={`${match.path}/settings/billing/subscription`}>
								<SubscriptionPlan />
							</Route>
							<Route path={`${match.path}/settings/billing/add-card`}>
								<AddCard />
							</Route>
							<Route path={`${match.path}/settings/billing/edit-card`}>
								<EditCard />
							</Route>
							<Route path={`${match.path}/create-new-position`}>
								<CreateNewPosition />
							</Route>
							<Route path={`${match.path}/positions/create-new-position`}>
								<CreateNewPosition />
							</Route>
							<Route path={`${match.path}/integrations`}>
								<Integrations />
							</Route>
							<Route exact path={`${match.path}/user-settings`}>
								<UserSettings mobile />
							</Route>
							<Route exact path={`${match.path}/notifications`}>
								<NotificationBox mobile />
							</Route>
						</Switch>
					</div>
				</div>
			</div>
		);
	}
}
