import React from "react";
import ProgressBar from "./ProgressBar";
import { ReactComponent as FaceIcon } from "../../../assets/icons/dashboard/Table/face-id.svg";
import { ReactComponent as ReferenceIcon } from "../../../assets/icons/dashboard/Table/reference-check.svg";
import { useSelector } from "react-redux";

export default function CandidateReportSummaryCard(props) {
	const candidateReportDetail = useSelector((state) => state.candidateTesting.candidateReportDetail);
	const correct = useSelector((state) => state.candidateTesting.CandidateSummaryReport.qualityCorrect);
	console.log("correct is " , correct);

	const total = useSelector(state => state.candidateTesting.CandidateSummaryReport.qualityTotal);

	return (
		<div
			className='d-flex flex-column align-items-start'
			style={{
				width: "100%",
				height: "179px",
				borderRadius: "10px",
				backgroundColor: "white",
				padding: "10px 20px",
			}}>
			<div>
				<span className='b2'>Candidate Summary</span>
			</div>
			<div className='d-flex w-100' style={{ marginTop: "18px" }}>
				<div
					className={
						props.extended
							? "d-flex flex-column align-items-start "
							: "d-flex flex-column align-items-start summary-card-right-padding"
					}
					style={{ borderRight: "2px solid #C8CBCD" , width: props.extended && "18%"}}>
					<span className='p2'>Correct Answers</span>
					<div style={{ marginTop: "10px" }}>
						<span
							className='hero3'
							style={{
								color:
									correct > "6" ||
									correct === "10"
										? "#00D69A"
										: "#F4C20D",
							}}>
							{ correct || "0"} <span className="headline1" >of {total || "0"}</span>
						</span>
					</div>
				</div>
				<div
					className={
						props.extended
							? "d-flex align-items-center justify-content-around w-100 "
							: "d-flex flex-column justify-content-between summary-card-padding"
					}
					style={{ }}>
					<div
						className={
							props.extended
								? "d-flex flex-column align-items-center justify-content-center"
								: "d-flex"
						}
						style={{
							padding: props.isExtended  || props.extended? "0px 40px 0px 25px  " : "0px",
							borderRight: props.isExtended  || props.extended? " 2px solid #C8CBCD" : "none",
						}}>
						<div
							style={{
								margin:  props.extended
									? "0px 0px 20px 0px"
									: "0px 25px 0px 0px",
							}}>
							<span style={{ color: "#3E454D" }} className='description'>
								Qualification
							</span>
						</div>
						<div style={{ height: "5px" }}>
							<ProgressBar
								barColor={ candidateReportDetail?.score  > "6" || candidateReportDetail?.score  === "10" ? "#00D69A": "#F4C20D"}
								completed={candidateReportDetail?.score * 10}
								style={{ backgroundColor: candidateReportDetail?.score > "6" || candidateReportDetail?.score === "10" ? "#90EE90" : "#F0E68C", width: "97px" }}
							/>
						</div>
					</div>
					<div
						className={
							props.extended
								? "d-flex flex-column align-items-center  justify-content-center"
								: "d-flex"
						}
						style={{
							padding: props.isExtended  || props.extended? "0px 40px 0px 15px" : "0px",
							borderRight:  props.extended ? " 2px solid #C8CBCD" : "none",
						}}>
						<div
							style={{
								margin:  props.extended
									? "0px 0px 10px 0px"
									: "0px 25px 0px 0px",
							}}>
							<span style={{ color: "#3E454D" }} className='description'>
								Personality Assesment
							</span>
						</div>
						<div style={{ width: "15px", height: "15px" }}>
							<FaceIcon
								fill={
									(candidateReportDetail?.pers_match === null || candidateReportDetail?.pers_match === undefined) ? "#F4C20D" : candidateReportDetail?.pers_match ? "#00D69A" : "#FC4848"
								}
							/>
						</div>
					</div>
					<div
						className={
							props.extended
								? "d-flex flex-column align-items-center justify-content-center"
								: "d-flex "
						}
						style={{
							paddingLeft:  props.extended ? "35px" : "0px",
						}}>
						<div
							style={{
								margin:  props.extended
									? "0px 0px 10px 0px"
									: "0px 25px 0px 0px",
								paddingRight:  props.extended ? "10px" : "0px",
							}}>
							<span style={{ color: "#3E454D" }} className='description'>
								Reference Checks
							</span>
						</div>
						<div style={{ width: "15px", height: "15px" }}>
							<ReferenceIcon
								fill={
									candidateReportDetail?.referral_status
										? "#00D69A"
										:"#F4C20D"
								}
							/>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
