/* eslint-disable no-mixed-spaces-and-tabs */
import React, { useState } from "react";
import Button from "../Button";
import Input from "../Input";
import ReactPlayer from "react-player";
import VideoRecorder from "../../VideoRecorder";
import { useSelector, useDispatch } from "react-redux";
import * as candidateTestingActions from "../../../redux/actions/Candidate/candidate-testing.actions";
import {ReactComponent as Flag} from "../../../assets/icons/basic/FlagBanner.svg";
import QuestionFeedbackModal from "./QuestionFeedbackModal";

export default function AnswerBox(props) {
	const dispatch = useDispatch();
	const [key, setKey] = useState(null);

	const testPack = useSelector((state) => state.candidateTesting.testPack);
	const assessmentId = useSelector(
		(state) => state.candidateTesting.assessmentId,
	);
	const getNextLoading = useSelector(
		(state) => state.candidateTesting.getNextLoading,
	);
	const timeOut = useSelector((state) => state.candidateTesting.timeOut);
	const textArea = useSelector((state) => state.candidateTesting.textArea);
	const videoBlob = useSelector((state) => state.candidateTesting.videoBlob);

	const handleSelected = (index) => {
		setKey(index);
		setDisabled(false);
	};

	const [openFeedback, setOpenFeedBack] = useState(false);

	const [submitted, setSubmitted] = useState(false);

	const [disabled, setDisabled] = useState(true);

	const handleSubmit = async () => {
		dispatch(candidateTestingActions.setAnswerSubmitted(true));

		if (testPack.response_type === "mcq") {
			await dispatch(
				candidateTestingActions.submitAnswer(
					{
						ques_id: testPack._id,
						response_type: testPack.response_type,
						response: key + 1,
					},
					assessmentId,
				),
			);

			setSubmitted(true);
		} else if (testPack.response_type === "text") {
			await dispatch(
				candidateTestingActions.submitAnswer(
					{
						ques_id: testPack._id,
						response_type: testPack.response_type,
						response: textArea,
					},
					assessmentId,
				),
			);

			setSubmitted(true);
			// setCharLeft(0);
			dispatch(candidateTestingActions.handleChangeTextArea(""));
		} else if (testPack.response_type === "video") {
			await dispatch(
				candidateTestingActions.submitAnswer(
					{
						ques_id: testPack._id,
						response_type: testPack.response_type,
						video: videoBlob,
						response: null,
					},
					assessmentId,
				),
			);

			setSubmitted(true);
			
		}
	};

	const maxChar = 500;

	const [charLeft, setCharLeft] = useState(500);

	const handleChange = (event) => {
		const input = event.target.value;
		const remaining = maxChar - input.length;

		dispatch(candidateTestingActions.handleChangeTextArea(event.target.value));
		setCharLeft(remaining);

		if (remaining <= 300) {
			setDisabled(false);
		} else {
			setDisabled(true);
		}
	};

	const openModal = () => {
		setOpenFeedBack(true);
	};

	return (
		<div>
			{props.mobile ? (
				<div>
					<div>
						{
							openFeedback && 
							<QuestionFeedbackModal onClick={() => setOpenFeedBack(false)} />
						}
					</div>
					{props.testPack.response_type === "text" ? (
						<div
							className='d-flex flex-column align-items-end'
							style={{
								padding: "30px 0px 0px 50px",
							}}>
							<span className='description' style={{ color: "#96999C" }}>
								{charLeft} characters left
							</span>
							<Input
								maxLength={maxChar}
								style={{ width: "100%" }}
								textarea
								disabled={submitted}
								label=' '
								value={textArea}
								inputStyle={{ height: "270px" }}
								onChange={handleChange}
							/>
						</div>
					) : props.testPack.response_type === "video" ? (
						<div>
							<VideoRecorder
								submitted={submitted}
								setDisabled={setDisabled}
								width='384px'
								height='474px'
							/>
						</div>
					) : (
						<div
							style={{
								width: "fit-content",
								height: "fit-content",
								marginLeft: "50px",
								marginTop: "30px",
							}}>
							{props.testPack.choices.map((choice, index) => {
								return (
									<div
										onClick={timeOut ? () => {} : () => handleSelected(index)}
										className={
											timeOut
												? "d-flex align-items-center mcq-time-up"
												: "d-flex align-items-center mcq-select pointer"
										}
										key={index}
										style={{
											backgroundColor:
												index === key ? "rgba(0, 180, 216, 0.1)" : "",
										}}>
										<div
											className='d-flex justify-content-center align-items-center'
											style={{
												width: "auto",
												minWidth: "20px",
												height: "auto",
												minHeight: "20px",
												borderRadius: "5px",
												border: "1px solid #00B4D8",
											}}>
											<span style={{ color: "#00B4D8" }}>
												{index === 0
													? "A"
													: index === 1
														? "B"
														: index === 2
															? "C"
															: index === 3
																? "D"
																: "E"}
											</span>
										</div>
										{choice.type === "image" ? (
											<div style={{ marginLeft: "10px", maxHeight: "120px", maxWidth: "120px" }}>
												<img
													src={choice.choice}
													alt=''
													style={{ width: "100%", height: "100%", objectFit: "contain" }}
												/>
											</div>
										) : choice.type === "video" ? (
											<div style={{ marginLeft: "20px" }}>
												<ReactPlayer
													width='100%'
													height='100%'
													controls
													url={choice.choice}
												/>
											</div>
										) : (
											<div
												style={{ marginLeft: "10px" }}
												className='d-flex text-justify'>
												<span>{choice.choice}</span>
											</div>
										)}
									</div>
								);
							})}
						</div>
					)}
					<div style={{ margin: "40px 50px" }}>
						{timeOut ? (
							<div className='d-flex'>
								<div>
									<Button
										button
										label='Question Timeout'
										style={{
											padding: "9.5px 15px",
											backgroundColor: "#FC4848",
										}}
									/>
								</div>
								<div style={{ marginLeft: "20px" }}>
									<Button
										button
										label='Next'
										style={{ padding: "9.5px 29.5px" }}
										loading={getNextLoading}
										onClick={async () => {
											await dispatch(
												candidateTestingActions.submitAnswer(
													testPack.response_type === "video"
														? {
															ques_id: testPack._id,
															response_type: testPack.response_type,
															video: "",
														  }
														: {
															ques_id: testPack._id,
															response_type: testPack.response_type,
															response: "",
														  },
													assessmentId,
												),
											);
											await dispatch(
												candidateTestingActions.getNextQuestion(assessmentId),
											);
											setSubmitted(false);
											setDisabled(true);
											setKey(null);
										}}
									/>
								</div>
							</div>
						) : (
							<div>
								{submitted ? (
									<div className="d-flex flex-column">
										<div className='d-flex'>
											<Button
												button
												label='Answer Submitted'
												style={{
													padding: "13px 20px",
													backgroundColor: "#00D69A",
												}}
											/>
											<div style={{ marginLeft: "20px" }}>
												<Button
													button
													label='Next'
													style={{ padding: "13px 40px" }}
													loading={getNextLoading}
													onClick={async () => {
														await dispatch(
															candidateTestingActions.getNextQuestion(
																assessmentId,
															),
														);
														dispatch(
															candidateTestingActions.setAnswerSubmitted(false),
														);
														dispatch(
															candidateTestingActions.setTimerReset(false),
														);
														setSubmitted(false);
														setDisabled(true);
														setKey(null);
														setCharLeft(500);

													}}
												/>
											</div>
										</div>
										<div className="d-flex align-items-center pt-3 pointer" onClick={openModal}>
											<Flag/>
											<span style={{color:"#FC4848"}} className="p1 pl-1">Give Feedback on Question</span>
										</div>
									</div>
									
								) : (
									<Button
										onClick={disabled ? () => {} : handleSubmit}
										button
										loading={getNextLoading}
										label='Submit'
										style={{
											padding: "13px 31px",
											opacity: disabled ? "0.25" : "1",
										}}
									/>
								)}
							</div>
						)}
					</div>
				</div>
			) : (
				<div >
					<div>
						{
							openFeedback && 
							<QuestionFeedbackModal onClick={() => setOpenFeedBack(false)} />
						}
					</div>
					{props.testPack.response_type === "text" ? (
						<div
							className='d-flex flex-column align-items-end'
							style={{
								padding: "30px 80px 0px 80px",
							}}>
							<span className='description' style={{ color: "#96999C" }}>
								{charLeft} characters left
							</span>
							<Input
								maxLength={maxChar}
								style={{ width: "100%" }}
								textarea
								disabled={submitted}
								label=' '
								value={textArea}
								inputStyle={{ height: "360px", backgroundColor: submitted && "white"  }}
								onChange={handleChange}
							/>
						</div>
					) : props.testPack.response_type === "video" ? (
						<div>
							<VideoRecorder
								submitted={submitted}
								setDisabled={setDisabled}
								width='640px'
								height='480px'
							/>
						</div>
					) : (
						<div
							style={{
								width: "fit-content",
								height: "fit-content",
								marginLeft: "80px",
								marginTop: "30px",
							}}>
							{props.testPack.choices.map((choice, index) => {
								return (
									<div
										onClick={timeOut ? () => {} : () => handleSelected(index)}
										className={
											timeOut
												? "d-flex align-items-center mcq-time-up"
												: "d-flex align-items-center mcq-select pointer"
										}
										key={index}
										style={{
											backgroundColor:
												index === key ? "rgba(0, 180, 216, 0.1)" : "",
										}}>
										<div
											className='d-flex justify-content-center align-items-center'
											style={{
												width: "auto",
												minWidth: "20px",
												height: "auto",
												minHeight: "20px",
												borderRadius: "5px",
												border: "1px solid #00B4D8",
											}}>
											<span style={{ color: "#00B4D8" }}>
												{index === 0
													? "A"
													: index === 1
														? "B"
														: index === 2
															? "C"
															: index === 3
																? "D"
																: "E"}
											</span>
										</div>
										{choice.type === "image" ? (
											<div style={{ marginLeft: "10px", maxWidth: "200px", maxHeight: "200px" }}>
												<img
													src={choice.choice}
													alt=''
													style={{ width: "100%", height: "100%", objectFit: "contain" }}
												/>
											</div>
										) : choice.type === "video" ? (
											<div style={{ marginLeft: "20px" }}>
												<ReactPlayer
													width='100%'
													height='100%'
													controls
													url={choice.choice}
												/>
											</div>
										) : (
											<div
												style={{ marginLeft: "10px" }}
												className='d-flex text-justify'>
												<span>{choice.choice}</span>
											</div>
										)}
									</div>
								);
							})}
						</div>
					)}
					<div style={{ margin: "40px 80px" }}>
						{timeOut ? (
							<div className='d-flex'>
								<Button
									button
									label='Question Timeout'
									style={{ padding: "13px 20px", backgroundColor: "#FC4848" }}
								/>
								<div style={{ marginLeft: "20px" }}>
									<Button
										button
										label='Next'
										style={{ padding: "13px 40px" }}
										loading={getNextLoading}
										onClick={async () => {
											await dispatch(
												candidateTestingActions.submitAnswer(
													testPack.response_type === "video"
														? {
															ques_id: testPack._id,
															response_type: testPack.response_type,
															video: "",
														  }
														: {
															ques_id: testPack._id,
															response_type: testPack.response_type,
															response: "",
														  },
													assessmentId,
												),
											);
											await dispatch(
												candidateTestingActions.getNextQuestion(assessmentId),
											);
											setSubmitted(false);
											setDisabled(true);
											setKey(null);
											setCharLeft(500);

										}}
									/>
								</div>
							</div>
						) : (
							<div >
								{submitted ? (
									<div className='d-flex flex-column'>
										<div className="d-flex ">
											<Button
												button
												label='Answer Submitted'
												style={{
													padding: "13px 20px",
													backgroundColor: "#00D69A",
												}}
											/>
											<div style={{ marginLeft: "20px" }}>
												<Button
													button
													label='Next'
													style={{ padding: "13px 40px" }}
													loading={getNextLoading}
													onClick={async () => {
														await dispatch(
															candidateTestingActions.getNextQuestion(
																assessmentId,
															),
														);
														dispatch(
															candidateTestingActions.setAnswerSubmitted(false),
														);
														dispatch(
															candidateTestingActions.setTimerReset(false),
														);
														setSubmitted(false);
														setDisabled(true);
														setKey(null);
														setCharLeft(500);
													}}
												/>
											</div>
										</div>
										<div className="d-flex align-items-center pointer pt-3" onClick={openModal}>
											<Flag/>
											<span style={{color:"#FC4848"}} className="p1 pl-1">Give Feedback on Question</span>
										</div>
									</div>
								) : (
									<Button
										onClick={disabled ? () => {} : handleSubmit}
										button
										loading={getNextLoading}
										label='Submit'
										style={{
											padding: "13px 31px",
											opacity: disabled ? "0.25" : "1",
										}}
									/>
								)}
							</div>
						)}
					</div>
				</div>
			)}
		</div>
	);
}
