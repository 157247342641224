import React, { useEffect, useState } from "react";
import ProgressBar from "./ProgressBar";
import Button from "../Button";
import { ReactComponent as FaceIcon } from "../../../assets/icons/dashboard/Table/face-id.svg";
import { ReactComponent as ReferenceIcon } from "../../../assets/icons/dashboard/Table/reference-check.svg";
import * as candidateTestingActions from "../../../redux/actions/Candidate/candidate-testing.actions";
import { useDispatch } from "react-redux";
import assessmentStatus from "../../../utilities/Constants";
import BlankPicture from "../../../assets/icons/Userpic/userprofile.svg";

export default function PositionCard(props) {
	const dispatch = useDispatch();

	const [selected, setSelected] = useState(false);

	const handleClick = () => {
		setSelected(!selected && !props.selected);
		props.onClick();
		if (props?.tests) {
			dispatch(
				candidateTestingActions.setPendingTestRightPanel({
					roleName: props.test.role.name,
					tenantName: props.tenantProfile.tenant_name,
					tenantState: props.tenantProfile.state,
					tenantCountry: props.tenantProfile.country,
					roleDetails: props.test.role.role_details,
					test: props.test,
					tenantLogo: props.tenantProfile.logo,
				}),
			);
		}
	};

	const handlePrevious = () => {
		setSelected(!selected && !props.selected);
		props.onPreviousClick();
		if (props?.tests) {
			dispatch(candidateTestingActions.setPreviousTestRightPanel(props.test));
		}
	};

	useEffect(() => {
		setSelected(selected && props.selected);
	}, [props ]);

	if (props.positions) {
		return (
			<div
				className={
					selected ? "selected-position-card pointer" : "position-card pointer"
				}
				onClick={handleClick}
				style={{ marginTop: "30px" }}>
				<div
					className='d-flex flex-column align-items-start justify-content-center'
					style={{ padding: "20px 23px" }}>
					<div className='d-flex '>
						<span className='headline2 text-justify'>
							{props.position.title}
						</span>
					</div>
					{props.position?.tenant_client && (
						<div className='d-flex justify-content-center align-items-center'>
							<img
								src={props.position?.tenant_client.logo || BlankPicture}
								alt=''
								style={{
									width: "16px",
									height: "16px",
									borderRadius: props.position?.tenant_client.logo === null ? "0%" :"50%",
								}}
							/>
							<div style={{ marginLeft: "5px" }}>
								<span className='p2'>{props.position?.tenant_client.name}</span>
							</div>
						</div>
					)}
				</div>
				<div className='d-flex flex-column' style={{ padding: "22px 26px" }}>
					<div style={{ height: "5px" }}>
						<ProgressBar
							barColor='#00D69A'
							completed={100}
							style={{ backgroundColor: "#90EE90", width: "97px" }}
						/>
					</div>
					<div style={{ height: "5px", marginTop: "5px" }}>
						<ProgressBar
							barColor='#FC4848'
							completed={30}
							style={{ backgroundColor: "#FFB6C1", width: "97px" }}
						/>
					</div>
					<div style={{ height: "5px", marginTop: "5px" }}>
						<ProgressBar
							barColor='#F4C20D'
							completed={50}
							style={{ backgroundColor: "yellow", width: "97px" }}
						/>
					</div>
				</div>
			</div>
		);
	} else if (props.tests) {
		return (
			<div
				className={
					selected ? "selected-test-card pointer" : "test-card pointer"
				}
				style={{ marginTop: "30px", padding: "15px 23px" }}
				onClick={
					!props.mobile
						? props.previous
							? handlePrevious
							: handleClick
						: () => {}
				}>
				<div
					className='d-flex flex-column align-items-start justify-content-center'
					onClick={
						props.mobile
							? props.previous
								? handlePrevious
								: handleClick
							: () => {}
					}>
					<span className='headline2'>{props.title}</span>

					<div className='d-flex justify-content-center align-items-center'>
						<img
							src={props.logo || BlankPicture}
							alt=''
							style={{
								width: "16px",
								height: "16px",
								borderRadius: props.logo === null ? "0%" :"50%",
							}}
						/>
						<div style={{ marginLeft: "5px" }}>
							<span className='p2'>{props.companyName}</span>
						</div>
					</div>
				</div>
				{props.previous ? (
					<div className='d-flex align-items-center justify-content-between'>
						<div className='start-test-card' style={{ marginRight: "25px" }}>
							<div
								className='d-flex align-items-center'
								style={{ marginBottom: "7px" }}>
								<span className='description'>Qualification</span>

								<div style={{ height: "5px", marginLeft: "10px" }}>
									<ProgressBar
										barColor={ props.test?.score > "6" || props.test?.score === "10" ? "#00D69A": "#F4C20D"}
										completed={props.test?.score * 10}
										style={{ backgroundColor: props.test?.score > "6" || props.test?.score === "10" ? "#90EE90" : "#F0E68C", width: "97px" }}
									/>
								</div>
							</div>
							<div className='d-flex w-100 justify-content-between'>
								<div>
									<span className='description'>Assessment</span>
								</div>
								<div style={{ width: "15px", height: "15px" }}>
									<FaceIcon
										fill={
											(props.test?.pers_match === null || props.test?.pers_match === undefined) ? "#F4C20D" : props.test?.pers_match ? "#00D69A" : "#FC4848"
										}
									/>
								</div>
								<div style={{ width: "15px", height: "15px" }}>
									<ReferenceIcon
										fill={props.test?.referral_status ? "#00D69A" : "#F4C20D"}
									/>
								</div>
							</div>
						</div>
						<div>
							<span
								className='hero2'
								style={{
									color:
										props.test?.score > "6" || props.test?.score === "10"
											? "#00D69A"
											: "#F4C20D",
								}}>
								{props.test?.score > 0 ? parseFloat(props.test?.score.toString()).toFixed(2) : props.test?.score}
							</span>
						</div>
					</div>
				) : (
					<div className='start-test-card'>
						<div>
							<div className='d-flex' style={{ marginTop: "10px" }}>
								{props.test.status === assessmentStatus.INITIALIZED && (
									<div className='d-flex'>
										<Button
											style={{
												backgroundColor: "#00D69A",
												padding: "3px 28px",
												marginRight: "10px",
											}}
											onClick={() => {
												dispatch(
													candidateTestingActions.setAssessmentId(
														props.test.id,
													),
												);
												dispatch(
													candidateTestingActions.setTestToResume(props.test),
												);
												props.onClickStart();
											}}
											button
											label='Start'
										/>
										<Button
											style={{
												backgroundColor: "#FC4848",
												padding: "3px 20px",
											}}
											button
											label='Decline'
										/>
									</div>
								)}

								{props.test.status === assessmentStatus.IN_PROGRESS && (
									<div className='d-flex'>
										<Button
											style={{
												backgroundColor: "#00B4D8",
												padding: "3px 28px",
												marginLeft: "20px",
											}}
											onClick={() => {
												dispatch(
													candidateTestingActions.setAssessmentId(
														props.test.id,
													),
												);
												dispatch(
													candidateTestingActions.setTestToResume(props.test),
												);
												props.onClickStart();
											}}
											button
											label='Resume'
										/>
									</div>
								)}
							</div>
						</div>
					</div>
				)}
			</div>
		);
	}
}
