import React from "react";
import CountUp from "react-countup";

export default function RoleSummaryCard(props) {
	return (
		<div
			className='w-100 d-flex flex-column justify-content-center align-items-center'
			style={{
				padding: "0px 15px",
				borderRight: "2px solid #C8CBCD",
				...props.style,
			}}>
			<div style={{ marginBottom: "15px" , cursor: props?.image && "pointer"}}>
				<span onClick={props?.image && props.onClick } className='p2'>{props.title || ""}</span>
			</div>
			{props.image ? (
				<div
					className='d-flex justify-content-center headline1 pointer'
					onClick={props.onClick}
					style={props.innerStyle}>
					<img src={props.image} alt='' />
				</div>
			) : (
				<div
					className='d-flex justify-content-center headline1'
					style={props.innerStyle}>
					<CountUp
						start={props.start || 0}
						end={props.end }
						delay={props.delay || 1}
						duration={5}
					/>
					{props.percent && "%"}
				</div>
			)}
		</div>
	);
}
