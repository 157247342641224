import React, { useEffect } from "react";
import { ReactComponent as Logo } from "../assets/icons/logo/Header/Header/rnr/logo.svg";
import Input from "../components/subcomponents/Input";
import Button from "../components/subcomponents/Button";
import * as authActions from "../redux/actions/Auth/authAction";
import { useDispatch, useSelector } from "react-redux";
import gif from "../assets/gifs/rnr-loading.gif";

export default function ReferenceForm(props) {
	const dispatch = useDispatch();

	const isCandidateReference = useSelector(
		(state) => state.user.isCandidateReference,
	);

	const referenceForm = useSelector((state) => state.user.referenceForm);
	const candidateName = useSelector((state) => state.user.candidateName);
	const positionName = useSelector((state) => state.user.positionName);
	const referrerName = useSelector((state) => state.user.referrerName);
	const referrerEmail = useSelector((state) => state.user.referrerEmail);

	const loading = useSelector((state) => state.user.loading);

	const handleChange = (target) => {
		dispatch(authActions.handleChangeReferenceForm(target));
	};

	const handleChangeTextArea = (value, index) => {
		dispatch(authActions.handleChangeReferenceFormTextArea(value, index));
	};

	const handleSubmit = () => {
		dispatch(
			authActions.submitReferenceForm(props.match.params.token, referenceForm),
		);
	};

	useEffect(() => {
		dispatch(authActions.getCandidateReferences(props.match.params.token));
	}, [props.match.params]);

	if (isCandidateReference === null) {
		return (
			<div
				className='d-flex align-items-center justify-content-center'
				style={{
					backgroundColor: "rgb(253, 253, 253)",
					minHeight: "100vh",
				}}>
				<img src={gif} width='50%' height='50%' />
			</div>
		);
	} else if (isCandidateReference === false) {
		return (
			<div
				className='dashboard-layout d-flex justify-content-center align-items-center'
				style={{ padding: "50px 150px " }}>
				<div className='reference-form invisible-scrollbar'>
					<div>
						<Logo />
					</div>
					<h4 style={{ padding: "10px 20px " }}>Thank You!</h4>
				</div>
			</div>
		);
	} else {
		return (
			<div
				className='dashboard-layout d-flex justify-content-center align-items-center'
				style={{ padding: "50px 150px " }}>
				<div className='reference-form invisible-scrollbar'>
					<div>
						<Logo />
					</div>
					<div style={{ paddingBlock: "50px" }} className='d-flex text-justify'>
						<span>
							Welcome to RanknRole {referrerName} ({referrerEmail}). Please
							provide feedback for {candidateName} for the position of{" "}
							{positionName}.
						</span>
					</div>
					<div className='w-100'>
						<div className='d-flex justify-content-between w-100'>
							<Input
								label='Designation'
								name='designation'
								value={referenceForm.designation}
								onChange={(e) => handleChange(e.target)}
								style={{ width: "48%" }}
							/>
							<Input
								label='Company/Institute'
								name='organisation'
								value={referenceForm.company}
								onChange={(e) => handleChange(e.target)}
								style={{ width: "48%" }}
							/>
						</div>
						{referenceForm.data.map((obj, index) => {
							return (
								<div style={{ paddingTop: "30px" }} key={index}>
									<Input
										label={obj.question}
										value={obj.response}
										onChange={(e) =>
											handleChangeTextArea(e.target.value, index)
										}
										textarea
									/>
								</div>
							);
						})}
						<div className='d-flex justify-content-center align-items-center'>
							<Button
								button
								label='Submit'
								textClass='b2'
								className='button-large'
								style={{ padding: "6.5px 67px", margin: "10px" }}
								onClick={handleSubmit}
								loading={loading}
							/>
						</div>
					</div>
				</div>
			</div>
		);
	}
}
