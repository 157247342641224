import React, { useEffect, useState } from "react";
import Bitmoji from "../../../assets/icons/emojis/Bitmoji.png";
import Turtorial from "../../turtorial/Turtorial";
import AnswerBox from "../../subcomponents/dashboard/AnswerBox";
import Button from "../../subcomponents/Button";
import QuestionBox from "../../subcomponents/dashboard/QuestionBox";
import { useDispatch, useSelector } from "react-redux";
import * as candidateTestingActions from "../../../redux/actions/Candidate/candidate-testing.actions";
import { useHistory } from "react-router-dom";
import assessmentStatus from "../../../utilities/Constants";
import ReferenceCheckScreen from "./ReferenceCheckScreen";

export default function CandidateInterview(props) {
	const dispatch = useDispatch();
	const history = useHistory();

	const user = useSelector((state) => state.user.user);
	const testPack = useSelector((state) => state.candidateTesting.testPack);
	const assessmentId = useSelector(
		(state) => state.candidateTesting.assessmentId,
	);
	const test = useSelector((state) => state.candidateTesting.testToResume);
	const loading = useSelector((state) => state.candidateTesting.getNextLoading);
	const isCandidateTestEnded = useSelector(
		(state) => state.candidateTesting.isCandidateTestEnded,
	);
	const candidateUserProfile = useSelector(
		(state) => state.candidateTesting.candidateUserProfile,
	);
	const isReferenceCheck = useSelector(
		(state) => state.candidateTesting.isReferenceCheck,
	);

	const [start, setStart] = useState(false);

	const [completed, setCompleted] = useState(false);

	const [isTurtorial , setTurtorial] = useState(false);


	const startTest = async () => {
		await dispatch(candidateTestingActions.getNextQuestion(assessmentId));

		localStorage.setItem("testStatus", assessmentStatus.IN_PROGRESS);

		setStart(true);
		setTurtorial(false);
	};


	useEffect(() => {
		dispatch(candidateTestingActions.getCandidateUserProfile(user.userId));
	}, [dispatch, user]);

	useEffect(() => {
		if (
			test?.id === assessmentId &&
			test?.status === assessmentStatus.IN_PROGRESS
		) {
			dispatch(candidateTestingActions.getNextQuestion(assessmentId));

			setStart(true);
		}

		if (assessmentId && test) {
			localStorage.setItem("assessmentId", assessmentId);

			localStorage.setItem("testStatus", test.status);
		}
	}, [test, assessmentId]);

	useEffect(() => {
		if (
			(assessmentId && test) ||
			(localStorage.getItem("assessmentId") &&
				localStorage.getItem("assessmentId"))
		) {
			props.welcome();
		}

		if (
			localStorage.getItem("assessmentId") &&
			localStorage.getItem("testStatus") === assessmentStatus.IN_PROGRESS
		) {
			dispatch(
				candidateTestingActions.getNextQuestion(
					localStorage.getItem("assessmentId"),
				),
			);

			dispatch(
				candidateTestingActions.setAssessmentId(
					localStorage.getItem("assessmentId"),
				),
			);

			setStart(true);
		}

		if (localStorage.getItem("testStatus") === assessmentStatus.INITIALIZED) {
			dispatch(
				candidateTestingActions.setAssessmentId(
					localStorage.getItem("assessmentId"),
				),
			);
		}
	}, []);

	useEffect(() => {
		if (isCandidateTestEnded) {
			setCompleted(true);
			setStart(false);
			props.onComplete();
		}
	}, [isCandidateTestEnded]);

	if (
		!assessmentId &&
		!test &&
		!localStorage.getItem("assessmentId") &&
		!localStorage.getItem("assessmentId") &&
		!isCandidateTestEnded
	) {
		history.push("/dashboard");
	}

	// console.log("Ref count is at interview screen" , test?.role?.reference_count);

	// console.log("test is at interview screen" , test)

	if (isReferenceCheck && test?.role?.reference_count !== 0 ) {
		return <ReferenceCheckScreen />;
	} else {
		return (
			<div>
				<div
					className=' flex-column justify-content-center align-items-center'
					style={{ padding: "20px 40px", display: start ? "none" : "flex" }}>
					{console.log("start value is " , start)}
					{isTurtorial ?
						<div
							className=' flex-column justify-content-center align-items-center'
							style={{ padding: "20px 40px", display: start ? "none" : "flex"  ,width:"100%" }}
						>
							<Turtorial
								// setLastSlide = {handleLastSlide}
								start = {start}
								startTest = {startTest}
							/>
						</div>
						:
						<div
							className=' flex-column justify-content-center align-items-center'
							style={{ padding: "20px 40px", display: start ? "none" : "flex" }}
						>
							<div style={{marginTop:"-50px"}}>
								<img src={Bitmoji} alt='' className='bitmoji-size' />
							</div>
							<div
								style={{
									width: "100%",
									height: "auto",
									minHeight: "130px",
									backgroundColor: "white",
									borderRadius: "12px",
									padding: "20px",
									margin: "25px 0px 30px 0px",
								}}>
								{completed ? (
									<span className='headline-responsiveness'>
										<span style={{ color: "#00B4D8" }}>
											{candidateUserProfile?.firstName +
										" " +
										candidateUserProfile?.lastName}
										</span>
								, you’ve completed your interview for the position of{" "}
										<span style={{ color: "#00D69A" }}>{test?.role?.name}</span>
										{"  "}
								successfully. Thank you for your time.
									</span>
								) : (
									<span className='headline-responsiveness'>
										Hi{" "}
										<span style={{ color: "#00B4D8" }}>
											{candidateUserProfile?.firstName +
												" " +
												candidateUserProfile?.lastName}
										</span>
										, I’m Thomas and I’m going to take you through your interview. I
										wish you very best of luck! Press continue if you’re ready.
									</span>
								)}
							</div>
							{(!completed ) && ( //&& !isRoleDetail
								<div className="d-flex align-items-center">
									<div>
										<Button
											onClick={()=> setTurtorial(true)}
											button
											label='Continue to Tutorial'
											style={{ padding: "13px 23px" }}
										/>
									</div>
									<div>
										<Button
											onClick={startTest}
											button
											label='Continue to Assessment'
											style={{ padding: "13px 23px" , marginLeft:"20px" }}
											loading={loading}
										/>
									</div>
								</div>
							)}

							{completed && (
								<div>
									<Button
										onClick={props.handleInterviewSummary}
										button
										label='Submit'
										style={{ padding: "13px 23px" }}
										loading={loading}
									/>
								</div>
							)}

						</div>}

				</div>
				{console.log("start value is " , start)}
				{start && assessmentId && testPack && (
					<div className="w-100">
						<QuestionBox testPack={testPack} mobile={props.mobile} />
						<AnswerBox testPack={testPack} mobile={props.mobile} />
					</div>
				)}
			</div>
		);
	}
}
