import React from "react";
import TeamSetup from "../../../components/onboarding/screens/TeamSetup";

export default function TeamSettings(){
	return(
		<>
			<div className="d-flex" style={{paddingBottom:"10px"}}>
				<span className="b2">List of Team Members</span>
			</div>
			<TeamSetup dashboard/>
		</>
	);
}