import { secure_instance } from "../../../api/axios/axios-config";

export const SET_HAS_CLIENTS = "[Add Clients] SET_HAS_CLIENTS";
export const SET_CLIENT_ID = "[Add Clients] SET_CLIENT_ID";
export const GET_TENANT = "[Add Clients] GET_TENANT";
export const SKIP_CLIENTS = "[Add Clients] SKIP_CLIENTS";
export const MENU_CLICKED = "[Add Clients] MENU_CLICKED";
export const GET_ALL_CLIENTS = "[Add Clients] GET_ALL_CLIENTS";
export const ADD_CLIENT_START = "[Add Clients] ADD_CLIENT_START";
export const CLIENT_DELETED_START = "[Add Clients] CLIENT_DELETED_START";
export const ADD_CLIENT_END = "[Add Clients] ADD_CLIENT_END";
export const CLIENT_DELETED_END = "[Add Clients] CLIENT_DELETED_END";
export const CLIENT_ADD_UPDATE_DELETED_FAILED =
	"[Add Clients] CLIENT_ADD_UPDATE_DELETED_FAILED";
export const SUBMIT_CLIENTS = "[Add Clients] SUBMIT_CLIENTS";
export const CLIENT_UPDATED_END = "[Add Clients] CLIENT_UPDATED_END";
export const CLIENT_UPDATED_START = "[Add Clients] CLIENT_UPDATED_START";

export const submitClients = () => {
	return {
		type: SUBMIT_CLIENTS,
	};
};

export const hasClients = (data) => {
	return async (dispatch) => {
		try {
			await secure_instance.request({
				url: `v1/tenant/${data.user.tenantId}`,
				method: "Patch",
				data: { has_clients: data.hasClients ? "true" : "false" },
			});

			dispatch({
				type: SET_HAS_CLIENTS,
				payload: data.hasClients,
			});
		} catch (e) {
			//
		}
	};
};

export const setClientId = (id) => {
	return {
		type: SET_CLIENT_ID,
		payload: id,
	};
};

export const getTenant = (user) => {
	return async (dispatch) => {
		try {
			if (user.tenantId !== 0) {
				const request = await secure_instance.request({
					url: `v1/tenant/${user.tenantId}`,
					method: "Get",
				});

				dispatch({
					type: GET_TENANT,
					payload: request.data,
				});
			}
		} catch (e) {
			//	console.log("FAIL get tanent");
		}
	};
};

export const skipClients = () => {
	return {
		type: SKIP_CLIENTS,
	};
};

export const addClientsMenuClicked = () => {
	return {
		type: MENU_CLICKED,
	};
};

export const addClient = (name, logo) => {
	return async (dispatch) => {
		const formData = new FormData();
		if (logo) {
			formData.append("logo", logo);
		}
		formData.append("name", name);

		try {
			dispatch({
				type: ADD_CLIENT_START,
			});

			const request = await secure_instance.request({
				url: "v1/tenant_clients/",
				method: "Post",
				data: formData,
			});

			console.log(request);

			dispatch({
				type: ADD_CLIENT_END,
				payload: request.data,
			});
		} catch (e) {
			dispatch({
				type: CLIENT_ADD_UPDATE_DELETED_FAILED,
			});
		}
	};
};

export const getAllClients = () => {
	return async (dispatch) => {
		try {
			const request = await secure_instance.request({
				url: "v1/tenant_clients/",
				method: "Get",
			});

			dispatch({
				type: GET_ALL_CLIENTS,
				payload: request.data,
			});
		} catch (e) {
			//
		}
	};
};

export const deleteClient = (id) => {
	return async (dispatch) => {
		try {
			dispatch({
				type: CLIENT_DELETED_START,
			});

			await secure_instance.request({
				url: `v1/tenant_clients/${id}`,
				method: "Delete",
			});

			dispatch({
				type: CLIENT_DELETED_END,
				payload: id,
			});
		} catch (e) {
			alert(e.response?.data?.message);
			dispatch({
				type: CLIENT_ADD_UPDATE_DELETED_FAILED,
			});
		}
	};
};

export const updateClient = (id, name, logo) => {
	return async (dispatch) => {
		console.log("id", id, logo, name);
		try {
			dispatch({
				type: CLIENT_UPDATED_START,
			});

			const formData = new FormData();
			if (typeof logo === "object") {
				formData.append("logo", logo);
			}
			formData.append("name", name);

			const request = await secure_instance.request({
				url: `v1/tenant_clients/${id}`,
				method: "Patch",
				data: formData,
			});

			dispatch({
				type: CLIENT_UPDATED_END,
				payload: request.data,
			});
		} catch (e) {
			dispatch({
				type: CLIENT_ADD_UPDATE_DELETED_FAILED,
			});
		}
	};
};
