import { secure_instance } from "../../../api/axios/axios-config";

export const SET_ONBOARDING_FORM_STATUS = "SET_ONBOARDING_FORM_STATUS";
export const GET_USER_PROFILE = "[Onboarding] GET_USER_PROFILE";
export const HANDLE_EDIT_PROFILE_CHANGE =
	"[Onboarding] HANDLE_EDIT_PROFILE_CHANGE";
export const UPLOAD_USER_LOGO_END = "[Onboarding] UPLOAD_USER_LOGO_END";
export const UPLOAD_USER_LOGO_START = "[Onboarding] UPLOAD_USER_LOGO_START";
export const UPLOAD_USER_LOGO_FAILED = "[Onboarding] UPLOAD_USER_LOGO_FAILED";
export const UPDATE_USER_PROFILE_START =
	"[Onboarding] UPDATE_USER_PROFILE_START";
export const UPDATE_USER_PROFILE_END = "[Onboarding] UPDATE_USER_PROFILE_END";
export const UPDATE_USER_PROFILE_FAILED =
	"[Onboarding] UPDATE_USER_PROFILE_FAILED";

export const setFormStatus = (obj) => {
	return {
		type: SET_ONBOARDING_FORM_STATUS,
		payload: obj,
	};
};

export const getUserProfile = (user) => {
	return async (dispatch) => {
		try {
			const request = await secure_instance.request({
				url: `v1/user_profile/${user.userId}`,
				method: "get",
			});

			console.log(request.data.data);
			dispatch({
				type: GET_USER_PROFILE,
				payload: request.data,
			});
		} catch (e) {
			//	console.log(e);
		}
	};
};

export const handleEditProfileChange = (data) => {
	return {
		type: HANDLE_EDIT_PROFILE_CHANGE,
		payload: data,
	};
};

export const updateUserProfile = (userId, form, editProfile) => {
	return async (dispatch) => {
		try {
			dispatch({
				type: UPDATE_USER_PROFILE_START,
			});

			await secure_instance.request({
				url: `v1/user_profile/${userId}`,
				method: "Patch",
				data: {
					first_name: form.firstName,
					last_name: form.lastName,
					city: form.city,
					country: form.country,
					state: form.state,
					zip_code: form.zipCode,
				},
			});

			dispatch({
				type: UPDATE_USER_PROFILE_END,
			});

			editProfile();
		} catch (e) {
			dispatch({
				type: UPDATE_USER_PROFILE_FAILED,
			});
		}
	};
};

export const uploadUserLogo = (userId, logo, editProfile) => {
	return async (dispatch) => {
		try {
			dispatch({
				type: UPLOAD_USER_LOGO_START,
			});

			const formData = new FormData();
			formData.append("logo", logo);

			const request = await secure_instance.request({
				url: `v1/user_profile/upload-logo/${userId}`,
				method: "Patch",
				data: formData,
			});

			setTimeout(() => {
				dispatch({
					type: UPLOAD_USER_LOGO_END,
					payload: request.data,
				});

				editProfile();
			}, 3000);
		} catch (e) {
			dispatch({
				type: UPLOAD_USER_LOGO_FAILED,
			});
		}
	};
};
