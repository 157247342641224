import React, { useState } from "react";
import { ReactComponent as FaceIcon } from "../../../assets/icons/dashboard/Table/face-id.svg";
import IMG1 from "../../../assets/images/onboarding/personality/1.png";
import IMG2 from "../../../assets/images/onboarding/personality/2.png";
import IMG3 from "../../../assets/images/onboarding/personality/3.png";
import IMG4 from "../../../assets/images/onboarding/personality/4.png";
import IMG5 from "../../../assets/images/onboarding/personality/5.png";
import IMG6 from "../../../assets/images/onboarding/personality/6.png";
import IMG7 from "../../../assets/images/onboarding/personality/7.png";
import IMG8 from "../../../assets/images/onboarding/personality/8.png";
import IMG9 from "../../../assets/images/onboarding/personality/9.png";
import IMG10 from "../../../assets/images/onboarding/personality/10.png";
import IMG11 from "../../../assets/images/onboarding/personality/11.png";
import IMG12 from "../../../assets/images/onboarding/personality/12.png";
import IMG13 from "../../../assets/images/onboarding/personality/13.png";
import IMG14 from "../../../assets/images/onboarding/personality/14.png";
import IMG15 from "../../../assets/images/onboarding/personality/15.png";
import IMG16 from "../../../assets/images/onboarding/personality/16.png";
import Button from "../Button";
import { useSelector } from "react-redux";


const personalities = [
	{
		id: 1,
		category: "Analysts",
		image: IMG1,
		name: "Architect",
		type: "INJT-A/ INTJ-T",
		description: "Imaginative and strategic thinker, with a plan of everything",
	},
	{
		id: 2,
		category: "Analysts",
		image: IMG2,
		name: "Logician",
		type: "INTP-A/ INTP-T",
		description: "Innovative inventors with an unquenchable thirst for knowledge.",
	},
	{
		id: 3,
		category: "Analysts",
		image: IMG3,
		name: "Commander",
		type: "ENTJ-A/ ENTJ-T",
		description: "Bold,imaginative and strong-willed leaders,always finding a way - or making one.",
	},
	{
		id: 4,
		category: "Analysts",
		image: IMG4,
		name: "Debator",
		type: "ENTP-A/ ENTP-T",
		description: "Smart and curious thinkers who cannot resist an intellectual challenge.",
	},
	{
		id: 5,
		category: "Diplomats",
		image: IMG5,
		name: "Advocate",
		type: "INFJ-A/ INFJ-T",
		description: "Quiet and mystical, yet very inspiring and tireless idealists.",
	},
	{
		id: 6,
		category: "Diplomats",
		image: IMG6,
		name: "Mediator",
		type: "INFP-A/ INFP-T",
		description: "Poetic, kind and altruistic people, always eager to help a good cause.",
	},
	{
		id: 7,
		category: "Diplomats",
		image: IMG7,
		name: "Protagonist",
		type: "ENFJ-A/ ENFJ-T",
		description: "Charismatic and inspiring leaders, able to mesmerize their listeners.",
	},
	{
		id: 8,
		category: "Diplomats",
		image: IMG8,
		name: "Campaigner",
		type: "ENFP-A/ ENFP-T",
		description: "Enthusiastic, creative and sociable free spirits, who can always find a reason to smile.",
	},

	{
		id: 9,
		category: "Sentinels",
		image: IMG9,
		name: "Logistician",
		type: "ISTJ-A/ ISTJ-T",
		description: "Practical and fact-minded individuals, whose reliability cannot be doubted.",
	},
	{
		id: 10,
		category: "Sentinels",
		image: IMG10,
		name: "Defender",
		type: "ISFJ-A/ ISFJ-T",
		description: "Very dedicated and warm protectors, always ready to defend their loved ones.",
	},
	{
		id: 11,
		category: "Sentinels",
		image: IMG11,
		name: "Executive",
		type: "ESTJ-A/ ESTJ-T",
		description: "Excellent administrators, unsurpassed at managing things - or people.",
	},
	{
		id: 12,
		category: "Sentinels",
		image: IMG12,
		name: "Consul",
		type: "ESFJ-A/ ESFJ-T",
		description: "Extraordinary caring, social and popular people, always eager to help.",
	},
	{
		id: 13,
		category: "Explorers",
		image: IMG13,
		name: "Virtuoso",
		type: "ISTP-A/ ISTP-T",
		description: "Bold and practical experimenters, masters of all kinds of tools.",
	},
	{
		id: 14,
		category: "Explorers",
		image: IMG14,
		name: "Adventurer",
		type: "ISFP-A/ ISFP-T",
		description: "Flexible and charming artists, always ready to explore and experience something new.",
	},
	{
		id: 15,
		category: "Explorers",
		image: IMG15,
		name: "Enterpreneur",
		type: "ESTP-A/ ESTP-T",
		description: "Smart, energetic and very perceptive people, who truly enjoy living on the edge.",
	},
	{
		id: 16,
		category: "Explorers",
		image: IMG16,
		name: "Entertainer",
		type: "ESFP-A/ ESFP-T",
		description: "Spontaneous, energetic and enthusiastic people - life is never boring around them.",
	},
];

export default function PersonalityAssessmentCard(props) {

	const personalityData = useSelector(state => state.candidateTesting.personalityData);

	const filteredresult = personalityData?.personality_types.find(o => o.confidence === Math.max(...personalityData?.personality_types?.map(o => o.confidence)));

	const filteredPersonalityAssessment = personalities.find((p) => {
		if(p.type.match(filteredresult?.personality) !== null){
			return {
				id: p.id,
				category: p.category,
				image: p.image,
				name: p.name,
				type: p.type,
				description: p.description
			};
		}
	});

	const [showType, setShowType] = useState(false);

	return (
		<div
			style={{
				width: "100%",
				height: "auto",
				borderRadius: "10px",
				padding: "16px",
				backgroundColor: "white",
			}}>
			<div className="d-flex align-items-center mb-3">
				<div style={{ width: "15px", }}>
					<FaceIcon fill={(props.pers_match === null || props.pers_match === undefined) ? "#F4C20D" : props.pers_match ? "#00D69A" : "#FC4848"} />
				</div>
				<span className='b2 ml-2'>Personality Assessment Result</span>
			</div>
			{props.pers_match === null || props.pers_match === undefined ?
				<div className="d-flex">
					<span className="p1">Personality Test Result Pending</span>
				</div>
				:
				!props.pers_match ?
					<div>
						<div className="d-flex">
							<span className="p1" style={{color:"red"}}>This candidate did not match your required personality types.</span>
						</div>
						<div className="mb-2">
							<Button
								whiteButton
								style={{ padding: "5px 37px", cursor: "pointer" }}
								textStyle={{ color: "#00D69A" }}
								label={showType ? "Hide" : "Show"}
								onClick={() => setShowType(!showType) }

							/>
						</div>
						{ showType &&
						<div style={{width: "100%",height: "149px",opacity:"0.5" ,borderRadius: "12px",padding: "15px",backgroundColor: "white", border: "2px solid #FC4848"}}>
							<div className="d-flex justify-content-between align-items-center">
								<div className="">
									<div >
										<span className="headline3">{filteredPersonalityAssessment?.name|| "Architect"}</span>
									</div>
									<div >
										<span className="description">{filteredPersonalityAssessment?.type || "INJT-A/ INTJ-T"}</span>
									</div>
									<div className="d-flex">
										<span className="description">{filteredPersonalityAssessment?.description || "Imaginative and strategic thinker, with a plan of everything"}</span>
									</div>
								</div>
								<div className="pl-5">
									<img
										src={filteredPersonalityAssessment?.image || IMG1}
										alt=''
										style={{width:"90px", height:"90px"}}
									/>
								</div>
							</div>
						</div>}
					</div>
					:

					<div style={{width: "100%",height: "149px",borderRadius: "12px",padding: "15px",backgroundColor: "white", border: "2px solid #00D69A" }}>
						<div className="d-flex justify-content-between align-items-center">
							<div className="">
								<div >
									<span className="headline3">{filteredPersonalityAssessment?.name || "Architect"}</span>
								</div>
								<div >
									<span className="description">{filteredPersonalityAssessment?.type || "INJT-A/ INTJ-T"}</span>
								</div>
								<div className="d-flex">
									<span className="description">{filteredPersonalityAssessment?.description || "Imaginative and strategic thinker, with a plan of everything"}</span>
								</div>
							</div>
							<div className="pl-5">
								<img
									src={filteredPersonalityAssessment?.image|| IMG1}
									alt=''
									style={{width:"90px", height:"90px"}}
								/>
							</div>
						</div>
					</div>}
		</div>
	);
}
