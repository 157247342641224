import React, { useEffect } from "react";
import CreditCard from "../../../assets/images/dashboard/credit-card.svg";
import { useDispatch, useSelector } from "react-redux";
import * as Actions from "../../../redux/actions/Dashboard/BillingInfo";

export default function CurrentPlanCard(props){

	const dispatch = useDispatch();

	const form = useSelector(state => state.billing.invoiceForm);

	// console.log(form, "form");

	useEffect(() => {
		dispatch(Actions.getInvoices());
	},[dispatch]);

	
	return(
		<div className="current-plan-card">
			
			<div className="mobile-padding d-flex flex-column align-items-start w-100">
				<div className="d-flex justify-content-between align-items-center w-100" style={{paddingBottom:"10px"}}>
					<div>
						<span className="p2" style={{color: "#56585A"}}>Current Subscription Stats</span>
					</div>
					<div>
						<img
							src={CreditCard}
							alt=""
							style={{width:"35px", height:"26px"}}
							onClick={props.alertclick}
						/>
					</div>
				</div>
				<div className="d-flex justify-content-between align-items-center  w-100" style={{paddingBlock:"10px"}}>
					<div>
						<span className="p1" >Current Assessment Count</span>
					</div>
					<div style={{marginTop:"5px"}}>
						<span className="p1">{form?.assess_count}</span>
					</div>
				</div>
				<div className="d-flex justify-content-between align-items-center  w-100" style={{borderBottom: "1px solid #C8CBCD", paddingBottom:"10px"}}>
					<div>
						<span className="p1" >Avg. Assessment Cost</span>
					</div>
					<div style={{marginTop:"5px"}}>
						<span className="p1">${form?.avg_cost === null ? "0" : form?.avg_cost}</span>
					</div>
				</div>
				<div className="d-flex justify-content-between align-items-center  w-100" style={{paddingTop:"15px"}} >
					<div>
						<span className="headline3" >Total Accumulated Cost</span>
					</div>
					<div style={{marginTop:"5px"}}>
						<span className="headline3">${form?.total_cost}</span>
					</div>
				</div>
			</div>
		</div>
	);
}