import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { useHistory, useRouteMatch } from "react-router-dom";
import NotificationIcon from "../../assets/icons/notification/bell.svg";
import NotificationBox from "../subcomponents/dashboard/NotificationBox";

export default function Notifications(props) {
	const history = useHistory();
	const match = useRouteMatch();
	const ref = useRef();

	const [isOpen, setIsOpen] = useState(false);

	const notifications = useSelector(
		(state) => state.notificationReducer.notifications,
	);

	const unReadNotifications = [];
	notifications.map((notification) => {
		if (notification.status === "UNREAD") {
			unReadNotifications.push(notification);
		}
	});

	useEffect(() => {
		const checkIfClickedOutside = e => {
			if (isOpen && ref.current && !ref.current.contains(e.target)) {
				setIsOpen(false);
			}
		};
	
		document.addEventListener("mousedown", checkIfClickedOutside);
	
		return () => {
			document.removeEventListener("mousedown", checkIfClickedOutside);
		};
	}, [isOpen]);
	

	const showNotifications = () => {
		setIsOpen(!isOpen);
	};

	const mobileNotifications = () => {
		history.push(`${match.path}/notifications`);
	};

	return (
		<div style={{ position: "relative", cursor: "pointer" }} ref={ref}>
			<div
				className='d-flex align-items-center justify-content-center'
				onClick={props.mobile ? mobileNotifications : showNotifications}
				style={{
					width: "38px",
					height: "38px",
					borderRadius: "50%",
					backgroundColor: "white",
					position: "relative",
				}}>
				<img src={NotificationIcon} />
				{unReadNotifications.length > 0 && (
					<div
						style={{
							position: "absolute",
							width: "4px",
							height: "4px",
							borderRadius: "50%",
							backgroundColor: "#FC4848",
							top: "8px",
							right: "10px",
						}}></div>
				)}
			</div>
			{isOpen && (
				<div
					style={{
						position: "absolute",
						zIndex: "100",
						right: "0",
						top: "53px",
						display: props.mobile ? "none" : "block",
					}}>
					<NotificationBox mobile={props.mobile} />
				</div>
			)}
		</div>
	);
}
