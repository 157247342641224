/* eslint-disable indent */
/* eslint-disable no-mixed-spaces-and-tabs */
import React, { useEffect, useState } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import Logo from "../../../assets/icons/logo/1.png";
import Logo2 from "../../../assets/icons/logo/Symbol.png";
import { ReactComponent as DashboardIcon } from "../../../assets/icons/Menu/Frame 2.svg";
import { ReactComponent as PositionIcon } from "../../../assets/icons/Menu/Frame 3.svg";
import DashboardMenuIcon from "../../subcomponents/dashboard/DashboardMenuIcon";
import ProfileBtn from "../../subcomponents/Profile_btn";
import { useSelector, useDispatch } from "react-redux";
import * as companySetupActions from "../../../redux/actions/onboarding/companySetup";

export default function CandidateDashboardMenu(props) {
	const dispatch = useDispatch();

	const user = useSelector((state) => state.user.user);

	const dashboardPages = [
		{ label: "Dashboard", route: "" },
		{ label: "Positions", route: "candidate-positions" },
	];

	const match = useRouteMatch();
	const history = useHistory();

	const menu = [
		{ icon: <DashboardIcon />, label: "Dashboard" },
		{ icon: <PositionIcon />, label: "Positions" },
	];

	const [selectedPage, setSelectedPage] = useState({
		pageIndex: 0,
	});

	const setOptions = (index) => {
		history.push(`${match.path}/${dashboardPages[index].route}`);
		setSelectedPage({ pageIndex: index, pageSubmenuIndex: null });

		props.getSelectedItem(index, dashboardPages[index].label);

		props.onClick();
	};

	// const setSubOption = (pageIndex, subPageIndex) => {
	// 	history.push(
	// 		`${match.path}/${dashboardPages[pageIndex].route}/${
	// 			dashboardPages[subPageIndex + menu.length].route
	// 		}`,
	// 	);
	// 	setSelectedPage({ ...selectedPage, pageSubmenuIndex: subPageIndex });
	// };

	// const openSubmenu = (menuItemIndex, mustOpen) => {
	// 	if (menu[menuItemIndex].subMenu) {
	// 		setMenu(
	// 			menu.map((item, itemIndex) =>
	// 				itemIndex === menuItemIndex
	// 					? {
	// 							...item,
	// 							isMenuOpen: mustOpen ? true : !menu[menuItemIndex].isMenuOpen,
	// 					  }
	// 					: item,
	// 			),
	// 		);
	// 	}
	// };

	// const setSubMenuByURL = (menuItemIndex, subMenuItemIndex) => {
	// 	openSubmenu(menuItemIndex, true);
	// 	setSelectedPage({
	// 		pageIndex: menuItemIndex,
	// 		pageSubmenuIndex: subMenuItemIndex,
	// 	});
	// };

	useEffect(() => {
		const url = history.location.pathname;

		if (url.includes("/dashboard/candidate-positions")) {
			setSelectedPage({ pageIndex: 1 });
			props.getSelectedItem(1, "Positions");
		} else if (url.includes("/candidate-interview")) {
			props.getSelectedItem(2, " ");
		} else if (url.includes("/interview-summary")) {
			props.getSelectedItem(3, " ");
		} else {
			if (url.includes("/user-settings")) {
				props.getSelectedItem(-1, "Settings");
			} else if (url.includes("/notifications")) {
				props.getSelectedItem(-2, " All Notifications");
			} else {
				setSelectedPage({ pageIndex: 0 });
				props.getSelectedItem(0, "Dashboard");
				history.push("/dashboard");
			}
		}
	}, [history.location.pathname]);

	useEffect(() => {
		dispatch(companySetupActions.getTenantProfile(user));
	}, [dispatch, user]);

	return (
		<div
			className='d-flex flex-column justify-content-between'
			style={{ height: "100%" }}>
			{props.isOpen ? (
				<div
					className='d-flex flex-column justify-content-between'
					style={{ height: "100%" }}>
					<div>
						<div style={{ padding: "38px 0px 70px 0px" }}>
							<img src={Logo2} alt='' />
						</div>
						{menu.map((item, index) => {
							return (
								<div
									key={index}
									className='d-flex flex-column align-items-center pointer'
									style={{ marginTop: "5px", marginRight: "15px" }}>
									<div className='d-flex align-items-center'>
										<DashboardMenuIcon
											image={item.icon}
											onClick={() => setOptions(index)}
											selected={index === selectedPage.pageIndex ? true : false}
										/>
									</div>
								</div>
							);
						})}
					</div>
					<div
						className='d-flex justify-content-center align-items-center'
						style={{ marginBottom: "3.47vh" }}>
						<ProfileBtn dashboard menu onClick={props.onSettingsClick} />
					</div>
				</div>
			) : (
				<div
					className='d-flex flex-column justify-content-between'
					style={{ height: "100%" }}>
					<div>
						<div
							className='mobile-menu'
							style={{ padding: "38px 0px 70px 0px" }}>
							<img src={Logo} alt='' />
						</div>
						{menu.map((item, index) => {
							return (
								<div
									key={index}
									className='d-flex flex-column align-items-start'
									style={{ marginTop: "5px", marginLeft: "15px" }}>
									<DashboardMenuIcon
										image={item.icon}
										label={item.label}
										onClick={() => setOptions(index)}
										selected={index === selectedPage.pageIndex ? true : false}
									/>
								</div>
							);
						})}
					</div>
					<div
						className='d-flex flex-column justify-content-center'
						style={{ marginBottom: "4.375em", paddingInline: "15px" }}>
						<ProfileBtn dashboard mobile onClick={props.onSettingsClick} />
					</div>
				</div>
			)}
		</div>
	);
}
