import {
	SUBMIT_SETUP_ROLE_FORM_START,
	SUBMIT_SETUP_ROLE_FORM_END,
} from "../../actions/onboarding/SetupRoleComponent";
const initialState = { modified: false, loading: false };

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case SUBMIT_SETUP_ROLE_FORM_START: {
			return { ...state, loading: true };
		}

		case SUBMIT_SETUP_ROLE_FORM_END: {
			return { ...action.payload, loading: false, modified: !state.modified };
		}

		default:
			return state;
	}
};

export default reducer;
