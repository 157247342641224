import React from "react";
import SwitcherProfile from "./SwitcherProfile";
import { useDispatch, useSelector } from "react-redux";
import * as authActions from "../../../redux/actions/Auth/authAction";
import { useHistory } from "react-router-dom";
import * as clientFilterActions from "../../../redux/actions/Dashboard/ClientFilter.actions";

export default function Switcher(props) {
	const dispatch = useDispatch();

	const history = useHistory();

	const user = useSelector((state) => state.user.user);
	const tenantProfiles = useSelector(
		(state) => state.user.tenantProfilesOfUser,
	);
	const switcherLoading = useSelector((state) => state.user.switcherLoading);

	const tenantProfilesForSwitcher = [];
	tenantProfiles?.map((tenantProfile) => {
		tenantProfilesForSwitcher.push({
			name: tenantProfile.tenant_name,
			avatar: tenantProfile.logo,
			tenantId: tenantProfile.tenant_id,
		});
	});

	const handleSwitchTenantClicked = (tenantId) => {
		if (user.tenantId === tenantId) {
			return;
		} else {
			dispatch(clientFilterActions.clientFilterRemoved());
			dispatch(authActions.switchTenantAccount(tenantId));
		}
	};

	const handleClick = () => {
		history.push(`/dashboard/user-settings`);
		props.onClick();
	};

	return (
		<div className='profile-btn-switcher'>
			<div
				className='w-100 d-flex flex-column align-items-start'
				style={{ borderBottom: "1px solid #EAEDEE", marginBottom: "8px" }}>
				<span
					className='description'
					style={{ color: "#96999C", margin: "0px 0px 10px 0px" }}>
					Switch Account
				</span>
				<div className='w-100'>
					{tenantProfilesForSwitcher.map((tenantProfile, index) => {
						return (
							<SwitcherProfile
								key={index}
								avatar={tenantProfile.avatar}
								name={tenantProfile.name}
								selected={user.tenantId === tenantProfile.tenantId}
								onClick={() =>
									handleSwitchTenantClicked(tenantProfile.tenantId)
								}
								loading={switcherLoading}
							/>
						);
					})}
				</div>
			</div>
			<div className='pointer' onClick={handleClick}>
				<span className='description'>Settings</span>
			</div>
			<div className='pointer'>
				<span
					className='description'
					style={{ color: "#00B4D8" }}
					onClick={() => dispatch(authActions.signOut())}>
					Logout
				</span>
			</div>
		</div>
	);
}
