import React, { useEffect, useState } from "react";

/**
 *
 * @param {Object} props Props object
 * @param {number} props.completed completed
 */
export default function ProgressBar(props) {
	const [progress, setProgress] = useState(0);

	useEffect(() => {
		setProgress(props.completed);
	}, [props.completed]);

	return (
		<>
			<div className='progress-container' style={props.style}>
				<div
					style={{
						width: `${progress}px`,
						height: "5px",
						backgroundColor: `${props.barColor}`,
						...props.BarStyle,
					}}
					className='progress-c-bar'></div>
			</div>
		</>
	);
}
