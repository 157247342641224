import React from "react";
import MenuDropdown from "../../../components/onboarding/MenuDropdown";
import { Route, useRouteMatch, useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import Roles from "../../../components/onboarding/screens/SetupRoles/Roles";
import Qualification from "../../../components/onboarding/screens/SetupRoles/Qualification";
import Personality from "../../../components/onboarding/screens/SetupRoles/PersonalityAssesment";
import Reference from "../../../components/onboarding/screens/SetupRoles/ReferenceChecks";
import Result from "../../../components/onboarding/screens/SetupRoles/Result";

export default function CreateNewPositionMobile() {
	const history = useHistory();

	const { path } = useRouteMatch();
	const isPath = (p, exact) =>
		useRouteMatch({ path: `/dashboard/create-new-position${p}`, exact })
			? true
			: false;

	const isDashboardSubmitted = useSelector(
		(state) => state.setupRoles.isDashboardSubmitted,
	);

	const isQualificationSubmitted = useSelector(
		(state) => state.setupRoles.isQualificationSubmitted,
	);

	const isPersonalitySubmitted = useSelector(
		(state) => state.setupRoles.isPersonalitySubmitted,
	);

	const isReferenceCheckSubmitted = useSelector(
		(state) => state.setupRoles.isReferenceCheckSubmitted,
	);

	const isRoleSummarySubmitted = useSelector(
		(state) => state.setupRoles.isRoleSummarySubmitted,
	);

	return (
		<div className='onboarding_layout'>
			<div className='onboarding_left_panel invisible-scrollbar'>
				<div
					className='headline1'
					style={{ paddingBottom: "18px", color: "#2B3340" }}>
					Onboarding
				</div>
				<div>
					<MenuDropdown
						label='Setup Role'
						selected={isPath("/setup-roles", false || isPath("", true))}
						done={isDashboardSubmitted}
						onClickDone={() =>
							history.push("/dashboard/create-new-position/setup-roles")
						}
					/>
					<div
						className='onboarding_right_panel invisible-scrollbar'
						style={{
							display:
								isPath("/setup-roles", false) || isPath("", true)
									? "block"
									: "none",
						}}>
						<Route exact path={[`${path}`, `${path}/`, `${path}/setup-roles`]}>
							<Roles dashboard />
						</Route>
					</div>
					<MenuDropdown
						label='Qualification'
						selected={isPath("/qualifications", false)}
						done={isQualificationSubmitted}
						onClickDone={() =>
							history.push("/dashboard/create-new-position/qualifications")
						}
					/>

					<div
						className='onboarding_right_panel invisible-scrollbar'
						style={{
							display: isPath("/qualifications", false) ? "block" : "none",
						}}>
						<Route exact path={`${path}/qualifications`}>
							<Qualification dashboard />
						</Route>
					</div>
					<MenuDropdown
						label='Personality Assessment'
						selected={isPath("/personality-assessment", false)}
						done={isPersonalitySubmitted}
						onClickDone={() =>
							history.push(
								"/dashboard/create-new-position/personality-assessment",
							)
						}
					/>

					<div
						className='onboarding_right_panel invisible-scrollbar'
						style={{
							display: isPath("/personality-assessment", false)
								? "block"
								: "none",
						}}>
						<Route exact path={`${path}/personality-assessment`}>
							<Personality dashboard />
						</Route>
					</div>
					<MenuDropdown
						label='Reference Checks'
						selected={isPath("/reference-checks", false)}
						done={isReferenceCheckSubmitted}
						onClickDone={() =>
							history.push("/dashboard/create-new-position/reference-checks")
						}
					/>

					<div
						className='onboarding_right_panel invisible-scrollbar'
						style={{
							display: isPath("/reference-checks", false) ? "block" : "none",
						}}>
						<Route path={`${path}/reference-checks`}>
							<Reference dashboard />
						</Route>
					</div>
					<MenuDropdown
						label='Role Summary'
						selected={isPath("/result", false)}
						done={isRoleSummarySubmitted}
						onClickDone={() =>
							history.push("/dashboard/create-new-position/result")
						}
					/>
					<div
						className='onboarding_right_panel'
						style={{ display: isPath("/result", false) ? "block" : "none" }}>
						<Route path={`${path}/result`}>
							<Result dashboard />
						</Route>
					</div>
				</div>
			</div>
		</div>
	);
}
