import React, { useEffect, useState } from "react";
import Input from "../../../subcomponents/Input";
import Button from "../../../subcomponents/Button";
import SelectRoleCard from "../../../common/SearchAndSelectRole";
import { useSelector, useDispatch } from "react-redux";
import AlertBox from "../../../AlertBox";
import * as setupRoleActions from "../../../../redux/actions/onboarding/SetupRoles";
import Selected from "../../../subcomponents/Select";
import { Redirect, useHistory } from "react-router-dom";
import * as inviteCandidateActions from "../../../../redux/actions/onboarding/inviteCandidate";
import Select from "react-select";
export default function SetupRole(props) {

	const states = [
		{
			"label": "Alabama",
			"value": "AL"
		},
		{
			"label": "Alaska",
			"value": "AK"
		},
		{
			"label": "American Samoa",
			"value": "AS"
		},
		{
			"label": "Arizona",
			"value": "AZ"
		},
		{
			"label": "Arkansas",
			"value": "AR"
		},
		{
			"label": "California",
			"value": "CA"
		},
		{
			"label": "Colorado",
			"value": "CO"
		},
		{
			"label": "Connecticut",
			"value": "CT"
		},
		{
			"label": "Delaware",
			"value": "DE"
		},
		{
			"label": "District Of Columbia",
			"value": "DC"
		},
		{
			"label": "Federated States Of Micronesia",
			"value": "FM"
		},
		{
			"label": "Florida",
			"value": "FL"
		},
		{
			"label": "Georgia",
			"value": "GA"
		},
		{
			"label": "Guam",
			"value": "GU"
		},
		{
			"label": "Hawaii",
			"value": "HI"
		},
		{
			"label": "Idaho",
			"value": "ID"
		},
		{
			"label": "Illinois",
			"value": "IL"
		},
		{
			"label": "Indiana",
			"value": "IN"
		},
		{
			"label": "Iowa",
			"value": "IA"
		},
		{
			"label": "Kansas",
			"value": "KS"
		},
		{
			"label": "Kentucky",
			"value": "KY"
		},
		{
			"label": "Louisiana",
			"value": "LA"
		},
		{
			"label": "Maine",
			"value": "ME"
		},
		{
			"label": "Marshall Islands",
			"value": "MH"
		},
		{
			"label": "Maryland",
			"value": "MD"
		},
		{
			"label": "Massachusetts",
			"value": "MA"
		},
		{
			"label": "Michigan",
			"value": "MI"
		},
		{
			"label": "Minnesota",
			"value": "MN"
		},
		{
			"label": "Mississippi",
			"value": "MS"
		},
		{
			"label": "Missouri",
			"value": "MO"
		},
		{
			"label": "Montana",
			"value": "MT"
		},
		{
			"label": "Nebraska",
			"value": "NE"
		},
		{
			"label": "Nevada",
			"value": "NV"
		},
		{
			"label": "New Hampshire",
			"value": "NH"
		},
		{
			"label": "New Jersey",
			"value": "NJ"
		},
		{
			"label": "New Mexico",
			"value": "NM"
		},
		{
			"label": "New York",
			"value": "NY"
		},
		{
			"label": "North Carolina",
			"value": "NC"
		},
		{
			"label": "North Dakota",
			"value": "ND"
		},
		{
			"label": "Northern Mariana Islands",
			"value": "MP"
		},
		{
			"label": "Ohio",
			"value": "OH"
		},
		{
			"label": "Oklahoma",
			"value": "OK"
		},
		{
			"label": "Oregon",
			"value": "OR"
		},
		{
			"label": "Palau",
			"value": "PW"
		},
		{
			"label": "Pennsylvania",
			"value": "PA"
		},
		{
			"label": "Puerto Rico",
			"value": "PR"
		},
		{
			"label": "Rhode Island",
			"value": "RI"
		},
		{
			"label": "South Carolina",
			"value": "SC"
		},
		{
			"label": "South Dakota",
			"value": "SD"
		},
		{
			"label": "Tennessee",
			"value": "TN"
		},
		{
			"label": "Texas",
			"value": "TX"
		},
		{
			"label": "Utah",
			"value": "UT"
		},
		{
			"label": "Vermont",
			"value": "VT"
		},
		{
			"label": "Virgin Islands",
			"value": "VI"
		},
		{
			"label": "Virginia",
			"value": "VA"
		},
		{
			"label": "Washington",
			"value": "WA"
		},
		{
			"label": "West Virginia",
			"value": "WV"
		},
		{
			"label": "Wisconsin",
			"value": "WI"
		},
		{
			"label": "Wyoming",
			"value": "WY"
		},
		{
			"label": "Punjab",
			"value": "Punjab"
		},
		{
			"label": "Sindh",
			"value": "Sindh"
		},
		{
			"label": "Balochistan",
			"value": "Balochistan"
		},
		{
			"label": "Azad Jammu and Kashmir",
			"value": "Azad Jammu and Kashmir"
		},
		{
			"label": "Islamabad Capital Territory",
			"value": "Islamabad Capital Territory"
		}
	];

	// const options = [
	// 	{ value: "chocolate", label: "Chocolate" },
	// 	{ value: "strawberry", label: "Strawberry" },
	// 	{ value: "vanilla", label: "Vanilla" }
	// ];
	const [hourlyRate, setHourlyRate] = useState("");
	const [salary, setSalary] = useState("");
	const [errors, setErrors] = useState({});
	const dispatch = useDispatch();

	const history = useHistory();

	const positionForEditCriteria = useSelector(
		(state) => state.clientFilter.positionForCandidateRightPanel,
	);
	const roleTemplateForEditCriteria = useSelector(
		(state) => state.clientFilter.roleTemplateForCriteria,
	);
	const categorySugestions = useSelector((state) => state.setupRoles.categorySuggestions);
	const isEditRole = useSelector((state) => state.clientFilter.isEditRole);

	const roleTemplates = useSelector((state) => state.setupRoles.roleTemplates);
	const roleTemplatesForSelect = [];
	roleTemplates.map((roleTemplate) => {
		roleTemplatesForSelect.push({
			id: roleTemplate.id,
			title: roleTemplate.name,
			details: roleTemplate.description,
		});
	});

	const form = useSelector((state) => state.setupRoles.setupRoleForm);
	const hasClients = useSelector((state) => state.clients.hasClients);

	const allClients = useSelector((state) => state.clients.allClients);
	const allClientsForSelect = [];
	allClients.map((client) => {
		allClientsForSelect.push({
			label: client.name,
			value: client.id,
			icon: client.logo,
		});
	});

	const setupRoleFormIsSubmitted = useSelector(
		(state) => state.setupRoles.isSubmitted,
	);
	const addClientsIsSubmitted = useSelector(
		(state) => state.clients.isSubmitted,
	);
	const isModifyRequested = useSelector(
		(state) => state.setupRoles.isModifyRequested,
	);

	const inviteCandidateRoles = useSelector(
		(state) => state.inviteCandidateReducer.roles,
	);

	useEffect(() => {
		dispatch(setupRoleActions.getRoleTemplates());
		dispatch(inviteCandidateActions.getRoles());
		dispatch(setupRoleActions.getCategories());
	}, [dispatch]);

	useEffect(() => {
		if (isEditRole) {
			dispatch(
				setupRoleActions.setSetupRoleFormForEdit({
					positionForEditCriteria,
					roleTemplateForEditCriteria,
				}),
			);
		}
	}, [
		dispatch,
		isEditRole,
		positionForEditCriteria,
		roleTemplateForEditCriteria,
	]);

	const getSelectedRole = (selectedRole) => {
		if (selectedRole) {
			dispatch(
				setupRoleActions.setSetupRoleForm({
					name: "selectedRoleId",
					value: selectedRole.id,
				}),
			);
			dispatch(
				setupRoleActions.setSetupRoleForm({
					name: "roleTitle",
					value: selectedRole.title,
				}),
			);
			dispatch(
				setupRoleActions.setSetupRoleForm({
					name: "roleTemplateDetails",
					value: selectedRole.details,
				}),
			);
		} else {
			dispatch(
				setupRoleActions.setSetupRoleForm({
					name: "selectedRoleId",
					value: null,
				}),
			);
			dispatch(
				setupRoleActions.setSetupRoleForm({
					name: "roleTitle",
					value: "",
				}),
			);
			dispatch(
				setupRoleActions.setSetupRoleForm({
					name: "roleTemplateDetails",
					value: "",
				}),
			);
		}
	};

	const handleChange = (target) => {
		if(target.name == "hourlyRate"){
			setHourlyRate(target.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1').replace(/(\.\d{2}).+/g, '$1'));
		}
		if(target.name == "salary"){
			setSalary(target.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1').replace(/(\.\d{2}).+/g, '$1'));
		}
		dispatch(setupRoleActions.setSetupRoleForm(target));
	};

	const handleSelectChange = (target) => {
		dispatch(setupRoleActions.handleSelectChange(target));
	};

	const validation = (values) => {
		const errors = {};

		if (!values.roleName) {
			errors.roleName = "Role name is required";
		}
		if (!values.selectedRoleId) {
			errors.selectedRoleId = "Please select a role template";
		}
		if (!values.country) {
			errors.country = "Country name is required";
		}
		if (!values.city) {
			errors.city = "City name is required";
		}
		if (!values.state) {
			errors.state = "State name is required";
		}
		if (!values.zipCode) {
			errors.zipCode = "Zip Code is required";
		}
		if (!values.roleDetails) {
			errors.roleDetails = "Role Details are required";
		}
		if (values.hourlyRate <= 0) {
			errors.hourlyRate = "Hourly rate should be greater than 0.";
		}
		if (values.salary <= 0) {
			errors.salary = "Salary rate should be greater than 0.";
		}
		return errors;
	};

	const handleSubmit = () => {
		setErrors(validation(form));

		const errors = validation(form);

		if (Object.keys(errors).length === 0) {
			if (props.dashboard) {
				dispatch(setupRoleActions.dashboardSetupRoleCompleted());

				history.push("/dashboard/create-new-position/qualifications");
			} else {
				return dispatch(setupRoleActions.saveSetupRoleForm(true));
			}
		}
	};

	if (props.dashboard) {
		//
	} else {
		if (inviteCandidateRoles.length > 0) {
			dispatch(setupRoleActions.roleAlreadyExists());

			return <Redirect to='/onboarding/inviteCandidate' />;
		} else {
			if (form.isSetupRoleForm) {
				return <Redirect to='/onboarding/setupRoles/qualifications' />;
			} else if (setupRoleFormIsSubmitted && !isModifyRequested) {
				return <Redirect to='/onboarding/inviteCandidate' />;
			} else if (!addClientsIsSubmitted) {
				return <Redirect to='/onboarding/addClients' />;
			}
		}
	}

	return (
		<div>
			<div className='web-title'>
				<div style={{ marginBottom: "20px" }} className='headline1'>
					Setup Role
				</div>
			</div>

			<div>
				{!props.clients ? (
					<div className='d-flex w-100 justify-content-between'>
						<div style={{ width: "100%", marginRight: "10px" }}>
							<Input
								type='text'
								name='roleName'
								asterik
								label='Role Name'
								inputStyle={{
									border: errors.roleName ? "1px solid #FC4848" : "none",
								}}
								value={form.roleName}
								onChange={(e) => handleChange(e.target)}
							/>
							{errors.roleName && (
								<AlertBox
									lineError
									color='error'
									title='Error'
									description={errors.roleName}
								/>
							)}
						</div>
						{hasClients && (
							<div style={{ width: "100%", marginLeft: "20px" }}>
								<div style={{ marginBottom: "-5px" }}>
									<span className='p2'>Clients</span>
									<span style={{ color: "red" }}> *</span>
								</div>
								{isEditRole ? (
									<Selected
										placeholder='Select Client'
										options={[{ value: null, label: "No Client", icon: null }, ...allClientsForSelect]}
										agency
										agencySetup
										updateSelections={(selectedClient) => {
											if (selectedClient) {
												handleChange({
													name: "selectedClientId",
													value: selectedClient.value,
												});
											}
										}}
										selected={allClientsForSelect.find(
											(c) => c.value === form.selectedClientId,
										)}
										inputStyle={{
											width: "100%",
											boxShadow: "none",
											border: errors.clients ? "1px solid #FC4848" : "none",
										}}
									/>
								) : (
									<Selected
										placeholder='Select Client'
										options={[{ value: null, label: "No Client", icon: null }, ...allClientsForSelect]}
										agency
										agencySetup
										updateSelections={(selectedClient) => {
											if (selectedClient) {
												handleChange({
													name: "selectedClientId",
													value: selectedClient.value,
												});
											}
										}}
										selected={allClientsForSelect.find(
											(c) => c.value === form.selectedClientId,
										)}
										inputStyle={{
											width: "100%",
											boxShadow: "none",
											border: errors.clients ? "1px solid #FC4848" : "none",
										}}
									/>
								)}
								{errors.clients && (
									<AlertBox
										lineError
										color='error'
										title='Error'
										description={errors.clients}
									/>
								)}
							</div>
						)}
					</div>
				) : (
					<div>
						<Input
							type='text'
							name='roleName'
							asterik
							label='Role Name'
							inputStyle={{
								border: errors.roleName ? "1px solid #FC4848" : "none",
							}}
							value={form.roleName}
							onChange={(e) => handleChange(e.target)}
						/>
						{errors.roleName && (
							<AlertBox
								lineError
								color='error'
								title='Error'
								description={errors.roleName}
							/>
						)}
					</div>
				)}

				<div className='d-flex flex-column' style={{ paddingTop: "20px" }}>
					<div style={{ paddingBottom: "5px" }}>
						<span className='p2'>Choose Role Template</span>
						<span style={{ color: "red" }}> *</span>
					</div>

					{roleTemplates.length > 0 && (
						<SelectRoleCard
							selectedRolesIds={
								form.selectedRoleId ? [form.selectedRoleId] : []
							}
							roles={roleTemplatesForSelect}
							getSelectedRoles={getSelectedRole}
							categorySugestions={categorySugestions}
						/>
					)}
				</div>
				{errors.selectedRoleId && (
					<AlertBox
						lineError
						color='error'
						title='Error'
						description={errors.selectedRoleId}
					/>
				)}
				<Input
					type='text'
					textarea
					asterik
					name='roleDetails'
					label='Role Details'
					inputStyle={{
						border: errors.roleDetails ? "1px solid #FC4848" : "none",
					}}
					style={{ paddingTop: "20px" }}
					value={form.roleDetails}
					onChange={(e) => handleChange(e.target)}
				/>
				{errors.roleDetails && (
					<AlertBox
						lineError
						color='error'
						title='Error'
						description={errors.roleDetails}
					/>
				)}
				<div
					className='w-100 d-flex justify-content-between'
					style={{ paddingTop: "10px" }}>
					<div
						className='d-flex flex-column align-items-start'
						style={{ width: "48%", marginTop: "20px" }}>
						<div style={{ marginBottom: "-5px" }}>
							<span className='p2'>State</span>
							<span style={{ color: "red" }}> *</span>
						</div>

						<Select
							options={states}
							styles={{width:"100%", border:"none"}}
							name="state"
							placeholder={form.state || "State"}
							onChange={(selectedState) => {
								handleSelectChange( selectedState );
							}}
						/>
						{/* <Selected
							varient="customizeSelect"
							name='state'
							value={form.state}
							placeholder={form.state || "States"}
							// containerStyle={{ width: "96%", }}
							// inputStyle={{
							// 	width: "100%",
							// 	border: errors.state ? "1px solid #FC4848" : "none",
							// 	boxShadow: "none",

							// }}
							options={states}
							selected={form.state}
							onSelect={(selectedState) => {
								handleChange({ name: "state", value: selectedState });
							}}
						/> */}
						{errors.state && (
							<AlertBox
								lineError
								color='error'
								title='Error'
								description={errors.state}
							/>
						)}
					</div>
					<div
						className='d-flex flex-column'
						style={{ width: "48%", marginTop: "20px" }}>
						<Input
							type="number"
							asterik
							name='zipCode'
							label='Zip Code'
							style={{ width: "100%" }}
							inputStyle={{
								border: errors.zipCode ? "1px solid #FC4848" : "none",
							}}
							value={form.zipCode}
							onChange={(e) => handleChange(e.target)}
						/>
						{errors.zipCode && (
							<AlertBox
								lineError
								color='error'
								title='Error'
								description={errors.zipCode}
							/>
						)}
					</div>
				</div>
				<div
					className='w-100 d-flex justify-content-between'
					style={{ paddingTop: "10px" }}>
					<div
						className='d-flex flex-column'
						style={{ width: "48%", marginTop: "20px" }}>
						<Input
							asterik
							name='city'
							label='City'
							style={{ width: "100%" }}
							inputStyle={{
								border: errors.city ? "1px solid #FC4848" : "none",
							}}
							value={form.city}
							onChange={(e) => handleChange(e.target)}
						/>
						{errors.city && (
							<AlertBox
								lineError
								color='error'
								title='Error'
								description={errors.city}
							/>
						)}
					</div>
					<div
						className='d-flex flex-column'
						style={{ width: "48%", marginLeft: "35px" }}>
						<Input
							name='country'
							asterik
							label='Country'
							inputStyle={{
								border: errors.country ? "1px solid #FC4848" : "none",
							}}
							style={{ width: "100%", marginTop: "20px" }}
							value={form.country || "USA"}
							onChange={(e) => handleChange(e.target)}
						/>
						{errors.country && (
							<AlertBox
								lineError
								color='error'
								title='Error'
								description={errors.country}
							/>
						)}
					</div>
				</div>
				<div
					className='w-100 d-flex justify-content-between'
					style={{ paddingTop: "10px" }}>
					<div
						className='d-flex flex-column'
						style={{ width: "48%", marginTop: "20px" }}>
							<Input
								type="number"
								name='hourlyRate'
								label='Hourly Rate (USD)'
								style={{ width: "100%" }}
								inputStyle={{
									border: errors.hourlyRate ? "1px solid #FC4848" : "none",
								}}
								value={ hourlyRate || form.hourlyRate}
								onChange={(e) => handleChange(e.target)}
							/>
						{errors.hourlyRate && (
								<AlertBox
									lineError
									color='error'
									title='Error'
									description={errors.hourlyRate}
								/>
							)}
					</div>
					<div
						className='d-flex flex-column'
						style={{ width: "48%", marginTop: "20px" }}>
						<Input
							type="number"
							name='salary'
							label='Salary'
							style={{ width: "100%" }}
							inputStyle={{
								border: errors.salary ? "1px solid #FC4848" : "none",
							}}
							value={salary || form.salary}
							onChange={(e) => handleChange(e.target)}
						/>
						{errors.salary && (
								<AlertBox
									lineError
									color='error'
									title='Error'
									description={errors.salary}
								/>
							)}	
					</div>	
				</div>
				<div
					className='d-flex justify-content-center'
					style={{ paddingTop: "40px" }}>
					<Button
						rightArrow
						label='Next'
						textClass='b2'
						className='button-large'
						style={{ padding: "6.5px 69px" }}
						onClick={handleSubmit}
					/>
				</div>
			</div>
		</div>
	);
}
