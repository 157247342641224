import React, { useState } from "react";
import Feature1 from "../assets/images/HowItworks/CostPic.png";

const featureTitle = (title, selected, onClick) => {
	return (
		<div style={{width:"276px", padding:"18px 80px", borderRadius:"8px", background: selected ? "#00B4D8" : "white", cursor:"pointer"}} onClick={onClick}>
			<span className="b1" style={{color: selected ? "white" : "black" }}>{title}</span>
		</div>
	);
};


export default function HighlightedFeatures() {

	const [selected, setSelected] = useState(0);

	const features = [
		"Feature 1",
		"Feature 2",
		"Feature 3",
		"Feature 4",
	];

	const handleSelected = (index) => {
		setSelected(index);
	};

	return (
		<div className="highlighted-features" >
			<div className="mt-4">
				<span>Highlighted Features</span>
			</div>
			<div className="mt-4 mb-5">
				<span className="span1">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc odio in et, lectus sit lorem id integer.</span>
			</div>
			<div className="d-flex w-100 justify-content-between align-items-center"style={{paddingInline:"23vw"}} >
				{
					features.map((feature,index) => {
						return (
							<div key={index} >
								{featureTitle(feature, (index === selected) ? true : false, () => handleSelected(index))}
							</div>
						);
					})
				}
			</div>
			{
				selected === 0 ?
					<div className="selected-features">
						<div  className="">
							<img
								src={Feature1}
								alt=""
							/>
						</div>
						<div className="feature-text">
							<div className="mt-4">
								<span>Feature Number 1</span>
							</div>
							<div className="mt-4 mb-5 text-justify">
								<span className="span1">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Posuere egestas sit ultricies lorem egestas. Sed massa feugiat morbi non massa morbi sem arcu duis. Augue mattis et, rhoncus nulla curabitur. Etiam auctor sed morbi nisi nibh cursus.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Posuere egestas sit ultricies lorem egestas. Sed massa feugiat morbi non massa morbi sem arcu duis. Augue mattis et, rhoncus nulla curabitur. Etiam auctor sed morbi nisi nibh cursus.</span>
							</div>
						</div>
					</div>
					:
					selected === 1 ?
						<div className="selected-features">
							<div  className="">
								<img
									src={Feature1}
									alt=""
								/>
							</div>
							<div className="feature-text">
								<div className="mt-4">
									<span>Feature Number 2</span>
								</div>
								<div className="mt-4 mb-5 text-justify">
									<span className="span1">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Posuere egestas sit ultricies lorem egestas. Sed massa feugiat morbi non massa morbi sem arcu duis. Augue mattis et, rhoncus nulla curabitur. Etiam auctor sed morbi nisi nibh cursus.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Posuere egestas sit ultricies lorem egestas. Sed massa feugiat morbi non massa morbi sem arcu duis. Augue mattis et, rhoncus nulla curabitur. Etiam auctor sed morbi nisi nibh cursus.</span>
								</div>
							</div>
						</div>
						:
						selected === 2 ?
							<div className="selected-features">
								<div  className="">
									<img
										src={Feature1}
										alt=""
									/>
								</div>
								<div className="feature-text">
									<div className="mt-4">
										<span>Feature Number 3</span>
									</div>
									<div className="mt-4 mb-5 text-justify">
										<span className="span1">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Posuere egestas sit ultricies lorem egestas. Sed massa feugiat morbi non massa morbi sem arcu duis. Augue mattis et, rhoncus nulla curabitur. Etiam auctor sed morbi nisi nibh cursus.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Posuere egestas sit ultricies lorem egestas. Sed massa feugiat morbi non massa morbi sem arcu duis. Augue mattis et, rhoncus nulla curabitur. Etiam auctor sed morbi nisi nibh cursus.</span>
									</div>
								</div>
							</div>
							: 
							selected === 3 ?
								<div className="selected-features">
									<div  className="">
										<img
											src={Feature1}
											alt=""
										/>
									</div>
									<div className="feature-text">
										<div className="mt-4">
											<span>Feature Number 4</span>
										</div>
										<div className="mt-4 mb-5 text-justify">
											<span className="span1">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Posuere egestas sit ultricies lorem egestas. Sed massa feugiat morbi non massa morbi sem arcu duis. Augue mattis et, rhoncus nulla curabitur. Etiam auctor sed morbi nisi nibh cursus.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Posuere egestas sit ultricies lorem egestas. Sed massa feugiat morbi non massa morbi sem arcu duis. Augue mattis et, rhoncus nulla curabitur. Etiam auctor sed morbi nisi nibh cursus.</span>
										</div>
									</div>
								</div> :
								<div>
								</div>
			}
			
		</div>
	);
}
