import React, { useState } from "react";
import { ReactComponent as PieChart } from "../../assets/icons/basic/pie-chart-alt.svg";
import { ReactComponent as Clock } from "../../assets/icons/basic/clock.svg";
import { ReactComponent as Checked } from "../../assets/icons/basic/checked.svg";
import { useDispatch, useSelector } from "react-redux";
// import BlankPicture from "../../assets/icons/Userpic/userprofile.svg";
import {Rating} from "react-simple-star-rating";
import Button from "../subcomponents/Button";
import Input from "../subcomponents/Input";
import * as candidateTesting from "../../redux/actions/Candidate/candidate-testing.actions";

export default function InterviewSummaryCard(props) {

	const dispatch = useDispatch();

	const [rating, setRating] = useState(0);
	const [submitted, setSubmitted] = useState(false);

	const [comments, setComments] = useState("");

	const handleRating = (rate) => {
		setRating(rate);
		setSubmitted(true);
	};

	const assessmentId = useSelector(
		(state) => state.candidateTesting.assessmentId,
	);
	
	const testEnded = useSelector((state) => state.candidateTesting.testEnded);
	console.log(testEnded, rating, assessmentId, comments);

	const handleSubmit = () => {
		
		dispatch(candidateTesting.submitAssessmentFeedback({
			assess_id: assessmentId,
			rating: rating,
			comments: comments,	
		}, props.history, props.match));

	};

	const handleChange = (event) => {
		setComments(event.target.value);
	};

	console.log(comments);

	return (
		<div>
			{props.mobile ? (
				<div className='interview-summary-card d-flex flex-column align-items-center' style={{ paddingTop: "0px" }}>
					
					<div
						
						className='d-flex  w-100'>
						<span className='headline3'>Test Summary</span>
						
					</div>
					<div
						className='d-flex flex-column align-items-center'
						style={{
							width: "calc(100vw - 50px)",
							height: "650px",
							borderRadius: "12px",
							backgroundColor: "white",
							padding: "35px 70px",
							margin: "20px 0px 30px 0px",
						}}>
						<div className='d-flex flex-column' style={{ paddingTop: "75px" }}>
							<div>
								<PieChart style={{ width: "40px", height: "40px" }} />
							</div>
							<span className='headline2'>
								{Math.floor(testEnded?.completionRate)}%
							</span>
							<span className='b3' style={{ color: "#96999C" }}>
								Completion Ratio
							</span>
						</div>
						<div
							className='d-flex flex-column'
							style={{ paddingBlock: "75px" }}>
							<div>
								<Clock style={{ width: "40px", height: "40px" }} />
							</div>
							<span className='headline2'>
								{testEnded?.minutes} minutes {Math.floor(testEnded?.seconds)}{" "}
								seconds
							</span>
							<span className='b3' style={{ color: "#96999C" }}>
								Time Taken
							</span>
						</div>
						<div
							className='d-flex flex-column'
							style={{ paddingBottom: "75px" }}>
							<div>
								<Checked style={{ width: "40px", height: "40px" }} />
							</div>
							<span className='headline2'>{testEnded?.questionsAttempted}</span>
							<span className='b3' style={{ color: "#96999C" }}>
								Questions Attempted
							</span>
						</div>
					</div>
					<div
						style={{ paddingTop: "20px" }}
						className='d-flex flex-column align-items-start'>
						<span className='b2'>Rate Your Experience</span>
						<div
							className='d-flex flex-column align-items-center'
							style={{
								width: "354px",
								height: submitted ? "360px" : "160px",
								borderRadius: "12px",
								backgroundColor: "white",
								
								margin: "20px 0px 30px 0px",
								
							}}
						>
							<div className="w-100" style={{padding: "32px 0px",borderBottom: submitted ? "1px solid #EAEDEE" : "none"}}>
								<Rating ratingValue={rating} onClick={handleRating} size={46} fillColor="#00B4D8">
									<svg width="83" height="80" viewBox="0 0 83 80" fill="none" xmlns="http://www.w3.org/2000/svg">
										<path d="M39.8015 1.73175C40.5841 0.47312 42.4159 0.473121 43.1985 1.73175L56.1473 22.558C56.4226 23.0009 56.86 23.3187 57.3663 23.4437L81.1746 29.323C82.6135 29.6784 83.1796 31.4205 82.2244 32.5537L66.4188 51.3045C66.0827 51.7032 65.9156 52.2174 65.9532 52.7375L67.7187 77.1974C67.8254 78.6756 66.3435 79.7523 64.9706 79.1941L42.2534 69.9564C41.7703 69.7599 41.2297 69.7599 40.7466 69.9564L18.0294 79.1941C16.6565 79.7523 15.1746 78.6756 15.2813 77.1974L17.0468 52.7375C17.0844 52.2174 16.9173 51.7032 16.5812 51.3045L0.775647 32.5537C-0.179557 31.4205 0.386512 29.6784 1.82536 29.323L25.6337 23.4437C26.14 23.3187 26.5774 23.0009 26.8527 22.558L39.8015 1.73175Z" fill="currentColor"/>
									</svg>

								</Rating>
							</div>
							
							{
								submitted && 
								<div className="w-100" style={{padding:"12px 16px"}} >
									<Input
										textarea
										label="Reason for rating"
										labelClass="b2"
										inputStyle={{border:"2px solid #EAEDEE", width:"100%",height:"144px"}}
										onChange={handleChange}
										value={comments}
									/>
								</div>
							}	
							
							
						</div>
						<div className="w-100 d-flex justify-content-center">
							<Button
								onClick={submitted ? handleSubmit : () => {}}
								label="Submit"
								button
								style={{padding:"10px 72px", opacity: submitted ? "1" :"0.25"}}
							/>
						</div>
					</div>
				</div>
			) : (
				<div className='d-flex flex-column align-items-center'>
					<div
						style={{ paddingTop: "60px", borderBottom: "1px solid #C8CBCD", }}
						className='d-flex flex-column align-items-start'>
						<span className='headline1'>Test Summary</span>
						<div
							className='d-flex justify-content-between align-items-center'
							style={{
								width: "915px",
								height: "160px",
								borderRadius: "12px",
								backgroundColor: "white",
								padding: "15px 129px",
								margin: "20px 0px 30px 0px",
							}}>
							<div className='d-flex flex-column'>
								<div>
									<PieChart style={{ width: "40px", height: "40px" }} />
								</div>
								<span className='headline2'>
									{Math.floor(testEnded?.completionRate)}%
								</span>
								<span className='b3' style={{ color: "#96999C" }}>
								Completion Ratio
								</span>
							</div>
							<div className='d-flex flex-column'>
								<div>
									<Clock style={{ width: "40px", height: "40px" }} />
								</div>
								<span className='headline2'>
									{testEnded?.minutes || "0"} minutes {Math.floor(testEnded?.seconds || "0")}{" "}
								seconds
								</span>
								<span className='b3' style={{ color: "#96999C" }}>
								Time Taken
								</span>
							</div>
							<div className='d-flex flex-column'>
								<div>
									<Checked style={{ width: "40px", height: "40px" }} />
								</div>
								<span className='headline2'>{testEnded?.correctQualifications || "0"} of {testEnded?.qualificationTotal  || "0"}</span>
								<span className='b3' style={{ color: "#96999C" }}>
								Answered Correctly
								</span>
							</div>
						</div>
					</div>
					<div
						style={{ paddingTop: "20px" }}
						className='d-flex flex-column align-items-start'>
						<span className='b2'>Rate Your Experience</span>
						<div
							className='d-flex flex-column align-items-center'
							style={{
								width: "915px",
								height: submitted ? "360px" : "160px",
								borderRadius: "12px",
								backgroundColor: "white",
								
								margin: "20px 0px 30px 0px",
								
							}}
						>
							<div className="w-100" style={{padding: "32px 0px",borderBottom: submitted ? "1px solid #EAEDEE" : "none"}}>
								<Rating ratingValue={rating} onClick={handleRating} size={90} fillColor="#00B4D8">
									<svg width="83" height="80" viewBox="0 0 83 80" fill="none" xmlns="http://www.w3.org/2000/svg">
										<path d="M39.8015 1.73175C40.5841 0.47312 42.4159 0.473121 43.1985 1.73175L56.1473 22.558C56.4226 23.0009 56.86 23.3187 57.3663 23.4437L81.1746 29.323C82.6135 29.6784 83.1796 31.4205 82.2244 32.5537L66.4188 51.3045C66.0827 51.7032 65.9156 52.2174 65.9532 52.7375L67.7187 77.1974C67.8254 78.6756 66.3435 79.7523 64.9706 79.1941L42.2534 69.9564C41.7703 69.7599 41.2297 69.7599 40.7466 69.9564L18.0294 79.1941C16.6565 79.7523 15.1746 78.6756 15.2813 77.1974L17.0468 52.7375C17.0844 52.2174 16.9173 51.7032 16.5812 51.3045L0.775647 32.5537C-0.179557 31.4205 0.386512 29.6784 1.82536 29.323L25.6337 23.4437C26.14 23.3187 26.5774 23.0009 26.8527 22.558L39.8015 1.73175Z" fill="currentColor"/>
									</svg>

								</Rating>
							</div>
							
							{
								submitted && 
								<div className="w-100" style={{padding:"12px 16px"}} >
									<Input
										textarea
										label="Reason for rating"
										labelClass="b2"
										inputStyle={{border:"2px solid #EAEDEE", width:"100%",height:"144px"}}
										onChange={handleChange}
										value={comments}
									/>
								</div>
							}	
							
							
						</div>
						<div className="w-100 d-flex justify-content-center">
							<Button
								onClick={submitted ? handleSubmit : () => {}}
								label="Submit"
								button
								style={{padding:"13px 92px", opacity: submitted ? "1" :"0.25"}}
							/>
						</div>
					</div>
					
							
						
					
					
				</div>
			)}
		</div>
	);
}

