/* eslint-disable no-mixed-spaces-and-tabs */
/* eslint-disable indent */
import React, { useEffect, useState } from "react";
import { Fragment } from "react";
import { ReactComponent as ArrowDown } from "../../../assets/icons/Arrows/down.svg";
import Checkbox from "../Checkbox";

/**
 * Select Component
 * @param {object} props Form Select Component
 * @param {Function} updateSelections Update Selected States which Send back the selected option To The Parent(or where you call it)
 * @param {String} varient should be either 'multi-select' or null (for single selection)
 * @param {Array} options array of string (options)
 * @param {Array} selected array of selected option. Pass it an array in which you want to save options
 */

export default function Dropdown(props) {
	const [isOpen, setIsOpen] = useState(false);
	const [selected, setSelected] = useState(props.selected);
	const [multiSelected, setMultiSelect] = useState(props.multiSelected);
	
	const optionSelected = (option) => {
		if (props.varient === "multi-select") {
			const tempArray = [];
			let alreadyExists = false;

			if(multiSelected.length > 0) {
				multiSelected.map((o) => {
					if(o.id === option.id) {
						alreadyExists = true;
					} else {
						tempArray.push(o);
					}
				});

				if(!alreadyExists) {
					tempArray.push(option);
				}
			} else {
				tempArray.push(option);
			}

			setMultiSelect([...tempArray]);

			document.getElementById(`${props.id}`).innerHTML = "";
			tempArray.map((e, i) => {
				if(i === 0) {
					document.getElementById(`${props.id}`).innerHTML = document.getElementById(`${props.id}`).innerHTML + " " + `${e.option}`;
				} else {
					document.getElementById(`${props.id}`).innerHTML = document.getElementById(`${props.id}`).innerHTML + ", " + `${e.option}`;
				}
			});
		} else {
			var dummyArr = [option];
			setSelected(dummyArr);

			document.getElementById(
				`${props.id}` || "dropdownId",
			).innerHTML = `${option}`;
		}

		setIsOpen(!isOpen);
	};

	useEffect(() => {
		if(props.row?.length === 0 && props.multiSelected.length === 0) {
			setMultiSelect([]);
		}
	}, [props.row]);

	return (
		<div
			className={
				"d-flex flex-column align-items-start position-relative " +
				`${props.className}`
			}
			style={{ ...props.style, outline: "none" }}
			tabIndex='0'
			onBlur={() => {
				setIsOpen(false);
			}}>
			<span className='b3'>{props.title}</span>
			<div
				style={{
					width: "100%",
					height: "23px",
					padding: "0px 5px",
					borderBottom: "1px solid #2B3340",
					borderTop: "none",
					borderRight: "none",
					borderLeft: "none",
					backgroundColor: "transparent",
					display: "flex",
					justifyContent: "space-between",
					alignItems: "center",
					...props.style,
				}}>
				{props.varient === "multi-select" ? (
					<div
						id={props.id}
						className='d-inline-block row-cell description align-items-center justify-content-center'
						style={{ overflow: "hidden", width: "100%", height: "100%", textOverflow: "ellipsis", whiteSpace: "nowrap"}}>
					</div>
				) : (
					<div>
						{selected === null ? (
							<span className='description'>
								{props.placeholder || "Select Position"}
							</span>
						) : (
							<div>
								<span
									id={props.id || "dropdownId"}
									className='row-cell description d-flex align-items-center justify-content-center'>
									{selected || props.placeholder || ""}
								</span>
							</div>
						)}
					</div>
				)}

				<ArrowDown
					width='16'
					height='16'
					onClick={props.disable ? () => {} : () => setIsOpen(!isOpen)}
					style={{ cursor: "pointer" }}
				/>
			</div>

			{isOpen && (
				<div
					className='custom-select-dropdown-container invisible-scrollbar'
					style={{ position: "absolute", marginTop: "2em", minWidth:"100%" }}>
					{props.options &&
						props.options.map((option, key) => {
							return (
								<Fragment key={key}>
									{props.varient === "multi-select" ? (
										<div
											className='custom-select-option'
											onClick={
												props.disable
													? () => {}
													: () => {
															optionSelected(option);
															if (props.onSelect) {
																props.onSelect(option);
															}
													  }
											}>
											<span className='row-cell description d-flex align-items-center justify-content-center'>
												<Checkbox
													value={
														!!multiSelected.find((o) => {
															if (o.id === option.id) {
																return true;
															} else {
																return false;
															}
														})
													}
													className='row-cell description'
												/>
												{option.option}
											</span>
										</div>
									) : (
										<div
											className='custom-select-option'
											onClick={
												props.disable
													? () => {}
													: () => {
															optionSelected(option.option);
															if (props.onSelect) {
																props.onSelect(option);
															}
													  }
											}>
											<span className='row-cell description'>
												{option.option}
											</span>
										</div>
									)}
								</Fragment>
							);
						})}
				</div>
			)}
		</div>
	);
}
