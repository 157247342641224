import React from "react";
import { Fragment } from "react";
import CountUp from "react-countup";
import img from "../../../assets/images/dashboard/Image.png";
import Graph from "../../../assets/images/dashboard/Graph.png";

export default function StatisticsCard(props) {
	return (
		<Fragment>
			{props.graph ? (
				<>
					<div className='statistics-card'>
						<div className='d-flex' style={{ padding: "10px" }}>
							<span className='p2'>{props.title || "Lorum Ipsum"}</span>
						</div>
						<div
							className='d-flex justify-content-start headline2'
							style={{ paddingLeft: "10px" }}>
							<CountUp
								start={props.start || 0}
								end={props.end || 0}
								delay={props.delay || 1}
								duration={2}
							/>
							%
						</div>
						<div style={{ marginBottom: "-1px" }}>
							<img
								src={props.image || Graph}
								alt=''
								style={{ width: "100%" }}
							/>
						</div>
					</div>
				</>
			) : (
				<>
					<div className='statistics-card'>
						<div
							className='d-flex justify-content-start'
							style={{ padding: "10px" }}>
							<span className='p2'>{props.title || "Lorum Ipsum"}</span>
						</div>
						<div
							className='d-flex justify-content-between'
							style={{ marginBottom: "-5px", paddingLeft: "10px" }}>
							<div className='hero2 '>
								<CountUp
									start={props.start || 0}
									end={props.end}
									delay={props.delay || 1}
									duration={5}
								/>
							</div>
							<div style={{ marginBottom: "5px" }}>
								<img src={props.image || img} alt='' />
							</div>
						</div>
					</div>
				</>
			)}
		</Fragment>
	);
}
