import React from "react";
import { Fragment } from "react";
import HelpIconComponent from "../../../HelpIconComponent";
import Button from "../../../subcomponents/Button";
import Checkbox from "../../../subcomponents/Checkbox";
import SwitchButton from "../../../subcomponents/ToggleButton";
import SelectNumber from "../../../subcomponents/SelectNumber";
import { useDispatch, useSelector } from "react-redux";
import * as setupRoleActions from "../../../../redux/actions/onboarding/SetupRoles";
import { useHistory } from "react-router-dom";

export default function Reference(props) {
	const dispatch = useDispatch();
	const history = useHistory();

	const form = useSelector((state) => state.setupRoles.setupRoleReferenceForm);

	const handleSelectReferences = (num) => {
		dispatch(setupRoleActions.setNumberOfReferences(num));
	};

	const handleSubmit = () => {
		dispatch(setupRoleActions.submitReferenceCheckForm());

		if (props.dashboard) {
			dispatch(setupRoleActions.referenceCheckCompleted());

			history.push("/dashboard/create-new-position/result");
		} else {
			props.history.push("/onboarding/setupRoles/result");
		}
	};

	const handleToggleReferenceIsChecked = () => {
		return dispatch(setupRoleActions.toggleReferenceIsChecked());
	};
	const handleToggleRefranceIsChecked = () => {
		return dispatch(setupRoleActions.switchAutoMail() );
		
	};


	return (
		<>
			{props.completed ? (
				<Fragment>
					<div
						className='headline3 d-flex'
						style={{ marginTop: "20px", marginBottom: "10px" }}>
						Number of References
					</div>
					<div
						style={{
							width: "100%",
							height: "10px",
							background: "white",
							position: "relative",
							padding: "20px",
							marginBottom: "20px",
							borderRadius: "12px",
						}}
						className='d-flex align-items-center'>
						<div>
							{props.criteria ? props.referenceCount : form.selectedReference}
						</div>
					</div>
				</Fragment>
			) : (
				<Fragment>
					<div className='d-flex justify-content-between'>
						<div style={{ marginBottom: "20px" }} className='headline1'>
							{props.dashboard ? "Reference Checks" : "Setup Role"}
						</div>
						<div>
							<HelpIconComponent />
						</div>
					</div>

					{!props.dashboard && (
						<div className='headline3' style={{ marginBottom: "10px" }}>
							Reference Checks
						</div>
					)}
					<Checkbox
						style={{ marginBottom: "30px" }}
						value={form.isChecked}
						toggle={() => handleToggleReferenceIsChecked()}
						className='p2'
						label='Do you require reference checks for this position?'
					/>

					{form.isChecked ? (
						<div>
							<div className='d-flex justify-content-start align-items-center' >
								<SwitchButton onChange={() => handleToggleRefranceIsChecked()} value={form.isAutoMail} description = "Notify referrers automatically?" className= "p2"/>
								<HelpIconComponent refranceComponent   description1 ="By enabling this, the referrers you add will automatically be emailed about the references."
									description2 ="  If disabled, referrer information will still be taken and stored, but you will manually have to ask them for references."
								/>
							</div>
							<div
								className='headline3 d-flex'
								style={{ margin: "20px 0px 10px 0px" }}>
								Number of References
							</div>
							<SelectNumber
								data={[1, 2, 3, 4]}
								onSelect={(e) => handleSelectReferences(e)}
								selected={form.selectedReference}
							/>

							<div
								className='d-flex justify-content-center'
								style={{ paddingTop: "40px" }}>
								<Button
									rightArrow
									label='Next'
									textClass='b2'
									className='button-large'
									style={{ padding: "6.5px 69px" }}
									onClick={handleSubmit}
								/>
							</div>
						</div>
					) : (
						<Button
							whiteButton
							className='white-button-sm'
							label='Skip'
							onClick={handleSubmit}
						/>
					)}
				</Fragment>
			)}
		</>
	);
}
