import React, { useState, useEffect } from "react";

import Select from "../subcomponents/Select";
import Button from "../subcomponents/Button";
import { Fragment } from "react";


export default function RoleFilterModal(props) {

	const [isOpen, setIsOpen] = useState(props.isOpen);
	const [fillter, setFillter] = useState(props.fillter);

	const suggestions = props.suggestions;

	useEffect(() => {
		setFillter(props.fillter);
		// console.log(fillter, "filter");
	}, [props.fillter]);

	useEffect(() => {
		if (props.isOpen) {
			setIsOpen(props.isOpen);
		}
	}, [props.isOpen]);


	const handleModalEvent = () => {
		props.setIsOpen(false);
		setIsOpen(false);
	};

	const updateStateSelection = (selections) => {
		setFillter({ ...fillter, "category": selections });
	};

	const applyFilter = () => {
		if (typeof props.updateFillter === "function") {
			props.updateFillter(suggestions.category.filter(suggestion => fillter.category.some(cat => cat == suggestion.name)));
		}
		props.setIsOpen(false);
		setIsOpen(false);
	};

	return (
		<Fragment>
			{
				isOpen &&
				<div
					className=" role-modal d-flex justify-content-center align-items-center"
				>
					<div className="modal-component-container justify-content-between" style={{ padding: "20px" }}>
						<div style={{ maxHeight: "max-content", zIndex: "inherit" }}>
							<div style={{ minHeight: "100px" }}>
								<Select
									updateSelections={updateStateSelection}
									varient="multi-select"
									title="Role Category"
									options={suggestions.category.map(cat => cat.name)}
									selected={fillter.category.map(cat => cat.name)}
								/>
							</div>
						</div>

						<div className="d-flex justify-content-center align-items-center">
							<Button
								button
								className="button-small"
								label="Apply"
								style={{ marginRight: "1px", padding:"5px 20px" }}
								onClick={() => applyFilter()}
							/>
							<Button
								whiteButton
								label="Cancel"
								className="white-button-sm b2 pointer"
								style={{padding:"5px 15px"}}
								onClick={() => handleModalEvent()} 
							/>
						</div>

					</div>
				</div>
			}
		</Fragment>

	);
}
