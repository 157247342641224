import React, { useEffect, useState } from "react";
import Bitmoji from "../../../assets/icons/emojis/Bitmoji.png";
import CustomCheckBox from "../../../components/CustomCheckBox";
import Input from "../../subcomponents/Input";
import Button from "../../subcomponents/Button";
import Select from "react-select";
import ReferenceBox from "../../subcomponents/dashboard/ReferenceBox";
import * as candidateTestingActions from "../../../redux/actions/Candidate/candidate-testing.actions";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";

const ReferenceDetails = (props) => {
	const dispatch = useDispatch();

	const handleChange = (target) => {
		dispatch(candidateTestingActions.setNewReferrers(target));
	};

	const options = [
		{id: 1 , label:"Teacher" , value:"Teacher" , name:`relationship${props.number}` },
		{id: 2 , label:"Manager / Former Manager" , value:"Manager / Former Manager" },
		{id: 1 , label:"Team Lead" , value:"Team Lead" },
		{id: 3 , label:"Colleague / Former Colleague" , value:"Colleague / Former Colleague" }
	];
	const candidateReferrers = useSelector(
		(state) => state.candidateTesting.referenceDetailsForm,
	);
	console.log("referrs are " , candidateReferrers);


	return (
		<div className='w-75'>
			<div className='d-flex' style={{ paddingTop: "30px" }}>
				<span className='b1'>{props.number}. Referee Detail</span>
			</div>
			<div style={{ paddingTop: "15px" }}>
				<Input
					label='Name'
					name={`name${props.number}`}
					value={props.name}
					onChange={(e) => handleChange(e.target)}
					asterik
				/>
			</div>
			<div style={{ paddingTop: "15px" }}>
				<Input
					label='Email'
					name={`email${props.number}`}
					value={props.email}
					onChange={(e) => handleChange(e.target)}
					asterik
				/>
			</div>
			<div style={{ paddingTop: "15px" }}>
				{/* <Input
					label='Relationship'
					name={`relationship${props.number}`}
					value={props.relationship}
					onChange={(e) => handleChange(e.target)}
					asterik
				/> */}
				<div className="d-flex justify-content-start" >
					<span className="p2">
					Relationship
					</span>
					<span style={{ color: "red" , marginLeft:"5px" }}> *</span>
				</div>
				<Select
					styles={{width:"100%", border:"none" , display:"flex" }}
					className="p2"
					name={`relationship${props.number}`}
					placeholder="Select Relationship"
					defaultValue={options[2].value}
					options={options}
					onChange={(e) => {
						handleChange( {
							name:`relationship${props.number}`,
							value:e.value ,
						} );
					}}
				/>
			</div>
		</div>
	);
};

export default function ReferenceCheckScreen(props) {
	const dispatch = useDispatch();

	const [checked, setChecked] = useState(false);

	const test = useSelector((state) => state.candidateTesting.testToResume);
	const referenceDetailsForm = useSelector(
		(state) => state.candidateTesting.referenceDetailsForm,
	);
	const candidateReferrers = useSelector(
		(state) => state.candidateTesting.candidateReferrers,
	);
	const candidateUserProfile = useSelector(
		(state) => state.candidateTesting.candidateUserProfile,
	);

	const referenceCount = [];
	for (
		let i = 1;
		i <=
		test?.role?.reference_count -
			referenceDetailsForm.existing_referrers.length;
		i++
	) {
		referenceCount.push({ number: i });
	}

	const handleToggle = () => {
		setChecked(!checked);
	};

	const handleSubmit = () => {
		const newReferrers = [];
		for (
			let i = 1;
			i <=
			test?.role?.reference_count -
				referenceDetailsForm.existing_referrers.length;
			i++
		) {
			if (i === 1) {
				newReferrers.push({
					name: referenceDetailsForm.new_referrers.name1,
					email: referenceDetailsForm.new_referrers.email1,
					relationship: referenceDetailsForm.new_referrers.relationship1,
				});
			} else if (i === 2) {
				newReferrers.push({
					name: referenceDetailsForm.new_referrers.name2,
					email: referenceDetailsForm.new_referrers.email2,
					relationship: referenceDetailsForm.new_referrers.relationship2,
				});
			} else if (i === 3) {
				newReferrers.push({
					name: referenceDetailsForm.new_referrers.name3,
					email: referenceDetailsForm.new_referrers.email3,
					relationship: referenceDetailsForm.new_referrers.relationship3,
				});
			} else if (i === 4) {
				newReferrers.push({
					name: referenceDetailsForm.new_referrers.name4,
					email: referenceDetailsForm.new_referrers.email4,
					relationship: referenceDetailsForm.new_referrers.relationship4,
				});
			}
		}

		dispatch(
			candidateTestingActions.submitReferenceCheck({
				assess_id: test.id,
				date_range: moment.utc().format(),
				existing_referrers: referenceDetailsForm.existing_referrers,
				new_referrers: newReferrers,
				position: test.role.name,
				name:
					candidateUserProfile.firstName + " " + candidateUserProfile.lastName,
			}),
		);
	};

	useEffect(() => {
		dispatch(candidateTestingActions.getCandidateReferrers());
	}, [dispatch]);

	return (
		<div>
			<div className='d-flex align-items-center'>
				<div>
					<img
						src={props.bitmoji || Bitmoji}
						alt=''
						style={{
							width: props.mobile ? "45px" : "60px",
							height: props.mobile ? "45px" : "60px",
						}}
					/>
				</div>

				<div
					className='d-flex justify-content-start align-items-center'
					style={{
						width: props.mobile ? "260px" : "544px",
						height: "auto",
						minHeight: "50px",
						borderRadius: "12px",
						padding: "5px 15px",
						backgroundColor: "rgba(24, 116, 195, 0.2)",
						borderTopLeftRadius: "0px",
						margin: "10px",
						...props.style,
					}}>
					<span className='p1 text-justify'>
						Q. Please provide details for {referenceCount?.length} referees?
					</span>
				</div>
			</div>
			<div
				className='d-flex flex-column align-items-start'
				style={{ paddingLeft: props.mobile ? "0px" : "80px" }}>
				{candidateReferrers?.length > 0 && (
					<div style={{ paddingTop: "20px" }}>
						<CustomCheckBox
							mobile={props.mobile}
							toggle={handleToggle}
							value={checked}
							label='Would you like to provide existing references? Mark all that you like to submit.'
						/>
					</div>
				)}

				{checked && (
					<div>
						{candidateReferrers.map((referrer, key) => {
							return (
								<div key={key} style={{ paddingTop: "25px" }}>
									<ReferenceBox mobile={props.mobile} referrer={referrer} />
								</div>
							);
						})}
					</div>
				)}


				<div
					className={props.mobile ? "w-100" : "w-75"}
					style={{ paddingLeft: props.mobile ? "20px" : "0px" }}>
					{referenceCount.map((ref, key) => {
						return (
							<div key={key}>
								<ReferenceDetails number={ref.number} />
							</div>
						);
					})}
				</div>
				<div
					style={{
						paddingTop: "50px",
						paddingLeft: props.mobile ? "20px" : "0px",
					}}>
					{referenceCount?.length > 0 && (
						<Button
							button
							label='Submit'
							style={{
								padding: "13px 31px",
							}}
							onClick={handleSubmit}
							loading={referenceDetailsForm?.loading}
						/>
					)}
				</div>
			</div>
		</div>
	);
}
