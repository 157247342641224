import React, { useEffect } from "react";
import { Route } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import * as formStatusActions from "../redux/actions/onboarding/formsStatus";
import * as addClientActions from "../redux/actions/onboarding/Clients";
import * as userActions from "../redux/actions/Auth/authAction";
import gif from "../assets/gifs/rnr-loading.gif";

const ProtectedRoute = ({ Component, ...rest }) => {
	const dispatch = useDispatch();

	const user = useSelector((state) => state.user.user);
	const userProfile = useSelector((state) => state.formsStatus.userProfile);

	const subscriptionCompleted = useSelector(state => state.user.subscriptionCompleted);

	// const isTenantSubscribed = useSelector(state => state.user.isTenantSubscribed);
	// console.log(user,"userprofile");

	useEffect(() => {
		dispatch(userActions.getSubscriptions());
	},[dispatch, subscriptionCompleted]);
	
	useEffect(() => {
		dispatch(addClientActions.getTenant(user));
		dispatch(formStatusActions.getUserProfile(user));
		// dispatch(userActions.getSubscriptions());
		
	}, [dispatch, user]);

		
	if (userProfile) {
		return (
			<>
				<Route
					{...rest}
					render={() => {
						return <Component {...rest} />;
					}}
				/>
			</>
		);
	}
		
	return (
		<div
			className='d-flex align-items-center justify-content-center'
			style={{
				backgroundColor: "rgb(253, 253, 253)",
				minHeight: "100vh",
			}}>
			<img src={gif} width='50%' height='50%' />
		</div>
	);
		
};
	

	


export default ProtectedRoute;
