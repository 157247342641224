import React from "react";
import Logo from "../assets/icons/logo/Header/Header/rnr/logo.svg";

export default function PageNotFound() {
	return (
		<div className="assessment-layout-expiry-page" >
			<div className=""
				style={{width:"250px" , cursor:"pointer" , padding:"5px  10px", borderRadius:"12px" , background:"linear-gradient(175.07deg, rgba(255, 255, 255, 0.7) 0%, rgba(255, 255, 255, 0.21) 100%)" , backropFilter:"blur(100px)"}} 
			>
				<img src={Logo} />
			
			</div>
			<div className="d-flex flex-column justify-content-center align-items-center" style={{marginTop:"160px"}} >
				<span className="hero3" style={{color:"#00B4D8"}}> Error 404 </span>
				<span className="p1">Oops! We can’t find the page you’re looking for.</span>
			</div>
		</div>
	);
}
