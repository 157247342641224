import React,{useState} from "react";
import OpenEye from "../../../assets/icons/dashboard/open_eye.png";
import ClosedEye from "../../../assets/icons/dashboard/closed_eye.png";

export default function PasswordInput(props){

	const [passwordVisible,setPasswordVisible] = useState(false);

	return (
		<div className="password_input">
			<div className="password_icon" onClick={()=>setPasswordVisible((visible) => !visible)}>
				<img src={passwordVisible === true ? OpenEye : ClosedEye}/>
			</div>
			<input id="signinpassword" style={{width:"100%"}} type={passwordVisible === true ? "text" : "password"} {...props}/>
		</div>
	);
}


