import { secure_instance } from "../../../api/axios/axios-config";

export const GET_ROLES = "[Invite Candidate] GET_ROLES";
export const UPDATE_INVITE_CANDIDATE_TABLE_ROWS =
	"[Invite Candidate] UPDATE_INVITE_CANDIDATE_TABLE_ROWS";
export const SET_POSITION = "[Invite Candidate] SET_POSITION";
export const HANDLE_SUBMIT = "[Invite Candidate] HANDLE_SUBMIT";
export const MENU_CLICKED = "[Invite Candidate] MENU_CLICKED";
export const ADD_MANUALLY_CLICKED = "[Invite Candidate] ADD_MANUALLY_CLICKED";
export const GET_CANDIDATE_ASSESSMENTS =
	"[Invite Candidate] GET_CANDIDATE_ASSESSMENTS";
export const CANDIDATES_ALREADY_INVITED =
	"[Invite Candidate] CANDIDATES_ALREADY_INVITED";
export const CANDIDATE_INVITED_END = "[Invite Candidate] CANDIDATE_INVITED_END";
export const CANDIDATE_INVITED_START =
	"[Invite Candidate] CANDIDATE_INVITED_START";
export const CANDIDATE_INVITED_FAILED =
	"[Invite Candidate] CANDIDATE_INVITED_FAILED";
export const DELETE_CANDIDATE_INVITED_END =
	"[Invite Candidate] DELETE_CANDIDATE_INVITED_END";
export const DELETE_CANDIDATE_INVITED_START =
	"[Invite Candidate] DELETE_CANDIDATE_INVITED_START";
export const DELETE_CANDIDATE_INVITED_FAILED =
	"[Invite Candidate] DELETE_CANDIDATE_INVITED_FAILED";
export const ACTIVATE_POSITION = "[Invite Candidate] ACTIVATE_POSITION";
export const DEACTIVATE_POSITION = "[Invite Candidate] DEACTIVATE_POSITION";
export const GET_CANDIDATE_TESTS = "[Invite Candidate] GET_CANDIDATE_TESTS";
export const GET_TENANT_PROFILE_FOR_CANDIDATE =
	"[Invite Candidate] GET_TENANT_PROFILE_FOR_CANDIDATE";
export const HANDLE_CHANGE = "[Invite Candidate] HANDLE_CHANGE";

export const getRoles = (search) => {
	return async (dispatch) => {
		try {
			const request = await secure_instance.request({
				url: search ? `v1/positions/?position=${search}` : "v1/positions/",
				method: "Get",
			});

			dispatch({
				type: GET_ROLES,
				payload: request.data,
			});
		} catch (e) {
			//
		}
	};
};

export const handleChange = (data) => {
	return {
		type: HANDLE_CHANGE,
		payload: data,
	};
};

export const updateInviteCandidateTableRows = (rows) => {
	return {
		type: UPDATE_INVITE_CANDIDATE_TABLE_ROWS,
		payload: rows,
	};
};

export const inviteCandidateByEmail = ({ row, positionId }) => {
	return async (dispatch) => {
		try {
			dispatch({
				type: CANDIDATE_INVITED_START,
			});

			const request = await secure_instance.request({
				url: `v1/user/?invite_candidate=${true}`,
				method: "Post",
				data: {
					email: row[1],
					pos_id: positionId,

					//15 is the role for candidate
					role_id: 15,
				},
			});

			dispatch({
				type: CANDIDATE_INVITED_END,
				payload: { data: request.data, email: row[1] },
			});
		} catch (e) {
			dispatch({
				type: CANDIDATE_INVITED_FAILED,
			});
		}
	};
};

export const setPosition = (position) => {
	return {
		type: SET_POSITION,
		payload: position,
	};
};

export const handleSubmit = () => {
	return {
		type: HANDLE_SUBMIT,
	};
};

export const inviteCandidateMenuClicked = () => {
	return {
		type: MENU_CLICKED,
	};
};

export const addManuallyClicked = () => {
	return {
		type: ADD_MANUALLY_CLICKED,
	};
};

export const getCandidateAssesments = (roleId) => {
	return async (dispatch) => {
		try {
			const request = await secure_instance.request({
				url: `v1/assessments/?role_id=${roleId}`,
				method: "Get",
			});

			dispatch({
				type: GET_CANDIDATE_ASSESSMENTS,
				payload: request.data,
			});
		} catch (e) {
			//
		}
	};
};

export const candidatesAlreadyInvited = () => {
	return {
		type: CANDIDATES_ALREADY_INVITED,
	};
};

export const deleteInvitedCandidate = ({ row, positionId }) => {
	return async (dispatch) => {
		try {
			dispatch({
				type: DELETE_CANDIDATE_INVITED_START,
			});

			await secure_instance.request({
				url: `v1/assessments/?user_id=${row[0]}&role_id=${positionId}`,
				method: "Delete",
			});

			dispatch({
				type: DELETE_CANDIDATE_INVITED_END,
			});
		} catch (e) {
			dispatch({
				type: DELETE_CANDIDATE_INVITED_FAILED,
			});
		}
	};
};

export const activatePosition = (positionId) => {
	return async (dispatch) => {
		try {
			const request = await secure_instance.request({
				url: `v1/positions/${positionId}`,
				method: "Patch",
				data: {
					is_active: true,
				},
			});

			dispatch({
				type: ACTIVATE_POSITION,
				payload: request.data,
			});
		} catch (e) {
			//
		}
	};
};

export const deactivatePosition = (positionId) => {
	return async (dispatch) => {
		try {
			const request = await secure_instance.request({
				url: `v1/positions/${positionId}`,
				method: "Patch",
				data: {
					is_active: false,
				},
			});

			dispatch({
				type: DEACTIVATE_POSITION,
				payload: request.data,
			});
		} catch (e) {
			//
		}
	};
};

export const getCandidateTests = () => {
	return async (dispatch) => {
		try {
			const response = await secure_instance.request({
				url: "v1/assessment_sessions/",
				method: "Get",
			});

			const request = await secure_instance.request({
				url: "v1/assessments/",
				method: "Get",
			});
			// console.log("get assessment sesssion in getCandidate Test" , response);
			// console.log("get assessment in getCandidate Test" , request);

			dispatch({
				type: GET_CANDIDATE_TESTS,
				payload: {
					assessments: request.data,
					assessmentSessions: response.data,
				},
			});
		} catch (e) {
			//
		}
	};
};

export const getTenantProfileForCandidate = (tenantId) => {
	return async (dispatch) => {
		try {
			const request = await secure_instance.request({
				url: `v1/tenant_profile/${tenantId}`,
				method: "Get",
			});
			console.log("get tenate profile for candidate hit" , request);

			dispatch({
				type: GET_TENANT_PROFILE_FOR_CANDIDATE,
				payload: request.data.data,
			});
		} catch (e) {
			//
		}
	};
};
