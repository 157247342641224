import React, { useState, useEffect } from "react";
import AlertBox from "../../AlertBox";
import { isEmailValid, isPasswordValid } from "../../../utilities/Validator";
// import baseURL from "../../../utilities/BaseURL";
import PasswordInput from "./PasswordInput";
import { ReactComponent as Add } from "../../../assets/icons/basic/addImage.svg";
import { signUp } from "../../../redux/actions/Auth/authAction";
import { connect, useDispatch, useSelector } from "react-redux";
import { useHistory, Link } from "react-router-dom";
import UserPic from "../../../assets/icons/Userpic/user.svg";
import * as authActions from "../../../redux/actions/Auth/authAction";
import PhoneInput from "react-phone-number-input";

//<AlertBox color="danger" title="Error" description={"Invalid Login"} />
function Form(props) {
	const history = useHistory();
	const dispatch = useDispatch();

	const userEmailByToken = useSelector((state) => state.user.userEmailByToken);
	const roleId = useSelector((state) => state.user.roleIdByToken);
	console.log("role id by token is " , roleId);
	const inviteToken = useSelector((state) => state.user.inviteToken);
	const inviteId = useSelector((state) => state.candidateTesting.inviteId );
	const [image, setImage] = useState(null);

	const fileInput = React.useRef(null);

	const handleClick = () => {
		fileInput.current.click();
	};

	const fileSelectedHandler = (event) => {
		setImage(event.target.files[0]);
		// props.getImage(image);
	};

	const [user, setUser] = useState({
		first_name: null,
		last_name: null,
		email: null,
		password: null,
		confirm_password: null,
		phone: null
	});


	const [errors, setErrors] = useState({
		first_name: false,
		last_name: false,
		email: false,
		password: false,
		confirm_password: false,
		phone:false
	});
	// console.log("user email" , user.email);
	// console.log("error email" , errors.email);

	const [alert, setAlert] = useState();

	function handleInput(event) {
		setUser({ ...user, [event.target.name]: event.target.value });
	}
	function handlePhoneInput(value) {
		setUser({ ...user, ['phone']: value });
	}
	function onBlur(event) {
		if (event.target.name === "email") {
			if (!isEmailValid(user[event.target.name])) {
				setErrors({ ...errors, [event.target.name]: true });
				return;
			}
		}

		if (event.target.name === "password") {
			if (!isPasswordValid(user[event.target.name])) {
				setErrors({ ...errors, [event.target.name]: true });
				return;
			}
		}
		if(event.target.name === "phone"){
			if(event.target.value && event.target.value.length != 12){
				setErrors({ ...errors, [event.target.name]: true });
				return
			}
		}
		setErrors({ ...errors, [event.target.name]: false });
	}

	function validateForm() {
		if (user.first_name === null) {
			return { isValid: false, message: "First Name is missing" };
		}
		if (errors.first_name) {
			return { isValid: false, message: "First Name is Invalid" };
		}

		if (user.last_name === null) {
			return { isValid: false, message: "Last Name is missing" };
		}
		if (errors.last_name) {
			return { isValid: false, message: "Last name is Invalid" };
		}

		if (user.email === null) {
			return { isValid: false, message: "Email is missing" };
		}

		if (errors.email) {
			return { isValid: false, message: "Email is Invalid" };
		}
		if ( user.phone == undefined || user.phone.length != 12 ) {
			return { isValid: false, message: "Phone Number is Invalid" };
		}
		if (user.password === null) {
			return { isValid: false, message: "Password is missing" };
		}

		if (errors.password) {
			return { isValid: false, message: "Weak password" };
		}

		if (user.confirm_password === null) {
			return { isValid: false, message: "Confirm Password is missing" };
		}

		if (user.password !== user.confirm_password) {
			return { isValid: false, message: "Password did not match" };
		}
		return { isValid: true, message: "" };
	}

	function handleSubmit(event) {
		event.preventDefault();
		const validation = validateForm();

		if (!validation.isValid) {
			setAlert(
				<AlertBox
					color='error'
					title='Error'
					description={validation.message}
				/>,
			);
			return;
		}

		if (userEmailByToken || inviteId) {
			dispatch(
				authActions.candidateSignup({
					invite_token: inviteToken,
					password: user.password,
					first_name: user.first_name,
					last_name: user.last_name,
					email:user.email,
					phone:user.phone,
					invite_id:inviteId,
					history,
					role_id:roleId,
					image
				}),
			);
		} else {
			props
				.signUp(user.first_name, user.last_name, user.email, user.password, user.phone)
				.then(() => {
					// dispatch(authActions.signIn(user.email, user.password));
					history.push("/");
				})
				.catch(() => {
					setAlert(
						<AlertBox
							color='error'
							title='Error'
							description='User Already exists'
						/>,
					);
				});
		}

		// fetch(`${baseURL}/v1/auth/register/`, {
		// 	method: "POST",
		// 	headers: {
		// 		"Content-Type": "application/json",
		// 	},
		// 	credentials: "include",
		// 	body: JSON.stringify(user)
		// }).then(async (res) => {

		// 	const json = await res.json();

		// 	if (res.ok) {
		// 		return json;
		// 	} else {

		// 		return Promise.reject(json);
		// 	}

		// }).then(response => {
		// 	//auth.authLogin(response);

		// }).catch(err => {
		// 	setAlert(
		// 		<AlertBox color="danger" title="Error" description={err.message} />
		// 	);
		// });
	}
	useEffect(() => {
		onBlur({ target: { name: "email" } });
	}, []);

	useEffect(() => {
		if (props.match?.params?.token) {
			dispatch(authActions.getEmailByToken(props.match?.params?.token));
		}
	}, [props.match?.params?.token]);

	useEffect(() => {
		setUser({ ...user, email: userEmailByToken });
		setErrors({ ...errors, email: false });
	}, [userEmailByToken , roleId]);

	return (
		<div
			style={{ minHeight: "350px", minWidth: "330px", textAlign: "initial" }}>
			<div style={{ paddingBottom: "9px" }}>
				<span className='h3'>Signup</span>
			</div>
			<div style={{ paddingBottom: "20px" }}>
				<span className='description'>
					Already a Member ?{" "}
					<Link to='/' style={{ color: "#00b4d8" }}>
						Login
					</Link>
				</span>
			</div>
			<div className='SignUpFormGroup'>
				{alert}
				{console.log("alert is " , alert)}
				{console.log("invite id " , inviteId)}
				{(userEmailByToken || inviteId) && (
					<div>
						<div
							onClick={handleClick}
							className='d-flex justify-content-center align-items-center pointer'
							style={{
								width: "69px",
								height: "69px",
								borderRadius: "50%",
								border: "1px solid #96999C",
								marginBottom: "25px",
								position: "relative",
							}}>
							<img
								src={image ? URL.createObjectURL(image) : UserPic}
								alt=''
								style={{
									width: image ? "100%" : "23px",
									height: image ? "100%" : "23px",
									borderRadius: image ? "50%" : "0%",
								}}
							/>
							{!image && (
								<div
									className='d-flex align-items-center justify-content-center pointer'
									style={{
										position: "absolute",
										width: "16px",
										height: "16px",
										borderRadius: "50%",
										backgroundColor: "#96999C",
										bottom: "5px",
										right: "5px",
									}}>
									<Add />
								</div>
							)}
						</div>

						<input
							style={{ display: "none" }}
							type='file'
							onChange={fileSelectedHandler}
							ref={fileInput}></input>
					</div>
				)}

				<input
					type='text'
					placeholder='First Name'
					label=''
					name='first_name'
					onChange={handleInput}
					onBlur={onBlur}
					className={
						"description " +
						(errors.first_name ? "input-underline-error" : "input-underline")
					}
				/>
				<input
					type='text'
					placeholder='Last Name'
					label=''
					name='last_name'
					onChange={handleInput}
					onBlur={onBlur}
					className={
						"description " +
						(errors.first_name ? "input-underline-error" : "input-underline")
					}
				/>
				<input
					type='text'
					placeholder='Email'
					label=''
					name='email'
					defaultValue={userEmailByToken ? userEmailByToken : ""}
					disabled={userEmailByToken ? userEmailByToken : ""}
					onChange={handleInput}
					onBlur={onBlur}
					className={
						"description " +
						(errors.first_name ? "input-underline-error" : "input-underline")
					}
				/>
				<PhoneInput
                    value={user["phone"]}
                    defaultCountry="US"
					name='phone'
					limitMaxLength= {12}
                    onChange={handlePhoneInput}
                    onBlur={onBlur}
                    placeholder="Enter phone number"
					className={
						"description " +
						(errors.phone ? "input-underline-error" : "input-underline")
					}
                    flags={false} />			
				<PasswordInput
					placeholder='Password'
					label=''
					name='password'
					onChange={handleInput}
					onBlur={onBlur}
					className={
						"description " +
						(errors.password ? "input-underline-error" : "input-underline")
					}
				/>
				<PasswordInput
					placeholder='Confirm password'
					label=''
					name='confirm_password'
					onChange={handleInput}
					onBlur={onBlur}
					className={
						"description " +
						(errors.confirm_password ? "input-underline-error" : "input-underline")
					}
				/>
				<div style={{ paddingBottom: "0px" }}>
					<span className='description'>
						I agree to receive marketing mails and messages from Ranknrole.com.  ?{" "}
						<Link to='/' style={{ color: "#00b4d8" }}>
							TnC*
						</Link>
					</span>
				</div>
				<div style={{ paddingBottom: "20px" }}>
					<span className='description'>
						I allow ranknrole.com to possess store and use information collected by me.  ?{" "}
						<Link to='/' style={{ color: "#00b4d8" }}>
							Agreement
						</Link>
					</span>
				</div>
				<input
					className='b2'
					type='button'
					value='Sign Up'
					onClick={handleSubmit}
				/>
			</div>
		</div>
	);
}

const mapDispatchToProps = (dispatch) => {
	return {
		signUp: (first_name, last_name, email, password, phone) =>
			dispatch(signUp(first_name, last_name, email, password, phone)),
	};
};

export default connect(null, mapDispatchToProps)(Form);
