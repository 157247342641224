import { instance } from "./axios/axios-config";

const signup = (first_name, last_name, email, password, phone) => {
	return instance.request({
		url: `v1/auth/register`,
		method: "post",
		data: {
			email,
			password,
			first_name,
			last_name,
			phone
		},
	});
};

const login = (email, password) => {
	return instance.request({
		url: `v1/auth/login`,
		method: "post",
		data: {
			email,
			password,
		},
	});
};

const logOut = () => {
	localStorage.clear();
};

export default { login, logOut, signup };
