import React from "react";
import Cross from "../../../../assets/icons/basic/x.svg";
import { useDispatch, useSelector } from "react-redux";
import * as Actions from "../../../../redux/actions/Dashboard/BillingInfo";
import PaymentCardRow from "../../../../components/subcomponents/Settings/PaymentCardRow";
import Button from "../../../../components/subcomponents/Button";


export default function EditCard(props){


	const dispatch = useDispatch();

	const cards = useSelector(state => state.billing.cardform);
	// console.log(cards, "cardasas");


	return (
		<>
			
			<div className="invite-modal">
			
				<div className="invite-modal-container d-flex flex-column">
					<div
						className='d-flex justify-content-end pt-4 pr-0'
						onClick={props.onCross}>
						<img src={Cross} alt='' style={{ cursor: "pointer" }} />
					</div>
					<div>
						<span className='headline1'>Payment Cards</span>
					</div>
					<div>
						<div
							style={{
								borderBottom: "1px solid #C8CBCD",
								padding: "20px 0px 5px 0px",
							}}>
				
							<div className='w-100 d-flex justify-content-between'>
								<div
								
								>
									<span className='description'>Cardholder Name</span>
								</div>
								<div >
									<span className='description'>Card Number</span>
								</div>
						
								<div >
									<span className='description'>Expiry</span>
								</div>
								<div>
									<span className='description'>Status</span>
								</div>
							
								<div >
									<span className='description'>Action</span>
								</div>
						
						
							</div>
				
						</div>
						<div>
							{
							
								cards.map((card, index) => {
								
									return (
										<div key={index}>
											<PaymentCardRow
												name={card?.billing_details?.name}
												last4={card?.card?.last4}
												expiryMonth={card?.card?.exp_month}
												expiryYear={card?.card?.exp_year}
												brand={card?.card?.brand}
												selected={card?.id === card?.customer?.invoice_settings?.default_payment_method ? true : false}
												onClick={() => {
													dispatch(Actions.setDefaultPaymentMethod(card?.id));
												}}
												onDelete={() => {
													if(card.customer.invoice_settings.default_payment_method === card.id){
														alert("You can't delete default card");
													}
													else{
														dispatch(Actions.detachPaymentMethod(card?.id));
													}
												
												}}
											
											/>
										</div>
									);
								})
							}
						
						</div>
						{props.updatePayment && 
					<div className="d-flex justify-content-center mt-3 mb-2">
						<Button 
							label="Add Card"
							whiteButton
							style={{padding:"5px 30px"}}
							onClick={props.onClick}
						/>
					</div>}
					</div>
				</div>

			</div>
		</>
	);
}