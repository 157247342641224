import React, { useState } from "react";
import Button from "../Button";
import {isEmailValid} from "../../../utilities/Validator";

export default function GetStarted() {

	const [user, setUser] = useState({email: null});

	const [errors, setErrors] = useState({email: false});

	const [alerts, setAlert] = useState();

	const handleInput = (event) => {
		setUser({ ...user, [event.target.name]: event.target.value });
	};

	function onBlur (event) {

		console.log(event.target.name);
		if (event.target.name === "email") {

			if (!isEmailValid(user[event.target.name])) {
				setErrors({ ...errors, [event.target.name]: true });
				return;
			}

		}
		setErrors({ ...errors, [event.target.name]: false });
	}

	function validateEmail() {
       
		if(user.email === null){
			return { isValid: false, message: "Email is missing" };
		}

		if (errors.email) {
			return { isValid: false, message: "Email is Invalid" };
		}
		return { isValid: true, message: "" };
	}

	

	const handleSubmit = (event) => {
		event.preventDefault();

		const validation = validateEmail();

		if (!validation.isValid) {
			setAlert(
				alert(validation.message)
			);
			return;
		}
		window.location.href="/signup";
	};

	const Email = localStorage.setItem("email2", user.email);
	console.log(Email);
    
	return(
		<div>
			
			<div className="btn-container">
				<input
					placeholder ="Enter your email"
					name="email"
					className={"signup-container p2" + (errors.email ? "input-underline-error" : "input-underline")}
					onChange={handleInput}  
					onBlur={onBlur}
					style={{border:"none",padding:"10px 25px",outline:"none"}}
				/>
				<div style={{textDecoration:"none"}}>
					<Button 
						signUpButton
						onClick={handleSubmit}
					/>
				</div>
				
			</div>
			{alerts}
		</div>
	);
}