/* eslint-disable no-unused-vars */
import React , { useState , useEffect , useRef } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import copy from "../../../assets/icons/basic/Copy.svg";
import cross from "../../../assets/icons/basic/x.svg";
import link from "../../../assets/icons/basic/LinkSimple.png";
import calender from "../../../assets/icons/basic/Calendar.png";
import {CopyToClipboard} from "react-copy-to-clipboard";
import { useSelector, useDispatch } from "react-redux";
import * as candidateTestingActions from "../../../redux/actions/Candidate/candidate-testing.actions";

import DateFnsUtils from "@date-io/date-fns";
import {
	MuiPickersUtilsProvider,
	KeyboardDatePicker,
	Day,
} from "@material-ui/pickers";
export default function AssessmentInviteModal(props){
	const dispatch = useDispatch();
	const linkref = useRef();
 
	const [isLink , setLink] = useState(false);
	const [date , setDate] = useState(null);
	const [IsDataValid , setIsDateValid] = useState(true);
	const host = window.location.host ;

	const linkId = useSelector((state) => state.candidateTesting.linkId);
	// console.log("linkid is " , linkId);
	
	const DateChange = (val) => {
		setDate(val);
		console.log("date is " , date);
	};
	const handleGenerateLink = () => {
		// console.log("date is  at generate link" , date);
		if(date){
			setLink(true);
			dispatch(candidateTestingActions.getLinkId(props.roleId , date));
		} 
		
	};
	const handleLinkClick = () => {
		dispatch(candidateTestingActions.inviteVerification(linkId));
	};
	useEffect(() => {
		
		dispatch(
			candidateTestingActions.linkIdParam(linkId)
		);
		
	}, [dispatch , linkId]);
	return(
		<div 
			style={{
				width:"440px" ,
				height:"198.5px",
				position:"absolute",
				right:"22px",
				backgroundColor:" #FFFFFF",
				boxShadow:"0px 4px 8px rgba(0, 0, 0, 0.15)",
				borderRadius:"8px",
				padding:"9.5px",
				marginRight:"10px",
				
			}}
		>
			<div className="d-flex flex-column justify-content-start align-items-end ">
				<img 
					style={{cursor:"pointer"}} 
					onClick={()=> props.setShareModal(false)}
					src={cross}/>
				<div
					className="d-flex flex-column justify-content-start align-items-start"
					style={{margin:"5px 42px 10px 24px" }} 

				>
					<span className="description" style={{fontWeight:"bolder" , paddingBottom:"6px" , }}>Share Assessment Invite</span>
					<span className="description" style={{color:"#96999C" , paddingBottom:"6px" , textAlign:"left"}}>{isLink ? "Anyone with the link will be able to view these assessment details" : "This link would be available until the selected date. After that, you will need to generate a new link."} </span>
				</div>
			</div>
			
		
			{!isLink ? 
				<div className="d-flex flex-row justify-content-between align-items-center"
					style={{margin:"5px 10px 16.5px 24px" }}>
					<div className="" >
						<MuiPickersUtilsProvider utils={DateFnsUtils}>
							<KeyboardDatePicker 
								clearable
								disableToolbar
								keyboardIcon={<img src={calender} />}
								autoOk={true}
								placeholder=" Expiry Date"
								margin="normal"
								variant="inline"
								format="MM/dd/yyyy"
								InputProps={{
									disableUnderline: true,
									color:"#00B4D8"
								}}
								
								style={{width:"160px" , borderRadius:"4px" , boxShadow:"0px 4px 8px rgba(0, 0, 0, 0.15)" , padding:"4px 8px" , height:"55x"}}
								PopoverProps={{
								// anchorEl: state.dateAnchor,
								// open: openKeyboardDatePicker,
									anchorOrigin: {
										vertical: "bottom",
										horizontal: "right",
									},
									transformOrigin: {
										vertical: "top",
										horizontal: "left",
									}
								}}
								
								value={date}
								onChange={DateChange}
								//   onAccept={(date) => onAccept(date)}
								//   onClose={() => onClose()}
								onError={(error) => {
									if (error) {
										setIsDateValid(false);
									}
									else {
										setIsDateValid(true);
									}
								}}
           
								// invalidLabel
								invalidDateMessage=""
							/>
						</MuiPickersUtilsProvider>
					</div>
					<div style={{ cursor:"pointer",border:"1px solid #00B4D8" , boxSizing:"border-box" , borderRadius:"50px" , padding:"5px 10px" , marginRight:"17.5px" }}>
						<span className="b3 " style={{paddingRight:"6.88px" , color:"#00B4D8"}} onClick={handleGenerateLink}>Generate Link</span>
						<img src={link}/>
					</div> 	
				</div> 
				:
				<div>
					<div className="d-flex justify-content-between align-items-center"
						style={{margin:"5px 10px 16.5px 24px" }}>
						<span
							onClick={handleLinkClick}
							className="description" style={{width:"242px" ,textOverflow:"ellipsis" , overflow:"hidden", whiteSpace:"nowrap" , fontWeight:"bold" , padding:"12px 14px" , borderRadius:"12px" , backgroundColor:"#FFFFFF", boxShadow:"0px 4px 8px rgba(0, 0, 0, 0.15)" }}>
							<Link ref={linkref} to={`/assessment_invite/${linkId}`}  target="_blank " onClick={handleLinkClick}>
								{host}{`/assessment_invite/${linkId}`}
							</Link>
						</span>
						<div style={{ cursor:"pointer",border:"1px solid #00B4D8" , boxSizing:"border-box" , borderRadius:"50px" , padding:"5px 10px" , marginRight:"17.5px" }}>
							<CopyToClipboard text={linkref?.current}>
								<span className="b3 " style={{paddingRight:"6.88px" , color:"#00B4D8"}}>Copy Link</span>
							</CopyToClipboard>
							<img src={copy}/> 
							
						</div>
					</div> 
					<div className="d-flex align-items-center "
						style={{marginLeft:"24px"}}>
						<span className="description" style={{color:"#96999C" , paddingBottom:"6px" }}>This link will expire on: <u style={{color:"#00B4D8", cursor:"pointer"}}>{date.toLocaleDateString()} </u> </span>
					</div>
				</div>
			}
			
		</div>
	);
}