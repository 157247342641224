import React from "react";
import MailIcon from "../../assets/icons/basic/mail.png";
import PhoneIcon from "../../assets/icons/basic/phone.png";
import BlipIcon from "../../assets/icons//basic/blip.png";

import TwitterIcon from "../../assets/icons/symbols/twitter.png";
import FacebookIcon from "../../assets/icons/symbols/facebook.png";
import GoogleIcon from "../../assets/icons/symbols/google.png";
import rnr_logo from "../../assets/icons/rnr_logo_light.png";

export default function Footer() {
	return (
		<div className='mobile_footer_container flex-column justify-center'>
			<div className='d-flex flex-column text-justify white-color'>
				<div className='h4'>
					<img className='footer_logo' src={rnr_logo} />
				</div>

				<div>
					<p className='text-justify'>
						Lorem ipsum dolor amet, consectetur adipiscing elit. Eget nisl nunc
						quam ac sed turpis volutpat. Cursus sed massa non nisi, placerat.
					</p>
				</div>
			</div>
			<div
				className='d-flex flex-row justify-content-between'
				style={{ marginTop: "30px" }}>
				<div className='d-flex flex-column text-justify'>
					<div className='footer_headings h3 white-color'>Quick Links</div>
					<div className='footer_content grey-color'>
						<p>Lorem Ipsum</p>
						<p>Lorem Ipsum</p>
						<p>Lorem Ipsum</p>
						<p>Lorem Ipsum</p>
						<p>Lorem Ipsum</p>
						<p>Lorem Ipsum</p>
					</div>
				</div>
				<div className='d-flex d-flex flex-column text-justify'>
					<div className='footer_headings h3 white-color'>Services</div>
					<div className='footer_content grey-color'>
						<p>Lorem Ipsum</p>
						<p>Lorem Ipsum</p>
						<p>Lorem Ipsum</p>
						<p>Lorem Ipsum</p>
						<p>Lorem Ipsum</p>
						<p>Lorem Ipsum</p>
					</div>
				</div>
			</div>
			<div
				className='d-flex flex-column white-color text-justify'
				style={{ marginTop: "30px" }}>
				<h3>Reach us</h3>
				<p>
					<img src={MailIcon} className='footer_icon_with_text' />{" "}
					hello@ranknrole.com
				</p>
				<p>
					<img src={PhoneIcon} className='footer_icon_with_text' /> 01 (123) 123
					1234
				</p>
				<p>
					<img src={BlipIcon} className='footer_icon_with_text' /> 1 RanknRole
					Ave, Los Angeles, California
				</p>
			</div>
			<div className='d-flex flex-column '>
				<div
					className='d-flex justify-content-center'
					style={{ marginBottom: "15px" }}>
					<div className='SocialIcons'>
						<a className='CircularButton' href='twitter.com'>
							<img src={TwitterIcon} />
						</a>

						<a className='CircularButton' href='twitter.com'>
							<img src={GoogleIcon} />
						</a>

						<a className='CircularButton' href='twitter.com'>
							<img src={FacebookIcon} />
						</a>
					</div>
				</div>
				<div className='white-color'>© 2021 RanknRole. All rights reserved</div>
				<div className='white-color'>
					<a className='grey-color' href='terms.com'>
						Terms {" & "} Conditions
					</a>
					{" | "}
					<a className='grey-color' href='policy.com'>
						Privacy Policy
					</a>
				</div>
			</div>
		</div>
	);
}
