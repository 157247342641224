import React from "react";
import NoNotification from "../../../assets/images/dashboard/Notifications/Empty.png";
import NotificationCard from "./NotificationCard";
import Background from "../../../assets/images/dashboard/Notifications/Rectangle565.png";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import * as notificationActions from "../../../redux/actions/Notification/notification.actions";

export default function NotificationBox(props) {
	const dispatch = useDispatch();

	const notifications = useSelector(
		(state) => state.notificationReducer.notifications,
	);

	const handleMarkAllAsReadClicked = () => {
		dispatch(notificationActions.markAllAsRead());
	};

	return (
		<div
			className='notification-card-box invisible-scrollbar'
			style={{
				backgroundImage: props.mobile ? "" : `url(${Background})`,
				paddingInline: props.mobile ? "" : "10px",
				overflow: props.mobile ? "none" : "auto",
			}}>
			{notifications.length > 0 ? (
				<>
					{!props.mobile && (
						<div
							className='d-flex justify-content-between'
							style={{ padding: "20px 20px 0px 15px" }}>
							<div>
								<span className='headline3'>All Notifications</span>
							</div>
							<div>
								<span
									className='description pointer'
									style={{ color: "#00B4D8" }}
									onClick={handleMarkAllAsReadClicked}>
									Mark all as read
								</span>
							</div>
						</div>
					)}
					{notifications.map((notification, index) => {
						return (
							<div key={index}>
								<div>
									<NotificationCard
										image={notification.image}
										description={notification.message_body}
										date={moment.utc(notification.createdAt).fromNow(true)}
										mobile={props.mobile}
										notification={notification}
									/>
								</div>
							</div>
						);
					})}
				</>
			) : (
				<div
					className='notification-box'
					style={{
						backgroundImage: props.mobile ? "" : `url(${Background})`,
						paddingInline: props.mobile ? "" : "10px",
					}}>
					<div>
						<img src={NoNotification} alt='' />
					</div>
					<div>
						<span className='headline2'>No Notifications yet</span>
						<div style={{ paddingInline: "73px" }}>
							<span className='description'>
								Stay tuned! Notifications for your activity will show up here.
							</span>
						</div>
					</div>
				</div>
			)}
		</div>
	);
}
