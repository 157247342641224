import React from "react";

export default function Input(props) {
	if (props.textarea) {
		return (
			<div style={props.style} className='d-flex flex-column align-items-start'>
				<div style={{ paddingBottom: "5px" }}>
					<span className={props.labelClass || "p2"}>
						{props.label || "Label"}
					</span>
					{props.asterik && <span style={{ color: "red" }}> *</span>}
				</div>
				<div className='w-100' style={{ marginTop: "0px" }}>
					<textarea
						autoComplete="off"
						placeholder={props.placeholder || ""}
						onChange={props.onChange}
						onBlur={props.onBlur}
						onFocus={props.onFocus}
						name={props.name}
						className={props.className || "p2"}
						value={props.value}
						defaultValue={props.defaultValue}
						readOnly={props.readOnly}
						maxLength={props.maxLength}
						disabled={props.disabled}
						style={{
							width: "100%",
							height: "75px",
							padding: "10px 20px",
							borderRadius: "12px",
							border: "none",
							resize: "none",
							outline: "none",
							...props.inputStyle,
						}}
					/>
				</div>
			</div>
		);
	} else if (props.tableInput) {
		return (
			<div>
				<input
					autoComplete="off"
					placeholder={props.placeholder || ""}
					style={{
						width: "100%",
						height: "23px",
						padding: "0px 5px",
						borderBottom: "1px solid #2B3340",
						borderTop: "none",
						borderRight: "none",
						borderLeft: "none",
						outline: "none",
						backgroundColor: "transparent",
						...props.style,
					}}
					className={props.className}
					name={props.name}
					type={props.type}
					min={props.min}
					max={props.max}
					value={props.value}
					id={props.id}
					onChange={props.onChange}
					onClick={props.onClick}
					onBlur={props.onBlur}
					onFocus={props.onFocus}
					defaultValue={props.defaultValue}
				/>
			</div>
		);
	} else {
		return (
			<div style={props.style} className='d-flex flex-column align-items-start'>
				<div style={{ paddingBottom: "5px" }}>
					<span className='p2'>{props.label || "Label"}</span>
					{props.asterik && <span style={{ color: "red" }}> *</span>}
				</div>
				<div className='w-100' style={{ marginTop: "0px" }}>
					<input
						autoComplete="off"
						placeholder={props.placeholder || ""}
						onChange={props.onChange}
						onBlur={props.onBlur}
						onFocus={props.onFocus}
						type={props.type}
						id={props.id}
						name={props.name}
						value={props.value}
						defaultValue={props.defaultValue}
						minLength={props.minLength}
						maxLength={props.maxLength}
						readOnly={props.readOnly}
						className={props.className || "p2"}
						style={{
							width: "100%",
							height: "40px",
							padding: "10px 20px",
							borderRadius: "12px",
							border: "none",
							resize: "none",
							outline: "none",
							...props.inputStyle,
						}}
					/>
				</div>
			</div>
		);
	}
}
