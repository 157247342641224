import React from "react";
import SetupRoleForm from "../../../components/onboarding/screens/CompanySetup";

export default function CompanySettings(props) {
	return (
		<div >
			<SetupRoleForm props={props} dashboard />
		</div>
	);
}
