import React from "react";
import CreateNewPositionWeb from "./CreateNewPositionWeb";
import CreateNewPositionMobile from "./CreateNewPositionMobile";

export default function CreateNewPosition() {
	return (
		<>
			<div className='onboarding-web'>
				<CreateNewPositionWeb />
			</div>
			<div className='onboarding-mobile'>
				<CreateNewPositionMobile />
			</div>
		</>
	);
}
