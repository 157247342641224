import React, {useEffect, useState} from "react";

import Header from "../components/onboarding/Header";
import Button from "./subcomponents/Button";
import { useSelector, useDispatch } from "react-redux";
import { Redirect } from "react-router-dom";
import * as userActions from "../redux/actions/Auth/authAction";
import * as Actions from "../redux/actions/Dashboard/BillingInfo";
import EditCard from "../views/pages/Settings/Billing/EditCard";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import AddCard from "../views/pages/Settings/Billing/AddCard";
import { stripePublicKey } from "../utilities/Constants";

export default function AccountSuspended() {
	const dispatch = useDispatch();
	const isTenantSubscribed = useSelector(state => state.user.isTenantSubscribed);
	const [updatePaymentCard, setUpdatePaymentCard] = useState(false);
	const [addCard, setAddCard] = useState(false);
	const [updateState, setUpdateState] = useState(true);
	const subscriptionCompleted = useSelector(state => state.billing.subscriptionCompleted);

	const cardForm = useSelector(state => state.billing.cardform);

	// const retryMessage = useSelector(state => state.billing.retryMessage);

	// console.log(isTenantSubscribed,"assadasdas");

	useEffect(() => {
		dispatch(userActions.getSubscriptions());
	},[dispatch, isTenantSubscribed]);

	useEffect(() => {
		dispatch(Actions.getCardDetails());
		if(subscriptionCompleted){
			setAddCard(false);
		}
		if(cardForm.length >= 5){
			setUpdateState(false);
		}
		else{
			setUpdateState(true);
		}
	},[dispatch, subscriptionCompleted, cardForm, setUpdateState]);


	if(isTenantSubscribed && isTenantSubscribed?.data[0]?.status !== "unpaid"){
		return <Redirect to="/dashboard"/>;
	}



	const retryInvoice = () => {
		dispatch(Actions.retryPayment());

	};

	const updatePayment = () => {
		setUpdatePaymentCard(true);

	};
	const stripePromise = loadStripe(stripePublicKey);

	const handleAddCard =() => {
		setAddCard(true);
	};
	return (
		<div className="error-layout d-flex flex-column justify-content-between">
			{
				updatePaymentCard &&

					<EditCard onCross={() => setUpdatePaymentCard(false)} updatePayment={updateState} onClick={handleAddCard}/>

			}
			{addCard &&
				<Elements stripe={stripePromise}>
					<AddCard onCross={() => setAddCard(false)}/>
				</Elements>
			}
			<div>
				<Header suspended dashboard/>
			</div>
			<div>
				<div >
					<span className="hero3" style={{color:"#00B4D8"}}>Account Suspended</span>
				</div>
				<div>
					<span className="p2">Your 30 days grace period has ended and your account has been suspended. To unlock
your account, please complete your payment.</span>
				</div>
				<div>
					<span className="p2">Do you want to retry the payment, or update your payment method?</span>
				</div>
				<div className="d-flex w-100 justify-content-center mt-5 mb-3">
					<Button
						label="Retry Payment"
						button
						style={{padding:"5px 70px"}}
						onClick={retryInvoice}
					/>
				</div>
				<div className="d-flex w-100 justify-content-center">
					<Button
						label="Update Payment Method"
						whiteButton
						style={{padding:"5px 35px"}}
						onClick={updatePayment}
					/>
				</div>
			</div>
			<div>

			</div>
			<div>

			</div>
		</div>
	);
}
