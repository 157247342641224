import * as Actions from "../../actions/Notification/notification.actions";

const initialState = {
	notifications: [],
};

const NotificationReducer = (state = initialState, action) => {
	const data = action.payload;

	switch (action.type) {
		case Actions.GET_ALL_NOTIFICATIONS:
			return (state = {
				...state,
				notifications: data,
			});

		case Actions.STATUS_UPDATED:
			return (state = {
				...state,
				notifications: state.notifications.map((notification) => {
					if (notification._id === data) {
						return {
							...notification,
							status: "READ",
						};
					}

					return notification;
				}),
			});

		case Actions.MARK_ALL_READ:
			return (state = {
				...state,
				notifications: state.notifications.map((notification) => {
					return {
						...notification,
						status: "READ",
					};
				}),
			});

		default:
			return state;
	}
};

export default NotificationReducer;
