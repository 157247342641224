import React from "react";
import RnRLogo from "../../assets/icons/logo/Header/Header/rnr/logo.svg";
import SignInForm from "../../components/dashboard/Signin/Form";
import {Link} from "react-router-dom";

export default function SignInMobile(){
	const year = new Date().getFullYear();
	return(
		<div className="signin-mobile">
			<div className="d-flex" style={{padding:"50px 35px", cursor:"pointer"}}>
				<img 
					src={RnRLogo}
					alt=""
				/>
			</div>
			<div className="d-flex" style={{padding:"0px 35px", textAlign:"start", textJustify:"initial"}}>
				<span className="hero3" style={{color:"#00B4D8", marginRight:"2.6vw"}}>
                    The AI-Driven Recruitment Platform
				</span>
			</div>
			<div className="d-flex flex-column justify-content-between" style={{backgroundColor:"white", borderRadius:"40px 40px 0px 0px", height: "calc(100vh - 260px)", minHeight:"590px", marginTop:"30px"}}>
				<div className="d-flex justify-content-center" style={{ padding:"40px 35px" }}>
					<SignInForm/>
				</div>
				<div className="d-flex justify-content-between" style={{padding:"0px 35px 30px 35px"}}>
					<Link to="google.com"><span className="description">RanknRole Inc, {year}</span></Link>
					<Link to="google.com"><span className="description">Privacy Policy</span></Link>
					<Link to="google.com"><span className="description">Terms and conditions</span></Link>
				</div>
			</div>
		</div>
	);
}