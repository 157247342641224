import React from "react";
import RoleSummaryCard from "./RoleSummaryCard";
import DocumentImage from "../../../assets/images/dashboard/Positions/Role Detail Summary/text-document.png";
import assessmentStatus from "../../../utilities/Constants";
import { useSelector } from "react-redux";

export default function RoleDetailSummary(props) {

	const assessmentSessions = useSelector(
		(state) => state.candidateTesting.assessmentSessions,
	);

	const invitedUserIds = [];
	const completedAssessments = [];
	const shortlistedAssessments = [];
	assessmentSessions.map((assessment) => {
		if (
			assessment.status === assessmentStatus.INITIALIZED ||
			assessment.status === assessmentStatus.IN_PROGRESS
		) {
			invitedUserIds.push(assessment.user_id);
		} else if (
			assessment.status === assessmentStatus.UNDER_EVALUATION ||
			assessment.status === assessmentStatus.FINISHED ||
			assessment.status === assessmentStatus.NOT_CONSIDERED
		) {
			completedAssessments.push(assessment);
		} else if (assessment.status === assessmentStatus.SHORTLISTED) {
			shortlistedAssessments.push(assessment);
		}
	});

	var shortlistRatio = 0;

	if(shortlistedAssessments.length > 0){
		shortlistRatio = ((shortlistedAssessments.length / assessmentSessions.length) * 100);
	}
	

	const roleSummary = [
		{ title: "Shortlist Ratio", start: 0, end: shortlistRatio, color: "black" },
		{ title: "Pending Tests", start: 0, end: invitedUserIds.length, color: "#FC4848" },
		{ title: "Shortlisted Applicants", start: 0, end: shortlistedAssessments.length, color: "#00D69A" },
		{ title: "View Criteria", image: DocumentImage },
	];

	return (
		<div
			className='role-detail-summary d-flex justify-content-center'
			style={{ padding: "20px 0px" }}>
			{roleSummary.map((summary, index) => {
				return (
					<div
						key={index}
						className='w-100 d-flex justify-content-center align-items-center'>
						<RoleSummaryCard
							percent={index === roleSummary.length - 4 ? true : false}
							title={summary.title}
							start={summary.start}
							end={summary.end}
							innerStyle={{ color: summary.color }}
							style={
								index === roleSummary.length - 1 ? { border: "none" } : null
							}
							image={index === roleSummary.length - 1 ? summary.image : false}
							onClick={props.onClick}
						/>
					</div>
				);
			})}
		</div>
	);
}
