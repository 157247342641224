import React, { useEffect } from "react";
import Select from "../../subcomponents/Select";
import Button from "../../subcomponents/Button";
import Table from "../../subcomponents/Table/Tabel";
import { useSelector, useDispatch } from "react-redux";
import { Redirect } from "react-router-dom";
import * as inviteCandidateActions from "../../../redux/actions/onboarding/inviteCandidate";

export default function InviteCandidate() {
	const dispatch = useDispatch();

	const setupRoleIsSubmitted = useSelector(
		(state) => state.setupRoles.isSubmitted,
	);
	const roles = useSelector((state) => state.inviteCandidateReducer.roles);
	const inviteCandidateTableRows = useSelector(
		(state) => state.inviteCandidateReducer.inviteCandidateTableRows,
	);
	const positionId = useSelector(
		(state) => state.inviteCandidateReducer.positionId,
	);
	const candidateAssessments = useSelector(
		(state) => state.inviteCandidateReducer.candidateAssessments,
	);

	const roleListForSelect = [];
	roles.map((role) => {
		roleListForSelect.push({
			label: role.name,
			value: role.id,
		});
	});
	const isSubmitted = useSelector(
		(state) => state.inviteCandidateReducer.isSubmitted,
	);
	const isModifyRequested = useSelector(
		(state) => state.inviteCandidateReducer.isModifyRequested,
	);
	const isAddManually = useSelector(
		(state) => state.inviteCandidateReducer.isAddManually,
	);
	const loading = useSelector((state) => state.inviteCandidateReducer.loading);

	const InviteCandidatesTable = ["Email"];

	const handleSubmit = () => {
		dispatch(inviteCandidateActions.handleSubmit());
	};

	const getUpdatedRows = (rows) => {
		dispatch(inviteCandidateActions.updateInviteCandidateTableRows(rows));
	};

	useEffect(() => {
		dispatch(inviteCandidateActions.getRoles());
	}, [dispatch]);

	useEffect(() => {
		dispatch(
			inviteCandidateActions.getCandidateAssesments(
				roleListForSelect[0]?.value,
			),
		);
	}, [dispatch]);

	const getUpdatedRow = (row, actionId) => {
		//Action Id 0 ADD row
		if (actionId === 0) {
			dispatch(
				inviteCandidateActions.inviteCandidateByEmail({ row, positionId }),
			);
		} else if (actionId === 2) {
			dispatch(
				inviteCandidateActions.deleteInvitedCandidate({ row, positionId }),
			);
		}
	};

	if (candidateAssessments?.length > 0) {
		dispatch(inviteCandidateActions.candidatesAlreadyInvited());

		return <Redirect to='/onboarding/teamSetup' />;
	} else if (!setupRoleIsSubmitted) {
		return <Redirect to='/onboarding/setupRoles' />;
	} else if (isSubmitted && !isModifyRequested) {
		return <Redirect to='/onboarding/teamSetup' />;
	}

	return (
		<div>
			<div style={{ marginBottom: "20px" }} className='headline1'>
				Invite Candidates
			</div>
			<div style={{ marginBottom: "-10px" }}>
				<span className='p2'>Position</span>
				<span style={{ color: "red" }}> *</span>
			</div>

			{roleListForSelect.length > 0 && (
				<Select
					agency
					agencySetup
					disable
					selected={roleListForSelect[0]}
					updateSelections={(position) => {
						if (position) {
							dispatch(inviteCandidateActions.setPosition(position));
						}
					}}
				/>
			)}

			{isAddManually ? (
				<div style={{ marginTop: "40px" }}>
					<Button
						whiteButton
						className='white-button-lg'
						label='Add Manually'
						onClick={() =>
							dispatch(inviteCandidateActions.addManuallyClicked())
						}
					/>
				</div>
			) : (
				<div>
					<div style={{ margin: "40px 0px 20px 0px" }}>
						<Button
							button
							className='button-large'
							label='Add Manually'
							onClick={() =>
								dispatch(inviteCandidateActions.addManuallyClicked())
							}
						/>
					</div>

					<Table
						activeImageInput={[]}
						isEditable
						headers={InviteCandidatesTable}
						rows={inviteCandidateTableRows}
						updateTable={getUpdatedRows}
						updatedRow={getUpdatedRow}
						activeSelectInput={[]}
						activeMultiSelectInput={[]}
					/>
					<div
						className='d-flex justify-content-center'
						style={{ paddingTop: "40px" }}>
						<Button
							rightArrow
							loading={loading}
							label='Next'
							textClass='b2'
							className='button-large'
							style={{ padding: "6.5px 69px" }}
							onClick={handleSubmit}
						/>
					</div>
				</div>
			)}
		</div>
	);
}
