import React from "react";
import {ReactComponent as Warning} from "../../assets/icons/basic/Warning.svg";

export default function DashboardAlert(props){
	return(
		<div className="dashboard-alert d-flex align-items-center">
			<Warning/>
			<div style={{marginLeft:"15px"}}>
				<span className="p2 pointer" style={{color:"white"}} onClick={props.onClick}>Alert: {props.alert}</span>
			</div>
		</div>
	);
}