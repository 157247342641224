import React, {useEffect} from "react";
import DashboardMobileContainer from "./DashboardMobileContainer";
import DashboardPageContainer from "./DashboardPageContainer";
import { useSelector , useDispatch} from "react-redux";
import { Redirect } from "react-router-dom";
import * as Actions from "../../redux/actions/Dashboard/BillingInfo";

export default function DashboardContainer() {

	const dispatch = useDispatch();

	const userProfile = useSelector((state) => state.formsStatus.userProfile);
	const user = useSelector(state => state.user.user);
	const isTenantSubscribed = useSelector(state => state.user.isTenantSubscribed);
	
	// console.log("isOnboarding at dashboard" , userProfile.is_onboarding) ;
	// console.log(form,"asasasas");

	useEffect(() => {
		dispatch(Actions.getInvoices()); 
	},[dispatch]);

	if(user.tenantId === 0 ){
		return  (
			<>
				<div className='dashboard-web'>
					<DashboardPageContainer  />
				</div>
				<div className='dashboard-mobile'>
					<DashboardMobileContainer  />
				</div>
			</>
		);
	}
	if(isTenantSubscribed){
		return (
			<>
				<div className='dashboard-web'>
					
					{!userProfile?.is_onboarding ? (
						<DashboardPageContainer  />
					) : (
						<Redirect to='/onboarding' />
					)}
					
					
				</div>
				<div className='dashboard-mobile'>
					{!userProfile?.is_onboarding ? (
						<DashboardMobileContainer  />
					) : (
						<Redirect to='/onboarding' />
					)}
					
				</div>
			</>
		);
	}
	
	return (

		<Redirect to="/pricing"/>

	);	
		
}
