import React from "react";

export default function DashboardMenuIcon(props){
	return(
		<div
			onClick={props.onClick}
			className={props.selected ? "dashboard-side-menu-icon-selected pointer" : "dashboard-side-menu-icon pointer"}
			style={props.style}
		>
			{ !props.submenu && 
				<div>
					{props.image}
				</div>
			}
			<span className="description" >{props.label}</span>
		</div>
	);
}

// 