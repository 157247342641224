import AuthApi from "../../../api/authentication";
import { instance, secure_instance } from "../../../api/axios/axios-config";
// import { HANDLE_MODAL_EMAIL_CHANGE } from "../Dashboard/ClientFilter.actions";

import { countryMapper } from "../../../utilities/utility";

export const SIGN_IN = "[Auth] SIGN_IN";
export const SIGN_IN_FAILED = "[Auth] SIGN_IN_FAILED";
export const REFRESH_TOKEN = "[Auth] REFRESH_TOKEN";
export const SIGN_OUT = "[Auth] SIGN_OUT";
export const GET_TENANT_PROFILES_OF_USER = "[Auth] GET_TENANT_PROFILES_OF_USER";
export const SWITCH_TENANT_ACCOUNT_START = "[Auth] SWITCH_TENANT_ACCOUNT_START";
export const SWITCH_TENANT_ACCOUNT_END = "[Auth] SWITCH_TENANT_ACCOUNT_END";
export const SWITCH_TENANT_ACCOUNT_FAILED =
	"[Auth] SWITCH_TENANT_ACCOUNT_FAILED";
export const GET_USER_EMAIL_BY_TOKEN = "[Auth] GET_USER_EMAIL_BY_TOKEN";
export const SET_RESET_TOKEN = "[Auth] SET_RESET_TOKEN";
export const GET_CANDIDATE_REFERENCES_END =
	"[Auth] GET_CANDIDATE_REFERENCES_END";
export const GET_CANDIDATE_REFERENCES_FAILED =
	"[Auth] GET_CANDIDATE_REFERENCES_FAILED";
export const SET_REFERENCE_FORM = "[Auth] SET_REFERENCE_FORM";
export const SET_REFERENCE_FORM_TEXT_AREA =
	"[Auth] SET_REFERENCE_FORM_TEXT_AREA";
export const SUBMIT_REFERENCE_FORM_START = "[Auth] SUBMIT_REFERENCE_FORM_START";
export const SUBMIT_REFERENCE_FORM_END = "[Auth] SUBMIT_REFERENCE_FORM_END";
export const SUBMIT_REFERENCE_FORM_FAILED =
	"[Auth] SUBMIT_REFERENCE_FORM_FAILED";
export const SIGN_IN_OAUTH = "[Auth] SIGN_IN_OAUTH";
export const SET_PAYMENT_METHOD_ID = "[Auth] SET_PAYMENT_METHOD_ID";
export const HANDLE_CHANGE = "[Auth] HANDLE_CHANGE";
export const SUBSCRIPTION_END = "[Auth] SUBSCRIPTION_END";
export const GET_SUBSCRIPTION = "[Auth] GET_SUBSCRIPTION";
export const GET_SUBSCRIPTION_FAILED = "[Auth] GET_SUBSCRIPTION_FAILED";
export const GET_SUBSCRIPTION_START = "[Auth] GET_SUBSCRIPTION_START";
export const SUBSCRIPTION_START = "[Auth] SUBSCRIPTION_START";
export const VALIDATE_STRIPE_FORM = "[Auth] VALIDATE_STRIPE_FORM";
export const SUBSCRIPTION_LOADING = "[Auth] SUBSCRIPTION_LOADING";
export const RESET_PASSWORD_REQUEST = "[Auth] RESET_PASSWORD_REQUEST";
export const HANDLE_ERROR_MESSAGE = "[Auth] HANDLE_ERROR_MESSAGE";
export const RESET_PASSWORD = "[Auth] RESET_PASSWORD";
export const HANDLE_SELECT_CHANGE = "[Auth] HANDLE_SELECT_CHANGE";
export const HANDLE_STATE_CHANGE = "[Auth] HANDLE_STATE_CHANGE";
export const HANDLE_PAYMENT_SELECT_CHANGE =
	"[Auth]  HANDLE_PAYMENT_SELECT_CHANGE";
export const HANDLE_COUNTRY_CHANGE = "[Auth] HANDLE_COUNTRY_CHANGE";

export const signIn = (email, password, oauth, inviteId) => {
	return async (dispatch) => {
		try {
			const request = await instance.request({
				url: `v1/auth/login?invite_id=${inviteId}`,
				method: "post",
				data: {
					email,
					password,
				},
			});

			if (oauth) {
				dispatch({
					type: SIGN_IN_OAUTH,
					payload: request.data,
				});
			} else {
				dispatch({
					type: SIGN_IN,
					payload: request.data,
				});
			}
		} catch (err) {
			dispatch({
				type: SIGN_IN_FAILED,
				payload: "Invalid Username or Password",
			});
		}
	};
};

export const signUp = (firstName, lastName, email, password, phone) => {
	return () => {
		return new Promise((resolve, reject) => {
			AuthApi.signup(firstName, lastName, email, password, phone)
				.then(() => {
					resolve();
				})
				.catch(() => {
					reject("Api can not register user");
				});
		});
	};
};

export const signOut = () => {
	return async () => {
		try {
			const request = await secure_instance.request({
				url: `v1/auth/logout`,
				method: "post",
			});

			// dispatch({
			// 	type: SIGN_OUT,
			// });

			if (request.data.message === "logged out") {
				window.location.href = "/";
			}
		} catch (e) {
			//
		}
	};
};

export const setErrorMessage = (message) => {
	return {
		type: SIGN_IN_FAILED,
		payload: message,
	};
};

export const refreshToken = (isStopRedirect, linkid) => {
	return async (dispatch) => {
		try {
			const request = await instance.request({
				url: `/v1/auth/refresh${linkid ? "?invite_id=" + linkid : ""}`,
				method: "post",
			});

			dispatch({
				type: REFRESH_TOKEN,
				payload: request.data,
			});
		} catch (e) {
			if (!isStopRedirect) {
				if (window.location.search) {
					window.location.href = `/signin/oauth${window.location.search}`;
				} else {
					window.location.href = "/";
				}
			}
		}
	};
};

export const getTenantIdsOfUser = () => {
	return async (dispatch) => {
		try {
			const request = await secure_instance.request({
				url: `v1/role_assignments/?get_tenant_ids=${true}`,
				method: "Get",
			});

			const tenant_ids = [];
			await request.data.data.map((tenant) => {
				tenant_ids.push(tenant.tenant_id);
			});

			const secondRequest = await secure_instance.request({
				url: `v1/tenant_profile/?get_tenant_ids=${true}`,
				method: "Post",
				data: {
					tenant_ids,
				},
			});

			dispatch({
				type: GET_TENANT_PROFILES_OF_USER,
				payload: secondRequest.data,
			});
		} catch (e) {
			//
		}
	};
};

export const switchTenantAccount = (tenantId) => {
	return async (dispatch) => {
		try {
			dispatch({
				type: SWITCH_TENANT_ACCOUNT_START,
			});

			const request = await secure_instance.request({
				url: "v1/user/switch-tenant",
				method: "Put",
				data: {
					new_tenant_id: tenantId,
				},
			});

			dispatch({
				type: SWITCH_TENANT_ACCOUNT_END,
				payload: request.data,
			});
		} catch (e) {
			dispatch({
				type: SWITCH_TENANT_ACCOUNT_FAILED,
			});
		}
	};
};

export const getEmailByToken = (token) => {
	return async (dispatch) => {
		try {
			dispatch({
				type: SET_RESET_TOKEN,
				payload: token,
			});

			const request = await instance.request({
				url: "v1/auth/email-from-token",
				method: "Post",
				data: {
					token,
				},
			});

			dispatch({
				type: GET_USER_EMAIL_BY_TOKEN,
				payload: request.data?.message,
			});
		} catch (e) {
			//
		}
	};
};

export const candidateSignup = ({
	invite_token,
	password,
	first_name,
	last_name,
	email,
	phone,
	invite_id,
	history,
	role_id,
	image,
}) => {
	return async () => {
		try {
			const formData = new FormData();

			if (invite_id) {
				const dataToSend = {
					password,
					first_name,
					last_name,
					email,
					phone,
					logo: image,
				};

				for (const key in dataToSend) {
					formData.append(key, dataToSend[key]);
				}

				await instance.request({
					url: `v1/auth/candidate-signup/?invite_id=${invite_id}`,
					method: "Patch",
					data: formData,
				});
			} else if (invite_token) {
				const dataToSend = {
					password,
					first_name,
					last_name,
					phone,
					invite_token,
					logo: image,
				};

				for (const key in dataToSend) {
					formData.append(key, dataToSend[key]);
				}

				if (role_id == 12 || role_id == 14) {
					await instance.request({
						url: "v1/auth/team-member-signup/",
						method: "Patch",
						data: formData,
					});
				} else {
					await instance.request({
						url: "v1/auth/candidate-signup",
						method: "Patch",
						data: formData,
					});
				}
			}

			history.push("/");
		} catch (e) {
			//
		}
	};
};

export const getCandidateReferences = (token) => {
	return async (dispatch) => {
		try {
			const request = await instance.request({
				url: `v1/candidate_references/validate/${token}`,
				method: "Get",
			});

			dispatch({
				type: GET_CANDIDATE_REFERENCES_END,
				payload: request.data,
			});
		} catch (e) {
			dispatch({
				type: GET_CANDIDATE_REFERENCES_FAILED,
			});
		}
	};
};

export const handleChangeReferenceForm = (data) => {
	return {
		type: SET_REFERENCE_FORM,
		payload: data,
	};
};

export const handleChange = (data) => {
	return {
		type: HANDLE_CHANGE,
		payload: data,
	};
};
// export const handlePaymentSelectChange = (data) => {
// 	return {
// 		type: HANDLE_PAYMENT_SELECT_CHANGE,
// 		payload: data,
// 	};
// };

export const handleCountryChange = (data) => {
	return {
		type: HANDLE_COUNTRY_CHANGE,
		payload: data,
	};
};

export const handleSelectChange = (data) => {
	return {
		type: HANDLE_SELECT_CHANGE,
		payload: data,
	};
};

// export const handleStateChange = (data) => {
// 	return {
// 		type: HANDLE_STATE_CHANGE,
// 		payload: data,
// 	};
// };

export const handleChangeReferenceFormTextArea = (value, index) => {
	return {
		type: SET_REFERENCE_FORM_TEXT_AREA,
		payload: { value, index },
	};
};

export const submitReferenceForm = (token, data) => {
	return async (dispatch) => {
		try {
			dispatch({
				type: SUBMIT_REFERENCE_FORM_START,
			});

			await instance.request({
				url: `v1/candidate_references/${token}`,
				method: "POST",
				data,
			});

			dispatch({
				type: SUBMIT_REFERENCE_FORM_END,
			});

			dispatch(getCandidateReferences(token));
		} catch (e) {
			dispatch({
				type: SUBMIT_REFERENCE_FORM_FAILED,
			});
		}
	};
};

export const oauthAuthorization = (url) => {
	return async () => {
		try {
			// console.log("BEFORE OAUTH");

			const response = await secure_instance.request({
				url: `v1/oauth/authorize${url}`,
				method: "Get",
			});

			// console.log("OAUTH RESPONSE: ", response);

			window.location.href = response.data.redirect_uri;
		} catch (e) {
			console.log(e);
		}
	};
};

export const createStripePaymentMethod = (
	stripe,
	elements,
	CardNumberElement,
	data
) => {
	return async (dispatch) => {
		try {
			dispatch({
				type: SUBSCRIPTION_START,
			});
			const paymentMethod = await stripe.createPaymentMethod({
				type: "card",
				card: elements.getElement(CardNumberElement),
				billing_details: {
					name: data.name,
					address: {
						country:
							data.country.length > 2
								? countryMapper[data.country]
								: data.country,
						postal_code: data.postalCode,
						line1: data.address,
						city: data.city,
						state: data.state,
					},
				},
			});

			const request = await secure_instance.request({
				url: "v1/subscriptions/",
				method: "Post",
				data: {
					name: data.name,
					payment_method_id: paymentMethod.paymentMethod.id,
				},
			});

			console.log(request, "req");

			dispatch({
				type: SUBSCRIPTION_END,
			});
		} catch (error) {
			console.log(error);
		}
	};
};

export const getSubscriptions = () => {
	return async (dispatch) => {
		dispatch({
			type: GET_SUBSCRIPTION_START,
		});
		try {
			const request = await secure_instance.request({
				url: "v1/subscriptions/",
				method: "Get",
			});

			// console.log(request, "subs");
			dispatch({
				type: GET_SUBSCRIPTION,
				payload: request.data,
			});
		} catch (error) {
			dispatch({
				type: GET_SUBSCRIPTION_FAILED,
			});
		}
	};
};

export const validateStripeForm = (data) => {
	return {
		type: VALIDATE_STRIPE_FORM,
		payload: data,
	};
};

export const handleResetPasswordRequest = (email) => {
	return async (dispatch) => {
		try {
			const request = await instance.request({
				url: "v1/auth/resetpassword/request",
				method: "POST",
				data: {
					email: email,
				},
			});

			// console.log(request.data, "reset");
			alert(request.data.message);

			dispatch({
				type: RESET_PASSWORD_REQUEST,
				payload: request.data.message,
			});
		} catch (error) {
			//
		}
	};
};

export const setEmailErrorMessage = (message) => {
	return {
		type: HANDLE_ERROR_MESSAGE,
		payload: message,
	};
};

export const resetPassword = (data) => {
	return async (dispatch) => {
		try {
			const request = await instance.request({
				url: "v1/auth/resetpassword",
				method: "PATCH",
				data,
			});

			// console.log(request.data, "reset");
			alert(request.data.message);

			dispatch({
				type: RESET_PASSWORD,
				payload: request.data.message,
			});
		} catch (error) {
			//
		}
	};
};
