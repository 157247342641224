import { combineReducers } from "redux";

//Add Additional Reducers Here
import UserReducer from "./Auth/User";
import formsStatus from "./onboarding/formsStatus";
import CompanySetupReducer from "./onboarding/companySetup";
import ClientsReducer from "./onboarding/Clients";
import SetupRoles from "./onboarding/SetupRoles";
import SetupRoleResult from "./onboarding/SetupRoleComponent";
import inviteCandidateReducer from "./onboarding/InviteCandidate";
import teamSetupReducer from "./onboarding/TeamSetup";
import clientFilter from "./Dashboard/ClientFilter.reducers";
import candidateTesting from "./Candidate/candidate-testing.reducer";
import notificationReducer from "./Notification/notification.reducer";
import Billingdetails from "./Dashboard/BillingInfo";

//add here also and give the name to the reducer
const allReducers = combineReducers({
	user: UserReducer,
	clients: ClientsReducer,
	companySetup: CompanySetupReducer,
	setupRoles: SetupRoles,
	SetupRoleResult: SetupRoleResult,
	formsStatus: formsStatus,
	billing: Billingdetails,
	inviteCandidateReducer,
	teamSetupReducer,
	clientFilter,
	candidateTesting,
	notificationReducer,
});

export default allReducers;
