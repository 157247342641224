import { secure_instance } from "../../../api/axios/axios-config";

export const CLIENT_FILTER_SELECTED = "[Client Filter] CLIENT_FILTER_SELECTED";
export const CLIENT_FILTER_REMOVED = "[Client Filter] CLIENT_FILTER_REMOVED";
export const GET_ROLES = "[Invite Candidate] GET_ROLES";
export const SET_CANDIDATE_RIGHT_PANEL =
	"[Client Filter] SET_CANDIDATE_RIGHT_PANEL";
export const CANDIDATE_INVITED_START =
	"[Client Filter] CANDIDATE_INVITED_START";
export const CANDIDATE_INVITED_END = "[Client Filter] CANDIDATE_INVITED_END";
export const CANDIDATE_INVITED_FAILED =
	"[Client Filter] CANDIDATE_INVITED_FAILED";
export const HANDLE_MODAL_EMAIL_CHANGE =
	"[Client Filter] HANDLE_MODAL_EMAIL_CHANGE";
export const RESET_CANDIDATE_EMAIL = "[Client Filter] RESET_CANDIDATE_EMAIL";
export const GET_SPECIFIC_ROLE_TEMPLATE =
	"[Client Filter] GET_SPECIFIC_ROLE_TEMPLATE";
export const IS_EDIT_ROLE = "[Client Filter] IS_EDIT_ROLE";
export const RESET_EDIT_ROLE = "[Client Filter] RESET_EDIT_ROLE";

export const clientFilterSelected = (client) => {
	return {
		type: CLIENT_FILTER_SELECTED,
		payload: client,
	};
};

export const clientFilterRemoved = () => {
	return {
		type: CLIENT_FILTER_REMOVED,
	};
};

export const getFilteredClientPositions = (clientId) => {
	return async (dispatch) => {
		try {
			const request = await secure_instance.request({
				url: `v1/positions/?client_ids=${JSON.stringify(clientId)}`,
				method: "Get",
			});

			dispatch({
				type: GET_ROLES,
				payload: request.data,
			});
		} catch (e) {
			//
		}
	};
};

export const setCandidateRightPanel = (position) => {
	return {
		type: SET_CANDIDATE_RIGHT_PANEL,
		payload: position,
	};
};

// export const inviteCandidateByEmail = (email, positionId) => {
// 	return async (dispatch) => {
// 		try {
// 			dispatch({
// 				type: CANDIDATE_INVITED_START,
// 			});

// 			await secure_instance.request({
// 				url: `v1/user/?invite_candidate=${true}`,
// 				method: "Post",
// 				data: {
// 					email: email,
// 					pos_id: positionId,
// 					//15 is the role for candidate
// 					role_id: 15,
// 				},
// 			});

// 			dispatch({
// 				type: CANDIDATE_INVITED_END,
// 			});
// 		} catch (e) {
// 			dispatch({
// 				type: CANDIDATE_INVITED_FAILED,
// 			});
// 		}
// 	};
// };

export const inviteMultipleCandidatesByEmail = (emails, positionId) => {
	return async (dispatch) => {
		try {
			dispatch({
				type: CANDIDATE_INVITED_START,
			});

			const request = await secure_instance.request({
				url: `v1/user/invite_multiple_candidates`,
				method: "Post",
				data: {
					emails: emails,
					pos_id: positionId,
				},
			});

			if (request.status === 200) {
				alert("Invites are sent to the candidates emails");
			}
			console.log(request, " invite");
			dispatch({
				type: CANDIDATE_INVITED_END,
			});
		} catch (e) {
			dispatch({
				type: CANDIDATE_INVITED_FAILED,
			});
		}
	};
};

export const handleModalEmailChange = (target) => {
	return {
		type: HANDLE_MODAL_EMAIL_CHANGE,
		payload: target,
	};
};

export const resetCandidateEmail = () => {
	return {
		type: RESET_CANDIDATE_EMAIL,
	};
};

export const getRoleTemplate = (roleTemplateId) => {
	return async (dispatch) => {
		try {
			const request = await secure_instance({
				url: `v1/role_templates/${roleTemplateId}`,
				method: "Get",
			});

			dispatch({
				type: GET_SPECIFIC_ROLE_TEMPLATE,
				payload: request.data,
			});
		} catch (e) {
			//
		}
	};
};

export const isEditRole = () => {
	return {
		type: IS_EDIT_ROLE,
	};
};

export const resetEditRole = () => {
	return {
		type: RESET_EDIT_ROLE,
	};
};
