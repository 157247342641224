import React, { useEffect } from "react";
import PositionCard from "../subcomponents/dashboard/PositionCard";
import PositionButton from "../subcomponents/Button";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import * as inviteCandidateActions from "../../redux/actions/onboarding/inviteCandidate";
import * as clientFilterActions from "../../redux/actions/Dashboard/ClientFilter.actions";
import assessmentStatus from "../../utilities/Constants";

export default function PositionComponent(props) {
	const dispatch = useDispatch();
	const history = useHistory();

	const user = useSelector((state) => state.user.user);
	const roles = useSelector((state) => state.inviteCandidateReducer.roles);
	const selectedClientFilter = useSelector(
		(state) => state.clientFilter.selectedClient,
	);
	const candidateTests = useSelector(
		(state) => state.inviteCandidateReducer.candidateTests,
	);
	const tenantProfiles = useSelector(
		(state) => state.inviteCandidateReducer.tenantProfiles,
	);

	const openPositions = [];
	const previousPositions = [];
	roles.map((role) => {
		if (role.is_active) {
			openPositions.push({
				title: role.name,
				id: role.id,
				...role,
			});
		} else {
			previousPositions.push({
				title: role.name,
				id: role.id,
				...role,
			});
		}
	});

	const handleClick = () => {
		history.push(`/dashboard/create-new-position/setup-roles`);
		props.onHandleClick();
	};

	const handlePositionClicked = (position) => {
		dispatch(clientFilterActions.setCandidateRightPanel(position));
	};

	useEffect(() => {
		if (selectedClientFilter?.value) {
			dispatch(
				clientFilterActions.getFilteredClientPositions([
					selectedClientFilter.value,
				]),
			);
		} else {
			if (user.tenantId !== 0) {
				dispatch(inviteCandidateActions.getRoles());
			} else if (user.tenantId === 0) {
				dispatch(inviteCandidateActions.getCandidateTests());
			}
		}
	}, [dispatch, selectedClientFilter, user]);

	useEffect(() => {
		candidateTests.map((test) => {
			dispatch(
				inviteCandidateActions.getTenantProfileForCandidate(
					test.role.tenant_id,
				),
			);
		});
	}, [dispatch, candidateTests]);

	if (props.candidate) {
		return (
			<div style={{ padding: "0px 0px 20px 0px" }}>
				<div className='d-flex align-items-center'>
					<span className='b2'>Pending Tests</span>
				</div>

				{candidateTests.map((test, index) => {
					if (
						test.status === assessmentStatus.INITIALIZED ||
						test.status === assessmentStatus.IN_PROGRESS
					) {
						const tenantProfile = tenantProfiles.find(
							(profile) => profile.tenant_id === test.role.tenant_id,
						);
						return (
							<div key={index}>
								<PositionCard
									onClickStart={props.onClickStart}
									onClick={props.onClick}
									tests
									test={test}
									selected={props.selected}
									title={test?.role.name}
									logo={tenantProfile?.logo}
									time={"20"}
									companyName={tenantProfile?.tenant_name}
									tenantProfile={tenantProfile}
									mobile={props.mobile}
								/>
							</div>
						);
					}
				})}

				{/* {props.previous && ( */}
				<div>
					<div
						className='d-flex align-items-center'
						style={{ paddingTop: "30px" }}>
						<span className='b2'>Previous Tests</span>
					</div>

					{candidateTests.map((test, index) => {
						if (
							test.status === assessmentStatus.UNDER_EVALUATION ||
							test.status === assessmentStatus.FINISHED ||
							test.status === assessmentStatus.NOT_CONSIDERED ||
							test.status === assessmentStatus.SHORTLISTED
						) {
							{/* console.log("tets at position board are " , test); */}
							const tenantProfile = tenantProfiles.find(
								(profile) => profile.tenant_id === test.role.tenant_id,
							);
							return (
								<div key={index}>
									<PositionCard
										tests
										test={test}
										previous
										selected={props.selected}
										// onClick={props.onClick}
										onPreviousClick={props.onPreviousClick}
										title={test?.role.name}
										logo={tenantProfile?.logo}
										companyName={tenantProfile?.tenant_name}
									/>
								</div>
							);
						}
					})}
				</div>
				{/* )} */}
			</div>
		);
	} else {
		return (
			<div style={{ padding: "0px 0px 20px 0px" }}>
				<div className='d-flex justify-content-between'>
					<div className='d-flex align-items-center'>
						{openPositions.length > 0 && (
							<span className='b2'>Open Positions</span>
						)}
					</div>
					<PositionButton
						onClick={handleClick}
						button
						className='button-large'
						label='+  Create New Position'
						style={{ padding: "5px 22px" }}
					/>
				</div>
				{openPositions.map((position, index) => {
					return (
						<div key={index}>
							<PositionCard
								positions
								selected={props.selected}
								onClick={() => {
									handlePositionClicked(position);
									props.onClick();
								}}
								position={position}
							/>
						</div>
					);
				})}

				{props.previous && (
					<div>
						<div
							className='d-flex align-items-center'
							style={{ paddingTop: "30px" }}>
							{previousPositions.length > 0 && (
								<span className='b2'>Previous Positions</span>
							)}
						</div>

						{previousPositions.map((position, index) => {
							return (
								<div key={index}>
									<PositionCard
										positions
										selected={props.selected}
										onClick={() => {
											handlePositionClicked(position);
											props.onClick();
										}}
										position={position}
									/>
								</div>
							);
						})}
					</div>
				)}
			</div>
		);
	}
}
