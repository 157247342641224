import React from "react";
import ReactPlayer from "react-player";
import { useHistory } from "react-router";


export default function FeatureHeader(props) {
	const history = useHistory();
	
	return (
		<div className="feature-component">
			<div className="mt-4">
				<span>Our Core Features</span>
			</div>
			<div className="mt-4 mb-5">
				<span className="span1">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc odio in et, lectus sit lorem id integer.</span>
			</div>
			
			<div className="header-content mt-5 w-100">
				<p className="title-size">{props.title || "One destination for all your hiring needs"}</p>
				{/* <p className="title-size">Ipsum.</p> */}
				<div style={{paddingBottom:"20px", paddingRight:"50px"}}>
					<span className="span1">{props.description || "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc odio in et, lectus sit lorem id integer."}</span>
				</div>
				<div>
					<div style={{width:"307px", height:"68px", borderRadius:"10px", padding:"10px 60px", background: "#00B4D8", margin:"30px 0px"}} className="d-flex flex-column justify-content-center align-items-center pointer" onClick={() => history.push("/signup")}>
						<span className="span3">Get Started</span>
						<span className="span4">10 Free Assessments on Sign up</span>
					</div>
				</div>
			</div>
			<div className="video-player">
				<ReactPlayer
					width='100%'
					height='100%'
					controls
					style={{ border:"2px solid #00b4d8"}}
					url={"https://www.youtube.com/watch?v=4F_b0YBc8vA"}								
				/>
			</div>
			
		</div>
	);
}
