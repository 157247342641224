import React from "react";
import IntegrationsCard from "../../components/subcomponents/IntegrationsCard";
import Select from "../../components/subcomponents/Select";
import Logo from "../../assets/icons/logo/Symbol.png";
import { Container, Row, Col } from "reactstrap";
import Logo1 from "../../assets/icons/logo/mc-logo.svg";
// import { useHistory, useRouteMatch } from "react-router-dom";

export default function Integrations(props) {
	// const history = useHistory();
	// const match = useRouteMatch();

	// const handleClick = () => {
	// 	history.push(`${match.path}/oauth`);
	// };
	const categories = [
		"Screening",
		"Recruitment",
		"Scheduling",
		"Message Channels",
		"Email Verification",
	];

	const integrationCards = [
		{
			label: "Recruitment",
			logo: Logo,
			name: "Lever via Zapier",
			description: "Recruiting Software Built for a Changing World",
		},
		{
			label: "Communication",
			logo: Logo,
			name: "Slack",
			description: "It brings your whole team together",
		},
		{
			label: "Screening",
			logo: Logo,
			name: "Goodhire",
			description:
				"The easiest, most flexible, and most delightful employment background screening ",
		},
		{
			label: "Payments",
			logo: Logo1,
			name: "Master Card",
			description: " Access digital benefits that come with your World",
		},
		{
			label: "Recruitment",
			logo: Logo,
			name: "Lever via Zapier",
			description: "Recruiting Software Built for a Changing World",
		},
	];
	return (
		<>
			<div>
				<div style={{ paddingBottom: "40px" }}>
					<Select
						title='Categories'
						options={categories}
						placeholder='Browse Categories'
					/>
				</div>
				<Container className='w-100' fluid={true}>
					<Row className='w-100'>
						{integrationCards.map((card, index) => {
							return (
								<Col
									className='pointer'
									xs='auto'
									key={index}
									style={{
										paddingBottom: "40px",
										paddingRight: "20px",
									}}>
									<IntegrationsCard
										onClick={props.onClick}
										label={card.label}
										logo={card.logo}
										name={card.name}
										description={card.description}
									/>
								</Col>
							);
						})}
					</Row>
				</Container>
			</div>
		</>
	);
}
