import React, { useState, useEffect } from "react";
import Cross from "../../../assets/icons/basic/x.svg";
import Button from "../Button";
import CustomCheckBox from "../Checkbox";
import Input from "../Input";
import { useSelector, useDispatch } from "react-redux";
import * as setupRoleActions from "../../../redux/actions/onboarding/SetupRoles";
import * as candidateTesting from "../../../redux/actions/Candidate/candidate-testing.actions";

export default function QuestionFeedbackModal(props) {

	const dispatch = useDispatch();

	const [selected, setSelected] = useState(false);
	
	const [checked, setChecked] = useState(
		[
			{id:1, isChecked: false, value:"This question has an error in it", enum: "QUES_HAS_ERROR" },
			{id:2, isChecked: false, value:"One of the answers has a typo in it", enum: "ANS_HAS_ERROR"},
			{id:3, isChecked: false, value:"This question was not clear", enum: "QUES_NOT_CLEAR"},
			{id:4, isChecked: false, value:"The time was not enough to complete this question", enum: "NOT_ENOUGH_TIME"},
			{id:5, isChecked: false, value:"This answer was not clear", enum: "ANS_NOT_CLEAR"},
			{id:6, isChecked: false, value:"Other", enum: "OTHER"}
		]
	);
	
	const [reasons, setReasons] = useState([]);
	const comments = useSelector(state => state.candidateTesting.comments);
	const testPack = useSelector((state) => state.candidateTesting.testPack);
	const assessmentId = useSelector(
		(state) => state.candidateTesting.assessmentId,
	);

	console.log(comments);
	
	const handleChecked = (enumType, id) => {
		var checkboxes = checked;
		var tempReasons = reasons;

		const tempArr = tempReasons.filter((item) => {return item === enumType;});
		if(tempArr.length > 0) {
			tempReasons = tempReasons.filter((item) => {return item !== enumType;});
		}
		else {
			tempReasons.push(enumType);
		}
		
		checkboxes.forEach(checkbox => {
			if (checkbox.id === id)
				checkbox.isChecked = !checkbox.isChecked;
			setSelected(!selected);
		}); 

		setReasons(tempReasons);
		setChecked(checkboxes);
	};

	const handleChange = (event) => {
		dispatch(candidateTesting.handleChangeComments(event.target.value));
	};

	const handleFeedback = () => {
		dispatch(candidateTesting.submitFeedback({
			ques_id: testPack._id,
			tp_id: testPack.tp_id,
			assess_id: assessmentId,
			reasons: reasons,
			comments: comments
		}));

		setTimeout(() => {
			props.onClick();
		},500);
	};

	useEffect(() => {
		dispatch(setupRoleActions.getTestPacks());
	},[dispatch]);

	return (
		<div className='invite-modal'>
			<div className="question-feedback-container d-flex flex-column ">
				<div
					className='d-flex justify-content-end pt-3 pr-0'
					onClick={props.onClick}>
					<img src={Cross} alt='' style={{ cursor: "pointer" }} />
				</div>
				<div className="d-flex">
					<span className="p1 pr-1">What is your reasoning for this feedback? </span>
					<span style={{ color: "red" }}>*</span>
				</div>
				
				<div className="container no-gutters pr-0 pl-0" style={{marginTop:"30px"}}>
					<div className="row no-gutters">
						{
							checked.map((checkbox, index) => {
								return (
									<CustomCheckBox
										className="col-md-6 mb-2 text-justify"
										key={index}
										label={checkbox.value}
										toggle={() => handleChecked(checkbox.enum, checkbox.id)}
										value={checkbox.isChecked }
									/>
								);
							
							})
						
						}
					</div>
					
				</div>
				<div className="w-100" style={{padding:"12px 0px"}}>
					<Input
						textarea
						label="Other Comments"
						labelClass="p1"
						inputStyle={{border:"2px solid #EAEDEE", width:"100%",height:"144px"}}
						onChange={handleChange}
						value={comments}
					/>
				</div>
				<div className="d-flex w-100 justify-content-end">
					<div>
						<Button
							whiteButton
							label="Cancel"
							style={{padding:"13px 31px"}}
							onClick={props.onClick}
						/>
					</div>
					<div className="ml-2">
						<Button
							onClick={( reasons?.length > 0)  ? handleFeedback : () =>{}}
							button
							label="Submit"
							style={{padding:"13px 31px", opacity: (reasons?.length > 0) ? "1" :"0.25" }}
						/>
					</div>
					
				</div>
			</div>
		</div>
	);
}
