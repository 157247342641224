import React, {useState} from "react";
import Slider from "react-rangeslider";
import LeftArrow from "../assets/icons/Arrows/yellowarrow.png";
import RightArrow from "../assets/icons/Arrows/Group.png";
import { useHistory } from "react-router";

// import PriceTile from "../components/subcomponents/PriceTile";

// const description =
//   "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc odio in et.";

// const points = ["lorem Ipsum", "lorem Ipsum", "lorem Ipsum", "lorem Ipsum"];

export default function Pricing(props) {

	const history = useHistory();

	const [price, setPrice] = useState(0);
	const [assessments, setAssessments] = useState(0);
	const [totalCost, setTotalCost] = useState(0); 

	const handleSliderChange = (value) => {
		setAssessments(value);
		if(value > 0 && value <= 50){
			setPrice(30);
			setTotalCost(30 * value);
		}
		else if(value > 50 && value <= 100){
			setPrice(((30 * 50) + (25 * (value - 50)))/ value);
			setTotalCost((30 * 50) + (25 * (value - 50)));
		}
		else if(value > 100 && value <= 500){
			setPrice(((30 * 50) + (25 * 50) + (20 * (value - 100)))/ value);
			setTotalCost((30 * 50) + (25 * 50) + (20 * (value - 100)));
		}
		else if(value > 500){
			setPrice(((30 * 50) + (25 * 50)+ (20 * 400) + (15 * (value - 500)))/value);
			setTotalCost((30 * 50) + (25 * 50)+ (20 * 400) + (15 * (value - 500)));
		}
		else {
			setPrice(0);
			setTotalCost(0);
		}
       
	};

	const pricetoStr = parseFloat(price.toString()).toFixed(2);

	const horizontalLabels = {
		0: "0",
		10: "10",
		20: "20",
		30: "30",
		40: "40",
		50: "50",
		60: "60",
		70: "70",
		80: "80",
		90: "90",
		101: "100+"
	};

	return (
		<div className="pricing-component" style={props.style}>
			<div className="mt-4">
				<span>Pay as you grow</span>
			</div>
			<div className="mt-4 mb-5">
				<span className="span1">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc odio in et, lectus sit lorem id integer.</span>
			</div>
			<div>
				<span className="span2">Assessments/Month</span>
			</div>
			<div className="slider-box mt-2">
				<div className="custom_pricing_slider">
					<Slider
						min={0}
						max={110}
						value={assessments}
						onChange={handleSliderChange}
						labels={horizontalLabels}
					/>
				</div>
			</div>
			<div className="price-box mt-4">
				<div className="price-sub-box">
					<span>Cost per Assessment</span>
					<span className="span1">${pricetoStr}</span>
				</div>
				<div className="price-sub-box mt-4">
					<span className="span1">x</span>
				</div>
				<div className="price-sub-box">
					<span>Total Assessments</span>
					<span className="span1">{assessments}</span>
				</div>
				<div className="price-sub-box mt-4">
					<span className="span1">=</span>
				</div>
				<div className="price-sub-box">
					<span>Total Cost</span>
					<span className="span1">${totalCost}</span>
				</div>
			</div>
			<div className="d-flex mt-4">
				<div  className="mt-5">
					<img
						src={LeftArrow}
						alt=""
					/>
				</div>
				<div style={{width:"300px", height:"68px", borderRadius:"10px", padding:"10px 60px", background: "#00D69A", margin:"60px 20px"}} className="d-flex flex-column justify-content-center pointer" onClick={() => history.push("/signup")}>
					<span className="span3">Get Started</span>
					<span className="span4">10 Free Assessments on Sign up</span>
				</div>
				<div className="mt-2">
					<img
						src={RightArrow}
						alt=""
					/>
				</div>
			</div>
		</div>
	);
}



{/* <div style={{ paddingTop: "99px" }}>
				<h1>Choose the plan that’s right for you</h1>
				<p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc odio in
          et, lectus sit lorem id integer.
				</p>
			</div>
			<div className="pricing_container d-flex justify-content-center align-items-center w-100">
				<div className="row justify-content-center align-items-center mobile-responsiveness">
					<div className="col d-flex justify-content-center" sm={12} md={3}>
						<PriceTile
							background="#00D69A"
							type="Basic"
							rate="10"
							rate_unit="month"
							description={description}
							points={points}
						/>
					</div>
					<div className="col d-flex justify-content-center" sm={12} md={3}>
						<PriceTile
							major={true}
							background="#00B4D8"
							type="Premium"
							rate="20"
							rate_unit="month"
							description={description}
							points={points}
						/>
					</div>
					<div className="col d-flex justify-content-center" sm={12} md={3}>
						<PriceTile
							background="#F4C20D"
							type="Enterprise"
							rate="30"
							rate_unit="month"
							description={description}
							points={points}
						/>
					</div>
				</div>
			</div> */}