import React, { useState } from "react";
import Check from "../../../assets/icons/basic/Frame 292.svg";
export default function SubscriptionCard(props) {

	const [selected, setSelected] = useState(false);

	const handleClick = () => {
		setSelected(!selected);
	};

	return(
		<div className="w-100 d-flex align-items-center" style={{height:"100px", position:"relative",borderRadius:"12px", backgroundColor:"white", marginBottom:"20px", border: (selected ? "0.5px solid #00B4D8" : "none")}} onClick={handleClick}>
			<div className="d-flex flex-column justify-content-center align-items-center" style={{ height:"100%", width:"153px", backgroundColor:"#00B4D8", borderRadius:"12px"}}>
				{
					selected && 
					<span style={{color:"white"}} className="description">
                    Current Plan
					</span>
				}
				<div>
					<span style={{color:"white"}}className="headline2">
						{props.plan || "basic"}
					</span>
				</div>
			</div>
			{
				selected && 
				<div style={{position:"absolute", left:"-10px"}}>
					<img
						src={Check}
						alt=""
						style={{width:"22px", height:"22px"}}
					/>
				</div>
			}
			
			<div className="w-100 d-flex justify-content-between">
				<div style={{paddingInline:"28px"}}>
					<span className="description">
						{props.description || "Lorem Ipsum"}
					</span>
				</div>
				<div style={{paddingRight:"20px"}}>
					<span className="headline2">
						${props.price || "10"}<span className="description"> /month</span>
					</span>
				</div>
			</div>
			
		</div>
	);
}