export const SUBMIT_SETUP_ROLE_FORM_START = "SUBMIT_SETUP_ROLE_FORM_START";
export const SUBMIT_SETUP_ROLE_FORM_END = "SUBMIT_SETUP_ROLE_FORM_END";
import { setFormStatus } from "./formsStatus";

//gives data of all the screens (components give it from props)
export const submitForm = (data) => {
	return (dispatch) => {
		dispatch({
			type: SUBMIT_SETUP_ROLE_FORM_START,
			payload: null,
		});

		//make api calls here to submit all the data
		//once its done
		//if any of the calls fail
		//you do not need to save the data
		//you can simply call resetForm of each redux component

		setTimeout(() => {
			dispatch({ type: SUBMIT_SETUP_ROLE_FORM_END, payload: data });
			dispatch(setFormStatus({ setupRoles: true }));
		}, 2000);
	};
};
