import React from "react";
import CandidateInterview from "../assets/images/HowItworks/CandidateInterview.png";
import DownRight from "../assets/icons/Arrows/Downright.png";
import DownLeft from "../assets/icons/Arrows/DownLeft.png";
import CostPic from "../assets/images/HowItworks/CostPic.png";
import CardPic from "../assets/images/HowItworks/CardPic.png";


const howitWorksCard = (image, heading, text, right) => {
	return(
		<div className="how-it-works-card">
			
			{right ? 
				<>
					<div className="card-text">
						<span>{heading}</span>
						<span className="span1">{text}</span>
					</div>
					<div>
						<img
							src={image}
						/>
					</div>
				</>
				:

				<>
					<div>
						<img
							src={image}
						/>
					</div>
			
					<div className="card-text">
						<span>{heading}</span>
						<span className="span1">{text}</span>
					</div>
				</>
			}
		</div>
	);
};


export default function HowItWorks() {
	return (
		<div className="how-it-works">
			<div>
				<span>How it works</span>
			</div>
			<div className="mt-4 mb-5">
				<span className="span1">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc odio in et, lectus sit lorem id integer.</span>
			</div>
			<div>
				{howitWorksCard(CandidateInterview, "Conduct assessments", "There is no limitation placed on your account and you can conduct as many assessments as needed.")} 
			</div>
			<div className="mt-4 mb-4">
				<img
					src={DownRight}
					alt=""
				/>
			</div>
			<div>
				{howitWorksCard(CostPic, "The next one costs less", "For every assessment you conduct, you lower the cost for the next one. That means that the more the assessments you conduct, the less you have to pay for each one. ", true)} 
			</div>
			<div className="mt-4 mb-4">
				<img
					src={DownLeft}
					alt=""
				/>
			</div>
			<div>
				{howitWorksCard(CardPic, "Pay at the end of the month", "No need to worry about paying for each assessment individually. Just pay the total cost for each month at the end of the month.")} 
			</div>
		</div>
	);
}
