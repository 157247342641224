import React,{useState, useEffect} from "react";
import { useDispatch, useSelector } from "react-redux";
// import { useHistory } from "react-router-dom";
import RnrLogo from "../../assets/icons/dashboard/logo.png";
import Signininfo from "../../components/dashboard/Signin/SigninInfo";
import Button from "../subcomponents/Button";
import * as Actions from "../../redux/actions/Auth/authAction";
import { isEmailValid } from "../../utilities/Validator";
import AlertBox from "../AlertBox";

function Link({ name, url }) {
	return (
		<a className='helper_link' href={url}>
			{name}
		</a>
	);
}


export default function ForgotPassword(props) {
	// const history = useHistory();
	const dispatch = useDispatch();

	const errorMessage = useSelector((state) => state.user.errorMessage);
	// const successMessage = useSelector(state => state.user.resetPasswordMessage);
	const emailSent = useSelector(state => state.user.emailSent);

	const [user, setUser] = useState({
		email: null,
	});

	const [errors, setErrors] = useState({
		email: false,
		invalid: false,
	});

	function handleInput(event) {
		setUser({ ...user, [event.target.name]: event.target.value });
	}

	function onBlur(event) {
		if (event.target.name === "email") {
			if (!isEmailValid(user[event.target.name])) {
				setErrors({ ...errors, [event.target.name]: true });
				return;
			}
		}

		
		setErrors({ ...errors, [event.target.name]: false });
		
	}

	function isEmailOkay() {
		if (user.email === null) {
			return false;
		}

		if (errors.email) {
			return false;
		}

		return true;
	}


	function validateForm() {
		if (user.email === null) {
			return { isValid: false, message: "Email is missing" };
		}

		if (errors.email) {
			return { isValid: false, message: "Email is Invalid" };
		}

		return { isValid: true, message: "" };
	}

	const handleResetPassword = (event) => {

		event.preventDefault();

		const validation = validateForm();

		if (!validation.isValid) {
			return dispatch(Actions.setEmailErrorMessage(validation.message));
		}

		else {
			return (
				dispatch(Actions.handleResetPasswordRequest(user.email))
			);
		}
	};

	useEffect(() => {
		onBlur({ target: { name: "email" } });	
	},[]);

	if(emailSent){
		dispatch(Actions.setErrorMessage(""));
	}



	return (
		<div className='signin_container'>
			<div className='main-row row row-no-gutters'>
				<div className='left_box col-xs-7 col-sm-7 col-md-7 col-lg-7 no-gutters'>
					<div className='content_wrapper d-flex flex-column justify-content-between'>
						<div
							className='d-flex justify-flex-start'
							style={{ paddingTop: "20px", paddingLeft: "80px" }}>
							<img
								onClick={() => props.history.push("/")}
								src={RnrLogo}
								width='237px'
								style={{ cursor: "pointer" }}
							/>
						</div>
						<div className='d-flex justify-content-center'>
							<div>
								<span className='headline3'>Forgot Password</span>
								<div className='SigninFormGroup' style={{ marginTop: "30px" }}>
									{errorMessage && (
										<AlertBox
											color='error'
											title='Error'
											description={`${errorMessage}`}
										/>
									)}
									<input
										type='text'
										placeholder='Email'
										label=''
										name='email'
										onChange={handleInput}
										onBlur={onBlur}
										className={
											"description " +
											(isEmailOkay() ? "input-underline" : "input-underline-error")
										}
									/>
								</div>
								<div>
									<Button
										onClick={handleResetPassword}
										button
										label='Reset Password'
										style={{ padding: "9.5px 124px" }}
									/>
								</div>
							</div>
						</div>
						<div className='links'>
							<Link name='RanknRole Inc, 2021' url='google.com' />
							<Link name='Privacy Policy' url='google.com' />
							<Link name='Terms and conditions' url='google.com' />
						</div>
					</div>
				</div>
				<div className='right_box col-xs-5 col-sm-5 col-md-5 col-lg-5 no-gutters'>
					<div className='background'></div>
					<div className='content_wrapper d-flex flex-column justify-content-between'>
						<div></div>
						<Signininfo />
						<div className='links'>
							<Link name='Info' url='google.com' />
							<Link name='Any Questions?' url='google.com' />
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
