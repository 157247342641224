import React from "react";
import { ReactComponent as ZapierLogo } from "../../assets/icons/logo/zapier.svg";
import Select from "../subcomponents/Select";
import Button from "../subcomponents/Button";
import Tick from "../../assets/icons/onboarding/checkmark.png";
import { useDispatch, useSelector } from "react-redux";
import * as authActions from "../../redux/actions/Auth/authAction";
import { useLocation } from "react-router-dom";
import Header from "../onboarding/Header";

export default function OAuthScreen(props) {
	const dispatch = useDispatch();
	const location = useLocation();

	const tenantProfilesOfUser = useSelector(
		(state) => state.user.tenantProfilesOfUser,
	);
	const user = useSelector((state) => state.user.user);

	const tenantProfiles = [];
	tenantProfilesOfUser?.map((tenantProfile) => {
		if (tenantProfile.tenant_id !== 0) {
			tenantProfiles.push({
				label: tenantProfile.tenant_name,
				value: tenantProfile.tenant_id,
				icon: tenantProfile.logo,
				...tenantProfile,
			});
		}
	});

	const handleSwitchTenantProfile = (selectedTenant) => {
		if (selectedTenant) {
			if (user.tenantId === selectedTenant.value) {
				return;
			} else {
				dispatch(authActions.switchTenantAccount(selectedTenant.value));
			}
		}
	};

	const handleAllowAccessClicked = () => {
		dispatch(authActions.oauthAuthorization(location.search));
	};

	// const companies = [
	// 	{ label: "Imroz", value: "1", icon: Logo },
	// 	{ label: "Imroz", value: "1", icon: Logo },
	// 	{ label: "Imroz", value: "1", icon: Logo },
	// ];

	// const zapieraccess = [
	// 	{ text: "Lorum Ipsum", icon: Tick },
	// 	{ text: "Lorum Ipsum", icon: Tick },
	// 	{ text: "Lorum Ipsum", icon: Tick },
	// ];

	return (
		<div className="error-layout d-flex flex-column justify-content-between">
			<div>
				<Header suspended dashboard/>
			</div>
			<div className="d-flex justify-content-center">
				<div className='oauth-card'>
					<div>
						<ZapierLogo />
					</div>
					<div style={{ padding: " 25px 0px 40px 0px" }}>
						<span className='p1'>
						Zapier wants to access your RanknRole account
						</span>
					</div>
					<div>
						<Select
							title='Select Company'
							placeholder='Select Company'
							agency
							agencySetup
							options={tenantProfiles}
							updateSelections={(e) => handleSwitchTenantProfile(e)}
						/>
					</div>
					<div className='d-flex' style={{ marginTop: "25px" }}>
						<span className='headline3'>Zapier will be able to:</span>
					</div>
					<div className='d-flex flex-column'>
						<div className='d-flex' style={{ margin: "5px 10px" }}>
							<img src={Tick} alt='' style={{ width: "25px", height: "25px" }} />
							<div>
								<span className='description'>
									{props.access || "Create or deactivate positions."}
								</span>
							</div>
						</div>
						<div className='d-flex' style={{ margin: "5px 10px" }}>
							<img src={Tick} alt='' style={{ width: "25px", height: "25px" }} />
							<div>
								<span className='description'>
									{props.access || "Invite candidates for a position."}
								</span>
							</div>
						</div>
						<div className='d-flex' style={{ margin: "5px 10px" }}>
							<img src={Tick} alt='' style={{ width: "25px", height: "25px" }} />
							<div>
								<span className='description'>
									{props.access || "Shortlist candidates for a position."}
								</span>
							</div>
						</div>
					</div>
					<div style={{ marginTop: "60px" }}>
						<span className='description'>
						By clicking on allow access, you authorize Zapier to use your
						information in accordance with its
							<span style={{ color: "#00B4D8" }}> privacy policy</span>. You can
						stop it at any time on the
							<span style={{ color: "#00B4D8" }}> integrations </span> page of
						your RanknRole account.
						</span>
					</div>
					<div
						className='d-flex justify-content-end'
						style={{ marginTop: "45px" }}>
						{/* <div style={{ marginRight: "20px" }}>
						<Button whiteButton label='Cancel' />
					</div> */}
						<Button
							button
							label='Allow Access'
							onClick={handleAllowAccessClicked}
						/>
					</div>
				</div>
			</div>
			<div>

			</div>
		</div>
	);
}
