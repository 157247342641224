import React,{useState} from "react";
import RnrLogo from "../../assets/icons/dashboard/logo.png";
import Signininfo from "../../components/dashboard/Signin/SigninInfo";
import Button from "../subcomponents/Button";
import PasswordInput from "./Signup/PasswordInput";
import { useParams } from "react-router";
import { Redirect } from "react-router-dom";
import { isPasswordValid } from "../../utilities/Validator";
import AlertBox from "../AlertBox";
import { useDispatch, useSelector } from "react-redux";
import * as Actions from "../../redux/actions/Auth/authAction";

function Link({ name, url }) {
	return (
		<a className='helper_link' href={url}>
			{name}
		</a>
	);
}

export default function ResetPassword(props) {

	const dispatch = useDispatch();

	const { token } = useParams();
	const passwordUpdated = useSelector(state => state.user.passwordUpdated);

	const [password, setPassword] = useState({
		password: null,
		confirm_password: null,
	});

	const [alert, setAlert] = useState();
	// const [processing, setProcessing] = useState();

	function handleInput(event) {
		setPassword({ ...password, [event.target.name]: event.target.value });
	}

	function validateForm() {

		if (!isPasswordValid(password.password)) {
			return {
				isValid: false, title: "Weak password", message: [
					<ul key="1">
						<li key="2">Password should contain atleast 1 special charater</li>
						<li key="4">Password should contain minimum 10 charaters</li>
					</ul>
				]
			};
		}

		if (password.password !== password.confirm_password) {
			return { isValid: false, title: "Error", message: "Password did not match" };
		}

		return { isValid: true, message: "" };

	}

	const handleSubmit = async (event) => {
		event.preventDefault();

		const validation = validateForm();

		if (!validation.isValid) {
			setAlert(
				<AlertBox color="danger" title={validation.title} description={validation.message} />
			);
			return;
		}
		const bodyData = { reset_token: token, password: password.password };

		dispatch(Actions.resetPassword(bodyData));

		// }
	};

	
	if(passwordUpdated){
		// alert("Password Updated");
		return <Redirect to="/"/>;
	}
		
	

	return (
		<div className='signin_container'>
			<div className='main-row row row-no-gutters'>
				<div className='left_box col-xs-7 col-sm-7 col-md-7 col-lg-7 no-gutters'>
					<div className='content_wrapper d-flex flex-column justify-content-between'>
						<div
							className='d-flex justify-flex-start'
							style={{ paddingTop: "20px", paddingLeft: "80px" }}>
							<img
								onClick={() => props.history.push("/")}
								src={RnrLogo}
								width='237px'
								style={{ cursor: "pointer" }}
							/>
						</div>
						<div className='d-flex justify-content-center'>
							<div>
								<span className='headline3'>Reset Password</span>
								<div className='SigninFormGroup' style={{ marginTop: "30px" }}>
									{alert}
									<PasswordInput
										placeholder='Password'
										label=''
										name='password'
										className='description input-underline'
										onChange={handleInput}
									/>
									<PasswordInput
										placeholder='Confirm password'
										label=''
										name='confirm_password'
										className='description input-underline'
										onChange={handleInput}
									/>
								</div>
								<div>
									<Button
										button
										label='Confirm'
										style={{ padding: "9.5px 124px" }}
										onClick={handleSubmit}
									/>
								</div>
							</div>
						</div>
						<div className='links'>
							<Link name='RanknRole Inc, 2021' url='google.com' />
							<Link name='Privacy Policy' url='google.com' />
							<Link name='Terms and conditions' url='google.com' />
						</div>
					</div>
				</div>
				<div className='right_box col-xs-5 col-sm-5 col-md-5 col-lg-5 no-gutters'>
					<div className='background'></div>
					<div className='content_wrapper d-flex flex-column justify-content-between'>
						<div></div>
						<Signininfo />
						<div className='links'>
							<Link name='Info' url='google.com' />
							<Link name='Any Questions?' url='google.com' />
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
