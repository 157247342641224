import React from "react";
import PaymentInfoMobile from "./PaymentInfoMobile";
import PaymentInfoWeb from "./PaymentInfoWeb";

export default function PaymentInfo() {
	return (
		<div>
            
			<div className="SignIn-DesktopView">
				<PaymentInfoWeb/>
			</div>
			<div className="SignIn-MobileView">
				<PaymentInfoMobile/>
			</div>
		
		</div>
	);
}
