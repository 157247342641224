import React, {useState} from "react";
import FAQCard from "./subcomponents/FAQCard";
import { Container, Row, Col } from "reactstrap";

const faqTitle = (title, selected, onClick) => {
	return (
		<div style={{width:"276px", padding:"18px 0px", borderRadius:"8px", background: selected ? "#00B4D8" : "white", cursor:"pointer"}} onClick={onClick}>
			<span className="b1" style={{color: selected ? "white" : "black" }}>{title}</span>
		</div>
	);
};


export default function FAQComponent(props) {

	const [selected, setSelected] = useState(0);

	const faqsMenu = ["Platform", "Assessments", "Managing Teams", "Payments", "Hiring", "Support", "Miscellaneous"];

	const Faqs = [
		{
			question:"Lorem Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc odio in et, lectus sit lorem id integer. Lorem Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc odio in et, lectus sit lorem id integer.",
			answer:"Lorem Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc odio in et, lectus sit lorem id integer.Lorem Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc odio in et, lectus sit lorem id integer.Lorem Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc odio in et, lectus sit lorem id integer."
		},
		{
			question:"Lorem Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc odio in et, lectus sit lorem id integer. Lorem Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc odio in et, lectus sit lorem id integer.",
			answer:"Lorem Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc odio in et, lectus sit lorem id integer.Lorem Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc odio in et, lectus sit lorem id integer.Lorem Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc odio in et, lectus sit lorem id integer."
		},
		{
			question:"Lorem Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc odio in et, lectus sit lorem id integer. Lorem Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc odio in et, lectus sit lorem id integer.",
			answer:"Lorem Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc odio in et, lectus sit lorem id integer.Lorem Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc odio in et, lectus sit lorem id integer.Lorem Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc odio in et, lectus sit lorem id integer."
		},
		{
			question:"Lorem Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc odio in et, lectus sit lorem id integer. Lorem Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc odio in et, lectus sit lorem id integer.",
			answer:"Lorem Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc odio in et, lectus sit lorem id integer.Lorem Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc odio in et, lectus sit lorem id integer.Lorem Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc odio in et, lectus sit lorem id integer."
		},
		{
			question:"Lorem Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc odio in et, lectus sit lorem id integer. Lorem Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc odio in et, lectus sit lorem id integer.",
			answer:"Lorem Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc odio in et, lectus sit lorem id integer.Lorem Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc odio in et, lectus sit lorem id integer.Lorem Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc odio in et, lectus sit lorem id integer."
		},
		{
			question:"Lorem Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc odio in et, lectus sit lorem id integer. Lorem Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc odio in et, lectus sit lorem id integer.",
			answer:"Lorem Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc odio in et, lectus sit lorem id integer.Lorem Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc odio in et, lectus sit lorem id integer.Lorem Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc odio in et, lectus sit lorem id integer."
		},
	];

	const AssessmentsFaqs = [
		{
			question:"Lorem Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc odio in et, lectus sit lorem id integer. Lorem Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc odio in et, lectus sit lorem id integer.",
			answer:"Lorem Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc odio in et, lectus sit lorem id integer.Lorem Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc odio in et, lectus sit lorem id integer.Lorem Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc odio in et, lectus sit lorem id integer."
		},
		{
			question:"Lorem Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc odio in et, lectus sit lorem id integer. Lorem Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc odio in et, lectus sit lorem id integer.",
			answer:"Lorem Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc odio in et, lectus sit lorem id integer.Lorem Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc odio in et, lectus sit lorem id integer.Lorem Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc odio in et, lectus sit lorem id integer."
		},
		{
			question:"Lorem Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc odio in et, lectus sit lorem id integer. Lorem Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc odio in et, lectus sit lorem id integer.",
			answer:"Lorem Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc odio in et, lectus sit lorem id integer.Lorem Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc odio in et, lectus sit lorem id integer.Lorem Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc odio in et, lectus sit lorem id integer."
		},
		
	];

	const onClick = (selected) => {
		!selected;
	};

	const handleSelected = (index) => {
		setSelected(index);
	};
	
	if(props.pricing) {
		return (
			<div className="faq-component">
				<div>
					<span style={{color:props.color}}>FAQs</span>
				</div>
				<div className="mt-4 mb-5">
					<span className="span1">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc odio in et, lectus sit lorem id integer.</span>
				</div>
	
				<Container className="no-gutters pr-0 pl-0 w-100">
					<Row className="d-flex justify-content-center">
						{
							Faqs.map((faq, key) => {
								return (
									<Col key={key}  lg="6" md="8"   style={{margin:"15px 0px"}} >
										<FAQCard 
											onToggle={(e) => onClick(e)} 
											open={open}
											question={faq.question}
											answer={faq.answer}
					
										/>
									</Col>
								);
							})
						}
					</Row>
				</Container>
				
			</div>
		);
	}
	else if (props.features) {
		return (
			<div className="features-faq-component">
				<div>
					<span>FAQs</span>
				</div>
				<div className="mt-4 mb-5">
					<span className="span1">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc odio in et, lectus sit lorem id integer.</span>
				</div>
				<div className="faqs-menu">
					<div className="d-flex flex-column pr-5">
						{
							faqsMenu.map((faq, index) => {
								return (
									<div key={index} className="mt-5">
										{faqTitle(faq, (index === selected) ? true : false, () => handleSelected(index))}
									</div>
								);
							})
						}
					</div>
					{
						selected === 0 ?

							<div>
								<Container className="no-gutters pr-0 pl-0 w-100">
									<Row className="d-flex justify-content-center">
										{
											Faqs.map((faq, key) => {
												return (
													<Col key={key}  lg="12" style={{margin:"10px 0px"}} >
														<FAQCard 
															features
															onToggle={(e) => onClick(e)} 
															open={open}
															question={faq.question}
															answer={faq.answer}
					
														/>
													</Col>
												);
											})
										}
									</Row>
								</Container>
							</div>
							:
							selected === 1 ?
								<div>
									<Container className="no-gutters pr-0 pl-0 w-100">
										<Row className="d-flex justify-content-center">
											{
												Faqs.map((faq, key) => {
													return (
														<Col key={key}  lg="12" style={{margin:"10px 0px"}} >
															<FAQCard 
																features
																onToggle={(e) => onClick(e)} 
																open={open}
																question={faq.question}
																answer={faq.answer}
					
															/>
														</Col>
													);
												})
											}
										</Row>
									</Container>
								</div>
								:

								selected === 2 ?
									<div>
										<Container className="no-gutters pr-0 pl-0 w-100">
											<Row className="d-flex justify-content-center">
												{
													AssessmentsFaqs.map((faq, key) => {
														return (
															<Col key={key}  lg="12" style={{margin:"10px 0px"}} >
																<FAQCard 
																	features
																	onToggle={(e) => onClick(e)} 
																	open={open}
																	question={faq.question}
																	answer={faq.answer}
					
																/>
															</Col>
														);
													})
												}
											</Row>
										</Container>
									</div>
									: 
									selected === 3 ?
										<div>
											<Container className="no-gutters pr-0 pl-0 w-100">
												<Row className="d-flex justify-content-center">
													{
														Faqs.map((faq, key) => {
															return (
																<Col key={key}  lg="12" style={{margin:"10px 0px"}} >
																	<FAQCard 
																		features
																		onToggle={(e) => onClick(e)} 
																		open={open}
																		question={faq.question}
																		answer={faq.answer}
					
																	/>
																</Col>
															);
														})
													}
												</Row>
											</Container>
										</div>
										:
										selected === 4 ?
											<div>
												<Container className="no-gutters pr-0 pl-0 w-100">
													<Row className="d-flex justify-content-center">
														{
															Faqs.map((faq, key) => {
																return (
																	<Col key={key}  lg="12" style={{margin:"10px 0px"}} >
																		<FAQCard 
																			features
																			onToggle={(e) => onClick(e)} 
																			open={open}
																			question={faq.question}
																			answer={faq.answer}
					
																		/>
																	</Col>
																);
															})
														}
													</Row>
												</Container>
											</div>
											:
											selected === 5 ?
												<div>
													<Container className="no-gutters pr-0 pl-0 w-100">
														<Row className="d-flex justify-content-center">
															{
																Faqs.map((faq, key) => {
																	return (
																		<Col key={key}  lg="12" style={{margin:"10px 0px"}} >
																			<FAQCard 
																				features
																				onToggle={(e) => onClick(e)} 
																				open={open}
																				question={faq.question}
																				answer={faq.answer}
					
																			/>
																		</Col>
																	);
																})
															}
														</Row>
													</Container>
												</div>
												:
												<div>
												</div>
					}
				</div>
			</div>
		);
	}
	
}
