import React from "react";
import CheckMark from "../../assets/icons/onboarding/checkmark.png";


export default function MenuItem({label,done,onClickDone,onClickNotDone}){

	return (
		<>
			<div onClick={done == true ? onClickDone : onClickNotDone} style={{paddingTop:"5px",paddingBottom:"5px"}} className="d-flex align-items-center w-100 justify-content-between">
				<span className="headline3" style={{marginBottom:"0px"}}>{label}</span>
				<div>
					{done === true ? <img style={{width:"50%"}} src={CheckMark}/> : null}
				</div>
			</div>

		</>
	);
}