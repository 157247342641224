import React, {useEffect, useState} from "react";
import BillingCard from "../../../assets/icons/pricing/card1.png";
import PencilEdit from "../../../assets/images/dashboard/edit.svg";
import AddIcon from "../../../assets/images/dashboard/add.svg";
// import Button from "../Button";

import AddCard from "../../../views/pages/Settings/Billing/AddCard";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import EditCard from "../../../views/pages/Settings/Billing/EditCard";
// import CancellationCard from "../../../views/pages/Settings/Billing/CancellationCard";
import { useDispatch, useSelector } from "react-redux";
import * as BillingActions from "../../../redux/actions/Dashboard/BillingInfo";
import { stripePublicKey } from "../../../utilities/Constants";

export default function BillingDetailsCard() {
	
	const [addCard, setAddCard] = useState(false);
	const [editCard, setEditCard] = useState(false);
	const dispatch = useDispatch();

	// const [cancellationCard, setCancellationCard] = useState(false);

	const handleAddCard = () => {
		if(cardForm.length >= 5){
			alert("Max limit reached, you can't add more than 5 cards");
		}
		else{
			setAddCard(true);
		}
		
	};

	const form = useSelector(state => state.billing.invoiceForm);

	const cardForm = useSelector(state => state.billing.cardform);

	// console.log(cardForm.length,"card");

	
	const defaultCard = useSelector(state => state.billing.defaultCard);
	// console.log(defaultCard);

	const subscriptionCompleted = useSelector(state => state.billing.subscriptionCompleted);

 
	const handleEditCard = () => {
		setEditCard(true);
	};

	// const handleCancel = () => {
	// 	setCancellationCard(true);
	// };


	useEffect(() => {
		cardForm.map(card => {
			if(card.customer.invoice_settings.default_payment_method === card.id){
				dispatch(BillingActions.setDefaultCard({
					last4: card.card.last4,
					expMonth: card.card.exp_month,
					expYear: card.card.exp_year
				}));
					
				
			}
		});
	},[cardForm]);


	
	useEffect(() => {
		dispatch(BillingActions.getCardDetails());
		if(subscriptionCompleted){
			setAddCard(false);
		}
		
	},[dispatch, subscriptionCompleted]);

	const stripePromise = loadStripe(stripePublicKey);

	return(
		<>
			{
				addCard && 
				<Elements stripe={stripePromise}>
					<AddCard onCross={() => setAddCard(false)}/>
				</Elements>
			}
			{
				editCard && 
					
						<EditCard onCross={() => setEditCard(false)} />
					
			}
			{/* {
				cancellationCard && 
				
					<CancellationCard  label="Continue" onClick={() => setCancellationCard(false)}/>
				
			} */}
			<div className="billing-details-card">
			
				<div className="spacing-billing-card">
					<img
						src={BillingCard}
						alt=""
						style={{width:"100%", height:"182px" }}
					/>
					<div style={{position:"absolute", right:"26px", bottom:"36px"}} className="card-digits">
						<span className="headline2" style={{color:"white"}}>{defaultCard?.last4|| "1234" }</span>
					</div>
					<div style={{position:"absolute", left:"20px", bottom:"8px"}}>
						<span className="description"  style={{color:"white"}}>{defaultCard?.expMonth + "/" + (defaultCard?.expYear.toString().substr(-2) || "MM/YY")}</span>
					</div>
				</div>
				<div className="w-100 d-flex justify-content-between">
					<div className="spacing-billing-details">
						<div>
							<span className="p2">Next Payment</span>
						</div>
						<div style={{marginTop:"5px"}}>
							<span className="hero3">${form?.total_cost}</span>
						</div>
						<div>
							<span className="p1">On {form.data?.due_date === null ? "N/A": form.data?.due_date}</span>
						</div>
						{/* <div style={{marginTop:"25px", marginBottom:"22px"}}>
							<Button
								button 
								textClass="b3"
								className="button-medium"
								label="Cancel Subscription"
								textStyle={{color: "#FC4848"}}
								onClick={handleCancel}
								style={{backgroundColor:"transparent" ,padding:"7.5px 11.5px"}}
							/>
						</div> */}
					</div>
					<div className="d-flex" style={{marginRight:"22px"}}>
						<div className="pointer" onClick={handleEditCard}>
							<img
								src={PencilEdit}
								alt=""
								style={{width:"19px", height:"19px"}}
							/>
						</div>
						<div className="pointer" style={{marginLeft:"18.67px"}} onClick={handleAddCard}>
							<img
								src={AddIcon}
								alt=""
								style={{width:"18.33px", height:"18.33px"}}
							/>
						</div>
					</div>
				</div>
			
			</div>
			
		</>
		
	);
}