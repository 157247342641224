import React, {useEffect} from "react";
import gif from "../../../assets/gifs/rnr-loading.gif";
import PageNotFound from "../../../views/PageNotFound";
import DashboardContainer from "../../../views/Dashboard/DashboardContainer";
import SignIn from "../../../views/SignIn/SignIn";
import { useSelector, useDispatch } from "react-redux";
import * as candidateTestingActions from "../../../redux/actions/Candidate/candidate-testing.actions";
import { useParams } from "react-router-dom";


export default function AssessmentInvite() {
	const dispatch = useDispatch();
	const param = useParams();
	
	const linkStatus = useSelector(state => state.candidateTesting.linkStatus);
	const isLoading = useSelector(state => state.candidateTesting.isLoading);
	const user = useSelector((state) => state.user.user);

	useEffect(() => {

		dispatch(candidateTestingActions.linkIdParam(param.linkId));
		dispatch(candidateTestingActions.inviteVerification(param.linkId));

	},[dispatch , param.linkId]);
    

	if(isLoading){
		return(
			<>
				<div
					className='d-flex align-items-center justify-content-center'
					style={{
						backgroundColor: "rgb(253, 253, 253)",
						minHeight: "100vh",
					}}>
					<img src={gif} width='50%' height='50%' />
				</div>
			</>
		);
	}

	if(linkStatus === 200) {
		return(
			<>
				{user?.accessToken ? 
					<DashboardContainer />  : 
					<SignIn linkId = {param.linkId}/>}	
			</>
		);
	}

	return(
		<>
			<PageNotFound/>
		</>
	);
}
