import React, { useState, useEffect } from "react";
import Slider from "react-rangeslider";
import { Redirect, useHistory } from "react-router-dom";
import Button from "./Button";
import Input from "./Input";
import { useDispatch, useSelector } from "react-redux";
import * as Actions from "../../redux/actions/Auth/authAction";

export default function PricingCalculator() {

	const history = useHistory();
	const dispatch = useDispatch();
	const [price, setPrice] = useState(0);
	const [assessments, setAssessments] = useState(0);
	const [totalCost, setTotalCost] = useState(0);
	const isTenantSubscribed = useSelector(state => state.user.isTenantSubscribed);
	const candidateUser = useSelector(state => state.user.user);

	const handleSliderChange = (value) => {
		setAssessments(value);
		if(value > 0 && value <= 50){
			setPrice(30);
			setTotalCost(30 * value);
		}
		else if(value > 50 && value <= 100){
			setPrice(((30 * 50) + (25 * (value - 50)))/ value);
			setTotalCost((30 * 50) + (25 * (value - 50)));
		}
		else if(value > 100 && value <= 500){
			setPrice(((30 * 50) + (25 * 50) + (20 * (value - 100)))/ value);
			setTotalCost((30 * 50) + (25 * 50) + (20 * (value - 100)));
		}
		else if(value > 500){
			setPrice(((30 * 50) + (25 * 50)+ (20 * 400) + (15 * (value - 500)))/value);
			setTotalCost((30 * 50) + (25 * 50)+ (20 * 400) + (15 * (value - 500)));
		}
		else {
			setPrice(0);
		}
       
	};

	const horizontalLabels = {
		0: "0",
		50: "50",
		100: "100",
		500: "500+",
	};
	
	const pricetoStr = parseFloat(price.toString()).toFixed(2);

	const handleSubmit = () => {
		history.push("/payment");
	};

	useEffect(() => {
		dispatch(Actions.getSubscriptions());
	},[dispatch]);


	if(candidateUser.tenantId === 0){
		return <Redirect to="/dashboard"/>;
	}
	else if(isTenantSubscribed ){
		if(isTenantSubscribed?.data[0]?.status !== "unpaid"){
			return <Redirect to="/onboarding/companySetup"/>;
		}
		else {
			return (
				<Redirect to="account-suspended"/>
			);
		}

	}

	const handleAssessmentChange = (e) => {
		setAssessments(e.target.value);
		if(e.target.value > 0 && e.target.value <= 50){
			setPrice(30);
			setTotalCost(30 * e.target.value);
		}
		else if(e.target.value > 50 && e.target.value <= 100){
			setPrice(((30 * 50) + (25 * (e.target.value - 50)))/ e.target.value);
			setTotalCost((30 * 50) + (25 * (e.target.value - 50)));
		}
		else if(e.target.value > 100 && e.target.value <= 500){
			setPrice(((30 * 50) + (25 * 50) + (20 * (e.target.value - 100)))/ e.target.value);
			setTotalCost((30 * 50) + (25 * 50) + (20 * (e.target.value - 100)));
		}
		else if(e.target.value > 500){
			setPrice(((30 * 50) + (25 * 50)+ (20 * 400) + (15 * (e.target.value - 500)))/e.target.value);
			setTotalCost((30 * 50) + (25 * 50)+ (20 * 400) + (15 * (e.target.value - 500)));
		}
		else {
			setPrice(0);
		}
	};

	return (
		<div className="d-flex flex-column" style={{width:"500px"}}>
			<span className="headline3">Pricing Calculator</span>
			<span className="description mt-2" style={{color:"#96999C"}}>The more the use, the less you pay. Get greater discounts and lower prices the more assessments you conduct.</span>
			<span className="description mt-3">Assessments / Month</span>
			<div className="custom_pricing_slider mt-5">
				<Slider
					min={0}
					max={510}
					value={assessments}
					onChange={handleSliderChange}
					labels={horizontalLabels}
				/>
			</div>
			<div className="pricing-calculator-input pr-5 mt-4 w-100">
				<div className="mb-2">
					<Input
						type="number"
						label="Average Cost Per Assessment $"
						inputStyle={{border: "1px solid #96999C"}}
						value={pricetoStr}
						style={{width:"90%"}}      
					/>
				</div>

				<div>
					<Input
						type="number"
						label="Total Assessments"
						inputStyle={{border: "1px solid #96999C"}}
						value={assessments}
						style={{width:"90%"}}
						onChange={handleAssessmentChange}
					/>
				</div>
				
			</div>
			<div className="d-flex justify-content-end mt-4">
				<span>Total Cost: <span style={{color:"#00B4D8"}}>${totalCost}</span></span>
			</div>
			<div className="d-flex justify-content-center mt-5">
				<Button
					style={{width:"100%", display: "flex", justifyContent:"center"}}
					button
					label='Continue'
					onClick={handleSubmit}
				/>
			</div>
			<div style={{marginTop: "20px"}} className="d-flex justify-content-center">
				<span className="description" style={{color:"#96999C"}}>You are rewarded with 10 free assessments</span>
			</div>
		</div>
	);
}
