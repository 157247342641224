import React, { useState, useEffect } from "react";
import ProgressBar from "../../subcomponents/dashboard/ProgressBar";
import { ReactComponent as ExclamationMark } from "../../../assets/icons/basic/exclamation-circle.svg";
import { ReactComponent as Minimize } from "../../../assets/icons/basic/minus.svg";
import { useSelector, useDispatch } from "react-redux";
import * as inviteCandidateActions from "../../../redux/actions/onboarding/inviteCandidate";
import * as candidateTestingActions from "../../../redux/actions/Candidate/candidate-testing.actions";
import BlankPicture from "../../../assets/icons/Userpic/userprofile.svg";

export default function InterviewDetails() {
	const dispatch = useDispatch();

	const [open, setOpen] = useState(false);

	const user = useSelector((state) => state.user.user);
	const pendingTestRightPanel = useSelector(
		(state) => state.candidateTesting.pendingTestRightPanel,
	);
	const candidateTests = useSelector(
		(state) => state.inviteCandidateReducer.candidateTests,
	);
	const tenantProfiles = useSelector(
		(state) => state.inviteCandidateReducer.tenantProfiles,
	);
	const testProgress = useSelector(
		(state) => state.candidateTesting.testProgress,
	);

	const OpenModal = () => {
		setOpen(!open);
	};

	useEffect(() => {
		if (user.tenantId === 0) {
			dispatch(inviteCandidateActions.getCandidateTests());
		}
	}, [dispatch, user]);

	useEffect(() => {
		if (candidateTests.length > 0) {
			const test = candidateTests.find(
				(t) => t.id == localStorage.getItem("assessmentId"),
			);

			dispatch(
				inviteCandidateActions.getTenantProfileForCandidate(
					test.role.tenant_id,
				),
			);
		}
	}, [dispatch, candidateTests]);

	useEffect(() => {
		if (pendingTestRightPanel?.roleName === null) {
			if (candidateTests.length > 0 && tenantProfiles.length > 0) {
				const test = candidateTests.find(
					(t) => t.id == localStorage.getItem("assessmentId"),
				);

				dispatch(candidateTestingActions.setTestToResume(test));

				const tenantProfile = tenantProfiles.find(
					(p) => p.tenant_id === test.role.tenant_id,
				);

				dispatch(
					candidateTestingActions.setPendingTestRightPanel({
						roleName: test.role.name,
						tenantName: tenantProfile.tenant_name,
						tenantState: tenantProfile.state,
						tenantCountry: tenantProfile.country,
						roleDetails: test.role.role_details,
						test: test,
						tenantLogo: tenantProfile.logo,
					}),
				);
			}
		}
	}, [pendingTestRightPanel, candidateTests, tenantProfiles]);

	return (
		<div
			className='h-100 d-flex flex-column justify-content-between'
			style={{ padding: "30px" }}>
			<div className='w-100 d-flex flex-column align-items-start'>
				<span className='headline1'>Interview Details</span>
				<div
					className='d-flex align-items-center'
					style={{
						width: "100%",
						height: "78px",
						borderRadius: "12px",
						backgroundColor: "white",
						padding: "8px 15px",
						marginTop: "10px",
					}}>
					<div>
						<img
							src={pendingTestRightPanel?.tenantLogo || BlankPicture}
							alt=''
							style={{ width: "43px", height: "43px", borderRadius: pendingTestRightPanel?.tenantLogo ? "50%" : "0%" }}
						/>
					</div>
					<div
						className='d-flex flex-column align-items-start'
						style={{ marginLeft: "20px" }}>
						<span className='description' style={{ color: "#96999C" }}>
							Position
						</span>
						<div>
							<span className='p1'>{pendingTestRightPanel?.roleName}</span>
						</div>
						<div>
							<span className='description'>
								{pendingTestRightPanel?.tenantName}
							</span>
						</div>
					</div>
				</div>
				<div
					className='d-flex w-100 justify-content-between'
					style={{
						width: "100%",
						height: "73px",
						borderRadius: "12px",
						backgroundColor: "white",
						padding: "15px 20px",
						marginTop: "20px",
					}}>
					<div>
						<div className='d-flex' style={{ paddingBottom: "5px" }}>
							<div style={{ paddingRight: "10px" }}>
								<span className='description'>Test Progress </span>
							</div>
							<div>
								<span className='description' style={{ color: "#96999C" }}>
									{Math.floor(
										(testProgress?.attempted_tp / testProgress?.total_tp) * 100,
									) || 0}
									% Complete
								</span>
							</div>
						</div>
						<div style={{ height: "7px" }}>
							<ProgressBar
								barColor='#00D69A'
								completed={Math.floor(
									(testProgress?.attempted_tp / testProgress?.total_tp) *
										100 *
										2,
								)}
								BarStyle={{ height: "7px" }}
								style={{ backgroundColor: "#90EE90", width: "200px" }}
							/>
						</div>
					</div>
					{/* <div>
						<span className='description'>Time Left</span>
						<div className='description' style={{ color: "#00D69A" }}>
							{props.time || "30:00"}
						</div>
					</div> */}
				</div>
			</div>

			<div
				className='w-100 d-flex justify-content-end'
				onClick={OpenModal}
				style={{ position: "relative", cursor: "pointer" }}>
				{open && (
					<div
						className='d-flex flex-column align-items-start invisible-scrollbar'
						style={{
							position: "absolute",
							width: "100%",
							height: "232px",
							overflowY: "auto",
							backgroundColor: "white",
							borderRadius: "12px",
							padding: "15px 20px",
							bottom: "30px",
						}}>
						<div className='d-flex w-100 justify-content-between'>
							<div>
								<span className='headline3'>Interview Instructions:</span>
							</div>
							<div onClick={() => setOpen(false)}>
								<Minimize />
							</div>
						</div>
						<div
							className='d-flex flex-column text-justify'
							style={{ paddingTop: "5px" }}>
							<span className='p2'>
								1. You’ll be asked several questions of different type and we’ll
								evaluate you according to your given answers.
							</span>
							<span className='p2'>
								2. In the interview details panel, you can see the progress of
								your interview as well as the time.
							</span>
							<span className='p2'>
								3. Asked questions will be marked in the interview details
								panel. If you will be unsure about your given answer, you can
								click on the question and update your answer.
							</span>
						</div>
					</div>
				)}
				<ExclamationMark />
			</div>
		</div>
	);
}
