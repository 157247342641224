import React, { useEffect, useState } from "react";
import CandidateReportSummaryCard from "../../subcomponents/dashboard/CandidateReportSummaryCard";
import QualificationPointsReportCard from "../../subcomponents/dashboard/QualificationPointsReportCard";
import ReviewTestReportCard from "../../subcomponents/dashboard/ReviewTestReportCard";
import PageNotFound from "../../../views/PageNotFound";
import { useSelector, useDispatch } from "react-redux";
import * as candidateTestingActions from "../../../redux/actions/Candidate/candidate-testing.actions";
import ReferenceReportCard from "../../subcomponents/dashboard/ReferenceReportCard";
import BlankPicture from "../../../assets/icons/Userpic/userprofile.svg";
import logo from "../../../assets/icons/rnr-logo/1.svg";
import { useParams } from "react-router-dom";
import PersonalityAssessmentCard from "../../subcomponents/dashboard/PersonalityAssessmentCard";


export default function CandidateAssessmentReport(props) {
	const dispatch = useDispatch();
	const paramas = useParams();

	const getWindowDimensions = () => {
		const { innerWidth: width, innerHeight: height } = window;
		return {
			width,
			height
		};
	};

	const candidateReportDetail = useSelector(
		(state) => state.candidateTesting.candidateReportDetail
	);
	const candidateReportProfile = useSelector(
		(state) => state.candidateTesting.candidateReportProfile,
	);
	const personalityQuestions = useSelector(
		(state) => state.candidateTesting.persquestionsReport ,
	);
	const isPersonality = useSelector(
		(state) => state.candidateTesting.persMatch,
	);
	const assessmentStatus = useSelector((state) => state.candidateTesting.assessmentStatus);
	// console.log(" report status is " , assessmentStatus);
	const  useWindowDimensions = () => {
		const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
	
		useEffect(() => {
			function handleResize() {
				setWindowDimensions(getWindowDimensions());
			}

			window.addEventListener("resize", handleResize);
			return () => window.removeEventListener("resize", handleResize);
		}, []);
	
		return windowDimensions;
	};


	useEffect(() => {
		
		dispatch(
			candidateTestingActions.getAssessmentReport(paramas.id),
		);
		
	}, [dispatch , paramas]);
	const { width } = useWindowDimensions();


	return (
		<div>
			{assessmentStatus === null ? <PageNotFound/> 
				:
				<div
					className="assessment-layout"
				>
					<div className="main-content"
						style={{width: width > 807 ? " 65%" : "100%" }}
					>
						<div
							className='w-100 d-flex justify-content-between'
							style={{ paddingBottom: "25px" }}>
							<div
								className='d-flex align-items-center'
								style={{ marginTop: "-30px" }}>
								<img
						
									src={ candidateReportProfile?.image  || BlankPicture}
									alt=''
									style={{ width: "75px", height: "75px", borderRadius: (candidateReportProfile?.image) ? "50%" : "0%" }}
								/>
								<div
									className='d-flex flex-column align-items-start'
									style={{ paddingLeft: "15px" }}>
									<span className='headline2'>
										{candidateReportProfile?.firstName +
								" " +
								candidateReportProfile?.lastName  }
									</span>
									<span className='description' style={{ color: "#96999C" }}>
										{props.time || "1 day"} ago
									</span>
								</div>
							</div>
							<div style={{marginTop:"-18px"}}>
								<img src={logo}/>
							</div>
						</div>
			
			
						<div>
							<CandidateReportSummaryCard  extended = {width > 1305 && "yes"} />
						</div>
						<div style={{ paddingTop: "30px" }}>
							<QualificationPointsReportCard />
						</div>
						{!personalityQuestions.length == 0 && <div style={{ paddingTop: "30px" }}>
							<PersonalityAssessmentCard pers_match={isPersonality}/>
						</div>}
						{!personalityQuestions.length == 0 ?	<div style={{ paddingTop: "30px" }}>
							<ReviewTestReportCard/>
						</div> 
							:
							null }
						{candidateReportDetail.req_reference_count && 
								<div style={{ paddingTop: "30px" }}>
									<ReferenceReportCard />
								</div>}
					</div>
				</div>

			}
		</div>
	);
}
