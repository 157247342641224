import React from "react";
import PropTypes from "prop-types";

/**
 * 
 * @param {object} props Props object for component
 * @param {string} props.color class name for alert
 * @param {string} props.title Title of the alert
 * @param {string} props.description Description of the alert
 * @returns 
 */
export default function AlertBox(props) {

	return (
		<div>
			{props.lineError ? 
				<span className="description" style={{color:"#fc4848"}}>{props.description}</span>

				:	
		
				<div className={`d-flex flex-column alert-form ${props.color || "success"}`}>
					<span className="description">{props.title}: {props.description}</span>
				</div>
			}
		</div>
	);

}

AlertBox.propTypes = {
	color: PropTypes.string,
	title: PropTypes.string,
	description: PropTypes.string,
};
