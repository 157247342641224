import React,{useState} from "react";
import { Fragment } from "react";
import HelpIcon from "./subcomponents/HelpIcon";
import OnHoverComponent from "./subcomponents/OnHoverComponent";

export default function HelpIconComponent(props){

	const [showBox, setShowBox] = useState(false);

	const OnHover = () =>{
		setShowBox(true);
	};
	const myStyle ={
		position:"absolute", right:"30px", top:"55px", zIndex:"10"
	};
	return(
		<Fragment>
			<div onMouseOver={OnHover} onMouseOut = { () => setShowBox(false)} style={{position:"relative"}}>
				<HelpIcon forRefrance = " true"/>
			</div>	
			{
				showBox 
					? <div style={props.refranceComponent ? null : myStyle}><OnHoverComponent refranceComponent = {props.refranceComponent} description={props.description1} description2={props.description2}/></div> 
					: null
			}
		</Fragment>		
	);
}