import React from "react";
import MasterCard from "../../../assets/icons/PaymentCards/Mastercard.svg";
import Dots from "../../../assets/icons/basic/numberdots.svg";
import { ReactComponent as Cross } from "../../../assets/icons/basic/cross.svg";
import Button from "../Button";
import VisaCard from "../../../assets/icons/PaymentCards/MainVisaWhite.png";

export default function PaymentCardRow(props) {

	return (
		<div className="w-100 d-flex justify-content-between on-hover align-items-center" style={{
			borderBottom: "1px solid #C8CBCD",
			padding: "9px 0px",
		}}  >
			<div style={{width:"15%"}} className="d-flex align-items-start">
				<span className="description">{props.name || "Mason Wyatt"}</span>
			</div>
			<div className="d-flex" style={{width:"20%"}}>
				<div>

					<img
						src={props.brand === "visa" ? VisaCard : MasterCard}
						alt=""
						style={{width:"26px", height:"26px"}}
					/>
				</div>
				<div className="pr-1 pl-1">
					<img
						src={Dots}
						alt
					/>
				</div>
				<div>
					<span className="description">{props.last4 || "1234"}</span>
				</div>
			</div>
			<div>
				<span className="description">{props.expiryMonth + "/" + props.expiryYear.toString().substr(-2) || "04/12"}</span>
			</div>
			<div>
				<Button
					dashboardbtn
					label={props.selected ? "Active": "Set Active"}
					style={{
						backgroundColor: props.selected ? "#00D69A" : "#C8CBCD",
						height: "18px",
						width: "90px",
						borderRadius: "5px",
						padding: "0px 5px",
						display:"flex",
						justifyContent: "center"
					}}
					textStyle={{ color: "#ffffff" }}
					textClass='description'
					onClick={props.onClick}
				/>
			</div>
			<div className='pointer' onClick={props.onDelete}>
				<Cross />
			</div>
		</div>
	);
}
