import React from "react";
import RightCandidateHeader from "../../subcomponents/dashboard/RightCandidateHeader";
import CandidateData from "./CandidateData";
import CriteriaDetails from "./CriteriaDetails";
import CandidateTestDetails from "./CandidateTestDetails";

export default function CandidateDetails(props) {
	if (props.tableClick) {
		return (
			<div className='candidate-right-header'>
				<RightCandidateHeader
					onClick={props.onClick}
					isExtended={props.isExtended}
					onCross={props.onCross}
					mobile={props.mobile}
				/>

				<div>
					<CandidateData candidate={props.candidate} />
				</div>
			</div>
		);
	} else if (props.testDetails) {
		return (
			<div className='candidate-right-header'>
				<RightCandidateHeader
					onClick={props.onClick}
					isExtended={props.isExtended}
					onCross={props.onCross}
					mobile={props.mobile}
				/>

				<div>
					<CandidateTestDetails onClickStart={props.onClickStart} />
				</div>
			</div>
		);
	} else {
		return (
			<div className='candidate-right-header'>
				<RightCandidateHeader
					onClick={props.onClick}
					isExtended={props.isExtended}
					onCross={props.onCross}
					mobile={props.mobile}
				/>

				<div>
					<CriteriaDetails onAllCross={props.onAllCross} />
				</div>
			</div>
		);
	}
}
