import React from "react";
import SendLeft from "../../../assets/icons/Arrows/send-left.svg";
import SendRight from "../../../assets/icons/Arrows/send-right.svg";
import CrossIcon from "../../../assets/icons/basic/x.svg";

export default function RightCandidateHeader(props) {
	return (
		<>
			{props.mobile ? (
				<div
					className='d-flex justify-content-end'
					style={{ marginTop: "-30px" }}>
					<div className='pointer' onClick={props.onCross}>
						<img src={CrossIcon} alt='' />
					</div>
				</div>
			) : (
				<div className='d-flex justify-content-end'>
					<div
						className='pointer'
						onClick={props.onClick}
						style={{ padding: "0px 15px 25px 0px" }}>
						{!props.isExtended ? (
							<img src={SendLeft} alt='' />
						) : (
							<img src={SendRight} />
						)}
					</div>
					<div className='pointer' onClick={props.onCross}>
						<img src={CrossIcon} alt='' />
					</div>
				</div>
			)}
		</>
	);
}
