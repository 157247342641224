import React, { useEffect } from "react";
import PositionComponent from "../../components/dashboard/PositionComponent";
import { useDispatch } from "react-redux";
import * as setupRoleActions from "../../redux/actions/onboarding/SetupRoles";
import * as clientFilterActions from "../../redux/actions/Dashboard/ClientFilter.actions";

export default function Positions(props) {
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(setupRoleActions.resetForm());
		dispatch(clientFilterActions.resetEditRole());
	}, [dispatch]);

	return (
		<div>
			<PositionComponent
				previous
				onHandleClick={props.onHandleClick}
				onClick={props.onClick}
				selected={props.selected}
				clients={props.clients}
				image={props.image}
				name={props.name}
			/>
		</div>
	);
}
