import * as Actions from "../../actions/onboarding/inviteCandidate";

const initialState = {
	roles: [],
	inviteCandidateTableRows: [],
	positionId: null,
	isSubmitted: false,
	isModifyRequested: false,
	isAddManually: true,
	candidateAssessments: null,
	loading: false,
	candidateTests: [],
	tenantProfiles: [],
	search: "",
};

const InviteCandidateReducer = (state = initialState, action) => {
	const data = action.payload;

	switch (action.type) {
		case Actions.GET_ROLES:
			return (state = {
				...state,
				roles: data,
			});

		case Actions.UPDATE_INVITE_CANDIDATE_TABLE_ROWS:
			return (state = {
				...state,
				inviteCandidateTableRows: data,
			});

		case Actions.SET_POSITION:
			return (state = {
				...state,
				positionId: data.value,
			});

		case Actions.HANDLE_SUBMIT:
			return (state = {
				...state,
				isSubmitted: true,
				isModifyRequested: false,
			});

		case Actions.MENU_CLICKED:
			return (state = {
				...state,
				isModifyRequested: true,
			});

		case Actions.ADD_MANUALLY_CLICKED:
			return (state = {
				...state,
				isAddManually: !state.isAddManually,
			});

		case Actions.GET_CANDIDATE_ASSESSMENTS:
			return (state = {
				...state,
				candidateAssessments: data,
			});

		case Actions.CANDIDATES_ALREADY_INVITED:
			return (state = {
				...state,
				isSubmitted: true,
				isModifyRequested: false,
			});

		case Actions.CANDIDATE_INVITED_END:
			return (state = {
				...state,
				loading: false,
				inviteCandidateTableRows: state.inviteCandidateTableRows.map((row) => {
					if (row[1] === data.email) {
						row[0] = data.data.user_id;
						return row;
					}
					return row;
				}),
			});

		case Actions.CANDIDATE_INVITED_START:
			return (state = {
				...state,
				loading: true,
			});

		case Actions.CANDIDATE_INVITED_FAILED:
			return (state = {
				...state,
				loading: false,
			});

		case Actions.DELETE_CANDIDATE_INVITED_START:
			return (state = {
				...state,
				loading: true,
			});

		case Actions.DELETE_CANDIDATE_INVITED_END:
			return (state = {
				...state,
				loading: false,
			});

		case Actions.DELETE_CANDIDATE_INVITED_FAILED:
			return (state = {
				...state,
				loading: false,
			});

		case Actions.ACTIVATE_POSITION:
			return (state = {
				...state,
				roles: state.roles.map((r) => {
					if (r.id === data.id) {
						return {
							...r,
							is_active: true,
						};
					}

					return r;
				}),
			});

		case Actions.DEACTIVATE_POSITION:
			return (state = {
				...state,
				roles: state.roles.map((r) => {
					if (r.id === data.id) {
						return {
							...r,
							is_active: false,
						};
					}

					return r;
				}),
			});

		case Actions.GET_CANDIDATE_TESTS:
			return (state = {
				...state,
				candidateTests: data.assessments.map((test) => {
					return {
						...test,
						status: data.assessmentSessions.find((t) => t._id === test.id)
							?.status,
						score: data.assessmentSessions.find((t) => t._id === test.id)?.score,
						pers_match: data.assessmentSessions.find((t) => t._id === test.id)
							?.pers_match,
						referral_status: data.assessmentSessions.find(
							(t) => t._id === test.id,
						)?.referral_status,
					};
				}),
			});

		case Actions.GET_TENANT_PROFILE_FOR_CANDIDATE:
			return (state = {
				...state,
				tenantProfiles: [...state.tenantProfiles, data],
			});

		case Actions.HANDLE_CHANGE:
			return (state = {
				...state,
				[data.name]: data.value,
			});

		default:
			return state;
	}
};

export default InviteCandidateReducer;
