import React from "react";
import PropTypes from "prop-types";
import Tick from "../../assets/icons/custom_checkbox/tick.png";

function CheckMark({value}){
	return (
		<>
			{value == true ? <img src={Tick} width="9.3px" height="6.7px"/>:null}
		</>
	);
}


function CustomCheckBox(props){

	const bg = (props.value === true ? "#32c3e0" : "transparent");
	const border = (props.value === true ? "none":"2px solid #96999C");
	let myclass= "custom_checkbox d-flex align-items-center";
	if (props.className){
		myclass+=" ";
		myclass+=props.className;
	}
   
	return (
		<div style={props.style ? props.style : {}} className={myclass}>
			<div style={{background:bg,border:border}} className="checkbox" onClick={props.toggle}>
				<CheckMark value={props.value}/>
			</div>
			<div className="checkbox_text"><p>{props.label}</p></div>
		</div>
	);
}


CustomCheckBox.propTypes = {
	className:PropTypes.string,
	value:PropTypes.bool,
	toggle:PropTypes.func,
	label:PropTypes.string
};

export default CustomCheckBox;