import * as Actions from "../../actions/Dashboard/BillingInfo";

const initialState = {
	cardform: [],
	stripeForm: {
		name: "",
		alertThresholdCost: "",
	},
	invoiceForm: {
		assessmentCost: null,
		totalCost: null,
		avgCost: null,
	},
	customerInvoiceForm: {
		invoices: [],
		transactionId: null,
		date: null,
		status: "",
		amountPaid: null,
	},

	retrymessage: "",
	alertThresholdForm: null,
	defaultCard: null,
	paymentMethodIdUpdated: false,
	error: "",
	loading: false,
	subscriptionCompleted: false,
};

const reducer = (state = initialState, action) => {
	const data = action.payload;

	switch (action.type) {
		case Actions.GET_CARD_DETAILS:
			return (state = {
				...state,
				cardform: data,
			});
		case Actions.HANDLE_CHANGE:
			return (state = {
				...state,
				stripeForm: {
					...state.stripeForm,
					[data.name]: data.value,
				},
			});
		case Actions.SUBSCRIPTION_START: {
			return (state = {
				...state,
				loading: true,
			});
		}

		case Actions.SUBSCRIPTION_END: {
			return (state = {
				...state,
				error: false,
				subscriptionCompleted: true,
				loading: false,
			});
		}
		case Actions.SUBSCRIPTION_ERROR: {
			return (state = {
				...state,
				error: data,
				loading: false,
			});
		}
		case Actions.GET_INVOICES: {
			return (state = {
				...state,
				invoiceForm: data,
			});
		}
		case Actions.GET_CUSTOMER_INVOICES: {
			return (state = {
				...state,
				customerInvoiceForm: data,
			});
		}
		case Actions.SET_DEFAULT_PAYMENT_METHOD: {
			return (state = {
				...state,
				paymentMethodIdUpdated: true,
				cardform: state.cardform.map((card) => {
					return {
						...card,
						customer: {
							...card.customer,
							invoice_settings: {
								...card.customer.invoice_settings,
								default_payment_method: data,
							},
						},
					};
				}),
			});
		}
		case Actions.DETACH_PAYMENT_METHOD: {
			return (state = {
				...state,
				cardform: state.cardform.filter((card) => {
					if (card.id !== data) {
						return card;
					}
				}),
			});
		}
		case Actions.SET_DEFAULT_CARD: {
			return (state = {
				...state,
				defaultCard: data,
			});
		}
		case Actions.UPDATE_SUBSCRIPTION:
			return (state = {
				...state,
				alertThresholdForm: data,
			});
		case Actions.RETRY_PAYMENT:
			return (state = {
				...state,
				retrymessage: data,
			});
		default:
			return state;
	}
};

export default reducer;
