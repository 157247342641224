import React, { useState, useEffect, useRef } from "react";
import { Fragment } from "react";
export default function SwitchButton(props) {
	const forState = props.forState || null;
	const [isChecked, setIsChecked] = useState(props.isChecked || false);
	const switchBtn = useRef();
	function handleChange(e) {
		// setIsChecked(!isChecked);
		props.onChange({ value: e.target.checked, forState: forState });
	}
	let myclass= "switch-container d-flex align-items-center";
	if (props.className){
		myclass+=" ";
		myclass+=props.className;
	}
	useEffect(() => {
		setIsChecked(props.value);
	}, [props.value, isChecked]);
	return (
		<Fragment>
			
				
			<div className= {myclass } style={props.style} >
				<label  >
					<input ref={switchBtn} checked={isChecked} onChange={e => handleChange(e)} className="switch" type="checkbox" />
					<div>
						<div> </div>
					</div>
				</label>
				<div > <p style={{marginLeft:"8px" , marginRight:"20px"}}> {props.description } </p></div>
				
			</div>
			
		</Fragment>
	);
}