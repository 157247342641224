import * as Actions from "../../actions/Auth/authAction";

const initialState = {
	user: {
		accessToken: null,
		userId: null,
		tenantId: null,
		roleId: null,
	},
	isLoggedIn: false,
	isloggedInOauth: false,
	errorMessage: "",
	tenantProfilesOfUser: [],
	switcherLoading: false,
	userEmailByToken: null,
	roleIdByToken:null,
	inviteToken: null,
	isCandidateReference: null,
	referenceForm: {
		designation: "",
		organisation: "",
		date: "2021-03-25 16:06:43.509517",
		data: [
			{
				question: "What are the strengths and weaknesses of the candidate?",
				response: "",
			},
			{
				question: "What are the strengths and weaknesses of the candidate?",
				response: "",
			},
			{
				question: "What are the strengths and weaknesses of the candidate?",
				response: "",
			},
		],
	},
	stripeForm: {
		name: "",
		address: "",
		postal_code: "",
		country: "US",
		city: "",
		state: "",
	},
	stripePaymentFormErrors: {
		name: false,
		address: false,
		country: false,
		postal_code: false,
		cardNumber: false,
		cardExpiry: false,
		cardCVC: false,
		city: false,
		state: false,
	},
	emailSent: false,
	passwordUpdated: false,
	resetPasswordMessage: "",
	candidateName: "",
	positionName: "",
	referrerName: "",
	referrerEmail: "",
	loading: false,
	isLoader : "pending" ,
	subscriptionCompleted: false,
	isTenantSubscribed: false,
};

const authReducer = (state = initialState, action) => {
	const data = action.payload;
	switch (action.type) {
		case Actions.SIGN_IN:
			return (state = {
				...state,
				isLoggedIn: true,
				errorMessage: "",
				user: {
					...state.user,
					accessToken: data.access_token,
					userId: data.user_id,
					tenantId: data.tenant_id,
					roleId: data.role_id,
				},
			});

		case Actions.SIGN_IN_OAUTH:
			return (state = {
				...state,
				isloggedInOauth: true,
				errorMessage: "",
				user: {
					...state.user,
					accessToken: data.access_token,
					userId: data.user_id,
					tenantId: data.tenant_id,
				},
			});

		case Actions.SIGN_IN_FAILED:
			return (state = {
				...state,
				errorMessage: data,
			});

		case Actions.REFRESH_TOKEN:
			return (state = {
				...state,
				user: {
					...state.user,
					accessToken: data.access_token,
					userId: data.user_id,
					tenantId: data.tenant_id,
					roleId: data.role_id,
				},
			});

		case Actions.SIGN_OUT:
			return (state = {
				state: initialState,
			});

		case Actions.GET_TENANT_PROFILES_OF_USER:
			return (state = {
				...state,
				tenantProfilesOfUser: data,
			});

		case Actions.SWITCH_TENANT_ACCOUNT_START:
			return (state = {
				...state,
				switcherLoading: true,
			});

		case Actions.SWITCH_TENANT_ACCOUNT_END:
			return (state = {
				...state,
				switcherLoading: false,
				user: {
					...state.user,
					accessToken: data.access_token,
					userId: data.user_id,
					tenantId: data.tenant_id,
					roleId:data.role_id,
				},
			});

		case Actions.SWITCH_TENANT_ACCOUNT_FAILED:
			return (state = {
				...state,
				switcherLoading: false,
			});

		case Actions.GET_USER_EMAIL_BY_TOKEN:
			return (state = {
				...state,
				userEmailByToken: data.email,
				roleIdByToken:data.role_id,
			});

		case Actions.SET_RESET_TOKEN:
			return (state = {
				...state,
				inviteToken: data,
			});

		case Actions.GET_CANDIDATE_REFERENCES_END:
			return (state = {
				...state,
				isCandidateReference: true,
				candidateName: data.candidate_name,
				positionName: data.candidate_position,
				referrerName: data.referrer_name,
				referrerEmail: data.referrer_email,
			});

		case Actions.GET_CANDIDATE_REFERENCES_FAILED:
			return (state = {
				...state,
				isCandidateReference: false,
			});

		case Actions.SET_REFERENCE_FORM:
			return (state = {
				...state,
				referenceForm: {
					...state.referenceForm,
					[data.name]: data.value,
				},
			});

		case Actions.SET_REFERENCE_FORM_TEXT_AREA:
			return (state = {
				...state,
				referenceForm: {
					...state.referenceForm,
					data: state.referenceForm.data.map((obj, index) => {
						if (index === data.index) {
							return {
								...obj,
								response: data.value,
							};
						}

						return obj;
					}),
				},
			});

		case Actions.SUBMIT_REFERENCE_FORM_START:
			return (state = {
				...state,
				loading: true,
			});

		case Actions.SUBMIT_REFERENCE_FORM_END:
			return (state = {
				...state,
				loading: false,
			});

		case Actions.SUBMIT_REFERENCE_FORM_FAILED:
			return (state = {
				...state,
				loading: false,
			});
		case Actions.HANDLE_CHANGE:
			return (state = {
				...state,
				stripeForm: {
					...state.stripeForm,
					[data.name]: data.value,
				},
			});
		case Actions.HANDLE_SELECT_CHANGE:
			return (state = {
				...state,
				stripeForm: {
					...state.stripeForm,
					state: data.label,
				},
			});

		case Actions.HANDLE_COUNTRY_CHANGE:
			return (state = {
				...state,
				stripeForm: {
					...state.stripeForm,
					country: data.label,
				},
			});

			// case Actions.HANDLE_PAYMENT_SELECT_CHANGE:
			// 	return (state = {
			// 		...state,
			// 		stripeForm: {
			// 			...state.stripeForm,
			// 			state: data.label,
			// 		},
			// 	});

		case Actions.SUBSCRIPTION_START: {
			return (state = {
				...state,
			});
		}
		case Actions.SUBSCRIPTION_END: {
			return (state = {
				...state,
				subscriptionCompleted: true,
			});
		}
		case Actions.GET_SUBSCRIPTION_START:
			return state = {
				...state ,
				isLoader:true ,
			};
		case Actions.GET_SUBSCRIPTION:
			return (state = {
				...state,
				isTenantSubscribed: data,
				isLoader:false ,
			});
		case Actions.GET_SUBSCRIPTION_FAILED:
			return state = {
				...state ,
				isLoader:false ,	
			};
		case Actions.VALIDATE_STRIPE_FORM:
			return (state = {
				...state,
				stripePaymentFormErrors: {
					...state.stripePaymentFormErrors,
					[data.name]: data.value,
				},
			});
		case Actions.RESET_PASSWORD_REQUEST: {
			return (state = {
				...state,
				emailSent: true,
				resetPasswordMessage: data,
			});
		}
		case Actions.HANDLE_ERROR_MESSAGE: {
			return (state = {
				...state,
				errorMessage: data,
			});
		}
		case Actions.RESET_PASSWORD: {
			return (state = {
				...state,
				passwordUpdated: true,
			});
		}

		default:
			return state;
	}
};

export default authReducer;
