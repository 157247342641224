import React, { useEffect } from "react";
import Table from "../../subcomponents/Table/Tabel";
import Button from "../../subcomponents/Button";
import { useSelector, useDispatch } from "react-redux";
import { Redirect } from "react-router-dom";
import * as teamSetupActions from "../../../redux/actions/onboarding/teamSetup";

export default function TeamSetup(props) {
	const dispatch = useDispatch();

	const user = useSelector((state) => state.user.user);
	const hasClients = useSelector((state) => state.clients.hasClients);
	const inviteCandidateIsSubmitted = useSelector(
		(state) => state.inviteCandidateReducer.isSubmitted,
	);
	const accessRoles = useSelector(
		(state) => state.teamSetupReducer.accessRoles,
	);
	const allClients = useSelector((state) => state.teamSetupReducer.allClients);
	const teamSetupTable = useSelector(
		(state) => state.teamSetupReducer.teamSetupTable,
	);
	const failure = useSelector(
		(state) => state.teamSetupReducer.failure,
	);

	const rolesFortable = [];
	accessRoles.map((role) => {
		rolesFortable.push({
			id: role.id,
			option: role.role_name,
		});
	});

	const clientsForTable = [];
	allClients.map((client) => {
		clientsForTable.push({
			id: client.id,
			option: client.name,
		});
	});

	const InviteTeamMembersTable = hasClients
		? ["Email", "Access", "Client"]
		: ["Email", "Access"];

	const handleSubmit = () => {
		if (props.dashboard) {
			//
		} else {
			dispatch(teamSetupActions.onBoardingCompleted(user));

			props.history.push("/dashboard");
		}
	};

	const getUpdatedRows = (rows) => {
		dispatch(teamSetupActions.setTeamSetupTable(rows));
	};

	useEffect(() => {
		dispatch(teamSetupActions.getAccessRoles());
		if (hasClients) {
			dispatch(teamSetupActions.getAllClients());
		}
	}, [dispatch, hasClients]);

	useEffect(() => {
		dispatch(teamSetupActions.getTeamMembers());
	}, [dispatch, failure]);

	const getUpdatedRow = (row, actionId) => {
		//Action ID 0 belongs to ADD.
		if (actionId === 0) {
			dispatch(teamSetupActions.addTeamMember(row));
		}
		//Action ID 1 belongs to UPDATE.
		else if (actionId === 1) {
			dispatch(teamSetupActions.updateTeamMember(row));
		}
		//Action ID 2 belongs to DELETE.
		else if (actionId === 2) {
			dispatch(teamSetupActions.deleteTeamMember(row));
		}
	};

	if (props.dashboard) {
		//
	} else if (!inviteCandidateIsSubmitted) {
		return <Redirect to='/onboarding/inviteCandidate' />;
	}

	return (
		<div>
			{!props.dashboard && (
				<div style={{ marginBottom: "20px" }} className='headline1'>
					Invite Team Members
				</div>
			)}

			<Table
				activeImageInput={[]}
				isEditable
				headers={InviteTeamMembersTable}
				rows={teamSetupTable}
				updateTable={getUpdatedRows}
				updatedRow={getUpdatedRow}
				activeSelectInput={[{ col: 2, data: rolesFortable }]}
				activeMultiSelectInput={[{ col: 3, data: clientsForTable }]}
			/>

			{props.dashboard ? (
				<div
					className='d-flex justify-content-center'>
					{/* <Button
						button
						label='Update'
						textClass='b2'
						className='button-large'
						style={{ padding: "6.5px 67px" }}
						onClick={handleSubmit}
					/> */}
				</div>
			) : (
				<div
					className='d-flex justify-content-center'
					style={{ paddingTop: "40px" }}>
					<Button
						rightArrow
						label='Finish'
						textClass='b1'
						className='button-large'
						style={{ padding: "6.5px 67px" }}
						onClick={handleSubmit}
					/>
				</div>
			)}
		</div>
	);
}
