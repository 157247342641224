import React from "react";
import ReactPlayer from "react-player";
import { useSelector } from "react-redux";

export default function ReviewTestReportCard() {  
	const personalityQuestions = useSelector(
		(state) => state.candidateTesting.persquestionsReport,
	);

	return (
		<div
			style={{
				width: "100%",
				minHeight: "600px",
				
				borderRadius: "10px",
				padding: "20px 10px",
				backgroundColor: "white",
				overflowY: "auto",
			}}>
			<div className='d-flex'>
				<span className='b2'>Review Test Session</span>
			</div>
			<div >
				{personalityQuestions.map((personality, index) => {
					return (
						<div key={index}>
							<div className='d-flex' style={{ padding: "15px 0px" }}>
								<span className='description' style={{ color: "#10182B" }}>
									{index + 1}. {personality.question}
								</span>
							</div>
							{personality.response_type === "video" ? (
								<div style={{ padding: "5px" }}>
									<div
										className='d-flex'
										style={{ padding: "0px 10px", wordBreak:"break-word" }}>
										<span className='description'>{personality.response}</span>
									</div>
									<ReactPlayer
										style={{ padding: "5px" }}
										controls
										width='100%'
										height='100%'
										url={personality.video_url}
									/>
								</div>
							) : (
								<div
									className='d-flex '
									style={{ padding: "0px 10px", wordBreak:"break-word" }}>
									<span className='description'>{personality.response}</span>
								</div>
							)}
						</div>
					);
				})}
			</div>
		</div>
	);
}
