import React, { useEffect, useState } from "react";
import Input from "../../subcomponents/Input";
import Button from "../../subcomponents/Button";
import { useDispatch, useSelector } from "react-redux";
import AlertBox from "../../AlertBox";
import * as companySetupActions from "../../../redux/actions/onboarding/companySetup";
import { Redirect } from "react-router-dom";
import Upload from "../../../assets/icons/Arrows/upload.svg";
// import SelectCountry from "../../subcomponents/Select";
import Select from "react-select";
//  Auto complete address
import Geocode from "react-geocode";
import { GoogleMapApiKey } from "../../../utilities/Constants";
import PlacesAutocomplete from "react-places-autocomplete";

function SetupRoleForm(props) {
	Geocode.setApiKey(GoogleMapApiKey);
	const states = [
		{
			"label": "Alabama",
			"value": "AL"
		},
		{
			"label": "Alaska",
			"value": "AK"
		},
		{
			"label": "American Samoa",
			"value": "AS"
		},
		{
			"label": "Arizona",
			"value": "AZ"
		},
		{
			"label": "Arkansas",
			"value": "AR"
		},
		{
			"label": "California",
			"value": "CA"
		},
		{
			"label": "Colorado",
			"value": "CO"
		},
		{
			"label": "Connecticut",
			"value": "CT"
		},
		{
			"label": "Delaware",
			"value": "DE"
		},
		{
			"label": "District Of Columbia",
			"value": "DC"
		},
		{
			"label": "Federated States Of Micronesia",
			"value": "FM"
		},
		{
			"label": "Florida",
			"value": "FL"
		},
		{
			"label": "Georgia",
			"value": "GA"
		},
		{
			"label": "Guam",
			"value": "GU"
		},
		{
			"label": "Hawaii",
			"value": "HI"
		},
		{
			"label": "Idaho",
			"value": "ID"
		},
		{
			"label": "Illinois",
			"value": "IL"
		},
		{
			"label": "Indiana",
			"value": "IN"
		},
		{
			"label": "Iowa",
			"value": "IA"
		},
		{
			"label": "Kansas",
			"value": "KS"
		},
		{
			"label": "Kentucky",
			"value": "KY"
		},
		{
			"label": "Louisiana",
			"value": "LA"
		},
		{
			"label": "Maine",
			"value": "ME"
		},
		{
			"label": "Marshall Islands",
			"value": "MH"
		},
		{
			"label": "Maryland",
			"value": "MD"
		},
		{
			"label": "Massachusetts",
			"value": "MA"
		},
		{
			"label": "Michigan",
			"value": "MI"
		},
		{
			"label": "Minnesota",
			"value": "MN"
		},
		{
			"label": "Mississippi",
			"value": "MS"
		},
		{
			"label": "Missouri",
			"value": "MO"
		},
		{
			"label": "Montana",
			"value": "MT"
		},
		{
			"label": "Nebraska",
			"value": "NE"
		},
		{
			"label": "Nevada",
			"value": "NV"
		},
		{
			"label": "New Hampshire",
			"value": "NH"
		},
		{
			"label": "New Jersey",
			"value": "NJ"
		},
		{
			"label": "New Mexico",
			"value": "NM"
		},
		{
			"label": "New York",
			"value": "NY"
		},
		{
			"label": "North Carolina",
			"value": "NC"
		},
		{
			"label": "North Dakota",
			"value": "ND"
		},
		{
			"label": "Northern Mariana Islands",
			"value": "MP"
		},
		{
			"label": "Ohio",
			"value": "OH"
		},
		{
			"label": "Oklahoma",
			"value": "OK"
		},
		{
			"label": "Oregon",
			"value": "OR"
		},
		{
			"label": "Palau",
			"value": "PW"
		},
		{
			"label": "Pennsylvania",
			"value": "PA"
		},
		{
			"label": "Puerto Rico",
			"value": "PR"
		},
		{
			"label": "Rhode Island",
			"value": "RI"
		},
		{
			"label": "South Carolina",
			"value": "SC"
		},
		{
			"label": "South Dakota",
			"value": "SD"
		},
		{
			"label": "Tennessee",
			"value": "TN"
		},
		{
			"label": "Texas",
			"value": "TX"
		},
		{
			"label": "Utah",
			"value": "UT"
		},
		{
			"label": "Vermont",
			"value": "VT"
		},
		{
			"label": "Virgin Islands",
			"value": "VI"
		},
		{
			"label": "Virginia",
			"value": "VA"
		},
		{
			"label": "Washington",
			"value": "WA"
		},
		{
			"label": "West Virginia",
			"value": "WV"
		},
		{
			"label": "Wisconsin",
			"value": "WI"
		},
		{
			"label": "Wyoming",
			"value": "WY"
		},
		// {
		// 	"label": "Punjab",
		// 	"value": "Punjab"
		// },
		// {
		// 	"label": "Sindh",
		// 	"value": "Sindh"
		// },
		// {
		// 	"label": "Balochistan",
		// 	"value": "Balochistan"
		// },
		// {
		// 	"label": "Pak Occupied Jammu and Kashmir",
		// 	"value": "Pak Occupied Jammu and Kashmir"
		// },
		// {
		// 	"label": "Islamabad Capital Territory",
		// 	"value": "Islamabad Capital Territory"
		// }
	];

	const countries = [
		{
			"label": "United States",
			"value": "US"
		},
		// {
		// 	"label": "Pakistan",
		// 	"value": "PK"
		// }
	];

	const industries = [
		{
			"label": "Accounting",
			"value": "Accounting"
		},
		{
			"label": "Airlines/Aviation",
			"value": "Airlines/Aviation"
		},
		{
			"label": "Alternative Dispute Resolution",
			"value": "Alternative Dispute Resolution"
		},
		{
			"label": "Alternative Medicine",
			"value": "Alternative Medicine"
		},
		{
			"label": "Animation",
			"value": "Animation"
		},
		{
			"label": "Apparel/Fashion",
			"value": "Apparel/Fashion"
		},
		{
			"label": "Architecture/Planning",
			"value": "Architecture/Planning"
		},
		{
			"label": "Arts/Crafts",
			"value": "Arts/Crafts"
		},
		{
			"label": "Automotive",
			"value": "Automotive"
		},
		{
			"label": "Aviation/Aerospace",
			"value": "Aviation/Aerospace"
		},
		{
			"label": "Banking/Mortgage",
			"value": "Banking/Mortgage"
		},
		{
			"label": "Biotechnology/Greentech",
			"value": "Biotechnology/Greentech"
		},
		{
			"label": "Broadcast Media",
			"value": "Broadcast Media"
		},
		{
			"label": "Building Materials",
			"value": "Building Materials"
		},
		{
			"label": "Business Supplies/Equipment",
			"value": "Business Supplies/Equipment"
		},
		{
			"label": "Capital Markets/Hedge Fund/Private Equity",
			"value": "Capital Markets/Hedge Fund/Private Equity"
		},
		{
			"label": "Chemicals",
			"value": "Chemicals"
		},
		{
			"label": "Civic/Social Organization",
			"value": "Civic/Social Organization"
		},
		{
			"label": "Civil Engineering",
			"value": "Civil Engineering"
		},
		{
			"label": "Commercial Real Estate",
			"value": "Commercial Real Estate"
		},
		{
			"label": "Computer Games",
			"value": "Computer Games"
		},
		{
			"label": "Computer Hardware",
			"value": "Computer Hardware"
		},
		{
			"label": "Computer Networking",
			"value": "Computer Networking"
		},
		{
			"label": "Computer Software/Engineering",
			"value": "Computer Software/Engineering"
		},
		{
			"label": "Computer/Network Security",
			"value": "Computer/Network Security"
		},
		{
			"label": "Construction",
			"value": "Construction"
		},
		{
			"label": "Consumer Electronics",
			"value": "Consumer Electronics"
		},
		{
			"label": "Consumer Goods",
			"value": "Consumer Goods"
		},
		{
			"label": "Consumer Services",
			"value": "Consumer Services"
		},
		{
			"label": "Cosmetics",
			"value": "Cosmetics"
		},
		{
			"label": "Dairy",
			"value": "Dairy"
		},
		{
			"label": "Defense/Space",
			"value": "Defense/Space"
		},
		{
			"label": "Design",
			"value": "Design"
		},
		{
			"label": "E-Learning",
			"value": "E-Learning"
		},
		{
			"label": "Education Management",
			"value": "Education Management"
		},
		{
			"label": "Electrical/Electronic Manufacturing",
			"value": "Electrical/Electronic Manufacturing"
		},
		{
			"label": "Entertainment/Movie Production",
			"value": "Entertainment/Movie Production"
		},
		{
			"label": "Environmental Services",
			"value": "Environmental Services"
		},
		{
			"label": "Events Services",
			"value": "Events Services"
		},
		{
			"label": "Executive Office",
			"value": "Executive Office"
		},
		{
			"label": "Facilities Services",
			"value": "Facilities Services"
		},
		{
			"label": "Farming",
			"value": "Farming"
		},
		{
			"label": "Financial Services",
			"value": "Financial Services"
		},
		{
			"label": "Fine Art",
			"value": "Fine Art"
		},
		{
			"label": "Fishery",
			"value": "Fishery"
		},
		{
			"label": "Food Production",
			"value": "Food Production"
		},
		{
			"label": "Food/Beverages",
			"value": "Food/Beverages"
		},
		{
			"label": "Fundraising",
			"value": "Fundraising"
		},
		{
			"label": "Furniture",
			"value": "Furniture"
		},
		{
			"label": "Gambling/Casinos",
			"value": "Gambling/Casinos"
		},
		{
			"label": "Glass/Ceramics/Concrete",
			"value": "Glass/Ceramics/Concrete"
		},
		{
			"label": "Government Administration",
			"value": "Government Administration"
		},
		{
			"label": "Government Relations",
			"value": "Government Relations"
		},
		{
			"label": "Graphic Design/Web Design",
			"value": "Graphic Design/Web Design"
		},
		{
			"label": "Health/Fitness",
			"value": "Health/Fitness"
		},
		{
			"label": "Higher Education/Acadamia",
			"value": "Higher Education/Acadamia"
		},
		{
			"label": "Hospital/Health Care",
			"value": "Hospital/Health Care"
		},
		{
			"label": "Hospitality",
			"value": "Hospitality"
		},
		{
			"label": "Human Resources/HR",
			"value": "Human Resources/HR"
		},
		{
			"label": "Import/Export",
			"value": "Import/Export"
		},
		{
			"label": "Individual/Family Services",
			"value": "Individual/Family Services"
		},
		{
			"label": "Industrial Automation",
			"value": "Industrial Automation"
		},
		{
			"label": "Information Services",
			"value": "Information Services"
		},
		{
			"label": "Information Technology/IT",
			"value": "Information Technology/IT"
		},
		{
			"label": "Insurance",
			"value": "Insurance"
		},
		{
			"label": "International Affairs",
			"value": "International Affairs"
		},
		{
			"label": "International Trade/Development",
			"value": "International Trade/Development"
		},
		{
			"label": "Internet",
			"value": "Internet"
		},
		{
			"label": "Investment Banking/Venture",
			"value": "Investment Banking/Venture"
		},
		{
			"label": "Investment Management/Hedge Fund/Private Equity",
			"value": "Investment Management/Hedge Fund/Private Equity"
		},
		{
			"label": "Judiciary",
			"value": "Judiciary"
		},
		{
			"label": "Law Enforcement",
			"value": "Law Enforcement"
		},
		{
			"label": "Law Practice/Law Firms",
			"value": "Law Practice/Law Firms"
		},
		{
			"label": "Legal Services",
			"value": "Legal Services"
		},
		{
			"label": "Legislative Office",
			"value": "Legislative Office"
		},
		{
			"label": "Leisure/Travel",
			"value": "Leisure/Travel"
		},
		{
			"label": "Library",
			"value": "Library"
		},
		{
			"label": "Logistics/Procurement",
			"value": "Logistics/Procurement"
		},
		{
			"label": "Luxury Goods/Jewelry",
			"value": "Luxury Goods/Jewelry"
		},
		{
			"label": "Machinery",
			"value": "Machinery"
		},
		{
			"label": "Management Consulting",
			"value": "Management Consulting"
		},
		{
			"label": "Maritime",
			"value": "Maritime"
		},
		{
			"label": "Market Research",
			"value": "Market Research"
		},
		{
			"label": "Marketing/Advertising/Sales",
			"value": "Marketing/Advertising/Sales"
		},
		{
			"label": "Mechanical or Industrial Engineering",
			"value": "Mechanical or Industrial Engineering"
		},
		{
			"label": "Media Production",
			"value": "Media Production"
		},
		{
			"label": "Medical Equipment",
			"value": "Medical Equipment"
		},
		{
			"label": "Medical Practice",
			"value": "Medical Practice"
		},
		{
			"label": "Mental Health Care",
			"value": "Mental Health Care"
		},
		{
			"label": "Military Industry",
			"value": "Military Industry"
		},
		{
			"label": "Mining/Metals",
			"value": "Mining/Metals"
		},
		{
			"label": "Motion Pictures/Film",
			"value": "Motion Pictures/Film"
		},
		{
			"label": "Museums/Institutions",
			"value": "Museums/Institutions"
		},
		{
			"label": "Music",
			"value": "Music"
		},
		{
			"label": "Nanotechnology",
			"value": "Nanotechnology"
		},
		{
			"label": "Newspapers/Journalism",
			"value": "Newspapers/Journalism"
		},
		{
			"label": "Non-Profit/Volunteering",
			"value": "Non-Profit/Volunteering"
		},
		{
			"label": "Oil/Energy/Solar/Greentech",
			"value": "Oil/Energy/Solar/Greentech"
		},
		{
			"label": "Online Publishing",
			"value": "Online Publishing"
		},
		{
			"label": "Other Industry",
			"value": "Other Industry"
		},
		{
			"label": "Outsourcing/Offshoring",
			"value": "Outsourcing/Offshoring"
		},
		{
			"label": "Package/Freight Delivery",
			"value": "Package/Freight Delivery"
		},
		{
			"label": "Packaging/Containers",
			"value": "Packaging/Containers"
		},
		{
			"label": "Paper/Forest Products",
			"value": "Paper/Forest Products"
		},
		{
			"label": "Performing Arts",
			"value": "Performing Arts"
		},
		{
			"label": "Pharmaceuticals",
			"value": "Pharmaceuticals"
		},
		{
			"label": "Philanthropy",
			"value": "Philanthropy"
		},
		{
			"label": "Photography",
			"value": "Photography"
		},
		{
			"label": "Plastics",
			"value": "Plastics"
		},
		{
			"label": "Political Organization",
			"value": "Political Organization"
		},
		{
			"label": "Primary/Secondary Education",
			"value": "Primary/Secondary Education"
		},
		{
			"label": "Printing",
			"value": "Printing"
		},
		{
			"label": "Professional Training",
			"value": "Professional Training"
		},
		{
			"label": "Program Development",
			"value": "Program Development"
		},
		{
			"label": "Public Relations/PR",
			"value": "Public Relations/PR"
		},
		{
			"label": "Public Safety",
			"value": "Public Safety"
		},
		{
			"label": "Publishing Industry",
			"value": "Publishing Industry"
		},
		{
			"label": "Railroad Manufacture",
			"value": "Railroad Manufacture"
		},
		{
			"label": "Ranching",
			"value": "Ranching"
		},
		{
			"label": "Real Estate/Mortgage",
			"value": "Real Estate/Mortgage"
		},
		{
			"label": "Recreational Facilities/Services",
			"value": "Recreational Facilities/Services"
		},
		{
			"label": "Religious Institutions",
			"value": "Religious Institutions"
		},
		{
			"label": "Renewables/Environment",
			"value": "Renewables/Environment"
		},
		{
			"label": "Research Industry",
			"value": "Research Industry"
		},
		{
			"label": "Restaurants",
			"value": "Restaurants"
		},
		{
			"label": "Retail Industry",
			"value": "Retail Industry"
		},
		{
			"label": "Security/Investigations",
			"value": "Security/Investigations"
		},
		{
			"label": "Semiconductors",
			"value": "Semiconductors"
		},
		{
			"label": "Shipbuilding",
			"value": "Shipbuilding"
		},
		{
			"label": "Sporting Goods",
			"value": "Sporting Goods"
		},
		{
			"label": "Sports",
			"value": "Sports"
		},
		{
			"label": "Staffing/Recruiting",
			"value": "Staffing/Recruiting"
		},
		{
			"label": "Supermarkets",
			"value": "Supermarkets"
		},
		{
			"label": "Telecommunications",
			"value": "Telecommunications"
		},
		{
			"label": "Textiles",
			"value": "Textiles"
		},
		{
			"label": "Think Tanks",
			"value": "Think Tanks"
		},
		{
			"label": "Tobacco",
			"value": "Tobacco"
		},
		{
			"label": "Translation/Localization",
			"value": "Translation/Localization"
		},
		{
			"label": "Transportation",
			"value": "Transportation"
		},
		{
			"label": "Utilities",
			"value": "Utilities"
		},
		{
			"label": "Venture Capital/VC",
			"value": "Venture Capital/VC"
		},
		{
			"label": "Veterinary",
			"value": "Veterinary"
		},
		{
			"label": "Warehousing",
			"value": "Warehousing"
		},
		{
			"label": "Wholesale",
			"value": "Wholesale"
		},
		{
			"label": "Wine/Spirits",
			"value": "Wine/Spirits"
		},
		{
			"label": "Wireless",
			"value": "Wireless"
		},
		{
			"label": "Writing/Editing",
			"value": "Writing/Editing"
		}
	];


	const fileInput = React.useRef(null);

	const handleClick = () => {
		fileInput.current.click();
	};

	const fileSelectedHandler = (event) => {
		if (event.target.files[0]) {
			dispatch(companySetupActions.uploadLogo(event.target.files[0], user));
		}
	};

	const dispatch = useDispatch();
	const form = useSelector((state) => state.companySetup.companySetupForm);
	const loading = useSelector(
		(state) => state.companySetup.isCompanySetupLoading,
	);
	const isSubmitted = useSelector((state) => state.companySetup.isSubmitted);
	const isVerified = useSelector((state) => state.companySetup.isVerified);
	const isModifyRequested = useSelector(
		(state) => state.companySetup.isModifyRequested,
	);

	const user = useSelector((state) => state.user.user);
	const userProfile = useSelector((state) => state.formsStatus.userProfile);

	const [errors, setErrors] = useState({});

	const handleChange = (target) => {
		dispatch(companySetupActions.handleChange(target));
	};

	const handleSelectChange = (target) => {
		dispatch(companySetupActions.handleSelectChange(target));
	};

	const handleIndustriesChange = (target) => {
		dispatch(companySetupActions.handleIndustriesChange(target));
	};

	const handleCountryChange = (target) => {
		dispatch(companySetupActions.handleCountryChange(target));
	};
	const validation = (values) => {
		const errors = {};
		if (!values.companyName) {
			errors.companyName = "Company name is required";
		}
		if (!values.industry) {
			errors.industry = "Industry name is required";
		}
		if (!values.country) {
			errors.country = "Country name is required";
		}
		if (!values.state) {
			errors.state = "State name is required";
		}
		if (!values.legalAddress1 && !values.legalAddress2) {
			errors.legalAddress1 = "Address is Required";
		}
		// if (values.phone.length > 15) {
		// 	errors.phone = "Phone number is too long";
		// }

		return errors;
	};
	const handleAddressChange = (address) => {
		handleChange({ name: "legalAddress1", value: address });
	};
	const handleAddressSelect = (address) => {
		Geocode.fromAddress(address)
			.then((response) => {
				let postalCode = "" ,
					city = "";
				const country = { label: "", value: "" };
				const state = { label: "", value: "" };

				for (
					let i = 0;
					i < response.results[0].address_components.length;
					i++
				) {
					for (
						let j = 0;
						j < response.results[0].address_components[i].types.length;
						j++
					) {
						switch (response.results[0].address_components[i].types[j]) {
							case "locality":
								city = response.results[0].address_components[i].long_name;
								break;
							case "administrative_area_level_1":
								state.label = response.results[0].address_components[i].long_name;
								state.value = response.results[0].address_components[i].short_name;
								break;
							case "country":
								country.label =
									response.results[0].address_components[i].long_name;
								country.value =
									response.results[0].address_components[i].short_name;
								break;
							case "postal_code":
								postalCode =
										response.results[0].address_components[i].long_name;
								break;
						}
					}
				}
				handleChange({ name: "legalAddress1", value: address });
				handleChange({ name: "city", value: city });
				handleChange({ name: "state", value: state.label });
				handleChange({ name: "country", value: country.label });
				handleChange({ name: "zipCode", value: postalCode });
				handleCountryChange(country);
				handleSelectChange(postalCode);
				handleSelectChange(state);
			})
			.catch((error) => console.log(error));
	};



	const handleSubmit = () => {
		setErrors(validation(form));

		const errors = validation(form);

		if (Object.keys(errors).length === 0) {
			return dispatch(
				companySetupActions.submitForm({ user, userProfile, ...form }),
			);
		}
	};

	useEffect(() => {
		dispatch(companySetupActions.getTenantProfile(user));
	}, [dispatch, user]);

	if (props.dashboard) {
		//
	} else if (isSubmitted && isVerified && !loading && !isModifyRequested) {
		return <Redirect to='/onboarding/addClients' />;
	}
	return (
		<>
			{!props.dashboard && (
				<div className='web-title'>
					<div style={{ marginBottom: "20px" }} className='headline1'>
						Company Setup
					</div>
				</div>
			)}
			<div
			>
				<div className='w-100 d-flex justify-content-between'
					style={{ marginTop: "20px" }}>
					<Input
						name='companyName'
						type='text'
						inputStyle={{
							border: errors.companyName ? "1px solid #FC4848" : "none",
						}}
						asterik
						label='Company Name'
						style={{ marginTop: "20px", width:"48%" }}
						value={form.companyName}
						onChange={(e) => handleChange(e.target)}
					/>
					{errors.companyName && (
						<AlertBox
							lineError
							color='error'
							title='Error'
							description={errors.companyName}
						/>
					)}
					<div
						className='d-flex flex-column align-items-start'
						style={{ width: "48%", marginTop: "20px" }}>
						<div style={{ marginBottom: "-5px" }}>
							<span className='p2'>Industry</span>
							<span style={{ color: "red" }}> *</span>
						</div>
						<Select
							options={industries}
							styles={{width:"100%", border:"none"}}
							name="state"
							placeholder={form.industry || "Industry"}
							onChange={(selectedState) => {
								handleIndustriesChange( selectedState );
							}}
						/>

						{errors.industry && (
							<AlertBox
								lineError
								color='error'
								title='Error'
								description={errors.industry}
							/>
						)}
					</div>
				</div>
				<PlacesAutocomplete
					value={form.legalAddress1}
					onSelect={handleAddressSelect}
					onChange={handleAddressChange}
				>
					{({ getInputProps, suggestions, getSuggestionItemProps }) => (

						<div>
							<Input
								{...getInputProps()}
								name='legalAddress1'
								type='text'
								asterik
								inputStyle={{
									border: errors.legalAddress1 ? "1px solid #FC4848" : "none",
								}}
								label='Address'
								value={form.legalAddress1}
								style={{ marginTop: "20px" }}

							/>

							<div
								className=' p2 d-flex flex-column align-items-start justify-content-start'
								style={{
									position: "absolute",
									zIndex: "1",
									width: "1052px",
									borderBottomLeftRadius: "10px",
									borderBottomRightRadius: "10px" ,
									backgroundColor:"#FFFFFF",
									marginTop:"10px",
									textAlign:"left",
									paddingLeft:"20px"
								}}>
								{suggestions.map((suggestion, index) => {
									return (
										<div
											style={{cursor:"pointer" ,textAlign:"left"}}
											key={index}
											{...getSuggestionItemProps(suggestion)}>
											<span>{suggestion.description}</span>
										</div>
									);
								})}
							</div>
						</div>
					)}
				</PlacesAutocomplete>

				<Input
					name='legalAddress2'
					type='text'
					label=' '
					inputStyle={{
						border: errors.legalAddress1 ? "1px solid #FC4848" : "none",
					}}
					value={form.legalAddress2}
					style={{ marginTop: "20px" }}
					onChange={(e) => {
						handleChange(e.target);
					}}
				/>
				{errors.legalAddress1 && (
					<div className="d-flex">
						<AlertBox
							lineError
							color='error'
							title='Error'
							description={errors.legalAddress1}
						/>
					</div>
				)}

				<div
					className='w-100 d-flex justify-content-between'
					style={{ marginTop: "20px" }}>
					<Input
						name='city'
						label='City'
						style={{ width: "48%", marginTop: "20px" }}
						value={form.city }
						onChange={(e) => handleChange(e.target)}
					/>
					<div
						className='d-flex flex-column align-items-start'
						style={{ width: "48%", marginTop: "20px" }}>
						<div style={{ marginBottom: "-5px" }}>
							<span className='p2'>State</span>
							<span style={{ color: "red" }}> *</span>
						</div>
						<Select
							options={states}
							value={form.state}
							styles={{width:"100%", border:"none"}}
							name="state"
							placeholder={form.state || "State"}
							onChange={(selectedState) => {
								handleSelectChange( selectedState );
							}}
						/>
						{errors.state && (
							<AlertBox
								lineError
								color='error'
								title='Error'
								description={errors.state}
							/>
						)}
					</div>

				</div>
				<div className='w-100 d-flex justify-content-between'>
					<Input
						type="number"
						name='zipCode'
						label='Zip code'
						style={{ width: "48%", marginTop: "20px" }}
						value={form.zipCode }
						onChange={(e) => handleChange(e.target)}
					/>
					<div
						className='d-flex flex-column align-items-start'
						style={{ width: "48%", marginLeft: "4%" ,marginTop: "20px"}}>
						<div style={{ marginBottom: "-5px" }}>
							<span className='p2'>Country</span>
							<span style={{ color: "red" }}> *</span>
						</div>
						<Select
							name="country"
							value={form.country}
							placeholder={form.country || "Country"}
							options={countries}
							selected={form.country || "US"}
							placeholderStyle={{color:"#2B3340", textAlign:"left" , marginBottom:"20px"}}
							onChange={(selectedState) => {
								handleCountryChange( selectedState );
							}}
						/>

						{errors.country && (
							<AlertBox
								lineError
								color='error'
								title='Error'
								description={errors.country}
							/>
						)}
					</div>
				</div>
				<div className='w-100 d-flex justify-content-between'>
					{/* <div className="d-flex flex-column w-100" >
						<Input
							name='phone'
							type="number"
							label='Phone'
							inputStyle={{
								border: errors.phone ? "1px solid #FC4848" : "none",
							}}
							style={{  marginTop: "20px" }}
							value={form.phone}
							onChange={(e) => handleChange(e.target)}
						/>
						{errors.phone && (
							<div className="d-flex">
								<AlertBox
									lineError
									color='error'
									title='Error'
									description={errors.phone}
								/>
							</div>
						)}
					</div> */}
					<div className=" w-100" style={{marginLeft:"0%"}}>
						<Input
							name='websiteUrl'
							label='Website Url'
							style={{  marginTop: "20px" }}
							value={form.websiteUrl}
							onChange={(e) => handleChange(e.target)}
						/>
					</div>

				</div>

				<div>
					<div style={{ margin: "15px 0px 10px 0px" }} className='d-flex'>
						<span className='p2'>Company Logo</span>
					</div>
					<div
						onClick={handleClick}
						className='d-flex justify-content-center align-items-center'
						style={{
							width: "50px",
							height: "50px",
							borderRadius: "12px",
							backgroundColor: "white",
						}}>
						<img
							src={form.logo ? form.logo : Upload}
							style={{
								width: form.logo ? "28px" : "15px",
								height: form.logo ? "28px" : "15px",
								borderRadius: form.logo ? "50%" : "0%",
							}}
						/>
					</div>
					<input
						style={{ display: "none" }}
						type='file'
						onChange={fileSelectedHandler}
						ref={fileInput}></input>
				</div>
				{props.dashboard ? (
					<div
						className='d-flex justify-content-center'
						style={{ paddingTop: "40px" }}>
						<Button
							button
							loading={loading}
							label='Update'
							textClass='b2'
							className='button-large'
							style={{ padding: "6.5px 69px" }}
							onClick={handleSubmit}
						/>
					</div>
				) : (
					<div
						className='d-flex justify-content-center'
						style={{ paddingTop: "40px" }}>
						<Button
							rightArrow
							loading={loading}
							label='Next'
							textClass='b2'
							className='button-large'
							style={{ padding: "6.5px 69px" }}
							onClick={handleSubmit}
						/>
					</div>
				)}
			</div>
		</>
	);
}

export default SetupRoleForm;
