import React from "react";
import OnboardingMobile from "./onboardingMobile";
import OnboardingWeb from "./onboardingWeb";
import { useSelector } from "react-redux";
import { Redirect } from "react-router-dom";

function Onboarding(props) {
	const userProfile = useSelector((state) => state.formsStatus.userProfile);
	const isTenantSubscribed = useSelector(state => state.user.isTenantSubscribed);

	if(isTenantSubscribed){
		return (
			<>
				<div className='onboarding-web'>
					{userProfile?.is_onboarding ? (
						<OnboardingWeb props={props} />
					) : (
						<Redirect to='/dashboard' />
					)}
				</div>
				<div className='onboarding-mobile'>
					{userProfile?.is_onboarding ? (
						<OnboardingMobile props={props} />
					) : (
						<Redirect to='/dashboard' />
					)}
					<OnboardingMobile props={props} />
				</div>
			</>
		);
	}
	return (
		<>
			<Redirect to="/payment"/>
		</>
	);
	
}

export default Onboarding;
