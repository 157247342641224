const assessmentStatus = {
	INITIALIZED: "Initialized",
	IN_PROGRESS: "In Progress",
	UNDER_EVALUATION: "Under Evaluation",
	FINISHED: "Finished",
	NOT_CONSIDERED: "Not Considered",
	SHORTLISTED: "Shortlisted",
};

let stripePublicKey = window._env_?.REACT_APP_STRIPE_PUBLIC_KEY;

console.log("STRIPE PUBLIC KEY: ", window._env_);

if (!stripePublicKey) {
	stripePublicKey = "pk_test_51LPJ4vSAGSOvCrHpMAWA6ryAyqHmCOZJXDI96hNoIdPx413DUqmVw1O8HV8WnBXRhsYL9zL1hWlXsMeh96vVtLGj00nA5gJvIH";
}

const GoogleMapApiKey = "AIzaSyB4q0tG0zXknsjl972OISSwVCvyr6TJz6I";

const teamMemberRoles = [
	{
		id: 12,
		role_name: "Admin",
	},
	{
		id: 14,
		role_name: "Recruiter",
	},
];

export default assessmentStatus;
export { stripePublicKey, GoogleMapApiKey, teamMemberRoles };
