import { secure_instance } from "../../../api/axios/axios-config";

export const GET_CARD_DETAILS = "[Card] GET_CARD_DETAILS";
export const SUBSCRIPTION_END = "[Card] SUBSCRIPTION_END";
export const HANDLE_CHANGE = "[Card] HANDLE_CHANGE";
export const SUBSCRIPTION_START = "[Card] SUBSCRIPTION_START";
export const GET_INVOICES = "[Card] GET_INVOICES";
export const GET_CUSTOMER_INVOICES = "[Card] GET_CUSTOMER_INVOICES";
export const SUBSCRIPTION_ERROR = "[Card] SUBSCRIPTION_ERROR";
export const SET_DEFAULT_PAYMENT_METHOD = "[Card] SET_DEFAULT_PAYMENT_METHOD";
export const DETACH_PAYMENT_METHOD = "[Card] DETACH_PAYMENT_METHOD";
export const SET_DEFAULT_CARD = "[Card] SET_DEFAULT_CARD";
export const UPDATE_SUBSCRIPTION = "[Card] UPDATE_SUBSCRIPTION";
export const RETRY_PAYMENT = "[Card] RETRY_PAYMENT";

export const getCardDetails = () => {
	return async (dispatch) => {
		try {
			const request = await secure_instance.request({
				url: "v1/subscriptions/customer_payment_methods/",
				method: "Get",
			});

			dispatch({
				type: GET_CARD_DETAILS,
				payload: request.data.data.data,
			});
		} catch (error) {
			//
		}
	};
};

export const createStripePaymentMethod = (
	stripe,
	elements,
	CardNumberElement,
	data,
	checked,
) => {
	return async (dispatch) => {
		try {
			dispatch({
				type: SUBSCRIPTION_START,
			});

			const paymentMethod = await stripe.createPaymentMethod({
				type: "card",
				card: elements.getElement(CardNumberElement),
				billing_details: {
					name: data.name,
				},
			});

			await secure_instance.request({
				url: "v1/subscriptions/create_payment_method",
				method: "Post",
				data: {
					payment_method_id: paymentMethod.paymentMethod.id,
					set_default: checked,
				},
			});

			dispatch({
				type: SUBSCRIPTION_END,
			});
		} catch (error) {
			dispatch({
				type: SUBSCRIPTION_ERROR,
				payload: "Please enter valid data",
			});
			// console.log(error, "error");
		}
	};
};

export const setDefaultPaymentMethod = (id) => {
	return async (dispatch) => {
		try {
			await secure_instance.request({
				url: "v1/subscriptions/default_payment_method",
				method: "Post",
				data: {
					payment_method_id: id,
				},
			});

			// if (request.status === 200) {
			// alert(request.data.data.description);
			// window.location.reload();
			// }/
			// console.log(request, "paymentMe");

			dispatch({
				type: SET_DEFAULT_PAYMENT_METHOD,
				payload: id,
			});
		} catch (error) {
			console.log(error, "err");
		}
	};
};

export const detachPaymentMethod = (id) => {
	return async (dispatch) => {
		try {
			await secure_instance.request({
				url: "v1/subscriptions/detach_payment_method",
				method: "Post",
				data: {
					payment_method_id: id,
				},
			});
			// if (request.status === 200) {
			// 	alert(request.data.data.description);
			// 	window.location.reload();
			// }
			// console.log(request, "paymentMe");

			dispatch({
				type: DETACH_PAYMENT_METHOD,
				payload: id,
			});
		} catch (error) {
			console.log(error, "err");
		}
	};
};

export const updateSubscription = (id, cost) => {
	return async (dispatch) => {
		try {
			const request = await secure_instance.request({
				url: `v1/subscriptions/${id}`,
				method: "Patch",
				data: {
					alert_threshold: cost,
				},
			});
			if (request.status === 200) {
				alert("Cost Limit Updated!");
			}
			dispatch({
				type: UPDATE_SUBSCRIPTION,
				payload: request.data,
			});
		} catch (error) {
			//
		}
	};
};

export const setErrorMessage = (data) => {
	return {
		type: SUBSCRIPTION_ERROR,
		payload: data,
	};
};

export const handleChange = (data) => {
	return {
		type: HANDLE_CHANGE,
		payload: data,
	};
};

export const getInvoices = () => {
	return async (dispatch) => {
		try {
			const request = await secure_instance.request({
				url: "v1/subscriptions/upcoming_invoice/",
				method: "Get",
			});

			dispatch({
				type: GET_INVOICES,
				payload: request.data,
			});
		} catch (error) {
			console.log(error, "error");
		}
	};
};

export const getCustomerInvoices = () => {
	return async (dispatch) => {
		try {
			const request = await secure_instance.request({
				url: "v1/subscriptions/customer_invoices/",
				method: "Get",
			});

			// console.log(request.data, "req");
			dispatch({
				type: GET_CUSTOMER_INVOICES,
				payload: request.data,
			});
		} catch (error) {
			console.log(error, "error");
		}
	};
};

export const setDefaultCard = (data) => {
	return {
		type: SET_DEFAULT_CARD,
		payload: data,
	};
};

export const retryPayment = () => {
	return async (dispatch) => {
		try {
			const request = await secure_instance.request({
				url: "v1/subscriptions/retry",
				method: "POST",
			});

			console.log(request, "retry");
			dispatch({
				type: RETRY_PAYMENT,
				payload: request.data,
			});
		} catch (error) {
			//
		}
	};
};
