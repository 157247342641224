import React from "react";

export default function Tag(props) {
	return (
		<div style={{ width: "fit-content", padding: "0px 5px", borderRadius: "5px",backgroundColor:"#00B4D8",...props.style }}
			onClick={props.onClick}
			className="d-flex justify-content-center align-items-center pointer" >
			<div className="d-flex justify-content-center align-items-center">
				<span className={"description white" + ` ${props.className}`}>{props.title || "Next"}</span>
				{/* <span style={{ marginLeft: "5px", cursor:"pointer" }} >X</span> */}
			</div>

		</div>
	);
}
