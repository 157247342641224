import React from "react";
import Roles from "./Roles";
import Qualification from "./Qualification";
import PersonalityAssesment from "./PersonalityAssesment";
import ReferenceChecks from "./ReferenceChecks";
import Result from "./Result";
import { Switch, Route, useRouteMatch } from "react-router-dom";

export default function Main() {
	const { path } = useRouteMatch();

	// const addClientsIsSubmitted = useSelector(
	// 	(state) => state.companySetup.isSubmitted,
	// );

	// if (!addClientsIsSubmitted) {
	// 	return <Redirect to='/onboarding/addClients' />;
	// }

	return (
		<Switch>
			<Route exact path={[`${path}`, `${path}/`]}>
				<Roles />
			</Route>
			<Route
				exact
				path={[`${path}/qualifications`]}
				component={Qualification}
			/>
			<Route
				exact
				path={[`${path}/personality`]}
				component={PersonalityAssesment}
			/>

			<Route
				exact
				path={[`${path}/referenceChecks`]}
				component={ReferenceChecks}
			/>

			<Route exact path={[`${path}/result`]} component={Result} />
		</Switch>
	);
}
