import React, { useState, useEffect } from "react";
import AlertBox from "../../AlertBox";
import { isEmailValid, isPasswordValid } from "../../../utilities/Validator";
import PasswordInput from "./PasswordInput";
import CheckBox from "../../CustomCheckBox";
import { useSelector, useDispatch } from "react-redux";
import { Link, useLocation, useParams } from "react-router-dom";
import * as authActions from "../../../redux/actions/Auth/authAction";
import { Redirect } from "react-router-dom";

function Form() {
	const dispatch = useDispatch();
	const params = useParams();
	const location = useLocation();
	const [isChecked, setIsChecked] = useState(false);

	const errorMessage = useSelector((state) => state.user.errorMessage);
	const isLoggedIn = useSelector((state) => state.user.isLoggedIn);
	const isloggedInOauth = useSelector((state) => state.user.isloggedInOauth);
	const isTenantSubscribed = useSelector(state => state.user.isTenantSubscribed);
	const candidateUser = useSelector(state => state.user.user);
	const inviteId = useSelector((state) => state.candidateTesting.inviteId );
	// console.log("invite id at sign in form" , inviteId);

	const [user, setUser] = useState({
		email: null,
		password: null,
	});

	const [errors, setErrors] = useState({
		email: false,
		password: false,
		invalid: false,
	});

	function handleInput(event) {
		setUser({ ...user, [event.target.name]: event.target.value });
	}

	function onBlur(event) {
		if (event.target.name === "email") {
			if (!isEmailValid(user[event.target.name])) {
				setErrors({ ...errors, [event.target.name]: true });
				return;
			}
		}

		if (event.target.name === "password") {
			if (!isPasswordValid(user[event.target.name])) {
				setErrors({ ...errors, [event.target.name]: true });
				return;
			}
		}

		setErrors({ ...errors, [event.target.name]: false });

	}

	function isEmailOkay() {
		if (user.email === null) {
			return false;
		}

		if (errors.email) {
			return false;
		}

		return true;
	}

	function isPasswordOkay() {
		if (user.password === null) {
			return false;
		}

		if (errors.password) {
			return false;
		}

		return true;
	}

	function validateForm() {
		if (user.email === null) {
			return { isValid: false, message: "Email is missing" };
		}

		if (errors.email) {
			return { isValid: false, message: "Email is Invalid" };
		}

		if (user.password === null) {
			return { isValid: false, message: "Password is missing" };
		}

		return { isValid: true, message: "" };
	}

	function handleSubmit(event) {


		event.preventDefault();

		const validation = validateForm();

		if (!validation.isValid) {
			return dispatch(authActions.setErrorMessage(validation.message));
		}

		if (params?.oauth) {
			return dispatch(
				authActions.signIn(user.email, user.password, params.oauth),
			);

		}
		if (inviteId) {
			return dispatch(
				authActions.signIn(user.email, user.password,false , inviteId),
			);}
		else {
			return (
				dispatch(authActions.signIn(user.email, user.password ))
			);

		}
	}

	const emptyState = () => {
		return dispatch(authActions.setErrorMessage(""));
	};

	if (isLoggedIn) {
		if(!isTenantSubscribed){
			return <Redirect to='/pricing' />;
		}
		else{
			return <Redirect to='/onboarding/companySetup' />;
		}

	} else if (isloggedInOauth) {
		return <Redirect to={`/oauth${location.search}`} />;
	}
	else if(candidateUser?.accessToken){
		return <Redirect to = "/dashboard" />;
	}

	return (
		<div
			style={{ minHeight: "350px", minWidth: "330px", textAlign: "initial" }}>
			<div style={{ marginBottom: "9px" }}>
				<span className='h3'>Login</span>
			</div>
			<div style={{ marginBottom: "20px" }}>
				<span className='description'>
					Don’t have an account?
					<Link to='/signup' style={{ color: "#00b4d8" }} onClick={emptyState}>
						{" "}
						Create New
					</Link>
				</span>
			</div>
			<div className='SigninFormGroup'>
				{errorMessage && (
					<AlertBox
						color='error'
						title='Error'
						description={`${errorMessage}`}
					/>
				)}
				<input
					type='text'
					placeholder='Email'
					label=''
					name='email'
					onChange={handleInput}
					onBlur={onBlur}
					className={
						"description " +
						(isEmailOkay() ? "input-underline" : "input-underline-error")
					}
				/>
				<PasswordInput
					placeholder='Password'
					label=''
					name='password'
					onChange={handleInput}
					onBlur={onBlur}
					className={
						"description " +
						(isPasswordOkay() ? "input-underline" : "input-underline-error")
					}
				/>
				<div style={{ marginBottom: "20px" }}>
					<CheckBox
						className='description'
						value={isChecked}
						toggle={() => setIsChecked((val) => !val)}
						label='Remember Me'
					/>
				</div>
				<input type='button' value='Login' onClick={handleSubmit} />
				<Link to='/forgot-password'>
					<p className='description forgotpassword pointer'>Forgot Password?</p>
				</Link>
			</div>
		</div>
	);
}

export default Form;
