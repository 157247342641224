import React, { useEffect, useState } from "react";
import CurrentPlanCard from "../../../../components/subcomponents/Settings/CurrentPlanCard";
import BillingDetailsCard from "../../../../components/subcomponents/Settings/BillingDetailsCard";
import Table from "../../../../components/subcomponents/Table/Tabel";
import Status from "../../../../components/subcomponents/Status";
import DashboardAlert from "../../../../components/subcomponents/DashboardAlert";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import AddCard from "./AddCard";
import SwitchButton from "../../../../components/subcomponents/ToggleButton";
import Input from "../../../../components/subcomponents/Input";
import { useDispatch, useSelector } from "react-redux";
import * as Actions from "../../../../redux/actions/Dashboard/BillingInfo";
import {ReactComponent as DownloadIcon} from "../../../../assets/icons/basic/download.svg";
import cross from "../../../../assets/icons/basic/x.png";
import tick from "../../../../assets/icons/basic/tick.png";
import Button from "../../../../components/subcomponents/Button";
import { useMediaQuery } from "react-responsive";
import { stripePublicKey } from "../../../../utilities/Constants";

export default function BillingSettings() {


	const dispatch = useDispatch();

	const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });
	const stripePromise = loadStripe(stripePublicKey);

	const [addCardModal, setCardModal] = useState(false);

	const [alertBox, setAlertBox] = useState(false);
	const [turnOnLimitations, setTurnOnLimitations] = useState(false);

	const CustomerForm = useSelector(state => state.billing.customerInvoiceForm);
	const alertThreshold = useSelector(state => state.billing.stripeForm);

	const billingTableHeader = ["Invoice ID", "Date & Time", "Status", "Amount Paid", "Receipts"];

	const getSubscriptions = useSelector(state => state.user.isTenantSubscribed);

	// console.log(getSubscriptions.data[0].id,"subsrip");

	const subscriptionId = getSubscriptions.data[0].id;

	const handleChange = (target) => {
		dispatch(Actions.handleChange(target));
	};

	function timeConverter(UNIX_timestamp) {
		var a = new Date(UNIX_timestamp * 1000);
		var months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
		var year = a.getFullYear();
		var month = months[a.getMonth()];
		var date = a.getDate();
		var hour = a.getHours();
		var min = a.getMinutes();
		var sec = a.getSeconds();
		var time = month + " " + date + ", " + year + " / " + hour + ":" + min + ":" + sec;
		return time;
	}

	const Arr = [];
	console.log("CustomerForm?.data?.data " , CustomerForm?.data?.data);

	if(CustomerForm?.data?.data) {
		CustomerForm.data.data.map((item, index) => {

			Arr.push(
				[
					index, index + 1, timeConverter(item.created),
					isTabletOrMobile ? <img src={ item.status === "paid" ? tick : cross}/> :
						<Status key={index} isSuccess={item.status === "paid" ? true : false} isFail={item.status !== "paid" ? true : false} status={item.status.charAt(0).toUpperCase() + item.status.slice(1)} />,
					`$${item.amount_paid / 100}`,
					<Button key={index} download title = {isTabletOrMobile ? " "  : "Download"}
						leftIcon={<DownloadIcon className="mr-1" />}
						style={{ padding: "6px"  }}
						fontStyle="b3"
						onClick={() => { window.open(item.hosted_invoice_url, "_blank"); }} />
				]
			);

		});

	}

	const updateSubscription = () => {
		dispatch(Actions.updateSubscription(subscriptionId, alertThreshold.alertThresholdCost));
	};


	const handleBillingLimitation = () => {
		setTurnOnLimitations(!turnOnLimitations);
	};

	useEffect(() => {
		dispatch(Actions.getCustomerInvoices());
	},[dispatch]);

	const handleAlert = () => {
		setCardModal(true);
		setAlertBox(false);
	};

	return (
		<div className="d-flex justify-content-center">
			{
				addCardModal &&
				<Elements stripe={stripePromise}>
					<AddCard expire onClick={() => setCardModal(false)}/>
				</Elements>

			}
			<div className="billing-responsiveness" style={{width:isTabletOrMobile? "100%" : "87%"}}>
				{ alertBox && <div className="pb-3">
					<DashboardAlert onClick={handleAlert} alert="Alert: Your payment method has expired. Please Click here to add a new payment method."/>
				</div>}
				<div className="responsive-screen ">
					<CurrentPlanCard
						alertclick={() => setAlertBox(true)}
					/>
					<BillingDetailsCard

					/>
				</div>
				<div className="d-flex flex-column mt-4 align-items-start">
					<div className="d-flex pt-2">
						<SwitchButton onChange={handleBillingLimitation} value={turnOnLimitations}/>
						<span className="p2 pt-1">Would you like to turn on alerts for billing limitation?</span>

					</div>
					{turnOnLimitations &&
						<div className="d-flex">
							<Input
								type="number"
								placeholder="Please specify your cost limit"
								label=" "
								value={alertThreshold.alertThresholdCost}
								onChange={(e) => handleChange(e.target)}
								name="alertThresholdCost"
							/>
							<Button
								button
								className="d-flex align-items-center ml-4"
								label="Save"
								style={{padding:"0px 30px"}}
								onClick={updateSubscription}
							/>
						</div>}
				</div>
				<div style={{ marginTop: "25px", display: "flex", flexDirection: "column" }}>
					<div className="d-flex align-items-start">
						<span className="b2" >Payment History</span>
					</div>
					<div style={{ marginTop: "15px" }} className="d-flex flex-column align-items-center">
						<Table
							isTabletOrMobile
							headers={billingTableHeader}
							rows={Arr}
							style={{ marginTop: "15px" }}
							isEditable={false}
						/>
					</div>
				</div>
			</div>

		</div>
	);
}
