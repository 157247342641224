import React, { useState } from "react";
import { ReactComponent as ArrowDown } from "../../assets/icons/Arrows/chevron-bottom.svg";

export default function SelectNumber({ data, onSelect, selected }) {
	const [refNum, setRefNum] = useState(null);
	const [showOptions, setShowOptions] = useState(false);

	return (
		<div className='number-selector'>
			<div className='w-100 search-number-bar-card'>
				<div
					onClick={() => setShowOptions(!showOptions)}
					className='w-100 search-number-bar-card-header'>
					<div className='description'>
						{selected || refNum || "Select number of references"}
					</div>

					<ArrowDown className='pointer' />
				</div>
				{showOptions ? (
					<div
						className='searched-number-list-container'
						style={{ padding: "0px 20px" }}>
						{data.map((num, index) => (
							<div
								className='pointer select-number-list-item description'
								style={{
									borderBottom:
										index === data.length - 1 ? "none" : "1px solid #C8CBCD",
								}}
								onClick={() => {
									setRefNum(num), setShowOptions(!showOptions), onSelect(num);
								}}
								key={index}>
								{num}
							</div>
						))}
					</div>
				) : null}
			</div>
		</div>
	);
}
