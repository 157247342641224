import React, { useEffect, useState } from "react";
import {
	CardNumberElement,
	useStripe,
	useElements,
	CardExpiryElement,
	CardCvcElement,
} from "@stripe/react-stripe-js";
import { useDispatch, useSelector } from "react-redux";
import * as Actions from "../../../redux/actions/Auth/authAction";
import { Redirect } from "react-router";
import Button from "../../subcomponents/Button";
import Select from "../../subcomponents/Select";
import Geocode from "react-geocode";
import { GoogleMapApiKey } from "../../../utilities/Constants";
import PlacesAutocomplete from "react-places-autocomplete";

export default function PaymentForm(){

	const dispatch = useDispatch();
	Geocode.setApiKey(GoogleMapApiKey);
	const form = useSelector(state => state.user.stripeForm);
	const errors = useSelector(
		(state) => state.user.stripePaymentFormErrors,
	);
	const [agreeMarked, setAgreeMarked] = useState(false)

	const states = [
		"Alabama",
		"Alaska",
		"Arizona",
		"Arkansas",
		"California",
		"Colorado",
		"Connecticut",
		"Delaware",
		"District Of Columbia",
		"Florida",
		"Georgia",
		"Hawaii",
		"Idaho",
		"Illinois",
		"Indiana",
		"Iowa",
		"Kansas",
		"Kentucky",
		"Louisiana",
		"Maine",
		"Maryland",
		"Massachusetts",
		"Michigan",
		"Minnesota",
		"Mississippi",
		"Missouri",
		"Montana",
		"Nebraska",
		"Nevada",
		"New Hampshire",
		"New Jersey",
		"New Mexico",
		"New York",
		"North Carolina",
		"North Dakota",
		"Ohio",
		"Oklahoma",
		"Oregon",
		"Pennsylvania",
		"Rhode Island",
		"South Carolina",
		"South Dakota",
		"Tennessee",
		"Texas",
		"Utah",
		"Vermont",
		"Virginia",
		"Washington",
		"West Virginia",
		"Wisconsin",
		"Wyoming",
		// "Balochistan",
		// "Khyber Pakhtunkhwa",
		// "Punjab",
		// "Sindh",
		// "Islamabad Capital Territory"
	];
	const candidateUser = useSelector(state => state.user.user);
	const handleAddressChange = (address) => {
		handleChange({ name: "address", value: address });
	};
	const handleAddressSelect = (address) => {
		Geocode.fromAddress(address)
			.then((response) => {
				let postalCode = "" ,
					city = "";
				const country = { label: "", value: "" };
				const state = { label: "", value: "" };

				for (
					let i = 0;
					i < response.results[0].address_components.length;
					i++
				) {
					for (
						let j = 0;
						j < response.results[0].address_components[i].types.length;
						j++
					) {
						switch (response.results[0].address_components[i].types[j]) {
							case "locality":
								city = response.results[0].address_components[i].long_name;
								break;
							case "administrative_area_level_1":
								state.label = response.results[0].address_components[i].long_name;
								state.value = response.results[0].address_components[i].short_name;
								break;
							case "country":
								country.label =
									response.results[0].address_components[i].long_name;
								country.value =
									response.results[0].address_components[i].short_name;
								break;
							case "postal_code":
								postalCode =
										response.results[0].address_components[i].long_name;
								break;
						}
					}
				}
				handleChange({ name: "address", value: address });
				handleChange({ name: "city", value: city });
				handleChange({ name: "state", value: state.label });
				handleChange({ name: "country", value: country.label });
				handleChange({ name: "postal_code", value: postalCode });

			})
			.catch((error) => console.log(error));
	};



	const handleSubmit = () => {
		validateForm();

		if (
			form.name &&
			form.address &&
			form.country &&
			form.postal_code &&
			form.city &&
			form.state &&
			!errors.cardNumber &&
			!errors.cardExpiry &&
			!errors.cardCVC && 
			agreeMarked
			) {
			dispatch(
				Actions.createStripePaymentMethod(stripe, elements, CardNumberElement, form)
			);
		}
	};

	const loading = useSelector(state => state.user.loading);

	const isTenantSubscribed = useSelector(state => state.user.isTenantSubscribed);

	// console.log(isTenantSubscribed,"istenant");
	const handleChange = (target) => {
		dispatch(Actions.handleChange(target));
	};

	// const handleSelectChange = (selected) => {
	// 	dispatch(Actions.handleSelectChange(selected));
	// };


	useEffect(() => {
		dispatch(Actions.getSubscriptions());
	},[dispatch]);


	if(candidateUser.tenantId === 0){
		return <Redirect to="/dashboard"/>;
	}
	else if(isTenantSubscribed ){
		if(isTenantSubscribed?.data[0]?.status !== "unpaid"){
			return <Redirect to="/onboarding/companySetup"/>;
		}
		else {
			return (
				<Redirect to="account-suspended"/>
			);
		}

	}


	const stripe = useStripe();
	const elements = useElements();

	const cardNumberStyle = {
		style: {

			base: {
				fontSize: "17px",

				"::placeholder": {
					color: "#96999C",
				},
				lineHeight: "30px",
				background: "white"

			},
			invalid: {
				color: "#fa755a",
				iconColor: "#fa755a",
			},
		},
		showIcon: true,
	};

	const cardStyle = {
		style: {
			base: {
				fontSize: "12px",
				fontWeight:500,
				fontStyle:"normal",
				"::placeholder": {
					color: "#56585A",
				},

				lineHeight: "30px",
			},

			invalid: {
				color: "#fa755a",
				iconColor: "#fa755a",
			},
		},
	};

	const validateForm = () => {
		if (!form.name) {
			dispatch(
				Actions.validateStripeForm({
					name: "name",
					value: "Name is required",
				}),
			);
		}
		if (!form.address) {
			dispatch(
				Actions.validateStripeForm({
					name: "address",
					value: "Address is required",
				}),
			);
		}
		if (!form.country) {
			dispatch(
				Actions.validateStripeForm({
					name: "country",
					value: "Country is required",
				}),
			);
		}
		if (!form.postal_code) {
			dispatch(
				Actions.validateStripeForm({
					name: "postal_code",
					value: "Postal Code is required",
				}),
			);
		}
		if (!form.city) {
			dispatch(
				Actions.validateStripeForm({
					name: "city",
					value: "City is required",
				}),
			);
		}
		if (!form.state?.value) {
			dispatch(
				Actions.validateStripeForm({
					name: "state",
					value: "State / Province is required",
				}),
			);
		}
		if (!form.checkbox?.value) {
			dispatch(
				Actions.validateStripeForm({
					name: "checkbox",
					value: "State / Province is required",
				}),
			);
		}
		if (form.name) {
			dispatch(
				Actions.validateStripeForm({
					name: "name",
					value: false,
				}),
			);
		}
		if (form.address) {
			dispatch(
				Actions.validateStripeForm({
					name: "address",
					value: false,
				}),
			);
		}
		if (form.country) {
			dispatch(
				Actions.validateStripeForm({
					name: "country",
					value: false,
				}),
			);
		}
		if (form.postal_code) {
			dispatch(
				Actions.validateStripeForm({
					name: "postal_code",
					value: false,
				}),
			);
		}
		if (form.city) {
			dispatch(
				Actions.validateStripeForm({
					name: "city",
					value: false,
				}),
			);
		}
		if (form.state) {
			dispatch(
				Actions.validateStripeForm({
					name: "state",
					value: false,
				}),
			);
		}
		if(form.checkbox){
			dispatch(
				Actions.validateStripeForm({
					name: "checkbox",
					value: false,
				}),
			);
		}

	};

	const agreeButtonPressed = () => {
		setAgreeMarked(prevState => !prevState)
	}

	return (
		<div className='d-flex justify-content-center'>
			<div style={{ minHeight: "350px", maxWidth: "330px",  textAlign: "initial" }}>
				<div style={{ paddingBottom: "15px" }}>
					<span className='h3'>Payment Info</span>
				</div>
				<div className='SignUpFormGroup'>
					<div className="d-flex flex-column mb-2">
						<input
							type='text'
							placeholder='Cardholder Name'
							label=''
							name='name'
							className="description"
							value={form.name}
							onChange={e => handleChange(e.target)}
							style={{ borderBottom: errors.name ? "1px solid #FC4848" : "", marginBottom:"5px"}}

						/>
						<span className='description' style={{color: "#FC4848"}}>{errors.name}</span>
					</div>
					<div>
						<CardNumberElement
							className='stripe-input-field-full'
							options={cardNumberStyle}
							onChange={(e) => {
								if (e?.error) {
									dispatch(
										Actions.validateStripeForm({
											name: "cardNumber",
											value: e?.error?.message,
										}),
									);
								} else {
									dispatch(
										Actions.validateStripeForm({
											name: "cardNumber",
											value: false,
										}),
									);
								}
							}}
						/>
						<span className='description' style={{color: "#FC4848"}}>{errors.cardNumber}</span>

					</div>
					<div className="row">
						<div className='col-lg-6'>
							<div>
								<CardExpiryElement
									className='stripe-input-field-half'
									options={cardStyle}
									onChange={(e) => {
										if (e?.error) {
											dispatch(
												Actions.validateStripeForm({
													name: "cardExpiry",
													value: e?.error?.message,
												}),
											);
										} else {
											dispatch(
												Actions.validateStripeForm({
													name: "cardExpiry",
													value: false,
												}),
											);
										}
									}}
								/>
								<span className='description' style={{color: "#FC4848"}}>{errors.cardExpiry}</span>
							</div>
						</div>
						<div className='col-lg-6'>
							<div>
								<CardCvcElement
									className='stripe-input-field-half'
									options={cardStyle}
									onChange={(e) => {
										if (e?.error) {
											dispatch(
												Actions.validateStripeForm({
													name: "cardCVC",
													value: e?.error?.message,
												}),
											);
										} else {
											dispatch(
												Actions.validateStripeForm({
													name: "cardCVC",
													value: false,
												}),
											);
										}
									}}
								/>
								<span className='description' style={{color: "#FC4848"}}>{errors.cardCVC}</span>
							</div>
						</div>

					</div>
					<PlacesAutocomplete
						value={form.address}
						onSelect={handleAddressSelect}
						onChange={handleAddressChange}
					>
						{({ getInputProps, suggestions, getSuggestionItemProps }) => (

							<div>
								<input
									{...getInputProps()}
									type='text'
									placeholder='Address'
									label=''
									name='address'
									className="description"
									style={{paddingTop:"25px",  borderBottom: errors.address ? "1px solid #FC4848" : "", marginBottom:"5px" , width:"100%" , }}
									value={form.address}

								/>
								<div
									className=' p2 d-flex flex-column align-items-start justify-content-start'
									style={{
										position: "absolute",
										zIndex: "1",
										width: "330px",
										borderBottomLeftRadius: "10px",
										borderBottomRightRadius: "10px" ,
										backgroundColor:"#FFFFFF",
										marginTop:"4px",
										boxShadow:"1px 2px 8px rgba(0, 0, 0, 0.1)"

									}}>
									{suggestions.map((suggestion, index) => {
										return (
											<div
												style={{cursor:"pointer" ,textAlign:"left"}}
												key={index}
												{...getSuggestionItemProps(suggestion)}>
												<span>{suggestion.description}</span>
											</div>
										);
									})}
								</div>
							</div>
						)}
					</PlacesAutocomplete>

					<span className='description' style={{color: "#FC4848"}}>{errors.address}</span>

					<div className="row">

						<div className="col-lg-6">
							<div>
							<PlacesAutocomplete
								value={form.address}
								onSelect={handleAddressSelect}
								onChange={handleAddressChange}
							>
							{({ getInputProps }) => (

								<div>
									<input
										{...getInputProps()}
										type='text'
										placeholder='Country'
										label=''
										name='Country'
										className="description"
										style={{paddingTop:"25px",  borderBottom: errors.country ? "1px solid #FC4848" : "", marginBottom:"5px" , width:"100%" , }}
										value={form.country || "USA"}
										disabled={true} 
									/>
									<div
										className=' p2 d-flex flex-column align-items-start justify-content-start'
										style={{
											position: "absolute",
											zIndex: "1",
											width: "330px",
											borderBottomLeftRadius: "10px",
											borderBottomRightRadius: "10px" ,
											backgroundColor:"#FFFFFF",
											marginTop:"4px",
											boxShadow:"1px 2px 8px rgba(0, 0, 0, 0.1)"

										}}>
									</div>
								</div>
						)}
							</PlacesAutocomplete>

								{/* <Select
									name='country'
									value={form.country}
									placeholder={form.country || "Country"}
									placeholderStyle={{color:"#56585A", marginLeft: "-15px"}}
									containerStyle={{ width: "96%" }}
									inputStyle={{
										width: "100%",
										borderRadius: "0px",
										borderBottom: errors.country ? "1px solid #FC4848" : "1px solid #96999C",
										boxShadow: "none",
									}}
									options={["US"]}
									selected={form.country}
									onSelect={(selectedState) => {
										handleChange({ name: "country", value: selectedState });
									}}
								/> 
								<span className='description' style={{color: "#FC4848"}}>{errors.country}</span>
								*/}
							</div>
						</div>
						<div className="col-lg-6">
							<div>
								{/* <Select
									name='state'
									value={form.state}
									placeholder={form.state || "State"}
									containerStyle={{ width: "96%" }}
									placeholderStyle={{color:"#56585A", marginLeft: "-15px"}}
									inputStyle={{
										width: "100%",
										borderRadius: "0px",
										borderBottom: errors.state ? "1px solid #FC4848" : "1px solid #96999C",
										boxShadow: "none",
									}}
									options={states}
									selected={form.state}
									onSelect={(selectedState) => {
										handleChange({ name: "state", value: selectedState });
									}}
								/> */}

								<select 
									onChange={(selectedState) => {handleChange({ name: "state", value: selectedState.target.value });}}
									className={"custom-select-div description"}
									value={form.state}	
									placeholder={form.state || "State"}
									style={{
										width: "100%",
										borderRadius: "0px",
										borderBottom: errors.state ? "1px solid #FC4848" : "1px solid #96999C",
										boxShadow: "none",
										outline: "0px"
									}}
								>
									{ states && states.map((state,index) => <option key={index} value={state}>{state}</option>	)}
								</select>

								<span className='description' style={{color: "#FC4848", fontSize: '12px', fontFamily:'Inter'}}>{errors.state}</span>
							</div>
						</div>
					</div>
					<div className="row">

						<div className="col-lg-6">
							<div>
								<input
									type='text'
									placeholder='City'
									label=''
									name='city'
									className="description"
									value={form.city}
									onChange={e => handleChange(e.target)}
									style={{width:"94%", paddingTop:"25px", borderBottom: errors.state ? "1px solid #FC4848" : "", marginBottom:"5px"}}
								/>
								<span className='description' style={{color: "#FC4848"}}>{errors.city}</span>
							</div>
						</div>
						<div className="col-lg-6">
							<div>
								<input
									type='text'
									placeholder='Postal/Zip Code'
									label=''
									name='postal_code'
									className="description"
									value={form.postal_code}
									onChange={e => handleChange(e.target)}
									style={{width:"94%", paddingTop:"25px", borderBottom: errors.postal_code ? "1px solid #FC4848" : "", marginBottom:"5px"}}
								/>
								<span className='description' style={{color: "#FC4848"}}>{errors.postal_code}</span>
							</div>
						</div>
					</div>
					<div style={{marginBottom: "20px"}}>
						<span className="description">You will be rewarded with 10 free assessments on a successfull account completion</span>
					</div>
					<div style={{marginBottom: "20px"}} >
						<div>
							<input type="checkbox" name="agree" onClick={agreeButtonPressed}/>
							<span className="description">&nbsp; The card is not being charged until used at the end of the usage calendar month.</span>
						</div>
						{ !agreeMarked && <span className='description' style={{color: "#FC4848"}}>{"Please agree to terms and conditions."}</span>}
					</div>
					
					<div className="d-flex justify-content-center">
						<Button
							style={{width:"100%", display: "flex", justifyContent:"center"}}
							button
							label='Continue'
							loading={loading}
							onClick={handleSubmit}
						/>
					</div>
				</div>
			</div>

		</div>
	);
}
