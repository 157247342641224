import * as Actions from "../../actions/onboarding/SetupRoles";

const initialState = {
	roleTemplates: [],
	categorySuggestions: [],
	categoryMappings: [],
	alerts: [],
	isSubmitted: false,
	isModifyRequested: false,
	loading: false,
	setupRoleForm: {
		roleName: "",
		selectedRoleId: null,
		roleDetails: "",
		roleTitle: "",
		city: "",
		country: "USA",
		state: "",
		zipCode: "",
		selectedClientId: null,
		hourlyRate: 0,
		salary: 0,
		roleTemplateDetails: "",
		isSetupRoleForm: false,
		isSubmitted: false,
	},
	setupRoleQualificationForm: {
		qualificationMappings: [],
		personalityMappings: [],
		isSubmitted: false,
	},
	setupRolePersonalityForm: {
		sections: [],
		selectedPersonalityIds: [],
		isSubmitted: false,
		isChecked: false,
	},
	setupRoleReferenceForm: {
		isChecked: false,
		selectedReference: 0,
		isSubmitted: false,
		isAutoMail : false ,
	},
	roleTemplateForResult: {
		qualifications: [],
		personalities: [],
	},
	testPacks: [],
	isDashboardSubmitted: false,
	isQualificationSubmitted: false,
	isPersonalitySubmitted: false,
	isReferenceCheckSubmitted: false,
	isRoleSummarySubmitted: false,
};

const reducer = (state = initialState, action) => {
	const data = action.payload;

	switch (action.type) {
		case Actions.SAVE_SETUP_ROLES_FORM:
			return (state = {
				...state,
				setupRoleForm: {
					...state.setupRoleForm,
					isSetupRoleForm: data,
				},
			});

		case Actions.RESET_ROLES_FORM:
			return (state = initialState);

		case Actions.GET_ROLES:
			return (state = {
				...state,
				roleTemplates: data,
			});

		case Actions.HANDLE_SELECT_CHANGE:
			return (state = {
				...state,
				setupRoleForm: {
					...state.setupRoleForm,
					state: data.label,
				},
			});
		case Actions.SET_ROLES_FORM:
			return (state = {
				...state,
				setupRoleForm: {
					...state.setupRoleForm,
					[data.name]: data.value,
				},
			});

		case Actions.SET_ERROR_ALERT:
			return (state = {
				...state,
				alerts: data,
			});

		case Actions.SET_PERSONALITIES_SECTION:
			return (state = {
				...state,
				setupRolePersonalityForm: {
					...state.setupRolePersonalityForm,
					sections: data,
				},
			});

		case Actions.ADD_SELECTED_PERSONALITY:
			return (state = {
				...state,
				setupRolePersonalityForm: {
					...state.setupRolePersonalityForm,
					selectedPersonalityIds: [
						...state.setupRolePersonalityForm.selectedPersonalityIds,
						data,
					],
				},
			});

		case Actions.REMOVE_SELECTED_PERSONALITY:
			return (state = {
				...state,
				setupRolePersonalityForm: {
					...state.setupRolePersonalityForm,
					selectedPersonalityIds:
						state.setupRolePersonalityForm.selectedPersonalityIds.filter(
							(v) => {
								if (v !== data) return v;
							},
						),
				},
			});
		case Actions.TOGGLE_PERSONALITY_IS_CHECKED:
			return (state = {
				...state,
				setupRolePersonalityForm: {
					...state.setupRolePersonalityForm,
					isChecked: !state.setupRolePersonalityForm.isChecked,
				},
			});
		case Actions.SET_NUMBER_OF_REFERENCES:
			return (state = {
				...state,
				setupRoleReferenceForm: {
					...state.setupRoleReferenceForm,
					selectedReference: data,
				},
			});
		case Actions.REMOVE_NUMBER_OF_REFERENCES:
			return (state = {
				...state,
				setupRoleReferenceForm: {
					...state.setupRoleReferenceForm,
					selectedReference: 0,
				},
			});
		case Actions.TOGGLE_REFERENCE_IS_CHECKED:
			return (state = {
				...state,
				setupRoleReferenceForm: {
					...state.setupRoleReferenceForm,
					isChecked: !state.setupRoleReferenceForm.isChecked,
				},
			});
		case Actions.SWITCH_AUTO_MAIL :
			return (state = {
				...state,
				setupRoleReferenceForm: {
					...state.setupRoleReferenceForm,
					isAutoMail: !state.setupRoleReferenceForm.isAutoMail,
				},
			});
		case Actions.GET_SPECIFIC_ROLE_TEMPLATE:
			return (state = {
				...state,
				setupRoleQualificationForm: {
					...state.setupRoleQualificationForm,
					qualificationMappings: data.qualification_mappings,
					personalityMappings: data.personality_mappings,
				},
			});
		case Actions.SET_QUALIFICATION_MAPPINGS_SLIDER:
			return (state = {
				...state,
				setupRoleQualificationForm: {
					...state.setupRoleQualificationForm,
					qualificationMappings:
						state.setupRoleQualificationForm.qualificationMappings.length > 0 &&
						state.setupRoleQualificationForm.qualificationMappings.map((q) => {
							if (q.test_pack_id === data.qualification.value) {
								return {
									test_pack_id: q.test_pack_id,
									test_pack_name: q.test_pack_name,
									test_pack_difficulty: data.difficulty,
								};
							}
							return q;
						}),
				},
			});

		case Actions.SET_QUALIFICATION_MAPPINGS_SELECT:
			return (state = {
				...state,
				setupRoleQualificationForm: {
					...state.setupRoleQualificationForm,
					qualificationMappings:
						state.setupRoleQualificationForm.qualificationMappings.length > 0 &&
						state.setupRoleQualificationForm.qualificationMappings.map((q) => {
							if (q.test_pack_id === data.qualification.value) {
								return {
									...q,
									test_pack_id: data.selected.value,
									test_pack_name: data.selected.label,
								};
							}

							return q;
						}),
				},
			});

		case Actions.ADD_QUALIFICATION_MAPPINGS_SELECT:
			state.setupRoleQualificationForm.qualificationMappings.push({
				test_pack_id: data.selected.value,
				test_pack_name: data.selected.label,
				test_pack_difficulty: 0,
			});
			return (state = {
				...state,
				setupRoleQualificationForm: {
					...state.setupRoleQualificationForm,
					qualificationMappings:
						state.setupRoleQualificationForm.qualificationMappings,
				},
			});

		case Actions.REMOVE_QUALIFICATION_MAPPINGS_SELECT:
			return (state = {
				...state,
				setupRoleQualificationForm: {
					...state.setupRoleQualificationForm,
					qualificationMappings:
						state.setupRoleQualificationForm.qualificationMappings.filter(
							(q) => {
								if (q.test_pack_id === data.value) {
									return;
								}
								return q;
							},
						),
				},
			});

		case Actions.SET_SELECTED_PERSONALITY_IDS:
			return (state = {
				...state,
				setupRolePersonalityForm: {
					...state.setupRolePersonalityForm,
					selectedPersonalityIds: data.map((obj) => {
						return obj.personality_type_id;
					}),
				},
			});

		case Actions.SUBMIT_QUALIFICATION_FORM:
			return (state = {
				...state,
				setupRoleQualificationForm: {
					...state.setupRoleQualificationForm,
					isSubmitted: true,
				},
			});

		case Actions.SUBMIT_PERSONALITY_FORM:
			return (state = {
				...state,
				setupRolePersonalityForm: {
					...state.setupRolePersonalityForm,
					isSubmitted: true,
				},
			});

		case Actions.SUBMIT_REFERENCE_CHECK_FORM:
			return (state = {
				...state,
				setupRoleReferenceForm: {
					...state.setupRoleReferenceForm,
					isSubmitted: true,
				},
			});

		case Actions.ADD_SETUP_ROLE_FORM_START:
			return (state = {
				...state,
				loading: true,
			});

		case Actions.ADD_SETUP_ROLE_FORM_FAILED:
			return (state = {
				...state,
				loading: false,
			});

		case Actions.ADD_SETUP_ROLE_FORM_END:
			return (state = {
				...initialState,
				loading: false,
				isSubmitted: true,
				isModifyRequested: false,
			});

		case Actions.MENU_CLICKED:
			return (state = {
				...state,
				isModifyRequested: true,
			});

		case Actions.ROLE_ALREADY_EXISTS:
			return (state = {
				...initialState,
				isSubmitted: true,
			});

		case Actions.DASHBOARD_ROLE_COMPLETED:
			return (state = {
				...state,
				isDashboardSubmitted: true,
			});

		case Actions.QUALIFICATION_COMPLETED:
			return (state = {
				...state,
				isQualificationSubmitted: true,
			});

		case Actions.PERSONALITY_COMPLETED:
			return (state = {
				...state,
				isPersonalitySubmitted: true,
			});

		case Actions.REFERENCE_CHECK_COMPLETED:
			return (state = {
				...state,
				isReferenceCheckSubmitted: true,
			});

		case Actions.GET_SPECIFIC_ROLE_TEMPLATE_FOR_RESULT:
			return (state = {
				...state,
				roleTemplateForResult: {
					...state.roleTemplateForResult,
					qualifications: data.qualification_mappings,
					personalities: data.personality_mappings,
				},
			});

		case Actions.GET_TEST_PACKS:
			return (state = {
				...state,
				testPacks: data,
			});
		case Actions.GET_CATEGORY_SUGGESTIONS:
			return (state = {
				...state,
				categorySuggestions: data,
			});
		
		case Actions.GET_CATEGORY_MAPPINGS:
			return (state = {
				...state,
				categoryMappings: data,
			});

		case Actions.SET_SETUP_ROLE_FORM_FOR_EDIT:
			return (state = {
				...state,
				setupRoleForm: {
					...state.setupRoleForm,
					roleName: data.positionForEditCriteria.name,
					selectedRoleId: data.roleTemplateForEditCriteria.id,
					roleDetails: data.positionForEditCriteria.role_details,
					roleTitle: data.roleTemplateForEditCriteria.name,
					city: data.positionForEditCriteria.city,
					country: data.positionForEditCriteria.country,
					state: data.positionForEditCriteria.state,
					selectedClientId: data.positionForEditCriteria.client_id,
					hourlyRate: data.positionForEditCriteria.hourly_rate,
					salary: data.positionForEditCriteria.salary,
					zipCode: data.positionForEditCriteria.zip_code,
					roleTemplateDetails: data.roleTemplateForEditCriteria.description,
				},
				setupRoleQualificationForm: {
					...state.setupRoleQualificationForm,
					qualificationMappings:
						data.roleTemplateForEditCriteria.qualification_mappings,
					personalityMappings:
						data.roleTemplateForEditCriteria.personality_mappings,
				},
				setupRolePersonalityForm: {
					...state.setupRolePersonalityForm,
					selectedPersonalityIds:
						data.roleTemplateForEditCriteria.personality_mappings.map((obj) => {
							return obj.personality_type_id;
						}),
				},
				setupRoleReferenceForm: {
					...state.setupRoleReferenceForm,
					selectedReference: data.positionForEditCriteria.reference_count,
					isChecked:
						data.positionForEditCriteria.reference_count > 0 ? true : false,
				},
				isDashboardSubmitted: true,
				isQualificationSubmitted: true,
				isPersonalitySubmitted: true,
				isReferenceCheckSubmitted: true,
				isRoleSummarySubmitted: true,
			});

		case Actions.UPDATE_SETUP_ROLE_FORM_START:
			return (state = {
				...state,
				loading: true,
			});

		case Actions.UPDATE_SETUP_ROLE_FORM_FAILED:
			return (state = {
				...state,
				loading: false,
			});

		case Actions.UPDATE_SETUP_ROLE_FORM_END:
			return (state = {
				...initialState,
				loading: false,
				isSubmitted: true,
				isModifyRequested: false,
			});

		default:
			return state;
	}
};

export default reducer;
