import React from "react";
import {ReactComponent as RnrLogo} from "../../../assets/icons/logo/Header/Header/rnr/logo.svg";
import {ReactComponent as ArrowDown} from "../../../assets/icons/Arrows/chevron-down.svg";
import {Link, useHistory} from "react-router-dom";
import GetStarted from "./GetStarted";

export default function Navbar(props) {

	const history = useHistory();
 
	return(
		<div className="navbar" style={props.style}>
			<div className="navbar-logo pointer" onClick={() => history.push("/")}>
				<RnrLogo/>
			</div>
			<div className="navbar-content">
				<div style={{display:"flex", width:"349px", justifyContent:"space-between", paddingRight:"20px"}}>
					<div className="d-flex" style={{ cursor:"pointer", position:"relative"}} onClick={() => history.push("/features")}>
						<div>
							<span className="b3" style={{color:props.textColor}}>Features</span>
						</div>
						
					</div>
		
					<div onClick={() =>  history.push("/pricing-screen")}>
						<span className="b3 pointer" style={{color:props.pricingColor}}>Pricing</span>
					</div>
					<div className="d-flex" style={{ cursor:"pointer", position:"relative"}} >
						<div>
							<span className="b3">Resources</span>
						</div>
						<div style={{width:"10px", height:"6px"}}>
							<ArrowDown fill="#2B3340"/>
						</div>
					</div>
					<div >
						<Link to="/" style={{textDecoration:"none"}}>
							<span className="b3 pointer">Login</span>
						</Link>
					</div>
				</div>
				<div>
					<GetStarted/>
				</div>
			</div>
		</div>
	);
}


