import React, {useState} from "react";
import Cross from "../../../assets/icons/basic/x.svg";
import Upload from "../../../assets/icons/Arrows/uploadImage.svg";
import Input from "../Input";
import Button from "../Button";
import { useDispatch, useSelector } from "react-redux";
import * as addClientActions from "../../../redux/actions/onboarding/Clients";

export default function AddClientsModal(props) {

	const dispatch = useDispatch();

	const [image, setImage] = useState(null);
	const [imageFile, setImageFile] = useState("");
	const [name, setName] = useState("");

	const fileInput = React.useRef(null);

	const handleClick = () => {
		fileInput.current.click();
	};

	const clientId = useSelector(state => state.clients.clientId);	
	// const allClients = useSelector((state) => state.clients.allClients);
	const loading = useSelector((state) => state.clients.addClientsLoading);

	const fileSelectedHandler = (event) => {
		setImage(URL.createObjectURL(event.target.files[0]));
		setImageFile(event.target.files[0]);
	};

	const handleChange = (event) => {
		setName(event.target.value);
		
	};

	

	const handleAddClient = () => {
		dispatch(addClientActions.addClient(name,imageFile));
		
		
		if(!loading) {
			props.onClick(); }
	};

	const handleUpdateClient = () => {
		dispatch(addClientActions.updateClient(clientId ,name, imageFile));
		
		if(loading === false) {
			props.onCross(); }
	};

	

	return (
		<div className='invite-modal'>
			<div className='invite-modal-container d-flex flex-column'>
				<div
					className='d-flex justify-content-end pt-3 pr-0'
					onClick={props.edittable ? props.onCross :props.onClick}>
					<img src={Cross} alt='' style={{ cursor: "pointer" }} />
				</div>
				<div className="d-flex flex-column align-items-center" style={{paddingInline:"20px"}}>
					<div onClick={handleClick} style={{width:"124px", height:"124px", borderRadius:"12px", backgroundColor:"#F4F6F6"}} className="d-flex flex-column align-items-center justify-content-center mb-4 pointer">
						<div>
							{
								image ? 
									<img
										src={image}
										alt=""
										style={{width:"39px", height:"39px"}}
									/>
									:
									<img
										src={Upload}
										alt=""
										style={{width:"39px", height:"39px"}}
									/>
							}
						
						</div>
						<input
							style={{ display: "none" }}
							type='file'
							onChange={fileSelectedHandler}
							ref={fileInput}></input>
						<div>
							{image ? <span className="description">Change logo</span> :<span className="description">Upload logo</span>}
						</div>
					</div>
					<Input
						label="Client Name"
						style={{width:"100%"}}
						inputStyle={{border:"2px solid #EAEDEE",height:"49px" }}
						onChange={handleChange}
						value={name}
                        
					/>
					{
						props.edittable ? 
							<div className="mt-3">
								<Button
									button 
									loading={loading}
									label="Update Client"
									style={{padding:"13px 29px"}}
									onClick={handleUpdateClient}
								/>
							</div>
							:
							<div className="mt-3">
								<Button
									button 
									loading={loading}
									label="Add Client"
									style={{padding:"13px 29px"}}
									onClick={handleAddClient}
								/>
							</div>

					}
					
				</div>
			</div>
		</div>
	);
}
