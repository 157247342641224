import React, { useEffect } from "react";
import Slider from "../../../subcomponents/Slider";
import Button from "../../../subcomponents/Button";
import Select from "../../../subcomponents/Select";
import HelpIconComponent from "../../../HelpIconComponent";
import { useSelector, useDispatch } from "react-redux";
import * as setupRoleActions from "../../../../redux/actions/onboarding/SetupRoles";
import { useHistory } from "react-router-dom";
import { ReactComponent as CrossSvg } from "../../../../assets/icons/basic/red-x.svg";

export default function Qualification(props) {
	const dispatch = useDispatch();

	const history = useHistory();

	const setupRoleForm = useSelector((state) => state.setupRoles.setupRoleForm);
	const testPacks = useSelector((state) => state.setupRoles.testPacks);
	const { qualificationMappings } = useSelector(
		(state) => state.setupRoles.setupRoleQualificationForm,
	);
	const isQualificationSubmitted = useSelector(
		(state) => state.setupRoles.isQualificationSubmitted,
	);
	const roleTemplateForCriteria = useSelector(
		(state) => state.clientFilter.roleTemplateForCriteria,
	);
	const isEditRole = useSelector((state) => state.clientFilter.isEditRole);

	const qualificationsForSelect = [];
	for (let index = 0; index < qualificationMappings.length + 1; index++) {
		if (index < qualificationMappings.length) {
			qualificationsForSelect.push({
				label: qualificationMappings[index]?.test_pack_name,
				value: qualificationMappings[index]?.test_pack_id,
				testPackDifficulty: qualificationMappings[index]?.test_pack_difficulty,
			});
		} else {
			if (!props.completed) {
				qualificationsForSelect.push({
					label: "",
					value: undefined,
					testPackDifficulty: 0,
				});
			}
		}
	}

	const testPacksForSelect = [];
	testPacks.map((testPack) => {
		if (qualificationsForSelect.find((q) => q.value === testPack.id)) {
			//
		} else {
			testPacksForSelect.push({
				value: testPack.id,
				label: testPack.name,
				...testPack,
			});
		}
	});

	const handleSliderChange = (difficulty, qualification) => {
		if (qualification?.value !== undefined) {
			dispatch(
				setupRoleActions.setQualificationMappingsSlider({
					difficulty,
					qualification,
				}),
			);
		}
	};

	const handleSelectChange = (selected, qualification) => {
		if (qualification.value === undefined && selected.value !== undefined) {
			dispatch(
				setupRoleActions.addQualificationMappingsSelect({
					selected,
					qualification,
				}),
			);
		} else {
			dispatch(
				setupRoleActions.setQualificationMappingsSelect({
					selected,
					qualification,
				}),
			);
		}
	};

	const handleCrossClicked = (qualification) => {
		dispatch(setupRoleActions.removeQualificationMappingsSelect(qualification));
	};

	const handleSubmit = () => {
		dispatch(setupRoleActions.submitQualificationForm());

		if (props.dashboard) {
			dispatch(setupRoleActions.qualificationCompleted());
			history.push("/dashboard/create-new-position/personality-assessment");
		} else {
			props.history.push("/onboarding/setupRoles/personality");
		}
	};

	useEffect(() => {
		if (!props.completed && !isQualificationSubmitted && !isEditRole) {
			dispatch(setupRoleActions.getRoleTemplate(setupRoleForm.selectedRoleId));
		}
	}, [dispatch, setupRoleForm, isEditRole]);

	useEffect(() => {
		if (!props.completed) {
			dispatch(setupRoleActions.saveSetupRoleForm(false));
			dispatch(setupRoleActions.getTestPacks());
		}
	}, [dispatch]);

	useEffect(() => {
		if (props.criteria && roleTemplateForCriteria?.id && !isEditRole) {
			dispatch(setupRoleActions.getRoleTemplate(roleTemplateForCriteria.id));
		}
	}, [dispatch, roleTemplateForCriteria, isEditRole]);

	return (
		<>
			{props.completed ? (
				props.criteria ? (
					<div style={{ marginBottom: "20px" }} className='d-flex headline3'>
						{"Qualification"}
					</div>
				) : null
			) : (
				<div className='d-flex justify-content-between'>
					<div style={{ marginBottom: "20px" }} className='headline1'>
						{props.dashboard ? "Qualification" : "Setup Role"}
					</div>

					<div style={{margin:"20px 0px 0px 10px"}}>
						<span className='headline2'>Difficulty</span>
					</div>
					<div>
						<HelpIconComponent />
					</div>
				</div>
			)}

			{!props.dashboard && 
			<div className="w-100 d-flex justify-content-between">
				<div className='headline3'>Qualification</div>
				<div className="w-50 mt-3">
					<span>Difficulty Level</span>
				</div>
			</div>
			}
			{qualificationsForSelect.map((qualification, key) => {
				return (
					<form key={key}>
					<div
						style={{ marginBottom: "20px" }}
						className='row w-100 align-items-center'>
						<div className='col-6'>
							<Select
								placeholder='Select '
								options={testPacksForSelect}
								agency
								agencySetup
								qualifications
								updateSelections={(selected) =>
									handleSelectChange(selected, qualification)
								}
								selected={qualification}
								disable={props.completed}
							/>
						</div>
						{ qualification.value !== undefined && 
						<div className='col-6 d-flex align-items-center' >
							<div> 
								<input type="radio" disabled={props.completed} checked={qualification.testPackDifficulty == 1} value={1} id={`beginner-${qualification.value}`} onChange={(event) => handleSliderChange(1, qualification)} name={qualification.value} style={{ marginRight: "10px" }}/>
								<label htmlFor={`beginner-${qualification.value}`} style={{marginRight: "10px"}}>Beginner </label>
							</div>
							<div>
								<input type="radio" disabled={props.completed} checked={qualification.testPackDifficulty == 3} value={3} id={`intermediate-${qualification.value}`}  onChange={(event) => handleSliderChange(3, qualification)} name={qualification.value} style={{ marginRight: "10px" }}/>
								<label htmlFor={`intermediate-${qualification.value}`} style={{marginRight: "10px"}}>Intermediate</label>
							</div>
							<div>
								<input type="radio" disabled={props.completed} checked={qualification.testPackDifficulty == 5} value={5} id={`advanced-${qualification.value}`}  onChange={(event) => handleSliderChange(5, qualification)} name={qualification.value} style={{ marginRight: "10px" }}/>
								<label htmlFor={`advanced-${qualification.value}`} style={{marginRight: "10px"}}>Advanced</label>
							</div>
							
							{/* <Slider
								style={{ width: "94%" }}
								min={0}
								max={5}
								step={1}
								defaultValue={qualification.testPackDifficulty}
								onChange={(value) => handleSliderChange(value, qualification)}
								disable={props.completed}
							/>
							 */}
							{!props.completed && qualification.value !== undefined && (
								<CrossSvg
									onClick={() => handleCrossClicked(qualification)}
									style={{ marginLeft: "5%", cursor:"pointer" }}
								/>
							)}
						</div>
						}
					</div>
					</form>
				);
			})}
			{props.completed ? null : (
				<div
					className='d-flex justify-content-center'
					style={{ paddingTop: "40px" }}>
					<Button
						rightArrow
						label='Next'
						textClass='b2'
						className='button-large'
						style={{ padding: "6.5px 69px" }}
						onClick={handleSubmit}
					/>
				</div>
			)}
		</>
	);
}
