import React from "react";
import { Container, Row, Col } from "react-bootstrap";

export default function Features(props) {
	return (
		<div style={props.style}>
			<Container
				style={{
					paddingTop: "64px",
				}}
			>
				<Row
					className="justify-content-center"
					style={{ paddingBottom: "50px" }}
				>
					<Col xs = {0} sm={3} />
					<Col xs={12} sm={6}>
						<span className={props.className || "hero2"} style={{color:props.color}}>{props.title || "Features"}</span>
						<p className="p1" style={{color:props.color}}>
							{props.text || "Lorem ipsum is common placeholder text used to demonstrate the graphic elements of a document or visual presentation."}
						</p>
					</Col>
					<Col xs={0} sm={3} />
				</Row>

				<Row className="justify-content-center">
					{props.features.map((data,index)=>
						<Col key={index} xs={12} md={6} lg={4} style={Styles.Feature_Item}>
							<div>
								<img src={data.logo} style={{marginBottom:"25px"}}/>
								<div>
									<span className="headline1" style={{color:props.color}}>{data.name}</span>
								</div>
								<div>
									<span className="p1" style={{color:props.color}}>
										{data.details}
									</span>
								</div>
							</div>
						</Col>
					)}
				</Row>
			</Container>
		</div>
	);
}

const Styles = {
	Feature_Item: {
		marginBottom: "65px",
	},
};
