import React, { useState } from "react";
import bitmoji from "../../assets/images/dashboard/bitmoji.svg";
import image from "../../assets/images/dashboard/img0.png";
import img1 from "../../assets/images/dashboard/img1.png";
import img2 from "../../assets/images/dashboard/img2.png";
import img3 from "../../assets/images/dashboard/img3.png";
import img4 from "../../assets/images/dashboard/img4.png";
import img5 from "../../assets/images/dashboard/img5.png";
import img6 from "../../assets/images/dashboard/img6.png";
import img7 from "../../assets/images/dashboard/img7.png";
import img8 from "../../assets/images/dashboard/img8.png";
import img9 from "../../assets/images/dashboard/img9.png";
import img10 from "../../assets/images/dashboard/img10.png";
import Bitmoji from "../../assets/icons/emojis/Bitmoji.png";
import Button from "../subcomponents/Button";
import { useSelector } from "react-redux";

const Turtorial = (props) => {

	const slides = [
		{
			id: 0 , image:img1 , description:" First, let’s get you acquainted with the dashboard where you will be taking your assessment."
		},
		{
			id: 1 , image:img2 , description:" This is your navigation menu. You can use this to access different pages."
		},
		{
			id: 2 , image:img3 , description:" On the right are your interview details. Here, you can find details for the position you are interviewing for, your test progress, and other details."
		},
		{
			id: 3 , image:img4 , description:" Here is where you will answer all questions in the assessment. After answering, press submit to submit your answer."
		},
		{
			id: 4 , image:img5 , description:" Note that each question has a timer. Make sure to submit your answer before your time runs out."
		},
		{
			id: 5 , image:img6 , description:" In your assessments, you will mainly be answering 4 types of questions."
		},
		{
			id: 6 , image:img7 , description:" The first type are Multiple Choice questions. To answer these questions, choose one answer from up to 4 different possible options."
		},
		{
			id: 7 , image:img8 , description:" The second type are Written Response questions. For these, you will be required to write multple sentences and paragraph answers."
		},
		{
			id: 8 , image:img9 , description:" The third are coding questions. Here you will be given a prompt, and you will have to write code to execute what the prompt requires."
		},
		{
			id: 9 , image:img10 , description:" The fourth type are Video Response questions. These will require you to record a video of yourself using your computer’s camera to answer the question."
		},

	];

	const loading = useSelector((state) => state.candidateTesting.getNextLoading);

	const [turtorial , setTurtorial] = useState(slides[0]);
	
	const [lastSlide , setLastSlide] = useState(false) ;
	

	const handleNext = (i) => {
		// console.log("handle next start with index" , i );
	
		if(slides[i+1] ){
			
			setTurtorial(slides[i+1]);
		}
		else
		{
			setLastSlide(true);
		}
	};
	const handleBack = (i) => {
		// console.log("handle back start with index" , i );

		if(slides[i-1] ){
		
			setTurtorial(slides[i-1]);
		}
	};
	return(
		<div>
			{lastSlide ? 
				<div>
					<div style={{marginTop:"-50px"}}>
						<img src={Bitmoji} alt='' className='bitmoji-size' />
					</div>
					<div
						style={{
							width: "100%",
							height: "auto",
							minHeight: "130px",
							backgroundColor: "white",
							borderRadius: "12px",
							padding: "20px",
							margin: "25px 0px 30px 0px",
						}} >
						<span className='headline1 '>
							That is it for now. Good luck for your assessment, and if you have any further questions, you can access this tutorial from your dashboard.
						</span> 
					</div>
					<div className="d-flex align-items-center justify-content-center">
						<Button
							onClick={props.startTest}
							button
							label='Start Assessment'
							style={{ padding: "13px 23px" }}
							loading={loading}
						/>
					</div>
					<div 	onClick={props.startTest} className="d-flex justify-content-end align-items-center" >
						<span  className="b3 " style={{color:"#2AA9D2" , cursor:"pointer" , marginTop:"10px"}}>  Skip Turtorial</span>
					</div>

				</div> 
				:
				<div className=' flex-column justify-content-center align-items-center' style={{marginTop:"-50px" }}>
					<div  className="d-flex justify-content-start align-items-center"
						style={{ width:"100%" , marginBottom:"20px"}} >
						<div>
							<img src={bitmoji}  />
						</div>
						<div
							style={{
								width: "100%",
								height: "auto",
								minHeight: "100px",
								backgroundColor: "white",
								borderRadius: "12px",
								padding: "5px",
								margin: "0px 0px 0px 30px",
								display:"flex",
								alignItems:"center"
							}}
						>
							<span className="headline3">
								{turtorial.description}
							</span>
						</div>
					</div>
				
					<div style={{width:"100%"}}>
						<img src={turtorial.image}  alt={image} style={{width:"100%"}}/>
					</div>
					<div className="d-flex align-items-center justify-content-center" style={{marginTop:"30px"}}>
						<div>
							<Button
								onClick={()=> handleBack(turtorial.id)}
								button
								label='Back'
								style={{ padding: "13px 33px" , border:"1px solid #00B4D8" , backgroundColor:"transparent" }}
								textStyle={{color:"#2B3340"}}
								textClass="b1"
								
							/>
						</div>
						<div style={{marginLeft:"20px"}} >
							<Button
								rightArrow
								onClick={()=> handleNext(turtorial.id) }
								label='Next'
								style={{ padding: "13px 33px" , marginLeft:"20px" }}
								textClass="b1"
								
							/>
						</div>
					</div>
					<div className="d-flex justify-content-end align-items-center" style={{ width:"100%"}} >
						<span  onClick={props.startTest} className="b3 " style={{color:"#2AA9D2" , cursor:"pointer" , marginTop:"10px"}}>  Skip Turtorial</span>
					</div>
				</div>
			}

		</div>
		
	);
};
export default Turtorial ;