import React, { useEffect } from "react";
import { ReactComponent as Delete } from "../../../assets/icons/basic/Delete.svg";
import * as candidateTestingActions from "../../../redux/actions/Candidate/candidate-testing.actions";
import { useDispatch, useSelector } from "react-redux";
import SearchInput from "../../subcomponents/dashboard/SearchInput";

export default function Invited(props) {
	const dispatch = useDispatch();

	const invitedUsers = useSelector(
		(state) => state.candidateTesting.invitedUsers,
	);

	console.log(invitedUsers);

	console.log(props.assessmentIds,"assa");

	useEffect(() => {
		if (props.invitedUserIds.length > 0) {
			dispatch(candidateTestingActions.getInvitedUsers(props.invitedUserIds));
		} else {
			dispatch(candidateTestingActions.resetInvitedusers());
		}
	}, [dispatch, props.invitedUserIds]);


	// const emails = ["asdaas@asd.com","asdaas@asd.com","asdaas@asd.com","asdaas@asd.com"];
		
	
	
	return (
		<div>
			{/* <div
				className='w-100 d-flex'
				style={{
					borderBottom: "1px solid #C8CBCD",
					padding: "20px 0px 5px 5px",
				}}>
				{invitedUsers.length === 1 && (
					<span className='description'>Email</span>
				)}
				{invitedUsers.length > 1 && <span className='description'>Emails</span>}
			</div> */}
			<div className="" style={{marginTop:"16px"}}>
				<SearchInput candidateSearch style={{backgroundColor:"#F4F6F6", width:"100%", height:"40px"}}/>
			</div>
			{
				props.assessmentIds && props.assessmentIds.map((id, key) => {
					return (
						<div key={key} >
							<div
								className='w-100 d-flex justify-content-between align-items-center on-hover pointer'
								style={{
									border: "2px solid #EAEDEE",
									borderRadius:"12px",
									padding: "20px 20px 20px 25px",
									margin:" 10px 0px"
								}}>
								<span className='description'>{invitedUsers[key]?.email}</span>
								<div className='pointer' onClick={() => dispatch(candidateTestingActions.deleteAssessment(id))}>
									<Delete />
								</div>
							</div>
							
						</div>
					);
				})
			}
		
			
				
			
			
		</div>
	);
}
