import React, { useState, useEffect } from "react";
import Invited from "./Invited";
import Completed from "./Completed";
import { useDispatch, useSelector } from "react-redux";
import * as candidateTestingActions from "../../../redux/actions/Candidate/candidate-testing.actions";
import assessmentStatus from "../../../utilities/Constants";
import Shortlisted from "./Shortlisted";

export default function CandidateListTable(props) {
	const dispatch = useDispatch();

	const assessmentSessions = useSelector(
		(state) => state.candidateTesting.assessmentSessions,
	);

	const positionForCandidateRightPanel = useSelector(
		(state) => state.clientFilter.positionForCandidateRightPanel,
	);

	const invitedUserIds = [];
	const assessmentIds = [];
	const completedAssessments = [];
	const shortlistedAssessments = [];
	assessmentSessions.map((assessment) => {
		if (
			assessment.status === assessmentStatus.INITIALIZED ||
			assessment.status === assessmentStatus.IN_PROGRESS
		) {
			invitedUserIds.push(assessment.user_id);
			assessmentIds.push(assessment._id);
		} else if (
			assessment.status === assessmentStatus.UNDER_EVALUATION ||
			assessment.status === assessmentStatus.FINISHED ||
			assessment.status === assessmentStatus.NOT_CONSIDERED
		) {
			completedAssessments.push(assessment);
		} else if (assessment.status === assessmentStatus.SHORTLISTED) {
			shortlistedAssessments.push(assessment);
		}
	});



	const [invited, setInvited] = useState(true);
	const [completed, setCompleted] = useState(false);
	const [shortlisted, setShortlisted] = useState(false);

	const handleInvited = () => {
		setInvited(true);
		setCompleted(false);
		setShortlisted(false);
	};

	const handleCompleted = () => {
		setInvited(false);
		setCompleted(true);
		setShortlisted(false);
	};

	const handleShortlisted = () => {
		setInvited(false);
		setCompleted(false);
		setShortlisted(true);
	};

	useEffect(() => {
		if (positionForCandidateRightPanel?.id) {
			dispatch(
				candidateTestingActions.getAssessmentSessions(
					positionForCandidateRightPanel.id,
				),
			);
		}
	}, [
		dispatch,
		positionForCandidateRightPanel,
		invited,
		completed,
		shortlisted,
	]);

	return (
		<div className='candidate-table invisible-scrollbar'>
			<div className='d-flex  justify-content-center w-100'>
				<div onClick={handleInvited} style={{ marginRight: "24px", backgroundColor: invited ? "#00B4D8" : "#F4F6F6", borderRadius:"8px", padding:"13px 30px" }}>
					<span
						style={{ color: invited ? "#FFFFFF" : "#3E454D" }}
						className="b1 pointer">
						Invited
					</span>
				</div>
				<div onClick={handleCompleted} style={{ marginRight: "24px", backgroundColor: completed ? "#00B4D8" : "#F4F6F6", borderRadius:"8px", padding:"13px 12px"  }}>
					<span
						style={{ color: completed ? "#FFFFFF" : "#3E454D" }}
						className="b1 pointer">
						Completed
					</span>
				</div>
				<div onClick={handleShortlisted} style={{   backgroundColor: shortlisted ? "#00B4D8" : "#F4F6F6", borderRadius:"8px", padding:"13px 13px"   }}>
					<span
						style={{ color: shortlisted ? "#FFFFFF" : "#3E454D" }}
						className="b1 pointer">
						Shortlisted
					</span>
				</div>
			</div>
			<div>
				{invited && (
					<div>
						<Invited invitedUserIds={invitedUserIds} assessmentIds={assessmentIds} />
					</div>
				)}
				{completed && (
					<div>
						<Completed
							considered
							onClick={props.onClick}
							web={props.web}
							completedAssessments={completedAssessments}
						/>
					</div>
				)}
				{shortlisted && (
					<div>
						<Shortlisted 
							onClick={props.onClick}
							shortlistedAssessments={shortlistedAssessments} />
					</div>
				)}
			</div>
		</div>
	);
}
