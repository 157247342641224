import React, { useState } from "react";
import Button from "../../components/subcomponents/Button";
import Input from "../../components/subcomponents/Input";
// import Select from "../../components/subcomponents/Select";
import Select from "react-select";
// import CustomCheckBox from "../../components/CustomCheckBox";
import { useSelector, useDispatch } from "react-redux";
import * as formStatusActions from "../../redux/actions/onboarding/formsStatus";
import BlankPicture from "../../assets/icons/Userpic/userprofile.svg";

export default function UserSettings(props) {

	const states = [
		{
			"label": "Alabama",
			"value": "AL"
		},	
		{
			"label": "Alaska",
			"value": "AK"
		},
		{
			"label": "American Samoa",
			"value": "AS"
		},
		{
			"label": "Arizona",
			"value": "AZ"
		},
		{
			"label": "Arkansas",
			"value": "AR"
		},
		{
			"label": "California",
			"value": "CA"
		},
		{
			"label": "Colorado",
			"value": "CO"
		},
		{
			"label": "Connecticut",
			"value": "CT"
		},
		{
			"label": "Delaware",
			"value": "DE"
		},
		{
			"label": "District Of Columbia",
			"value": "DC"
		},
		{
			"label": "Federated States Of Micronesia",
			"value": "FM"
		},
		{
			"label": "Florida",
			"value": "FL"
		},
		{
			"label": "Georgia",
			"value": "GA"
		},
		{
			"label": "Guam",
			"value": "GU"
		},
		{
			"label": "Hawaii",
			"value": "HI"
		},
		{
			"label": "Idaho",
			"value": "ID"
		},
		{
			"label": "Illinois",
			"value": "IL"
		},
		{
			"label": "Indiana",
			"value": "IN"
		},
		{
			"label": "Iowa",
			"value": "IA"
		},
		{
			"label": "Kansas",
			"value": "KS"
		},
		{
			"label": "Kentucky",
			"value": "KY"
		},
		{
			"label": "Louisiana",
			"value": "LA"
		},
		{
			"label": "Maine",
			"value": "ME"
		},
		{
			"label": "Marshall Islands",
			"value": "MH"
		},
		{
			"label": "Maryland",
			"value": "MD"
		},
		{
			"label": "Massachusetts",
			"value": "MA"
		},
		{
			"label": "Michigan",
			"value": "MI"
		},
		{
			"label": "Minnesota",
			"value": "MN"
		},
		{
			"label": "Mississippi",
			"value": "MS"
		},
		{
			"label": "Missouri",
			"value": "MO"
		},
		{
			"label": "Montana",
			"value": "MT"
		},
		{
			"label": "Nebraska",
			"value": "NE"
		},
		{
			"label": "Nevada",
			"value": "NV"
		},
		{
			"label": "New Hampshire",
			"value": "NH"
		},
		{
			"label": "New Jersey",
			"value": "NJ"
		},
		{
			"label": "New Mexico",
			"value": "NM"
		},
		{
			"label": "New York",
			"value": "NY"
		},
		{
			"label": "North Carolina",
			"value": "NC"
		},
		{
			"label": "North Dakota",
			"value": "ND"
		},
		{
			"label": "Northern Mariana Islands",
			"value": "MP"
		},
		{
			"label": "Ohio",
			"value": "OH"
		},
		{
			"label": "Oklahoma",
			"value": "OK"
		},
		{
			"label": "Oregon",
			"value": "OR"
		},
		{
			"label": "Palau",
			"value": "PW"
		},
		{
			"label": "Pennsylvania",
			"value": "PA"
		},
		{
			"label": "Puerto Rico",
			"value": "PR"
		},
		{
			"label": "Rhode Island",
			"value": "RI"
		},
		{
			"label": "South Carolina",
			"value": "SC"
		},
		{
			"label": "South Dakota",
			"value": "SD"
		},
		{
			"label": "Tennessee",
			"value": "TN"
		},
		{
			"label": "Texas",
			"value": "TX"
		},
		{
			"label": "Utah",
			"value": "UT"
		},
		{
			"label": "Vermont",
			"value": "VT"
		},
		{
			"label": "Virgin Islands",
			"value": "VI"
		},
		{
			"label": "Virginia",
			"value": "VA"
		},
		{
			"label": "Washington",
			"value": "WA"
		},
		{
			"label": "West Virginia",
			"value": "WV"
		},
		{
			"label": "Wisconsin",
			"value": "WI"
		},
		{
			"label": "Wyoming",
			"value": "WY"
		}
	];

	const dispatch = useDispatch();

	const userProfile = useSelector((state) => state.formsStatus.userProfile);
	
	const form = useSelector((state) => state.formsStatus.updateProfileForm);

	console.log(form, "formstatus");
	const loading = useSelector((state) => state.formsStatus.loading);

	// console.log(form,"formuser");
	// const [checked, setChecked] = useState(false);
	// const [checked2, setChecked2] = useState(false);
	// const [checked3, setChecked3] = useState(false);

	const [activeforEdit, setActiveforEdit] = useState(false);

	// const handleToggle = () => {
	// 	setChecked(!checked);
	// };
	// const handleCheck2 = () => {
	// 	setChecked2(!checked2);
	// };

	// const handleCheck3 = () => {
	// 	setChecked3(!checked3);
	// };
	// const notifications = [
	// 	"Send Email Notifications",
	// 	"Do not send email notifications",
	// ];

	const editProfile = () => {
		setActiveforEdit(!activeforEdit);
	};

	const handleChange = (e) => {
		dispatch(formStatusActions.handleEditProfileChange(e));
	};

	const handleUploadUserLogo = (logo) => {
		dispatch(
			formStatusActions.uploadUserLogo(userProfile.user_id, logo, editProfile),
		);
	};

	return (
		<div className='d-flex flex-column align-items-start'>
			<span className='b2'>My Profile</span>
			{/* {props.mobile ? ( */}
			{/* <div className='d-flex w-100 justify-content-between align-items-center'>
					<div className='d-flex align-items-center'>
						<div style={{ marginTop: "28px" }}>
							<img
								src={Image}
								alt=''
								style={{ width: "150px", height: "150px" }}
							/>
						</div>
					</div>
					<div className='d-flex flex-column align-items-center'>
						{activeforEdit && (
							<div>
								<Button
									whiteButton
									textClass='b3'
									className='white-button-lg'
									label='Replace Avatar'
									style={{ padding: "9.5px 27.5px" }}
								/>
							</div>
						)}
						{activeforEdit ? (
							<div style={{ paddingTop: "40px" }}>
								<Button
									button
									label='Update'
									textClass='b2'
									style={{ padding: "6.5px 69px" }}
									onClick={editProfile}
								/>
							</div>
						) : (
							<div style={{ paddingTop: "40px" }}>
								<Button
									button
									label='Edit Profile'
									textClass='b2'
									style={{ padding: "6.5px 69px" }}
									onClick={editProfile}
								/>
							</div>
						)}
					</div>
				</div>
			) : (
				<div className='d-flex w-100 justify-content-between align-items-center'>
					<div className='d-flex align-items-center'>
						<div style={{ marginTop: "28px" }}>
							<img
								src={Image}
								alt=''
								style={{ width: "150px", height: "150px" }}
							/>
						</div>
						{activeforEdit && (
							<div style={{ marginLeft: "40px" }}>
								<Button
									whiteButton
									textClass='b2'
									label='Replace avatar'
									style={{ padding: "9.5px 27.5px" }}
								/>
							</div>
						)} */}
			<div className='d-flex w-100  align-items-center'>
				<div className='d-flex align-items-center justify-content-between w-100'>
					<div style={{ marginTop: "28px", marginRight: "20px" }}>
						<img
							src={userProfile?.image_url || BlankPicture}
							alt=''
							style={{ width: "150px", height: "150px", borderRadius:userProfile?.image_url ? "50%" : "0%"  }}
						/>
					</div>
					{activeforEdit ? (
						<div
							className={
								props.mobile
									? "d-flex flex-column"
									: "d-flex justify-content-between w-100"
							}>
							<div style={{ paddingTop: "40px" }}>
								<Button
									whiteButton
									textClass='b1'
									label='Replace avatar'
									onClick={() => {
										document.getElementById("userProfileUploadImage").click();
									}}
									style={{ padding: "6.5px 23.5px" }}
								/>
								<input
									id='userProfileUploadImage'
									type='file'
									style={{ display: "none" }}
									onChange={(e) => handleUploadUserLogo(e.target.files[0])}
								/>
							</div>
							<div style={{ paddingTop: "40px" }}>
								<Button
									button
									label='Update'
									textClass='b1'
									style={{ padding: "6.5px 54px" }}
									loading={loading}
									onClick={() => {
										dispatch(
											formStatusActions.updateUserProfile(
												userProfile.user_id,
												form,
												editProfile,
											),
										);
									}}
								/>
							</div>
						</div>
					) : (
						<div style={{ paddingTop: "40px" }}>
							<Button
								button
								label='Edit Profile'
								textClass='b1'
								style={{ padding: "6.5px 40px" }}
								onClick={editProfile}
							/>
						</div>
					)}
					{/* {activeforEdit ? (
						<div>
							<Button
								button
								label='Update'
								textClass='b1'
								style={{ padding: "6.5px 69px" }}
								onClick={editProfile}
							/>
						</div>
					) : (
						<div style={{ paddingTop: "40px" }}>
							<Button
								button
								// label='Edit Profile'
								textClass='b2'
								label='Replace avatar'
								onClick={() => {
									document.getElementById("userProfileUploadImage").click();
								}}
								style={{ padding: "9.5px 27.5px" }}
							/>
							<input
								id='userProfileUploadImage'
								type='file'
								style={{ display: "none" }}
								onChange={(e) => handleUploadUserLogo(e.target.files[0])}
							/>
						</div>
					)} */}
				</div>
			</div>
			<div
				className='w-100'
				style={{ borderBottom: "1px solid #96999C", paddingBottom: "40px" }}>
				<div className='w-100 d-flex justify-content-between'>
					<Input
						name='firstName'
						label='First Name'
						value={form?.firstName}
						readOnly={!activeforEdit}
						style={{ width: "48%", marginTop: "20px" }}
						onChange={(e) => handleChange(e.target)}
					/>
					<Input
						name='lastName'
						label='Last Name'
						value={form?.lastName}
						readOnly={!activeforEdit}
						style={{ width: "48%", marginTop: "20px" }}
						onChange={(e) => handleChange(e.target)}
					/>
				</div>
				<div className='w-100 d-flex justify-content-between'>
					<Input
						name='city'
						label='City'
						value={form?.city}
						readOnly={!activeforEdit}
						style={{ width: "48%", marginTop: "20px" }}
						onChange={(e) => handleChange(e.target)}
					/>
					<Input
						name='country'
						label='Country'
						value={form?.country}
						readOnly={!activeforEdit}
						style={{ width: "48%", marginTop: "20px" }}
						onChange={(e) => handleChange(e.target)}
					/>
				</div>
				<div className='w-100 d-flex justify-content-between'>
					<div
						className='d-flex flex-column align-items-start'
						style={{ width: "48%", marginTop: "20px" }}>
						<div style={{ marginBottom: "-5px" }}>
							<span className='p2'>State</span>
						
						</div>
						<Select
							options={states}
							styles={{width:"100%", border:"none"}}
							name="state"
							placeholder={form.state || "States"}
							onChange={(selectedState) => {
								handleChange( selectedState );
							}}
							isDisabled={!activeforEdit}
						/>
						{/* <Select
							name='state'
							value={form?.state}
							placeholder={form?.state || "States"}
							containerStyle={{ width: "48%" }}
							disable={!activeforEdit}
							inputStyle={{
								width: "100%",
								border: "none",
								boxShadow: "none",
							}}
							options={states}
							selected={form?.state}
							onSelect={(selectedState) => {
								handleChange({ name: "state", value: selectedState });
							}}
						/> */}
					
					</div>
					<Input
						name='zipCode'
						label='Zip Code'
						value={form?.zipCode}
						readOnly={!activeforEdit}
						style={{ width: "48%", marginTop: "20px" }}
						onChange={(e) => handleChange(e.target)}
					/>
				</div>
			</div>
			{/* <div
				style={{ paddingTop: "40px" }}
				className='d-flex flex-column align-items-start'>
				<span className='b2'>Notifications</span>
				<div style={{ paddingTop: "15px" }}>
					<span>{"Email notifications for candidate's activity"} </span>
				</div>
				<div
					className='d-flex flex-column align-items-start'
					style={{ width: "100%", marginTop: "10px" }}>
					<Select
						name='state'
						placeholder=' '
						containerStyle={{ width: "96%" }}
						options={notifications}
					/>
				</div>
				<div style={{ paddingTop: "10px" }}>
					<span className='description'>
						Get email notifications for candidate activity when:{" "}
					</span>
					<div style={{ paddingTop: "10px" }}>
						<CustomCheckBox
							toggle={handleToggle}
							value={checked}
							label='Candidate complete test'
						/>
					</div>
					<div style={{ paddingTop: "10px" }}>
						<CustomCheckBox
							toggle={handleCheck2}
							value={checked2}
							label='Candidate matches given criteria'
						/>
					</div>
					<div style={{ paddingTop: "10px" }}>
						<CustomCheckBox
							toggle={handleCheck3}
							value={checked3}
							label='Candidate accepts invitation'
						/>
					</div>
				</div>
				<div style={{ paddingTop: "40px" }}>
					<Button
						button
						label='Save Changes'
						textClass='b2'
						style={{ padding: "6.5px 41.5px" }}
					/>
				</div>
			</div> */}
		</div>
	);
}
