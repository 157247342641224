import * as Actions from "../../actions/onboarding/Clients";

const initialState = {
	hasClients: false,
	isSubmitted: false,
	isModifyRequested: false,
	addClientsLoading: false,
	allClients: [],
	clientId: null,
};

const reducer = (state = initialState, action) => {
	const data = action.payload;

	switch (action.type) {
		case Actions.SET_HAS_CLIENTS: {
			return (state = {
				...state,
				hasClients: data,
			});
		}

		case Actions.SET_CLIENT_ID: {
			return (state = {
				...state,
				clientId: data,
			});
		}

		case Actions.GET_TENANT:
			return (state = {
				...state,
				hasClients: data.has_clients,
			});

		case Actions.SKIP_CLIENTS:
			return (state = {
				...state,
				isSubmitted: true,
				isModifyRequested: false,
			});

		case Actions.MENU_CLICKED:
			return (state = {
				...state,
				isModifyRequested: true,
			});

		case Actions.GET_ALL_CLIENTS:
			return (state = {
				...state,
				allClients: data,
				isSubmitted: data.length > 0 ? true : false,
			});

		case Actions.ADD_CLIENT_START:
			return (state = {
				...state,
				addClientsLoading: true,
			});

		case Actions.ADD_CLIENT_END:
			return (state = {
				...state,
				addClientsLoading: false,
				allClients: [
					...state.allClients,
					{ id: data._id, name: data.name, logo: data.logo },
				],
			});

		case Actions.CLIENT_DELETED_START:
			return (state = {
				...state,
				addClientsLoading: true,
			});

		case Actions.CLIENT_ADD_UPDATE_DELETED_FAILED:
			return (state = {
				...state,
				addClientsLoading: false,
			});

		case Actions.CLIENT_DELETED_END:
			return (state = {
				...state,
				addClientsLoading: false,
				allClients: state.allClients.filter((client) => {
					if (client.id === data) {
						return;
					}
					return client;
				}),
			});

		case Actions.SUBMIT_CLIENTS:
			return (state = {
				...state,
				isSubmitted: true,
				isModifyRequested: false,
			});

		case Actions.CLIENT_UPDATED_START:
			return (state = {
				...state,
				addClientsLoading: true,
			});

		case Actions.CLIENT_UPDATED_END:
			return (state = {
				...state,
				addClientsLoading: false,
				allClients: state.allClients.filter((client) => {
					if (client.id === data._id) {
						if (data.name) {
							client.name = data.name;
						}
						if (data.logo) {
							client.logo = data.logo;
						}
						return client;
					}
					return client;
				}),
			});

		default:
			return state;
	}
};

export default reducer;
