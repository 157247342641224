import React, { useEffect, useState } from "react";
import CustomCheckBox from "../../subcomponents/Checkbox";
import Button from "../../subcomponents/Button";
// import Table from "../../subcomponents/Table/Tabel";
import * as addClientActions from "../../../redux/actions/onboarding/Clients";
import { useDispatch, useSelector } from "react-redux";
import HelpIconComponent from "../../HelpIconComponent";
import { Redirect } from "react-router-dom";
import { Fragment } from "react";
import AddClientsModal from "../../subcomponents/onboarding/AddClientsModal";
import { ReactComponent as Delete } from "../../../assets/icons/basic/Delete.svg";
import {ReactComponent as Edit} from "../../../assets/images/dashboard/pencilEdit.svg";


function AddClients(props) {

	const dispatch = useDispatch();

	const [openAddClientModal, setOpenAddClientModal] = useState(false);
	const [editClientModal, setEditClientModal] = useState(false);

	const hasClients = useSelector((state) => state.clients.hasClients);
	const isSubmitted = useSelector((state) => state.clients.isSubmitted);
	const isModifyRequested = useSelector(
		(state) => state.clients.isModifyRequested,
	);
	const loading = useSelector((state) => state.clients.addClientsLoading);
	const user = useSelector((state) => state.user.user);
	const allClients = useSelector((state) => state.clients.allClients);
	
	const companySetupIsSubmitted = useSelector(
		(state) => state.companySetup.isSubmitted,
	);
	// const rows = [];
	// allClients.map((client) => {
	// 	rows.push([client.id, client.name, client.logo]);
	// });

	// const clientsTable = ["Company Name", "Company Logo"];

	const handleClientModal = () => {
		setOpenAddClientModal(!openAddClientModal);
	};

	const Checked = () => {
		if (allClients.length < 1) {
			dispatch(addClientActions.hasClients({ hasClients: !hasClients, user }));
		} else {
			alert("You must have to delete all clients first");
		}
	};

	// const getUpdatedRows = () => {
	// 	// console.log("rows", rows);
	// };

	// const getUpdatedRow = (row) => {
	// 	if (rows.length - allClients.length === 1) {
	// 		if (row[1]) {
	// 			dispatch(addClientActions.addClient(row));
	// 		}
	// 	} else if (rows.length - allClients.length === -1 && row[1]) {
	// 		dispatch(addClientActions.deleteClient(row));
	// 	} else if (rows.length - allClients.length === 0 && row[1]) {
	// 		dispatch(addClientActions.updateClient(row));
	// 	}
	// };

	// const handleDelete = (id) => {
	// 	allClients.map(client => {
	// 		client.i
	// 	})
	// };

	const handleSkipButton = () => {
		dispatch(addClientActions.skipClients());
	};

	const handleNextButton = () => {
		if (allClients.length < 1) {
			alert("You must have to add atleast one client");
		} else {
			dispatch(addClientActions.submitClients());
		}
	};

	useEffect(() => {
		dispatch(addClientActions.getTenant(user));
	}, [dispatch, user]);

	useEffect(() => {
		if (hasClients) {
			dispatch(addClientActions.getAllClients());
		}
	}, [dispatch, hasClients, user]);

	if (props.dashboard) {
		//
	} else {
		if (!companySetupIsSubmitted) {
			return <Redirect to='/onboarding/companySetup' />;
		} else if (isSubmitted && !isModifyRequested) {
			return <Redirect to='/onboarding/setupRoles' />;
		}
	}

	return (
		<div>
			{!props.dashboard && (
				<Fragment>
					{props.mobileView ? (
						<div className='d-flex justify-content-end mr-3'>
							<HelpIconComponent />
						</div>
					) : (
						<div className='d-flex justify-content-between'>
							<div style={{ marginBottom: "20px" }} className='headline1'>
								Add Clients
							</div>
							<div>
								<HelpIconComponent />
							</div>
						</div>
					)}

					<CustomCheckBox
						value={hasClients}
						toggle={Checked}
						label='We recruit for our clients as well'
					/>
				</Fragment>
			)}

			{props.dashboard && (
				<Fragment>
					<CustomCheckBox
						value={hasClients}
						toggle={Checked}
						label='We recruit for our clients as well'
					/>
				</Fragment>
			)}

			{hasClients ? (
				<div>
					
					<div className="d-flex flex-column">

						<div className="d-flex w-100 justify-content-between">
							<div style={{ margin: "25px 0px 10px 0px" }}>
								<span className='headline3'>Connect your Clients</span>
								{allClients.length < 1 && <div className="d-flex mt-3">
									<span className="p2">No clients connected</span>
								</div>}
							</div>
							<div>
								<Button
									onClick={handleClientModal}
									button
									className='button-large'
									label='+ Connect New Client'
									style={{ padding: "5px 22px" }}
								/>
							</div>
						</div>
						<div>
							{
								allClients.map((client, index) => {
									return (
										<div key={index} className="position-card pointer d-flex align-items-center" style={{marginTop:"20px", paddingInline:"30px"}}>
											<div className="d-flex align-items-center">
												<div style={{marginRight:"60px"}}>
													<img
														src={client.logo}
														alt=""
														style={{width:"36px", height:"36px"}}
													/>
												</div>
												<span>{client.name}</span>
													
											</div>
											<div className="d-flex">
												<div>
													<Edit onClick={() => { 
														dispatch(addClientActions.setClientId(client.id));
														setEditClientModal(true);
													}
													}/>
												</div>
												<div style={{marginLeft:"20px"}} onClick={() => dispatch(addClientActions.deleteClient(client.id))}>
													<Delete/>
												</div>
											</div>
										</div>
									);
								})

									
							}
						</div>
					</div>


					
					{
						editClientModal && <AddClientsModal edittable onCross={() => setEditClientModal(false)}/>
					}
					{
						openAddClientModal && <AddClientsModal onClick={handleClientModal}/>
					}


					
					{/* // <Table
					// 	activeImageInput={[{ col: 2, data: null }]}
					// 	isEditable
					// 	headers={clientsTable}
					// 	rows={rows}
					// 	updateTable={getUpdatedRows}
					// 	updatedRow={getUpdatedRow}
					// 	activeSelectInput={[]}
					// 	activeMultiSelectInput={[]}
					// /> */}

					{props.dashboard ? (
						<div
							className='d-flex justify-content-center'>
							{/* <Button
								button
								loading={loading}
								label='Update'
								textClass='b2'
								className='button-large'
								style={{ padding: "6.5px 69px" }}
								onClick={handleNextButton}
							/> */}
						</div>
					) : (
						<div
							className='d-flex justify-content-center'
							style={{ paddingTop: "40px" }}>
							<Button
								rightArrow
								loading={loading}
								label='Next'
								textClass='b2'
								className='button-large'
								style={{ padding: "6.5px 69px" }}
								onClick={handleNextButton}
							/>
						</div>
					)}
				</div>
			) : (
				<div style={{ paddingTop: "40px" }}>
					{!props.dashboard && (
						<Button
							whiteButton
							className='white-button-sm'
							label='Skip'
							onClick={() => handleSkipButton()}
						/>
					)}
				</div>
			)}
		</div>
	);
}

export default AddClients;
