import React, { useEffect, useState } from "react";
import Upload from "../../../assets/icons/Arrows/upload.svg";

export default function ImageUploader(props) {
	const [image, setImage] = useState(null);
	const [imageFile, setImageFile] = useState(null);

	const fileInput = React.useRef(null);

	const handleClick = () => {
		fileInput.current.click();
	};

	const fileSelectedHandler = (event) => {
		setImage(URL.createObjectURL(event.target.files[0]));
		setImageFile(event.target.files[0]);
		console.log(event.target.files[0]);
		// props.getImage(image);
	};

	useEffect(() => {
		if (props.image) {
			setImage(props.image);
		}
	}, [props.image]);

	useEffect(() => {
		const tempObj = {
			image: image,
			imageFor: props.imageFor,
			imageFile: imageFile,
		};
		props.getImage(tempObj);
	}, [image, imageFile]);

	useEffect(() => {
		if(props.row.length === 0) {
			setImage(null);
		}
	}, [props.row]);

	return (
		<div>
			<div onClick={handleClick}>
				<div
					className='d-flex justify-content-center align-items-center'
					style={{ width: "27px", height: "27px", borderRadius: "50%" }}>
					<img
						id={props.id}
						src={image ? image : Upload}
						alt='Upload'
						style={{
							width: image ? "27px" : "15px",
							height: image ? "27px" : "15px",
							borderRadius: image ? "50%" : "0%",
						}}
					/>
				</div>
			</div>
			<input
				style={{ display: "none" }}
				type='file'
				onChange={fileSelectedHandler}
				ref={fileInput}></input>
		</div>
	);
}
