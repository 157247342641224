import React,{useState} from "react";
import {ReactComponent as Add} from "../../assets/icons/basic/addFaq.svg";
import {ReactComponent as Minus} from "../../assets/icons/basic/minimize.svg";
export default function FAQCard(props) {

	const [open, setOpen] = useState(false);

	const onClick = () => {
		setOpen(!open);
		props.onToggle(open);
	};

	return (
		<div className="faq-card" style={{...props.style, backgroundColor: props.features ? (open ? "#00B4D8" :"#EAEDEE") : (open ? "#F4F6F6" :"#2B3340"),  minHeight: props.features ? open ? "212px": "124px" : open ? "300px" : "176px"}}>
			<div className="d-flex flex-column">
				<div className="d-flex">
					<span className="question-span" style={{color: props.features ? open ? "white" : "black" : open ? "black" : "white"}}>Q. {props.question}</span>
				</div>
				{open && <div className="d-flex mt-2"><span className="question-span" style={{color: props.features ? open ? "white" : "black" : open ? "black" : "white"}}>Ans. {props.answer}</span>
				</div>
				}
			</div>
			<div style={{paddingLeft:"50px"}} onClick={onClick}>
				{
					open ? <Minus stroke={props.features ? "white" :"black"}/> :<Add stroke={props.features ? "black" :"white"} />}
			</div>
		</div>
	);
}
