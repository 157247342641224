import React from "react";
import SubscriptionCard from "../../../../components/subcomponents/dashboard/SubscriptionCard";
import Button from "../../../../components/subcomponents/Button";

export default function SubscriptionPlan(){

	const plans = [
		{plan:"Basic", description:"", price:"10"},
		{plan:"Popular", description:"", price:"20"},
		{plan:"Enterprise", description:"", price:"30"}
	];
	return(
		<div>
			{
				plans.map((plan, index) =>{
					return(
						<div key={index}>
							<SubscriptionCard
								plan={plan.plan}
								description={plan.description}
								price={plan.price}
							/>
						</div>
					);
				})
			}
			<div
				className='d-flex justify-content-center'
				style={{ paddingTop: "20px" }}>
				<Button
					button
					label='Continue'
					textClass='b2'
					className='button-large'
					style={{ padding: "6.5px 63px" }}
				/>
			</div>
			
		</div>
	);
}