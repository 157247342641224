import React, { useState } from "react";
import Button from "../subcomponents/Button";
import RightCandidateHeader from "../subcomponents/dashboard/RightCandidateHeader";
import RoleDetailSummary from "../subcomponents/dashboard/RoleDetailSummary";
import CandidateListTable from "./CandidateListTable/CandidateListTable";
import { useSelector, useDispatch } from "react-redux";
import Modal from "../subcomponents/dashboard/Modal";
import AssessmentInviteModal from "./CandidateDetails/AssessmentInviteModal";
import share from "../../assets/icons/basic/Share.svg";
import * as inviteCandidateActions from "../../redux/actions/onboarding/inviteCandidate";

export default function CandidateRightPanel(props) {
	const dispatch = useDispatch();
	
	const [isModelOpen, setIsModalOpen] = useState(false);
	const [isShareModal , setShareModal] = useState(false);

	const getSubscriptions = useSelector(state => state.user.isTenantSubscribed);

	const handleClick = () => {
		if(getSubscriptions?.data[0]?.status !== "active"){
			alert("Your payment is pending, Please clear your dues to invite candidate");
		}
		else{
			setIsModalOpen(true);
		}
		
	};

	const handleShareModal = () => {
		
		setShareModal(!isShareModal);
	};

	

	const position = useSelector(
		(state) => state.clientFilter.positionForCandidateRightPanel,
	);

	const handleDeactivatePosition = () => {
		dispatch(inviteCandidateActions.deactivatePosition(position.id));
		props.onCross();
	};

	const handleActivatePosition = () => {
		dispatch(inviteCandidateActions.activatePosition(position.id));
		props.onCross();
	};

	return (
		<div className='candidate-right-header'>
			{isModelOpen && (
				<Modal positionId={position.id} onClick={() => setIsModalOpen(false)}  />
			)}
			{!props.selected && (
				<RightCandidateHeader
					onClick={props.onClick}
					isExtended={props.isExtended}
					onCross={props.onCross}
					mobile={props.mobile}
				/>
			)}
			<div className='d-flex flex-column  align-items-start'>
				<div className='d-flex justify-content-between w-100'>
					<div
						className='d-flex flex-column align-items-start'
						style={{ paddingRight: "20px", width:"55%"}}>
						<span className='headline2'>Role Name</span>
						
						<span className='headline1 text-justify'>{position?.title}</span>
						
					</div>
					<div style={{ display: props.isExtended ? "flex" : "block" }}>
						<div
							style={{
								paddingBottom: "10px",
								paddingRight: props.isExtended ? "10px" : "0px",
							}}>
							{position?.is_active && (
								<Button
									dashboardbtn
									style={{ backgroundColor: "#E0FAFF" }}
									textStyle={{ color: "#00B4D8" }}
									label='Invite Candidate'
									onClick={handleClick}
								/>
							)}
						</div>

						{!position?.is_active ? (
							<div>
								<Button
									dashboardbtn
									style={{
										backgroundColor: "#E0FFF6",
										padding: "2px 42px",
									}}
									textStyle={{ color: "#00D69A" }}
									label='Activate'
									onClick={handleActivatePosition}
								/>
							</div>
						) : (
							<div>
								<Button
									dashboardbtn
									style={{ backgroundColor: "#FFE1E1", padding: "2.5px 31px" }}
									textStyle={{ color: "#FC4848" }}
									label='Deactivate'
									onClick={handleDeactivatePosition}
								/>
							</div>
						)}
					</div>
				</div>
			</div>
			<div >
				<div className="d-flex align-items-center justify-content-end "
					
				>
					<div className="d-flex align-items-center justify-content-between"
						style={{
					
							backgroundColor:"#00B4D8" ,
							borderRadius:"50px" ,
							padding:"5px 15px",
							marginBottom:"10px",
							marginTop:"15px",
							cursor:"pointer"

						}}
						onClick={handleShareModal}
					>
						<span className="p1" style={{color:"#FFFFFF" , marginRight:"5px"}}> Share Assessment Invite </span>
						<img src={share} />
					</div>
				</div>
				<div>
					{isShareModal && <AssessmentInviteModal report roleId = {position.id}  setShareModal= {setShareModal}/> }

				</div>
			</div>
			<RoleDetailSummary onClick={props.documentClick} />
			<div className='w-100'>
				<CandidateListTable
					extended={props.isExtended}
					onClick={props.onRowClick}
					web={props.web}
				/>
			</div>

		</div>
	);
}
