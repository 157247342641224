/* eslint-disable no-mixed-spaces-and-tabs */
import React, { useState } from "react";
import Bitmoji from "../../../assets/icons/emojis/Bitmoji.png";
import ReactPlayer from "react-player";
import Timer from "react-compound-timer";
import { useDispatch, useSelector } from "react-redux";
import * as candidateTestingActions from "../../../redux/actions/Candidate/candidate-testing.actions";

export default function QuestionBox(props) {
	const dispatch = useDispatch();

	const [currentTime, setCurrentTime] = useState(null);

	const timeOut = useSelector((state) => state.candidateTesting.timeOut);
	const answerSubmitted = useSelector(
		(state) => state.candidateTesting.answerSubmitted,
	);
	const timerReset = useSelector((state) => state.candidateTesting.timerReset);

	const handleTimerStopped = () => {
		dispatch(candidateTestingActions.setTimerReset(true));
	};

	return (
		<div className='d-flex flex-column align-items-start'>
			{props.mobile ? (
				<div className='d-flex flex-column align-items-center'>
					<div
						className='d-flex justify-content-between '
						style={{
							width: "180px",
							height: "30px",

							backgroundColor: "white",
							borderRadius: "12px",
							padding: "5px 15px",
							marginTop: "10px",
						}}>
						<span className='description'>Time Remaining</span>
						<span className='description'>
							{!timeOut && !timerReset ? (
								<Timer
									formatValue={(value) => value.toString().padStart(2, "0")}
									direction='backward'
									initialTime={
										props.testPack.duration.minutes * 60000 +
										props.testPack.duration.seconds * 1000
									}
									onStop={handleTimerStopped}
									checkpoints={[
										{
											time: 0,
											callback: () =>
												dispatch(candidateTestingActions.setTimeOut(true)),
										},
									]}>
									{({ stop, getTime }) => {
										setCurrentTime(getTime());
										answerSubmitted && stop();
										return (
											<div>
												<Timer.Minutes /> : <Timer.Seconds />
											</div>
										);
									}}
								</Timer>
							) : (
								<div>
									{!timeOut
										? Math.floor(currentTime / 60000)
											.toString()
											.padStart(2, "0") +
										  " : " +
										  ((currentTime % 60000) / 1000)
										  	.toFixed(0)
										  	.toString()
										  	.padStart(2, "0")
										: "00 : 00"}
								</div>
							)}
						</span>
					</div>
					<div className='d-flex align-items-center'>
						<div>
							<img
								src={props.bitmoji || Bitmoji}
								alt=''
								style={{ width: "45px", height: "45px" }}
							/>
						</div>

						<div
							className='d-flex justify-content-start align-items-center'
							style={{
								width: "334px",
								height: "auto",
								minHeight: "50px",
								borderRadius: "12px",
								padding: "0px 5px",
								backgroundColor: "rgba(24, 116, 195, 0.2)",
								borderTopLeftRadius: "0px",
								margin: "10px 0px 10px 10px",
								...props.style,
							}}>
							<span className='description text-justify'>
								{`Q. ${props.testPack.problem_statement.title}`}
							</span>
						</div>
					</div>
				</div>
			) : (
				<div className='d-flex'>
					<div>
						<img
							src={props.bitmoji || Bitmoji}
							alt=''
							style={{ width: "60px", height: "60px" }}
						/>
					</div>

					<div
						className='d-flex justify-content-start align-items-center'
						style={{
							width: "344px",
							height: "auto",
							minHeight: "50px",
							borderRadius: "12px",
							padding: "0px 25px",
							backgroundColor: "rgba(24, 116, 195, 0.2)",
							borderTopLeftRadius: "0px",
							margin: "10px 20px",
							...props.style,
						}}>
						<span className='p1'>
							{`Q. ${props.testPack.problem_statement.title}`}
						</span>
					</div>

					<div
						className='d-flex flex-column'
						style={{
							width: "132px",
							height: "auto",
							maxHeight:"80px",
							minHeight: "67px",
							backgroundColor: "white",
							borderRadius: "12px",
							padding: "5px 8px",
							marginTop: "10px",
						}}>
						<span className='p1'>Time Remaining</span>
						<span className='headline3'>
							{!timeOut && !timerReset ? (
								<Timer
									formatValue={(value) => value.toString().padStart(2, "0")}
									direction='backward'
									initialTime={
										props.testPack.duration.minutes * 60000 +
										props.testPack.duration.seconds * 1000
									}
									onStop={handleTimerStopped}
									checkpoints={[
										{
											time: 0,
											callback: () =>
												dispatch(candidateTestingActions.setTimeOut(true)),
										},
									]}>
									{({ stop, getTime }) => {
										setCurrentTime(getTime());
										answerSubmitted && stop();
										return (
											<div>
												<Timer.Minutes /> : <Timer.Seconds />
											</div>
										);
									}}
								</Timer>
							) : (
								<div>
									{!timeOut
										? Math.floor(currentTime / 60000)
											.toString()
											.padStart(2, "0") +
										  " : " +
										  ((currentTime % 60000) / 1000)
										  	.toFixed(0)
										  	.toString()
										  	.padStart(2, "0")
										: "00 : 00"}
								</div>
							)}
						</span>
					</div>
				</div>
			)}
			{props.testPack.problem_statement.type === "image" && (
				<div className='container no-gutters pr-0 pl-0'>
					<div
						className='row'
						style={{
							padding: props.mobile ? "40px 0px 0px 50px" : "40px 0px 0px 80px",
						}}>
						{props.testPack.problem_statement.img_url.map((image, key) => {
							return (
								<div
									key={key}
									className='col-xs-12'
									style={{ margin: "10px 30px", maxWidth: props.mobile ? "" : "600px", maxHeight: props.mobile ? "" : "400px" }}>
									<img
										src={image}
										alt=''
										style={{ width: "100%", height: "100%", objectFit: "contain" }}

									/>
								</div>
							);
						})}
					</div>
				</div>
			)}
			{props.testPack.problem_statement.type === "video" && (
				<div
					style={{
						padding: props.mobile ? "40px 0px 0px 50px" : "40px 0px 0px 80px",
					}}>
					<ReactPlayer
						controls
						url={props.testPack.problemStatement.video_url}
					/>
				</div>
			)}
		</div>
	);
}
