import { secure_instance } from "../../../api/axios/axios-config";

export const GET_ALL_NOTIFICATIONS = "[Notification] GET_ALL_NOTIFICATIONS";
export const STATUS_UPDATED = "[Notification] STATUS_UPDATED";
export const MARK_ALL_READ = "[Notification] MARK_ALL_READ";

export const getAllNotifications = () => {
	return async (dispatch) => {
		try {
			const request = await secure_instance.request({
				url: "v1/notifications",
				method: "Get",
			});

			dispatch({
				type: GET_ALL_NOTIFICATIONS,
				payload: request.data,
			});
		} catch (e) {
			//
		}
	};
};

export const changeStatusToRead = (id) => {
	return async (dispatch) => {
		try {
			const request = await secure_instance.request({
				url: "v1/notifications/change-status",
				method: "Patch",
				data: {
					id,
				},
			});

			dispatch({
				type: STATUS_UPDATED,
				payload: request.data,
			});
		} catch (e) {
			//
		}
	};
};

export const markAllAsRead = () => {
	return async (dispatch) => {
		try {
			await secure_instance.request({
				url: "v1/notifications/mark-all",
				method: "Patch",
			});

			dispatch({
				type: MARK_ALL_READ,
			});
		} catch (e) {
			//
		}
	};
};
