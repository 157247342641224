import { secure_instance } from "../../../api/axios/axios-config";

export const GET_ACCESS_ROLES = "[Team Setup] GET_ACCESS_ROLES";
export const GET_ALL_CLIENTS = "[Team Setup] GET_ALL_CLIENTS";
export const SET_TEAM_SETUP_TABLE = "[Team Setup] SET_TEAM_SETUP_TABLE";
export const ADD_TEAM_MEMBER_START = "[Team Setup] ADD_TEAM_MEMBER_START";
export const ADD_TEAM_MEMBER_END = "[Team Setup] ADD_TEAM_MEMBER_END";
export const ADD_TEAM_MEMBER_FAILED = "[Team Setup] ADD_TEAM_MEMBER_FAILED";
export const DELETE_TEAM_MEMBER_START = "[Team Setup] DELETE_TEAM_MEMBER_START";
export const DELETE_TEAM_MEMBER_END = "[Team Setup] DELETE_TEAM_MEMBER_END";
export const DELETE_TEAM_MEMBER_FAILED =
	"[Team Setup] DELETE_TEAM_MEMBER_FAILED";
export const UPDATE_TEAM_MEMBER_START = "[Team Setup] UPDATE_TEAM_MEMBER_START";
export const UPDATE_TEAM_MEMBER_END = "[Team Setup] UPDATE_TEAM_MEMBER_END";
export const UPDATE_TEAM_MEMBER_FAILED =
	"[Team Setup] UPDATE_TEAM_MEMBER_FAILED";
export const GET_TEAM_MEMBERS = "[Team Setup] GET_TEAM_MEMBERS";

export const getAccessRoles = () => {
	return async (dispatch) => {
		try {
			// const request = await secure_instance.request({
			// 	url: "v1/roles/",
			// 	method: "Get",
			// });

			dispatch({
				type: GET_ACCESS_ROLES,
				// payload: request.data,
			});
		} catch (e) {
			//
		}
	};
};

export const getAllClients = () => {
	return async (dispatch) => {
		try {
			const request = await secure_instance.request({
				url: "v1/tenant_clients/",
				method: "Get",
			});

			dispatch({
				type: GET_ALL_CLIENTS,
				payload: request.data,
			});
		} catch (e) {
			//
		}
	};
};

export const setTeamSetupTable = (rows) => {
	return {
		type: SET_TEAM_SETUP_TABLE,
		payload: rows,
	};
};

export const addTeamMember = (row) => {
	return async (dispatch) => {
		const tenantClientIds = [];

		row[3]?.map((client) => {
			tenantClientIds.push({ client_id: client.id });
		});

		try {
			dispatch({
				type: ADD_TEAM_MEMBER_START,
			});

			const request = await secure_instance.request({
				url: `v1/user/?invite_team_member=${true}`,
				method: "Post",
				data: {
					email: row[1],
					role_id: row[2].id,
					tenant_client_ids: tenantClientIds,
				},
			});

			dispatch({
				type: ADD_TEAM_MEMBER_END,
				payload: { data: request.data, email: row[1] },
			});
		} catch (e) {
			alert("Team member already exists!");
			dispatch({
				type: ADD_TEAM_MEMBER_FAILED,
			});
		}
	};
};

export const deleteTeamMember = (row) => {
	return async (dispatch) => {
		try {
			dispatch({
				type: DELETE_TEAM_MEMBER_START,
			});

			await secure_instance.request({
				url: `v1/user/${row[0]}?delete_team_member=${true}`,
				method: "Delete",
			});

			dispatch({
				type: DELETE_TEAM_MEMBER_START,
			});
		} catch (e) {
			dispatch({
				type: DELETE_TEAM_MEMBER_FAILED,
			});
		}
	};
};

export const updateTeamMember = (row) => {
	return async (dispatch) => {
		const tenantClientIds = [];

		row[3]?.map((client) => {
			tenantClientIds.push({ client_id: client.id });
		});

		try {
			dispatch({
				type: UPDATE_TEAM_MEMBER_START,
			});

			await secure_instance.request({
				url: `v1/user/${row[0]}?update_team_member=${true}`,
				method: "Patch",
				data: {
					email: row[1],
					role_id: row[2].id,
					tenant_client_ids: tenantClientIds,
				},
			});

			dispatch({
				type: UPDATE_TEAM_MEMBER_END,
			});
		} catch (e) {
			dispatch({
				type: UPDATE_TEAM_MEMBER_FAILED,
			});
		}
	};
};

export const onBoardingCompleted = (user) => {
	return async () => {
		try {
			await secure_instance.request({
				url: `v1/user_profile/onboarding-completed/${user.userId}`,
				method: "Patch",
			});
		} catch (e) {
			//
		}
	};
};

export const getTeamMembers = () => {
	return async (dispatch) => {
		try {
			const request = await secure_instance.request({
				url: "v1/role_assignments/team-members",
				method: "Get",
			});

			dispatch({
				type: GET_TEAM_MEMBERS,
				payload: request.data,
			});
		} catch (e) {
			//
		}
	};
};
