import React, { useState, useEffect } from "react";
import { Fragment } from "react";
import ReactRoundedImage from "react-rounded-image";
import { ReactComponent as Add } from "../../assets/images/dashboard/AddFilter.svg";
import Tag from "./Tag";

/**
 * Select Component
 * @param {object} props Form Select Component
 * @param {Function} updateSelections Update Selected States which Send back the selected option To The Parent(or where you call it)
 * @param {String} varient should be either 'multi-select' or null (for single selection)
 * @param {Array} options array of string (options)
 * @param {Array} selected array of selected option. Pass it an array in which you want to save options
 * [IMPORTANT] *********** This Component accept only options as object array with three attributes label, value and image. Image attribute is optional.
 */

export default function Select(props) {
	const [isOpen, setIsOpen] = useState(false);
	const [selected, setSelected] = useState(props.selected || null);

	useEffect(() => {
		// console.log(selected, "Selected Items");
		if (typeof props.updateSelections === "function") {
			props.updateSelections(selected);
		}
	}, [selected]);

	const optionSelected = (option) => {
		if (props.varient === "multi-select") {
			setSelected([...selected, option]);
		} else {
			// var dummyArr = [option];
			setSelected(option);
		}
		setIsOpen(!isOpen);
	};

	const removeTag = (selectedTag) => {
		setSelected(selected.filter((item) => item != selectedTag));
	};

	return (
		<div
			className='d-flex flex-column w-100 align-items-start position-relative'
			style={{ ...props.style, outline: "none" }}
			tabIndex='0'
			onBlur={() => {
				setIsOpen(false);
			}}>
			<span className='p2'>{props.title}</span>
			<div
				className={props.mobile ? "filter-input w-100" : "filter-input"}
				style={props.inputStyle}>
				{props.varient === "multi-select" ? (
					<div></div>
				) : (
					<div>
						{selected === null ? (
							<span className='description'>{props.placeholder || ""}</span>
						) : (
							<div>
								{props.agency ? (
									<div className='d-flex align-items-center'>
										{!props.agencySetup && (
											<>
												<span className='description'>{selected.value}</span>
												<div
													style={{
														width: "4px",
														height: "4px",
														marginLeft: "10px",
														borderRadius: "50%",
														backgroundColor: "#00B4D8",
													}}></div>
											</>
										)}
										<div style={{ marginLeft: "5px" }}>
											{selected?.icon && (
												<ReactRoundedImage
													image={selected.icon}
													roundedSize='0'
													imageWidth='20'
													imageHeight='20'
													roundedColor='white'
												/>
											)}
										</div>
										{props.agencySetup ? (
											<span
												className='description'
												style={{ padding: "0px 5px" }}>
												{selected?.label}
											</span>
										) : (
											<span
												className='description'
												style={{ color: "#96999C", padding: "0px 5px" }}>
												{selected?.label}
											</span>
										)}
									</div>
								) : (
									<span className='description'>{selected}</span>
								)}
							</div>
						)}
					</div>
				)}
				{!props.disable && (
					<Add
						onClick={props.disable ? () => {} : () => setIsOpen(!isOpen)}
						className='pointer'
					/>
				)}
			</div>
			{props.varient === "multi-select" && (
				<div className='d-flex flex-wrap' style={{ marginTop: "10px" }}>
					{selected &&
						selected.map((selection, index) => {
							return (
								<div
									key={index}
									style={{ marginLeft: "5px", marginBottom: "5px" }}>
									<Tag
										title={selection}
										onClick={() => {
											removeTag(selection);
										}}
									/>
								</div>
							);
						})}
				</div>
			)}

			{isOpen && (
				<div
					className=' filter-option-container invisible-scrollbar'
					style={{ position: "absolute", marginTop: "2.80em" }}>
					{props.options &&
						props.options.map((option, key) => {
							return (
								<Fragment key={key}>
									{props.varient === "multi-select" ? (
										!selected.includes(option) && (
											<div
												className='custom-select-option'
												onClick={() => {
													optionSelected(option);
													if (props.onSelect) {
														props.onSelect(option);
													}
												}}>
												<span className='description'>{option}</span>
											</div>
										)
									) : (
										<div
											className='custom-select-option'
											onClick={
												props.disable
													? () => {}
													: () => {
														if (props.agency) {
															optionSelected({
																value: option.value,
																icon: option.icon,
																label: option.label,
															});
															if (props.onSelect) {
																props.onSelect(option);
															}
														} else {
															optionSelected(option);
															if (props.onSelect) {
																props.onSelect(option);
															}
														}
														// eslint-disable-next-line no-mixed-spaces-and-tabs
													  }
											}>
											{props.agency ? (
												<div className='d-flex align-items-center'>
													{!props.agencySetup && (
														<>
															<span className='description'>{option}</span>
															<div
																style={{
																	width: "4px",
																	height: "4px",
																	marginLeft: "10px",
																	borderRadius: "50%",
																	backgroundColor: "#00B4D8",
																}}></div>
														</>
													)}
													<div style={{ marginLeft: "5px" }} key={key}>
														{option.icon && (
															<ReactRoundedImage
																image={option.icon}
																roundedSize='0'
																imageWidth='20'
																imageHeight='20'
																roundedColor='white'
															/>
														)}
													</div>
													{props.agencySetup ? (
														<span
															className='description'
															style={{ padding: "0px 5px" }}>
															{option.label}
														</span>
													) : (
														<span
															className='description'
															style={{ color: "#96999C", padding: "0px 5px" }}>
															{option.label}
														</span>
													)}
												</div>
											) : (
												<span className='description'>
													<input
														style={{ display: "none" }}
														name={props.name}
													/>
													{option}
												</span>
											)}
										</div>
									)}
								</Fragment>
							);
						})}
				</div>
			)}
		</div>
	);
}
