import React from "react";
import Button from "../../subcomponents/Button";
import { ReactComponent as Location } from "../../../assets/icons/basic/pin.svg";
import Input from "../../subcomponents/Input";
import { useSelector, useDispatch } from "react-redux";
import * as candidateTestingActions from "../../../redux/actions/Candidate/candidate-testing.actions";
import assessmentStatus from "../../../utilities/Constants";

export default function CandidateTestDetails(props) {
	const dispatch = useDispatch();

	const pendingTestRightPanel = useSelector(
		(state) => state.candidateTesting.pendingTestRightPanel,
	);

	return (
		<div>
			<div className='d-flex justify-content-between'>
				<div className='d-flex flex-column align-items-start justify-content-center'>
					<span className='headline1'>{pendingTestRightPanel?.roleName}</span>

					<span className='p2 text-justify'>
						{pendingTestRightPanel?.tenantName}
					</span>
					<div className='d-flex' style={{ paddingTop: "3px" }}>
						<Location style={{ marginRight: "3px" }} />
						<span className='description'>
							{pendingTestRightPanel?.tenantState || "state"},{" "}
						</span>
						<span className='description'>
							{pendingTestRightPanel?.tenantCountry || "country"}
						</span>
					</div>
				</div>
				<div
					className='d-flex flex-column align-items-center'
					style={{ width: "30%" }}>
					{pendingTestRightPanel?.test?.status ===
					assessmentStatus.INITIALIZED ? (
							<div className='test-btn-display' style={{ marginTop: "5px" }}>
								<div className='test-btn-padding'>
									<Button
										style={{
											backgroundColor: "#00D69A",
											padding: "3px 28px",
											marginRight: "10px",
											marginBottom: "10px",
										}}
										onClick={() => {
											dispatch(
												candidateTestingActions.setAssessmentId(
													pendingTestRightPanel?.test.id,
												),
											);
											dispatch(
												candidateTestingActions.setTestToResume(
													pendingTestRightPanel?.test,
												),
											);
											props.onClickStart();
										}}
										button
										label='Start'
									/>
									<Button
										style={{ backgroundColor: "#FC4848", padding: "3px 20px" }}
										button
										label='Decline'
									/>
								</div>
							</div>
						) : (
							<div className='d-flex' style={{ marginTop: "5px" }}>
								<Button
									style={{
										backgroundColor: "#00B4D8",
										padding: "3px 28px",
										marginLeft: "20px",
									}}
									onClick={() => {
										dispatch(
											candidateTestingActions.setAssessmentId(
												pendingTestRightPanel?.test.id,
											),
										);
										dispatch(
											candidateTestingActions.setTestToResume(
												pendingTestRightPanel?.test,
											),
										);
										props.onClickStart();
									}}
									button
									label='Resume'
								/>
							</div>
						)}
					{/* <div>
						<span className='description'>
							Approximate Time:{" "}
							<span style={{ color: "#00B4D8" }}>
								{props.time || "30"} Minutes
							</span>
						</span>
					</div> */}
				</div>
			</div>
			<div style={{ paddingTop: "30px" }}>
				<Input
					textarea
					labelClass='b2'
					label='Role Details'
					defaultValue={pendingTestRightPanel?.roleDetails}
					readOnly
					className='description'
					inputStyle={{ height: "170px" }}
				/>
			</div>
		</div>
	);
}
