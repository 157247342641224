import React from "react";

export default function OnHoverComponent(props){
	if (props.refranceComponent) {
		return (
			<div className="help-component">
				<div style={{padding:"0px 20px 10px 20px "}}>
					<span className="p2">{ props.description || 
			"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer diam pretium purus proin libero nulla tincidunt et. Sit ac, amet, egestas amet, amet. Consequat egestas id enim diam eget sodales nibh. Feugiat libero, vel felis varius ac massa sagittis. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer diam pretium purus proin libero nulla tincidunt et. Sit ac, amet, egestas amet, amet."} 
					</span>
					<p style={{marginTop:"10px"}} className="p2">{ props.description2 || 
			"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer diam pretium purus proin libero nulla tincidunt et. Sit ac, amet, egestas amet, amet. Consequat egestas id enim diam eget sodales nibh. Feugiat libero, vel felis varius ac massa sagittis. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer diam pretium purus proin libero nulla tincidunt et. Sit ac, amet, egestas amet, amet."} 
					</p>
				</div>
			</div> 
		);
	} else   {
		return (
			<div className="invisible-scrollbar" style={{width:"333px", height:"212px", borderRadius:"12px", backgroundColor:"white",overflow:"auto", boxShadow:"5px 5px 5px #aaaaaa"}}>
					
				<div style={{padding:"15px 20px 10px 20px"}}>
					<span className="headline3">{props.title || "Lorum Ipsum?"}</span>
				</div>
					
				<div style={{padding:"0px 20px 10px 20px "}}>
					<span className="p2">{ props.description || 
			"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer diam pretium purus proin libero nulla tincidunt et. Sit ac, amet, egestas amet, amet. Consequat egestas id enim diam eget sodales nibh. Feugiat libero, vel felis varius ac massa sagittis. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer diam pretium purus proin libero nulla tincidunt et. Sit ac, amet, egestas amet, amet."} 
					</span>
						
				</div>
			</div>
		);
	}
			
				
	
}   