import React from "react";
import { useEffect } from "react";
import SignInMobile from "./SignInMobile";
import SignInWeb from "./SignInWeb";
import { useDispatch} from "react-redux";
import * as authActions from "../../redux/actions/Auth/authAction";
// import { Redirect } from "react-router-dom";

export default function SignIn(props){
	const dispatch = useDispatch() ;
	
	useEffect( () => {
		// console.log("link Id at sign in page " , props?.linkId);
		dispatch(authActions.refreshToken(true , props?.linkId));
	} , [dispatch , props?.linkId]);
	
	return(
		<>
			<div className="SignIn-DesktopView">
				<SignInWeb/>
			</div>
			<div className="SignIn-MobileView">
				<SignInMobile/>
			</div>
		</>
	);
}
