import React, {useState} from "react";
import Input from "../../../../components/subcomponents/Input";
import Cross from "../../../../assets/icons/basic/x.svg";
import {
	CardNumberElement,
	useStripe,
	useElements,
	CardExpiryElement,
	CardCvcElement,
} from "@stripe/react-stripe-js";
import Button from "../../../../components/subcomponents/Button";
import { useDispatch, useSelector } from "react-redux";
import * as Actions from "../../../../redux/actions/Dashboard/BillingInfo";
import CustomCheckBox from "../../../../components/CustomCheckBox";

export default function AddCard(props){

	const stripe = useStripe();
	const elements = useElements();
	const form = useSelector(state => state.billing.stripeForm);
	const dispatch = useDispatch();
	const loading = useSelector(state => state.billing.loading);
	const error = useSelector(state => state.billing.error);
	const subscriptionCompleted = useSelector(state => state.billing.subscriptionCompleted);

	const [checked, setChecked] = useState(false);

	const setDefault = () => {
		setChecked(!checked);
	};
	// console.log(error,"asasa");

	const handleSubmit = () => {
		dispatch(
			Actions.createStripePaymentMethod(stripe, elements, CardNumberElement, form, checked)
		);
		if(subscriptionCompleted){
			dispatch(Actions.setErrorMessage(" "));
		}
		
	};

	const handleChange = (target) => {
		dispatch(Actions.handleChange(target));
	};

	

	const cardNumberStyle = {
		style: {
			
			base: {
				fontSize: "17px",

				"::placeholder": {
					color: "#96999C",
				},
				lineHeight: "30px",
				background: "white"
				
			},
			invalid: {
				color: "#fa755a",
				iconColor: "#fa755a",
			},
		},
		showIcon: true,
	};

	const cardStyle = {
		style: {
			base: {
				fontSize: "12px",
				fontWeight:500,
				fontStyle:"normal",
				"::placeholder": {
					color: "#56585A",
				},
				
				lineHeight: "30px",
			},
			invalid: {
				color: "#fa755a",
				iconColor: "#fa755a",
			},
		},
	};

	return (
		<div className="invite-modal">
			<div className="invite-modal-container d-flex flex-column">
				<div
					className='d-flex justify-content-end pt-4 pr-0'
					onClick={props.onClick}>
					<img src={Cross} alt='' style={{ cursor: "pointer" }} />
				</div>
				<div>
					<span className='headline1'>Add Card</span>
				</div>
				{
					error && 
					<div>
						<span className="p2" style={{color:"rgb(252, 72, 72)"}}>{error}</span>
					</div>
				}
				<div>
					<Input
						label='Cardholder Name'
						asterik
						type='text'
						name='name'
						value={form.name}
						onChange={e => handleChange(e.target)}
						inputStyle={{ boxShadow: "0px 3px 5px #d3d3d3" }}
					/>
				</div>
				<div className='d-flex flex-column align-items-start pt-3'>
					<div style={{ paddingBottom: "5px" }}>
						<span className='p2'>Card Number</span>
						<span style={{ color: "red" }}> *</span>
					</div >
					<CardNumberElement
						className='stripe-input-field-card'
						options={cardNumberStyle}
					/>
				</div>
				<div className="d-flex justify-content-between pt-4">
					<div>
						<div style={{ paddingBottom: "5px" }}className="d-flex" >
							<span className='p2'>Expiry Date (MM/YY)</span>
							<span style={{ color: "red" }}> *</span>
						</div >
						<CardExpiryElement
							className='stripe-input-field-card-half'
							options={cardStyle}
						/>
					</div>
					<div>
						<div style={{ paddingBottom: "5px" }} className="d-flex">
							<span className='p2'>Security Code (CVV)</span>
							<span style={{ color: "red" }}> *</span>
						</div >
						<CardCvcElement
							className='stripe-input-field-card-half'
							options={cardStyle}   
						/>
					</div>
				</div>
				<div className="pt-4">
					<CustomCheckBox
						label='Mark as Default Card'
						textClass="description"
						toggle={setDefault}
						value={checked}
					/>
				</div>
				<div className="d-flex align-items-center justify-content-center pt-4 pb-4" >
					<Button
						button
						label="Save"
						loading={loading}
						style={{padding:"4px 40px"}}
						onClick={handleSubmit}
					/>
				</div>
			</div>

		</div>
	);
}