import React, { useEffect, useRef, useState } from "react";
import { ReactMediaRecorder ,useReactMediaRecorder } from "react-media-recorder";
import { ReactComponent as Stop } from "../assets/icons/basic/Stop.svg";
import { ReactComponent as Record } from "../assets/icons/basic/Record.svg";
import { ReactComponent as Recording } from "../assets/icons/basic/Recording.svg";
import * as candidateActions from "../redux/actions/Candidate/candidate-testing.actions";

import { useDispatch, useSelector } from "react-redux";

const VideoPreview = ({ stream }) => {
	const videoRef = useRef(null); 
	useEffect(() => {
		if (videoRef.current && stream) {
			videoRef.current.srcObject = stream;
		}
	}, [stream]);
	if (!stream) {
		return null;
	}
	return (
		<video
			ref={videoRef}
			width='100%'
			height='100%'
			style={{
				borderRadius: "10px"
			}}
			autoPlay
		/>
	);
};

export default function Integrations(props) {
	const dispatch = useDispatch();
	const {
		stopRecording
	} = useReactMediaRecorder({ video: true });

	const [isRecording, setIsRecording] = useState(false);
	const videoBlob = useSelector((state) => state.candidateTesting.videoBlob);
	const timeOut = useSelector((state) => state.candidateTesting.timeOut);

	const handleStopRecording = async (stopRecording) => {
		setIsRecording(false);
		stopRecording();
		props.setDisabled(false);
	};

	const handleStartRecording = (startRecording , stopRecording) => {
		!timeOut && setIsRecording(true);
		!timeOut && startRecording();
		timeOut && handleStopRecording(stopRecording) ;
	
	};
	const handleOnStop = (blobUrl, blob) => {
		dispatch(candidateActions.setVideoBlob(blob));
	};

	// const handlePauseRecording = (pauseRecording) => {
	// 	setIsRecording(false);
	// 	pauseRecording();
	// };
	useEffect(() => {
		console.log(timeOut, " time");
		if (timeOut){
			handleStopRecording(stopRecording);
		}
		
	},[timeOut] );


	return (
		<div className='d-flex' style={{ paddingLeft: "80px" }}>
			<ReactMediaRecorder
				onStop={handleOnStop}
				video
				render={({
					status,
					startRecording,
					stopRecording,
					mediaBlobUrl,
					previewStream,
				}) => (
					console.log("current status is in " ,status) ,
					<div>
						<div style={{ position: "relative", paddingTop: "50px" }}>
							{isRecording &&  (
								<div
									style={{ position: "absolute", right: "20px", top: "70px" }}>
									<Recording />
								</div>
							)}
							{isRecording && <VideoPreview stream={previewStream} />}
							{!isRecording && videoBlob && (
								<video
									width={status === "idle" ? props.width : "100%"}
									height={status === "idle" ? props.height : "100%"}
									style={{
										borderRadius: "10px",
									}}
									src={mediaBlobUrl}
									controls
								/>
							)}
							{!isRecording && !videoBlob && (
								<video
									width={status === "idle" ? props.width : "100%"}
									height={status === "idle" ? props.height : "100%"}
									style={{
										borderRadius: "10px",
									}}
									src={null}
									controls
								/>
							)}
						</div>
						{
							props.submitted ? 
								<div className='video-controls'>
								</div>
								: 
								<div className='video-controls'>
									<div style={{ marginRight: "20px" }}>
										<Record onClick={() => handleStartRecording(startRecording ,stopRecording)} />
									</div>
									<div style={{ marginRight: "20px" }}>
										<Stop onClick={() => handleStopRecording(stopRecording)} />
									</div>
								</div>
						}
						
					</div>
				)}
			/>
		</div>
	);
}
