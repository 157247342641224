import React, { useEffect } from "react";
import RoleTemplateCard from "../../../common/RoleTemplateCard";
import Button from "../../../subcomponents/Button";
import Input from "../../../subcomponents/Input";
import Personality from "./PersonalityAssesment";
import Qualification from "./Qualification";
import Reference from "./ReferenceChecks";
import { useDispatch, useSelector } from "react-redux";
import * as setupRoleActions from "../../../../redux/actions/onboarding/SetupRoles";
import Select from "../../../subcomponents/Select";
import { Redirect, useHistory } from "react-router-dom";

export default function Result(props) {
	const dispatch = useDispatch();

	const history = useHistory();

	const isEditRole = useSelector((state) => state.clientFilter.isEditRole);
	const positionForCandidateRightPanel = useSelector(
		(state) => state.clientFilter.positionForCandidateRightPanel,
	);
	const setupRoleForm = useSelector((state) => state.setupRoles.setupRoleForm);
	const IsPerRequire = useSelector((state) => state.setupRoles.setupRolePersonalityForm.isChecked);
	const isAutoMail = useSelector((state) => state.setupRoles.setupRoleReferenceForm.isAutoMail);
	const setupRoleQualificationForm = useSelector(
		(state) => state.setupRoles.setupRoleQualificationForm,
	);
	const setupRolePersonalityForm = useSelector(
		(state) => state.setupRoles.setupRolePersonalityForm,
	);
	const setupRoleReferenceForm = useSelector(
		(state) => state.setupRoles.setupRoleReferenceForm,
	);
	const hasClients = useSelector((state) => state.clients.hasClients);
	const allClients = useSelector((state) => state.clients.allClients);

	const setupRoleFormIsSubmitted = useSelector(
		(state) => state.setupRoles.isSubmitted,
	);
	const isModifyRequested = useSelector(
		(state) => state.setupRoles.isModifyRequested,
	);
	const loading = useSelector((state) => state.setupRoles.loading);

	const roleTemplateForResult = useSelector(
		(state) => state.setupRoles.roleTemplateForResult,
	);

	const allClientsForSelect = [];
	allClients.map((client) => {
		allClientsForSelect.push({
			label: client.name,
			value: client.id,
			icon: client.logo,
		});
	});

	const handleCheckCustomRoleTemplate = () => {
		let anyChange = false;
		if (
			roleTemplateForResult.personalities.length ===
			setupRolePersonalityForm.selectedPersonalityIds.length
		) {
			roleTemplateForResult.personalities.map((p) => {
				setupRolePersonalityForm.selectedPersonalityIds.find((pId) => {
					if (pId === p.personality_type_id) {
						//
					} else {
						anyChange = true;
					}
				});
			});
		} else {
			anyChange = true;
		}

		if (
			roleTemplateForResult.qualifications.length ===
			setupRoleQualificationForm.qualificationMappings.length
		) {
			roleTemplateForResult.qualifications.map((q, i) => {
				if (
					q.test_pack_id ===
					setupRoleQualificationForm.qualificationMappings[i].test_pack_id
				) {
					if (
						q.test_pack_difficulty !==
						setupRoleQualificationForm.qualificationMappings[i]
							.test_pack_difficulty
					) {
						anyChange = true;
					}
				} else {
					anyChange = true;
				}
			});
		} else {
			anyChange = true;
		}

		if (anyChange) {
			handleSubmitSetupRole({
				client_id: setupRoleForm.selectedClientId,
				name: setupRoleForm.roleName,
				role_details: setupRoleForm.roleDetails,
				reference_count: setupRoleReferenceForm.selectedReference,
				city: setupRoleForm.city,
				country: setupRoleForm.country,
				state: setupRoleForm.state,
				hourly_rate: setupRoleForm.hourlyRate,
				salary: setupRoleForm.salary,
				zip_code: setupRoleForm.zipCode,
				pers_assess_required: IsPerRequire,
				auto_ref_email:isAutoMail ,
				cust_role_temp_data: {
					name: setupRoleForm.roleTitle,
					description: setupRoleForm.roleTemplateDetails,
					qualifications: setupRoleQualificationForm.qualificationMappings.map(
						(q) => {
							return {
								test_pack_id: q.test_pack_id,
								difficulty: q.test_pack_difficulty,
							};
						},
					),
					personalities: setupRolePersonalityForm.selectedPersonalityIds,
				},
			});
		} else {
			handleSubmitSetupRole({
				client_id: setupRoleForm.selectedClientId,
				role_template_id: setupRoleForm.selectedRoleId,
				name: setupRoleForm.roleName,
				role_details: setupRoleForm.roleDetails,
				reference_count: setupRoleReferenceForm.selectedReference,
				city: setupRoleForm.city,
				country: setupRoleForm.country,
				state: setupRoleForm.state,
				hourly_rate: setupRoleForm.hourlyRate,
				salary: setupRoleForm.salary,
				zip_code: setupRoleForm.zipCode,
				pers_assess_required: IsPerRequire,
				auto_ref_email:isAutoMail ,
			});
		}
	};

	const handleSubmitSetupRole = (data) => {
		if (isEditRole) {
			dispatch(
				setupRoleActions.updateSetupRole(
					data,
					history,
					props.dashboard,
					positionForCandidateRightPanel.id,
				),
			);
		} else {
			dispatch(
				
				setupRoleActions.submitSetupRole(data, history, props.dashboard  ),
			);
		}
	};

	useEffect(() => {
		dispatch(
			setupRoleActions.getRoleTemplateForResult(setupRoleForm.selectedRoleId),
		);
	}, [dispatch, setupRoleForm ]);

	if (props.dashboard) {
		//
	} else if (setupRoleFormIsSubmitted && !isModifyRequested) {
		return <Redirect to='/onboarding/inviteCandidate' />;
	}

	return (
		<div>
			<div style={{ marginBottom: "20px" }} className='headline1'>
				{props.dashboard ? "New Position Summary" : "Setup Role"}
			</div>
			<div className='d-flex w-100 justify-content-between'>
				<Input
					style={{ width: "100%", marginRight: "10px" }}
					type='text'
					label='Role Name'
					readOnly
					value={setupRoleForm.roleName}
				/>
				{hasClients && (
					<div style={{ width: "100%", marginLeft: "20px" }}>
						<div style={{ marginBottom: "-5px" }}>
							<span className='p2'>Clients</span>
						</div>
						<Select
							placeholder='Select Client'
							agency
							agencySetup
							disable
							selected={allClientsForSelect.find(
								(c) => c.value === setupRoleForm.selectedClientId,
							)}
						/>
					</div>
				)}
			</div>
			<div style={{ padding: "18px 0px" }}>
				<span className='p2'>Role Template</span>
				<RoleTemplateCard
					style={{ marginTop: "5px" }}
					details={setupRoleForm.roleTemplateDetails}
					title={setupRoleForm.roleTitle}
				/>
			</div>
			<Input
				type='text'
				textarea
				name='roleDetails'
				label='Role Details'
				style={{ paddingTop: "20px" }}
				defaultValue={setupRoleForm.roleDetails}
				readOnly
			/>
			<div>
				<Qualification completed />
			</div>
			<div>
				{IsPerRequire ? <Personality completed /> : null}
			</div>
			<div>
				<Reference completed />
			</div>
			<div style={{ display: "flex", justifyContent: "center" }}>
				<Button
					button
					loading={loading}
					label='Finish'
					textClass='b2'
					className='button-large'
					style={{ padding: "6.5px 69px" }}
					onClick={handleCheckCustomRoleTemplate}
				/>
			</div>
		</div>
	);
}
