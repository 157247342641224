import React, { useEffect, useState } from "react";
import Cross from "../../../assets/icons/basic/x.svg";
import Button from "../Button";
import { useSelector, useDispatch } from "react-redux";
import * as clientFilterActions from "../../../redux/actions/Dashboard/ClientFilter.actions";
import { ReactMultiEmail, isEmail } from "react-multi-email";
import "react-multi-email/style.css";

// const styles = {
// 	fontFamily: "sans-serif",
// 	width: "500px",
// 	border: "1px solid #eee",
// 	background: "#f3f3f3",
// 	padding: "25px",
// 	margin: "20px"
// };


export default function Modal(props) {


	const dispatch = useDispatch();

	const [emails, setEmails] = useState([]);

	const loading = useSelector((state) => state.clientFilter.loading);
	// const isCandidateInvited = useSelector(
	// 	(state) => state.clientFilter.isCandidateInvited,
	// );

	// if(isCandidateInvited){
	// 	alert("Invite has been sent");
	// }

	const handleSendInvitationClicked = (positionId) => {
		dispatch(
			clientFilterActions.inviteMultipleCandidatesByEmail(emails, positionId),
		);
		setTimeout(() => {
			props.onClick();
		},1000);
		
		
	};

	useEffect(() => {
		setEmails([]);
	}, [setEmails]);

	return (
		<div className='invite-modal'>
			<div className='invite-modal-container d-flex flex-column'>
				<div
					className='d-flex justify-content-end pt-3 pr-0'
					onClick={props.onClick}>
					<img src={Cross} alt='' style={{ cursor: "pointer" }} />
				</div>
				<div style={{ padding: "10px 0px 25px 0px" }}>
					<span className='headline2'>Invite New Candidates</span>
				</div>
				{/* <div className='d-flex justify-content-center'>
					<Button
						button
						label='Add Manually'
						style={{ padding: "9.5px 13px" }}
					/>
				</div> */}
				<div className="mt-4">
					<ReactMultiEmail
						placeholder="Enter emails"
						emails={emails}
						onChange={(emails) => {
							setEmails(emails);
						}}
						validateEmail={email => {
							return isEmail(email); // return boolean
						}}
						getLabel={(email, index, removeEmail) => {
							return (
								<div data-tag key={index}>
									{email}
									<span data-tag-handle onClick={() => removeEmail(index)}>
                  ×
									</span>
								</div>
							);
						}}
					/>

					{/* <Input
						label='Candidate Email'
						asterik
						type='text'
						name='candidateEmail'
						value={candidateEmail}
						inputStyle={{ boxShadow: "0px 3px 5px #d3d3d3" }}
						onChange={(e) =>
							dispatch(clientFilterActions.handleModalEmailChange(e.target))
						}
					/> */}
				</div>
				<div
					className='d-flex justify-content-center'
					style={{ marginTop: "75px" }}>
					<div
						className='d-flex justify-content-center'
						style={{ marginRight: "20px" }}>
						<Button
							button
							label='Send Invitation'
							style={{ padding: "9.5px 15.5px" }}
							onClick={() => handleSendInvitationClicked(props.positionId)}
							loading={loading}
						/>
					</div>
					<div className='d-flex justify-content-center'>
						<Button
							whiteButton
							label='Cancel'
							style={{ padding: "9.5px 40.5px" }}
							onClick={props.onClick}
						/>
					</div>
				</div>
			</div>
		</div>
	);
}

// import * as React from "react";
// import { render } from "react-dom";
// import { ReactMultiEmail } from "react-multi-email";

// interface IProps {}
// interface IState {
//   emails: string[];
// }

// class App extends React.Component<IProps, IState> {
//   state = {
//     emails: []
//   };

//   render() {
//     const { emails } = this.state;

//     return (
//       <div style={styles}>
//         <h3>react-multi-email</h3>
//         <ReactMultiEmail
//           placeholder="Input your Email Address"
//           emails={emails}
//           onChange={(_emails: string[]) => {
//             this.setState({ emails: _emails });
//           }}
//           getLabel={(
//             email: string,
//             index: number,
//             removeEmail: (index: number) => void
//           ) => {
//             return (
//               <div data-tag key={index}>
//                 {email}
//                 <span data-tag-handle onClick={() => removeEmail(index)}>
//                   ×
//                 </span>
//               </div>
//             );
//           }}
//         />
//         <br />
//         <h4>react-multi-email value</h4>
//         <p>{emails.join(", ") || "empty"}</p>
//       </div>
//     );
//   }
// }

// render(<App />, document.getElementById("root"));
