import React, { useState } from "react";
import CustomCheckBox from "../../../components/CustomCheckBox";
import { ReactComponent as Ellipse } from "../../../assets/icons/basic/Ellipse 256.svg";
import { ReactComponent as ArrowDown } from "../../../assets/images/onboarding/profile/Strokedown.svg";
import { ReactComponent as ArrowUp } from "../../../assets/images/onboarding/profile/Strokeup.svg";
import { useDispatch } from "react-redux";
import * as candidateTestingActions from "../../../redux/actions/Candidate/candidate-testing.actions";

export default function ReferenceBox(props) {
	const dispatch = useDispatch();

	const [open, setOpen] = useState(false);
	const [checked, setChecked] = useState(false);

	const handleToggle = (referrer) => {
		if (!checked) {
			dispatch(candidateTestingActions.addExistingReferrer(referrer._id));
		} else {
			dispatch(candidateTestingActions.removeExistingReferrer(referrer._id));
		}
		setChecked(!checked);
	};

	return (
		<div
			className='d-flex justify-content-between invisible-scrollbar'
			style={{
				height: open ? "268px" : "68px",
				transition: "height 0.3s ease-out",
				width: props.mobile ? "100%" : "490px",
				backgroundColor: "white",
				borderRadius: "12px",
				padding: "15px",
				overflowY: "auto",
			}}>
			<div className='d-flex '>
				<div>
					<CustomCheckBox
						toggle={() => handleToggle(props.referrer)}
						value={checked}
						label=''
					/>
				</div>
				<div className='d-flex flex-column' style={{ paddingLeft: "10px" }}>
					<div className='d-flex align-items-center'>
						<span className={props.mobile ? "description" : "b2"}>
							{props.referrer.name}
						</span>
						<div style={{ paddingInline: "10px" }}>
							<Ellipse />
						</div>
						<span className='description' style={{ color: "#96999C" }}>
							{props.referrer.designation}
						</span>
						<div style={{ paddingInline: "10px" }}>
							<Ellipse />
						</div>
						<span className='description' style={{ color: "#96999C" }}>
							{props.referrer.organisation || props.referrer.organization}
						</span>
					</div>
					<div className='d-flex'>
						<span className='description'>{props.referrer.email}</span>
					</div>
					{open && (
						<div>
							{props.referrer?.reference?.data.map((question, key) => {
								return (
									<div
										key={key}
										style={{ paddingTop: "10px" }}
										className='d-flex flex-column align-items-start text-justify'>
										<span className='description'>{question.question}</span>
										<div>
											<span
												className='description'
												style={{ color: "#56585A" }}>
												{question.response}
											</span>
										</div>
									</div>
								);
							})}
						</div>
					)}
				</div>
			</div>
			<div
				onClick={() => setOpen(!open)}
				className='pointer'
				style={{ paddingLeft: props.mobile ? "5px" : "0px" }}>
				{open ? <ArrowUp stroke='black' /> : <ArrowDown />}
			</div>
		</div>
	);
}
