import React from "react";
import CheckMark from "../../assets/icons/onboarding/checkmark.png";
import {ReactComponent as ArrowUp} from "../../assets/images/onboarding/profile/Strokeup.svg";
import ArrowDown from "../../assets/images/onboarding/profile/Strokedown.svg";

export default function MenuDropdown({label,selected,done,onClickDone,onClickNotDone,children, mobileView}){

	if(selected == undefined || selected == null)
		selected = false;

	const buttonClass = selected === true ? "onboarding_button onboarding_button_selected" : "onboarding_button";

	const Hr = ({len,index})=>{
		return index != len-1 ? <hr style={{margin:"0px"}}/> : null;
	};

	return (
		<div className="onboarding_button_container">
			<div className={buttonClass} onClick={done ? onClickDone : onClickNotDone}>
				<div className="d-flex align-items-center w-100 h-100 justify-content-between">
					<span className="headline3">{label}</span>
					<div className="d-flex">
						
						{(done === true & selected === false)? <img style={{width:"20px",height:"20px"}} src={CheckMark}/> : null}
						{mobileView ? 
							<div style={{marginLeft:"15px"}}>
								{
									selected  ? 
										<ArrowUp stroke="white"/>
										: 
										<img
											src={ArrowDown}
										/>
								}
							</div>
							: 
							null
						}
					</div>
				</div>
			</div>

			{			
				children ?
					<div className={(selected === true && children.length > 0) ? "onboarding_dropdown_items onboarding_dropdown_items_selected" : "onboarding_dropdown_items" }>
						{
							children.map((Child,index)=>{
								return (
									<div key={index}>
										{Child}
										<Hr len={children.length} index={index}/>
									</div>
								);
							})
						}
					</div>
					:
					null
			}
		</div>
	);
}