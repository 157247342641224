import * as Actions from "../../actions/onboarding/companySetup";

const initialState = {
	companySetupForm: {
		companyName: "",
		industry: "",
		legalAddress1: "",
		legalAddress2: "",
		city: "",
		state: "",
		country: "",
		phone: "",
		zipCode: "",
		websiteUrl: "",
		logo: null,
	},
	isSubmitted: false,
	isVerified: false ,
	isCompanySetupLoading: false,
	isModifyRequested: false,
};

const reducer = (state = initialState, action) => {
	const data = action.payload;

	switch (action.type) {
		case Actions.SUBMIT_COMPANY_SETUP_FORM_START:
			return (state = {
				...state,
				isCompanySetupLoading: true,
			});

		case Actions.SUBMIT_COMPANY_SETUP_FORM_END:
			if (data) {
				return (state = {
					...state,
					isCompanySetupLoading: false,
					isSubmitted: true,
					isVerified:true ,
					isModifyRequested: false,
				});
			} else {
				return (state = {
					...state,
					isCompanySetupLoading: false,
				});
			}

		case Actions.HANDLE_CHANGE:
			return (state = {
				...state,
				companySetupForm: {
					...state.companySetupForm,
					[data.name]: data.value,
				},
			});

		case Actions.HANDLE_COUNTRY_CHANGE:
			return (state = {
				...state,
				companySetupForm: {
					...state.companySetupForm,
					country: data.label,
				},
			});

		case Actions.HANDLE_SELECT_CHANGE:
			return (state = {
				...state,
				companySetupForm: {
					...state.companySetupForm,
					state: data.label,
				},
			});

		case Actions.HANDLE_INDUSTRIES_CHANGE:
			return (state = {
				...state,
				companySetupForm: {
					...state.companySetupForm,
					industry: data.label,
				},
			});
		case Actions.GET_TENANT_PROFILE:
			return (state = {
				...state,
				companySetupForm: {
					...state.companySetupForm,
					companyName: data.data.tenant_name,
					industry: data.data.industry,
					legalAddress1: data.data.address_1,
					legalAddress2: data.data.address_2,
					state: data.data.state,
					country: data.data.country,
					phone: data.data.phone,
					zipCode: data.data.zip_code,
					websiteUrl: data.data.web_url,
					logo: data.data.logo,
				},
				isSubmitted: true,
			});

		case Actions.MENU_CLICKED:
			return (state = {
				...state,
				isModifyRequested: true,
			});

		case Actions.UPLOAD_COMPANY_LOGO_START:
			return (state = {
				...state,
				isCompanySetupLoading: true,
			});

		case Actions.UPLOAD_COMPANY_LOGO_END:
			return (state = {
				...state,
				isCompanySetupLoading: false,
				companySetupForm: {
					...state.companySetupForm,
					logo: data.data,
				},
			});

		default:
			return state;
	}
};

export default reducer;
