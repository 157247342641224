import React from "react";
import RnrLogo from "../../../assets/icons/dashboard/logo.png";
import Signupinfo from "../Signup/SignupInfo";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import PaymentForm from "./PaymentForm";
import { stripePublicKey } from "../../../utilities/Constants";

const Link = (name, url) => {
	return (
		<a className='helper_link' href={url}>
			{name}
		</a>
	);
};

export default function PaymentInfoWeb(props) {
	const year = new Date().getFullYear();

	const stripePromise = loadStripe(stripePublicKey);

	return (
		<div className='signup_container'>
			<div className='main-row row row-no-gutters'>
				<div className='left_box col-xs-7 col-sm-7 col-md-7 col-lg-7 no-gutters'>
					<div className='content_wrapper d-flex flex-column justify-content-between'>
						<div
							className='d-flex justify-flex-start'
							style={{ paddingTop: "20px", paddingLeft: "80px" }}>
							<img
								onClick={() => props.history.push("/")}
								style={{ cursor: "pointer" }}
								src={RnrLogo}
								width='237px'
							/>
						</div>
						
						<div>
							<Elements stripe={stripePromise}>
								<PaymentForm/>
							</Elements>
						</div>
						<div className='links'>
							{[
								Link(`RanknRole Inc, ${year}`, "google.com"),
								Link("Privacy Policy", "google.com"),
								Link("Terms and conditions", "google.com"),
							]}
						</div>
					</div>
				</div>
				<div className='right_box col-xs-5 col-sm-5 col-md-5 col-lg-5 no-gutters'>
					<div className='background'></div>
					<div className='content_wrapper d-flex flex-column justify-content-between'>
						<div></div>
						<Signupinfo />
						<div className='links'>
							{[
								Link("Info", "google.com"),
								Link("Any Questions?", "google.com"),
							]}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}


