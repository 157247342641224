import React from "react";
import { ReactComponent as FaceIcon } from "../../../assets/icons/dashboard/Table/face-id.svg";
import { ReactComponent as ReferenceIcon } from "../../../assets/icons/dashboard/Table/reference-check.svg";
import BlankPicture from "../../../assets/icons/Userpic/userprofile.svg";
import {ReactComponent as VectorIcon} from "../../../assets/icons/dashboard/Table/Vector.svg";

export default function CandidateRow(props) {
	const handleSelected = () => {
		props.onClick();
	};

	return (
		<div>
			<div
				className={
					props.selected ? "candidate-selected on-hover" : "candidate-row on-hover"
				}
				onClick={props.onClick ? handleSelected : () => {}}
				style={{
					border: "2px solid #EAEDEE",
					borderRadius:"12px",
					padding: "20px 20px 20px 25px",
					margin:" 10px 0px"
				}}
			>
				
				<div className='d-flex align-items-center' >
					
					<div style={{width:"8px", height:"8px", borderRadius:"50%", backgroundColor: props.notConsidered ? "#FC4848" : props.shortlisted ? "#00D69A" :"#F4C20D", marginRight:"15px"}}>
								
					</div>
						
					
					<div style={{ borderRadius: "50%" }}>
						<img
							src={(props.logo === null) ? BlankPicture : props.logo}
							alt=''
							style={{ width: "26px", height: "26px", borderRadius:(props.logo === null) ? "0%" : "50%" }}
						/>
					</div>
					<div style={{ paddingLeft: "10px" }}>
						<span className='description'>{props.name}</span>
					</div>
				</div>
				
				<div className='d-flex justify-content-end align-items-center'>
					<div   className='d-flex align-items-center'>
						<span className='headline1'>{props.score > 0 ? parseFloat(props.score.toString()).toFixed(1) : props.score}</span>
					</div>
					<div style={{ paddingLeft:"27px" }}>
						<div style={{ width: "15px", }}>
							<FaceIcon fill={
								(props.persMatch === null || props.persMatch === undefined) ? "#F4C20D" : props.persMatch ? "#00D69A" : "#FC4848"
							} />
						</div>
					</div>
					<div style={{ paddingLeft:"13px" }}>
						<div style={{ width: "15px", }}>
							<VectorIcon stroke={props.persMatch ? "#00D69A" : "#FC4848"} />
						</div>
					</div>
					<div style={{ paddingLeft:"13px"}}>
						<div style={{ width: "15px", }}>
							<ReferenceIcon
								fill={props.referralStatus ? "#00D69A" : "#F4C20D"}
							/>
						</div>
					</div>
					{/* {props.web && (
						<div style={{ width: "25%" }}>
							<span>{props.time}</span>
						</div>
					)} */}
				</div>
					
			</div>
		</div>
	);
}
