import React, { useEffect } from "react";
import CandidateRow from "./CandidateRow";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import * as candidateTestingActions from "../../../redux/actions/Candidate/candidate-testing.actions";
import assessmentStatus from "../../../utilities/Constants";
import SearchInput from "../../subcomponents/dashboard/SearchInput";

export default function Completed(props) {
	const dispatch = useDispatch();

	const completedUserProfiles = useSelector(
		(state) => state.candidateTesting.completedUserProfiles,
	);

	const previousTestRightPanel = useSelector(
		(state) => state.candidateTesting.previousTestRightPanel,
	);

	const completedUserIds = [];
	props?.completedAssessments?.map((assessment) => {
		completedUserIds.push(assessment.user_id);
	});


	useEffect(() => {
		if (completedUserIds.length > 0) {
			dispatch(candidateTestingActions.getUserProfileOfIds(completedUserIds));
		}  else {
			dispatch(candidateTestingActions.resetCompletedUserProfiles());
		}
	}, [dispatch]);

	return (
		<div>
			<div className="" style={{marginTop:"16px"}}>
				<SearchInput candidateSearch style={{backgroundColor:"#F4F6F6", width:"100%", height:"40px"}}/>
			</div>
			
			<div>
				{props?.completedAssessments?.map((assessment, index) => {
					const userProfile = completedUserProfiles.find(
						(user) => user.user_id === assessment.user_id,
					);
					if(userProfile){
						return (
							<div key={index}>
								<CandidateRow
									selected={previousTestRightPanel?._id === assessment._id}
									onClick={() => {
										// console.log("assessment is having  " , assessment);
										dispatch(
											candidateTestingActions.setPreviousTestRightPanel(
												assessment
											),
										);
										dispatch(
											candidateTestingActions.getSingleAssessmentSession(
												assessment._id,
											),
										);
										props.onClick();
									}}
									logo={userProfile?.image_url}
									time={moment.utc(assessment.finish_date).fromNow(true)}
									name={userProfile?.first_name + " " + userProfile?.last_name}
									score={assessment.score}
									notConsidered={
										assessment.status === assessmentStatus.NOT_CONSIDERED
									}
									web={props.web}
									persMatch={assessment?.pers_match}
									referralStatus={assessment?.referral_status}
								/>
							</div>
						);
					}
					
								
				})
					
				}

			</div>
		</div>
	);
}
