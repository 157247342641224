import React from "react";
import { ReactComponent as Arrow } from "../../assets/icons/Arrows/right.svg";

export default function Button(props) {
	if (props.webButton) {
		return (
			<div
				className={props.className || "btn"}
				onClick={props.onClick}
				style={props.style}>
				<span className=''>{props.label || "Button"}</span>
			</div>
		);
	} else if (props.signUpButton) {
		return (
			<button
				type='button'
				className='signup-button p2'
				style={{ color: "white", outline: "none" }}
				onClick={props.onClick}>
				Get Started
			</button>
		);
	} else if (props.button) {
		return (
			<div
				id='abcd123'
				className={`${props.className} button-small`}
				onClick={props.onClick}
				style={{ ...props.style }}>
				{props.loading ? (
					<div className='spinner-border text-light' role='status'>
						<span className='sr-only'>Loading...</span>
					</div>
				) : (
					<span className={props.textClass} style={{ color: "white", ...props.textStyle }}>
						{props.label || "Button"}
					</span>
				)}
			</div>
		);
	} else if (props.rightArrow) {
		return (
			<div
				className={props.className || "button-small"}
				onClick={props.onClick}
				style={props.style}>
				<div className='d-flex align-items-center justify-content-center'>
					<div>
						{props.loading ? (
							<div className='spinner-border text-light' role='status'>
								<span className='sr-only'>Loading...</span>
							</div>
						) : (
							<span className={props.textClass} style={{ color: "white" }}>
								{props.label || "Button"}
							</span>
						)}
					</div>
					<div style={{ marginLeft: "5px", marginBottom: "3px" }}>
						{props.loading ? null : <Arrow stroke='white' />}
					</div>
				</div>
			</div>
		);
	} else if (props.whiteButton) {
		return (
			<div
				className={props.className || "white-button-sm"}
				onClick={props.onClick}
				style={{ ...props.style }}>
				<span className={props.textClass}>{props.label || "Button"}</span>
			</div>
		);
	} else if (props.dashboardbtn) {
		return (
			<div>
				<div
					className={props.className || "dashboard-button"}
					onClick={props.onClick}
					style={{ ...props.style }}>
					<span className={props.textClass} style={props.textStyle}>
						{props.label || "Button"}
					</span>
				</div>
			</div>
		);
	}
	else if (props.download) {
		return (

			<div style={{ width: "fit-content", padding: "10px 20px", border: "1px solid #00B4D8", borderRadius: "50px", cursor: "pointer", ...props.style }}
				onClick={props.onClick}
				className="d-flex justify-content-center align-items-center" >
				{
					props.leftIcon &&
					props.leftIcon
				}
				<div className="d-flex justify-content-center align-items-center">
					<span className={`${props.fontStyle || "b2"}` + ` ${props.className}`}
						style={{ color: "#00B4D8" }}
					>{props.title || "Next"}</span>
				</div>
			</div>
		);
	}
}
