import * as Actions from "../../actions/Dashboard/ClientFilter.actions";

const initialState = {
	selectedClient: null,
	positionForCandidateRightPanel: null,
	loading: false,
	candidateEmail: "",
	roleTemplateForCriteria: null,
	isEditRole: false,
	isCandidateInvited: false,
};

const ClientFilter = (state = initialState, action) => {
	const data = action.payload;

	switch (action.type) {
		case Actions.CLIENT_FILTER_SELECTED:
			return (state = {
				...state,
				selectedClient: data,
			});

		case Actions.CLIENT_FILTER_REMOVED:
			return (state = {
				...state,
				selectedClient: null,
			});

		case Actions.SET_CANDIDATE_RIGHT_PANEL:
			return (state = {
				...state,
				positionForCandidateRightPanel: data,
			});

		case Actions.CANDIDATE_INVITED_START:
			return (state = {
				...state,
				loading: true,
			});

		case Actions.CANDIDATE_INVITED_END:
			return (state = {
				...state,
				loading: false,
				candidateEmail: "",
				isCandidateInvited: true,
			});

		case Actions.CANDIDATE_INVITED_FAILED:
			return (state = {
				...state,
				loading: false,
			});

		case Actions.HANDLE_MODAL_EMAIL_CHANGE:
			return (state = {
				...state,
				[data.name]: data.value,
			});

		case Actions.RESET_CANDIDATE_EMAIL:
			return (state = {
				...state,
				candidateEmail: "",
			});

		case Actions.GET_SPECIFIC_ROLE_TEMPLATE:
			return (state = {
				...state,
				roleTemplateForCriteria: data,
			});

		case Actions.IS_EDIT_ROLE:
			return (state = {
				...state,
				isEditRole: true,
			});

		case Actions.RESET_EDIT_ROLE:
			return (state = {
				...state,
				positionForCandidateRightPanel: null,
				roleTemplateForCriteria: null,
				isEditRole: false,
			});

		default:
			return state;
	}
};

export default ClientFilter;
