import { secure_instance } from "../../../api/axios/axios-config";

export const SUBMIT_COMPANY_SETUP_FORM_START =
	"[Company Setup] SUBMIT_COMPANY_SETUP_FORM_START";
export const SUBMIT_COMPANY_SETUP_FORM_END =
	"[Company Setup] SUBMIT_COMPANY_SETUP_FORM_END";
export const HANDLE_CHANGE = "[Company Setup] HANDLE_CHANGE";
export const HANDLE_COUNTRY_CHANGE = "[Company Setup] HANDLE_COUNTRY_CHANGE";
export const GET_TENANT_PROFILE = "[Company Setup] GET_TENANT_PROFILE";
export const MENU_CLICKED = "[Company Setup] MENU_CLICKED";
export const UPLOAD_COMPANY_LOGO_END =
	"[Company Setup] UPLOAD_COMPANY_LOGO_END";
export const UPLOAD_COMPANY_LOGO_START =
	"[Company Setup] UPLOAD_COMPANY_LOGO_START";
export const HANDLE_SELECT_CHANGE = "[Company Setup] HANDLE_SELECT_CHANGE";
export const HANDLE_INDUSTRIES_CHANGE =
	"[Company Setup] HANDLE_INDUSTRIES_CHANGE";

export const submitForm = (data) => {
	return async (dispatch) => {
		try {
			dispatch({
				type: SUBMIT_COMPANY_SETUP_FORM_START,
			});

			const request = await secure_instance.request({
				url: `v1/tenant_profile/${data.user.tenantId}`,
				method: "patch",
				data: {
					tenant_name: data.companyName,
					industry: data.industry,
					address_1: data.legalAddress1 ,
					address_2: data.legalAddress2,
					zip_code: data.zipCode,
					state: data.state,
					country: data.country,
					phone: data.phone,
					web_url: data.websiteUrl,
				},
			});

			dispatch({
				type: SUBMIT_COMPANY_SETUP_FORM_END,
				payload: request.data,
			});
		} catch (e) {
			dispatch({
				type: SUBMIT_COMPANY_SETUP_FORM_END,
				payload: null,
			});
		}
	};
};

export const handleChange = (data) => {
	return {
		type: HANDLE_CHANGE,
		payload: data,
	};
};

export const handleSelectChange = (data) => {
	return {
		type: HANDLE_SELECT_CHANGE,
		payload: data,
	};
};

export const handleCountryChange = (data) => {
	return {
		type: HANDLE_COUNTRY_CHANGE,
		payload: data,
	};
};

export const handleIndustriesChange = (data) => {
	return {
		type: HANDLE_INDUSTRIES_CHANGE,
		payload: data,
	};
};

export const getTenantProfile = (user) => {
	return async (dispatch) => {
		try {
			const request = await secure_instance.request({
				url: `v1/tenant_profile/${user.tenantId}`,
				method: "get",
			});
			console.log(request.data);
			dispatch({
				type: GET_TENANT_PROFILE,
				payload: request.data,
			});
		} catch (e) {
			//
		}
	};
};

export const companySetupMenuClicked = () => {
	return {
		type: MENU_CLICKED,
	};
};

export const uploadLogo = (file, user) => {
	return async (dispatch) => {
		try {
			dispatch({
				type: UPLOAD_COMPANY_LOGO_START,
			});

			const formData = new FormData();
			formData.append("logo", file);

			const request = await secure_instance.request({
				url: `v1/tenant_profile/upload-logo/${user.tenantId}`,
				method: "Patch",
				data: formData,
			});

			setTimeout(() => {
				dispatch({
					type: UPLOAD_COMPANY_LOGO_END,
					payload: request.data,
				});
			}, 3000);
		} catch (e) {
			//
		}
	};
};
