import React , {useEffect} from "react";
import SignUpMobile from "./SignUpMobile";
import SignUpWeb from "./SignUpWeb";
import { useDispatch, useSelector } from "react-redux";
import * as authActions from "../../redux/actions/Auth/authAction";
import { Redirect } from "react-router-dom";

export default function SignUp(props) {
	const dispatch = useDispatch() ;
	const user = useSelector((state) => state.user.user);
	const linkId = useSelector((state) => state.candidateTesting.linkId);
	
	useEffect( () => {
		dispatch(authActions.refreshToken(true , linkId));
	} , [dispatch , linkId]);
	if(user?.accessToken){
		return <Redirect to = "/dashboard" />; 
	}
	return (
		<>
			<div className='SignIn-DesktopView'>
				<SignUpWeb {...props} />
			</div>
			<div className='SignIn-MobileView'>
				<SignUpMobile />
			</div>
		</>
	);
}
