import React from "react";
import DesktopFooter from "./DesktopFooter";
import MobileFooter from "./MobileFooter";

export default function Footer(){
	return (
		<>
			<div className="DesktopFooterContainer">
				<DesktopFooter/>
			</div>
			<div className="MobileFooterContainer">
				<MobileFooter/>
			</div>
		</>
	);
}