import "../assets/styles/App.scss";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import React from "react";
import ProtectedRoute from "../components/ProtectedRoute";

//Components
import Home from "./pages/Home";
import SignIn from "../views/SignIn/SignIn";
import SignUp from "../views/SignUp/SignUp";
import ForgotPassword from "../components/dashboard/ForgotPassword";
import Onboarding from "./Onboarding/Onboarding";
import Dashboard from "./Dashboard/DashboardContainer";
import ResetPassword from "../components/dashboard/ResetPassword";
import ReferenceForm from "./ReferenceForm";
import  PaymentInfo  from "../components/dashboard/PaymentInfo/PaymentInfo";
import AccountSuspended from "../components/AccountSuspended";
import OAuthScreen from "../components/dashboard/OAuthScreen";
import PricingScreen from "../views/PricingScreen/PricingScreen";
import FeaturesPage from "./pages/FeaturesPage";
import PricingPage from "./pages/PricingPage";
import AssessmentInvite from "../components/dashboard/CandidateDashboard/AssessmentInvite";
import CandidateAssessmentReport from "../components/dashboard/CandidateDetails/CandidateAssessmentReport";
import PageNotFound from "./PageNotFound";

function App() {
	return (
		<div className='App'>
			<Router>
				<Switch>
					{/* <Route exact path='/' component={Home} /> */}
					<Route exact path='/features' component={FeaturesPage} />
					<Route exact path='/pricing-screen' component={PricingPage} />
					<ProtectedRoute path='/onboarding' component={Onboarding} />
					<Route path='/signin/:oauth' component={SignIn} />
					<Route exact path='/' component={SignIn} />
					<Route path='/signup/:token' component={SignUp} />
					<Route path='/signup' component={SignUp} />
					<ProtectedRoute path="/pricing" component={PricingScreen}/>
					<ProtectedRoute path="/payment" component={PaymentInfo}/>
					<Route path='/forgot-password' component={ForgotPassword} />
					<Route path='/reset-password/:token' component={ResetPassword} />
					<ProtectedRoute path='/dashboard' component={Dashboard} />
					<Route path='/reference-form/:token' component={ReferenceForm} />
					<ProtectedRoute path="/account-suspended" component={AccountSuspended}/>
					<ProtectedRoute path="/oauth" component={OAuthScreen}/>
					<Route exact path='/assessment_reports/:id' component={CandidateAssessmentReport} />
					<Route exact path='/assessment_invite/:linkId' component={AssessmentInvite} />
					<Route component={PageNotFound} />
				</Switch>
			</Router>
		</div>
	);
}

export default App;
