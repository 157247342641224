import React, { useEffect } from "react";
import Header from "../../components/onboarding/Header";
import MenuDropDown from "../../components/onboarding/MenuDropdown";
import MenuItem from "../../components/onboarding/MenuItem";
import CompanySetup from "../../components/onboarding/screens/CompanySetup";
import SetupRoles from "../../components/onboarding/screens/SetupRoles/index";
import AddClients from "../../components/onboarding/screens/AddClients";
import InviteCandidate from "../../components/onboarding/screens/InviteCandidate";
import TeamSetup from "../../components/onboarding/screens/TeamSetup";
import { Switch, Route, useRouteMatch } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import * as onBoardingActions from "../../redux/actions/onboarding/formsStatus";
import * as comapnySetupActions from "../../redux/actions/onboarding/companySetup";
import * as addClientActions from "../../redux/actions/onboarding/Clients";
// import * as setupRoleActions from "../../redux/actions/onboarding/SetupRoles";
import * as inviteCandidateActions from "../../redux/actions/onboarding/inviteCandidate";

function onboardingWeb({ props }) {
	const dispatch = useDispatch();
	const { path } = useRouteMatch();
	const isPath = (p, exact) =>
		useRouteMatch({ path: `/onboarding${p}`, exact }) ? true : false;

	const formsStatus = useSelector((state) => state.formsStatus);
	const user = useSelector((state) => state.user.user);

	const isSubmittedCompanySetup = useSelector(
		(state) => state.companySetup.isSubmitted,
	);

	const isSubmittedAddClients = useSelector(
		(state) => state.clients.isSubmitted,
	);

	const setupRoleQualificationForm = useSelector(
		(state) => state.setupRoles.setupRoleQualificationForm,
	);
	const setupRolePersonalityForm = useSelector(
		(state) => state.setupRoles.setupRolePersonalityForm,
	);
	const setupRoleReferenceForm = useSelector(
		(state) => state.setupRoles.setupRoleReferenceForm,
	);
	const setupRoleFormIsSubmitted = useSelector(
		(state) => state.setupRoles.isSubmitted,
	);
	const inviteCandidateIsSubmitted = useSelector(
		(state) => state.inviteCandidateReducer.isSubmitted,
	);

	const isSubmitted = (item) => formsStatus[item] == true;
	const isNextToSubmit = (item) => {
		const formOrder = [
			"companySetup",
			"addClients",
			"setupRoles",
			"inviteCandidates",
			"teamSetup",
		];
		const found = [];
		formOrder.forEach((name) => {
			found.push(formsStatus[name]);
		});
		let index = -1;
		for (let i = 0; i < found.length; i++) {
			if (found[i] == false) {
				index = i;
				break;
			}
		}
		if (index < 0) return false;
		if (formOrder[index] == item) return true;
		else return false;
	};
	const onClickNotDone = (item, route) => {
		return isNextToSubmit(item) ? () => props.history.push(route) : null;
	};

	const handleCompanySetupClicked = () => {
		dispatch(comapnySetupActions.companySetupMenuClicked());

		props.history.push("/onboarding/companySetup");
	};

	const handleAddClientsClicked = () => {
		dispatch(addClientActions.addClientsMenuClicked());

		props.history.push("/onboarding/addClients");
	};

	const handleSetupRolesClicked = () => {
		// dispatch(setupRoleActions.setupRolesMenuClicked());
		// props.history.push("/onboarding/setupRoles");
	};

	const handleInviteCandidateClicked = () => {
		dispatch(inviteCandidateActions.inviteCandidateMenuClicked());

		props.history.push("/onboarding/inviteCandidate");
	};

	useEffect(() => {
		dispatch(onBoardingActions.getUserProfile(user));
	}, [dispatch, user]);

	return (
		<div className='onboarding_layout'>
			<Header />
			<div className='row'>
				<div className='col-sm-12 col-md-4'>
					<div className='onboarding_left_panel'>
						<div
							className='headline1'
							style={{ paddingBottom: "18px", color: "#2B3340" }}>
							Onboarding
						</div>
						<div
							id='scrollbar_style-1'
							style={{
								paddingRight: "12px",
								minHeight: "40vh",
								maxHeight: "69vh",
								overflowY: "auto",
							}}>
							<MenuDropDown
								label='Company Setup'
								done={isSubmittedCompanySetup}
								selected={isPath("/companySetup", false) || isPath("", true)}
								onClickNotDone={onClickNotDone(
									"companySetup",
									"/onboarding/companySetup",
								)}
								onClickDone={handleCompanySetupClicked}
							/>
							<MenuDropDown
								label='Add Clients'
								done={isSubmittedAddClients}
								selected={isPath("/addClients", false)}
								onClickNotDone={onClickNotDone(
									"addClients",
									"/onboarding/addClients",
								)}
								onClickDone={handleAddClientsClicked}
							/>
							<MenuDropDown
								label='Setup roles'
								done={setupRoleFormIsSubmitted}
								selected={isPath("/setupRoles", false)}
								onClickNotDone={onClickNotDone(
									"setupRoles",
									"/onboarding/setupRoles",
								)}
								onClickDone={handleSetupRolesClicked}>
								<MenuItem
									label='Qualification'
									done={setupRoleQualificationForm.isSubmitted}
								/>
								<MenuItem
									label='Personality'
									done={setupRolePersonalityForm.isSubmitted}
								/>
								<MenuItem
									label='Reference Checks'
									done={setupRoleReferenceForm.isSubmitted}
								/>
							</MenuDropDown>
							<MenuDropDown
								label='Invite Candidate'
								done={inviteCandidateIsSubmitted}
								selected={isPath("/inviteCandidate", false)}
								onClickNotDone={onClickNotDone(
									"inviteCandidates",
									"/onboarding/inviteCandidates",
								)}
								onClickDone={handleInviteCandidateClicked}
							/>
							<MenuDropDown
								label='Team Setup'
								done={isSubmitted("teamSetup")}
								selected={isPath("/teamSetup", false)}
								onClickNotDone={onClickNotDone(
									"teamSetup",
									"/onboarding/teamSetup",
								)}
								onClickDone={() => history.push(`/onboarding/teamSetup`)}
							/>
						</div>
					</div>
				</div>
				<div className='col-sm-12 col-md-8'>
					<div
						className='onboarding_right_panel invisible-scrollbar'
						style={{ overflow: "auto", scrollbarWidth: "none" }}>
						<div>
							<Switch>
								<Route
									exact
									path={[`${path}`, `${path}/`, `${path}/companySetup`]}
									component={CompanySetup}
								/>
								<Route
									exact
									path={[`${path}/addClients`]}
									component={AddClients}
								/>
								<Route path={[`${path}/setupRoles`]} component={SetupRoles} />
								<Route
									exact
									path={[`${path}/inviteCandidate`]}
									component={InviteCandidate}
								/>
								<Route
									exact
									path={[`${path}/teamSetup`]}
									component={TeamSetup}
								/>
							</Switch>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
export default onboardingWeb;
