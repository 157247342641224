import React from "react";

export default function SignupInfo(){
	return (
		<div className="signup_info">
			<div>
				<span className="h1" style={{color:"#00B4D8",fontWeight:"700"}}>
				The AI-Driven <br/> Recruitment<br/> Platform
				</span>
			</div>
			<span className="p2">
				Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquet vitae fusce sagittis mi feugiat scelerisque ullamcorper. Odio adipiscing orci aliquam scelerisque volutpat velit nulla vestibulum vivamus. Proin interdum libero, erat praesent faucibus ac. A risus eget enim ut odio condimentum urna.
			</span>
		</div>
	);
}