import * as Actions from "../../actions/onboarding/formsStatus";

const initialState = {
	userProfile: null,
	companySetup: false,
	addClients: false,
	setupRoles: false,
	qualifications: false,
	personalityCheck: false,
	referenceCheck: false,
	inviteCandidates: false,
	teamSetup: false,
	updateProfileForm: {
		firstName: "",
		lastName: "",
		city: "",
		country: "",
		state: "",
		zipCode: "",
	},
	loading: false,
};

const reducer = (state = initialState, action) => {
	const data = action.payload;

	switch (action.type) {
		case Actions.SET_ONBOARDING_FORM_STATUS: {
			const obj = action.payload;
			return { ...state, ...obj };
		}

		case Actions.GET_USER_PROFILE:
			console.log(data);
			return (state = {
				...state,
				userProfile: data.data,
				updateProfileForm: {
					...state.updateProfileForm,
					firstName: data.data.first_name,
					lastName: data.data.last_name,
					city: data.data.city,
					country: data.data.country,
					state: data.data.state,
					zipCode: data.data.zip_code,
				},
			});

		case Actions.HANDLE_EDIT_PROFILE_CHANGE:
			return (state = {
				...state,
				updateProfileForm: {
					...state.updateProfileForm,
					state: data.label,
				},
			});

		case Actions.UPLOAD_USER_LOGO_END:
			return (state = {
				...state,
				loading: false,
				userProfile: {
					...state.userProfile,
					image_url: data.data,
				},
			});

		case Actions.UPLOAD_USER_LOGO_START:
			return (state = {
				...state,
				loading: true,
			});

		case Actions.UPLOAD_USER_LOGO_FAILED:
			return (state = {
				...state,
				loading: false,
			});

		case Actions.UPDATE_USER_PROFILE_START:
			return (state = {
				...state,
				loading: true,
			});

		case Actions.UPDATE_USER_PROFILE_END:
			return (state = {
				...state,
				loading: false,
				userProfile: {
					...state.userProfile,
					first_name: state.updateProfileForm.firstName,
					last_name: state.updateProfileForm.lastName,
					city: state.updateProfileForm.city,
					country: state.updateProfileForm.country,
					state: state.updateProfileForm.state,
					zip_code: state.updateProfileForm.zipCode,
				},
			});

		case Actions.UPDATE_USER_PROFILE_FAILED:
			return (state = {
				...state,
				loading: false,
			});

		default:
			return state;
	}
};

export default reducer;
