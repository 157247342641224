import React from "react";
import Footer from "../../components/Footer/Footer";
import Pricing from "../../components/PricingComponent";
import HowItWorks from "../../components/HowItWorks";
import Navbar from "../../components/subcomponents/WebNavbar/Navbar";
import FAQComponent from "../../components/FAQComponent";

export default function PricingPage() {
	return (
		<div >
			<Navbar style={{position:"absolute"}} pricingColor="#00B4D8"/>
			<Pricing />
			<HowItWorks/>
			<FAQComponent color="white" pricing/>
			<Footer/>
		</div>
	);
}
