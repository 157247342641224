import React, { useEffect } from "react";
import CandidateRow from "./CandidateRow";
import { useDispatch, useSelector } from "react-redux";
import * as candidateTestingActions from "../../../redux/actions/Candidate/candidate-testing.actions";
import assessmentStatus from "../../../utilities/Constants";
import SearchInput from "../../subcomponents/dashboard/SearchInput";

export default function Shortlisted(props) {
	const dispatch = useDispatch();

	const completedUserProfiles = useSelector(
		(state) => state.candidateTesting.completedUserProfiles,
	);

	const previousTestRightPanel = useSelector(
		(state) => state.candidateTesting.previousTestRightPanel,
	);

	const shortlistedUserIds = [];
	props?.shortlistedAssessments?.map((assessment) => {
		shortlistedUserIds.push(assessment.user_id);
	});

	

	useEffect(() => {
		if (shortlistedUserIds.length > 0) {
			dispatch(candidateTestingActions.getUserProfileOfIds(shortlistedUserIds));
		} else {
			dispatch(candidateTestingActions.resetCompletedUserProfiles());
		}
	}, [dispatch]);

	return (
		<div>
			<div className="" style={{marginTop:"16px"}}>
				<SearchInput candidateSearch style={{backgroundColor:"#F4F6F6", width:"100%", height:"40px"}}/>
			</div>
			
			<div>

				{props?.shortlistedAssessments?.map((assessment, index) => {
					const userProfile = completedUserProfiles.find(
						(user) => user.user_id === assessment.user_id,
					);
						
					if(userProfile){
						return (
							<div key={index}>
								<CandidateRow
									selected={previousTestRightPanel?._id === assessment._id}
									onClick={() => {
											
										dispatch(
											candidateTestingActions.setPreviousTestRightPanel(
												assessment
											),
										);
										dispatch(
											candidateTestingActions.getSingleAssessmentSession(
												assessment._id,
											),
										);
										props.onClick();
									}}
									logo={userProfile?.image_url}
									name={userProfile?.first_name + " " + userProfile?.last_name}
									score={assessment.score}
									persMatch={assessment?.pers_match}
									referralStatus={assessment?.referral_status}
									shortlisted={assessment.status === assessmentStatus.SHORTLISTED}
		
								/>
							</div>
						);
					}
					
				})	
				}
			</div>
		</div>
	);
}
