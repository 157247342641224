import React, { useState, useEffect, useRef } from "react";
import ArrowDown from "../../assets/images/onboarding/profile/Strokedown.svg";
import { ReactComponent as ArrowUp } from "../../assets/images/onboarding/profile/Strokeup.svg";
import { useSelector, useDispatch } from "react-redux";
import * as authActions from "../../redux/actions/Auth/authAction";
import { Fragment } from "react";
import Switcher from "./dashboard/Switcher";
import * as formStatusActions from "../../redux/actions/onboarding/formsStatus";
import BlankPicture from "../../assets/icons/Userpic/userprofile.svg";

function Profile_btn(props) {
	const dispatch = useDispatch();
	const ref = useRef();

	const [clicked, setClicked] = useState(false);

	const userProfile = useSelector((state) => state.formsStatus.userProfile);
	const user = useSelector((state) => state.user.user);

	const toggleButton = () => {
		setClicked(!clicked);
	};

	const handleLogoutClicked = () => {
		return dispatch(authActions.signOut());
	};

	useEffect(() => {
		const checkIfClickedOutside = e => {
			if (clicked && ref.current && !ref.current.contains(e.target)) {
				setClicked(false);
			}
		};

		document.addEventListener("mousedown", checkIfClickedOutside);

		return () => {
			document.removeEventListener("mousedown", checkIfClickedOutside);
		};
	}, [clicked]);

	useEffect(() => {
		dispatch(formStatusActions.getUserProfile(user));
		dispatch(authActions.getTenantIdsOfUser());
	}, [dispatch, user]);

	if (props.dashboard) {
		return (
			<div style={{ position: "relative" }} ref={ref}>
				{
					props.suspended &&
					<>
						{clicked && (
							<div style={{ position: "absolute", top: "60px", zIndex: "100" }}>
								<Switcher onClick={props.onClick} />
							</div>
						)}
					</>
				}
				<Fragment>
					{props.menu ? (
						<div
							className='d-flex justify-content-center align-items-center pointer'
							onClick={toggleButton}
							style={{
								width: "50px",
								height: "50px",
								borderRadius: "10px",
								backgroundColor: "white",
								position: "relative",
							}}>
							<div>
								<img
									width='30px'
									height='30px'
									style={{ borderRadius:  userProfile?.image_url ? "50%" : "0%" }}
									src={(userProfile.image_url === null) ? BlankPicture : userProfile?.image_url}
								/>
							</div>
							<div
								style={{
									position: "absolute",
									width: "5px",
									height: "5px",
									backgroundColor: "#00D69A",
									borderRadius: "50%",
									left: "40px",
									top: "6px",
								}}></div>
						</div>
					) : (
						<div
							className='d-flex profile_btn'
							onClick={toggleButton}
							style={{
								position: "relative",
								width: props.mobile ? "196px" : "",
								height: props.mobile ? "50px" : "",
							}}>
							<div style={{ flex: 2 }}>
								<img
									width='30px'
									height='30px'
									style={{ borderRadius:  userProfile?.image_url ? "50%" : "0%"  }}
									src={(userProfile?.image_url === null) ? BlankPicture : userProfile?.image_url}
								/>
							</div>
							<div style={{ flex: 4 }} className='d-flex align-items-center'>
								<span style={{ textAlign: "initial" }} className='description'>
									{userProfile &&
										`${userProfile.first_name} ${userProfile.last_name}`}
								</span>
							</div>
							<div
								style={{ flex: 1, paddingTop: "3px" }}
								className='d-flex align-items-center'>
								{props.suspended ? (clicked ?<ArrowUp stroke='black'/>  : <img src={ArrowDown} />) :(clicked ? <img src={ArrowDown} /> : <ArrowUp stroke='black'/>) }
							</div>
							<div
								style={{
									position: "absolute",
									width: "5px",
									height: "5px",
									backgroundColor: "#00D69A",
									borderRadius: "50%",
									left: "44px",
									top: "6px",
								}}></div>
						</div>
					)}
				</Fragment>
				{
					props.suspended ?
						<>
						</>
						:
						<>
							{clicked && (
								<div style={{ position: "absolute", bottom: "60px", zIndex: "100" }}>
									<Switcher onClick={props.onClick} />
								</div>
							)}
						</>
				}
			</div>
		);
	} else if (props.onboarding) {
		return (
			<div ref={ref}>
				<div
					className='profile_btn'
					style={{
						borderBottomLeftRadius: clicked ? "0px" : "10px",
						borderBottomRightRadius: clicked ? "0px" : "10px",
					}}

					onClick={toggleButton}>
					<div>
						<img
							width='24px'
							height='24px'
							style={{ borderRadius: userProfile?.image_url ? "50%" : "0%" }}
							src={(userProfile.image_url === null) ? BlankPicture : userProfile?.image_url}
						/>
					</div>
					<div
						className='d-flex align-items-center'
						style={{ margin: "0px 5px 0px 5px" }}>
						<span className='description'>
							{userProfile &&
								`${userProfile.first_name} ${userProfile.last_name}`}
						</span>
					</div>
					<div
						style={{ paddingTop: "3px" }}
						className='d-flex align-items-center'>
						{clicked ? <ArrowUp stroke='black' /> : <img src={ArrowDown} />}
					</div>
				</div>
				<div
					className='profile_button_options description'
					onClick={() => handleLogoutClicked()}
					style={{
						display: clicked ? "flex" : "none",
						justifyContent: "center",
						alignItems: "center",
						color: "#00B4D8",
					}}>
					Logout
				</div>
			</div>
		);
	} else {
		return (
			<div
				style={{
					width: "50px",
					height: "50px",
					borderRadius: "10px",
					backgroundColor: "white",
				}}>
				<div style={{ flex: 2 }}>
					<img
						width='30px'
						height='30px'
						style={{ borderRadius: userProfile?.image_url ? "50%" : "0%" }}
						src={(userProfile.image_url === null) ? BlankPicture : userProfile?.image_url}
					/>
				</div>
			</div>
		);
	}
}

export default Profile_btn;
