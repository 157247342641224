import { secure_instance , instance } from "../../../api/axios/axios-config";
import assessmentStatus from "../../../utilities/Constants";

export const GET_NEXT_QUESTION_START =
	"[Candidate Testing] GET_NEXT_QUESTION_START";
export const GET_NEXT_QUESTION_END =
	"[Candidate Testing] GET_NEXT_QUESTION_END";
export const GET_NEXT_QUESTION_FAILED =
	"[Candidate Testing] GET_NEXT_QUESTION_FAILED";
export const SET_ASSESSMENT_ID_TO_START_TEST =
	"[Candidate Testing] SET_ASSESSMENT_ID_TO_START_TEST";
export const SET_TIME_OUT = "[Candidate Testing] SET_TIME_OUT";
export const SET_ANSWER_SUBMITTED = "[Candidate Testing] SET_ANSWER_SUBMITTED";
export const SUBMIT_ANSWER_START = "[Candidate Testing] SUBMIT_ANSWER_START";
export const SUBMIT_ANSWER_END = "[Candidate Testing] SUBMIT_ANSWER_END";
export const SUBMIT_ANSWER_FAILED = "[Candidate Testing] SUBMIT_ANSWER_FAILED";
export const SET_TIMER_RESET = "[Candidate Testing] SET_TIMER_RESET";
export const HANDLE_CHANGE_TEXT_AREA =
	"[Candidate Testing] HANDLE_CHANGE_TEXT_AREA";
export const RESET_TEXT_AREA = "[Candidate Testing] RESET_TEXT_AREA";
export const SET_TEST_TO_RESUME = "[Candidate Testing] SET_TEST_TO_RESUME";
export const RESET_CANDIDATE_TESTING =
	"[Candidate Testing] RESET_CANDIDATE_TESTING";
export const CANDIDATE_TEST_ENDED = "[Candidate Testing] CANDIDATE_TEST_ENDED";
export const SET_VIDEO_BLOB = "[Candidate Testing] SET_VIDEO_BLOB";
export const SET_PENDING_TEST_RIGHT_PANEL =
	"[Candidate Testing] SET_PENDING_TEST_RIGHT_PANEL";
export const SET_CANDIDATE_USER_PROFILE =
	"[Candidate Testing] SET_CANDIDATE_USER_PROFILE";
export const SET_PREVIOUS_TEST_RIGHT_PANEL =
	"[Candidate Testing] SET_PREVIOUS_TEST_RIGHT_PANEL";
export const SET_SINGLE_ASSESSMENT_SESSION =
	"[Candidate Testing] SET_SINGLE_ASSESSMENT_SESSION";
export const SET_ASSESSMENT_REPORT =
	"[Candidate Testing] SET_ASSESSMENT_REPORT";
export const GET_ASSESSMEST_SESSIONS =
	"[Candidate Testing] GET_ASSESSMEST_SESSIONS";
export const SET_INVITED_USERS = "[Candidate Testing] SET_INVITED_USERS";
export const RESET_INVITED_USERS = "[Candidate Testing] RESET_INVITED_USERS";
export const SET_COMPLETED_USER_PROFILES =
	"[Candidate Testing] SET_COMPLETED_USER_PROFILES";
export const RESET_COMPLETED_USER_PROFILES =
	"[Candidate Testing] RESET_COMPLETED_USER_PROFILES";
export const SET_CANDIDATE_STATUS = "[Candidate Testing] SET_CANDIDATE_STATUS";
export const RESET_PREVIOUS_TEST_RIGHT_PANEL =
	"[Candidate Testing] RESET_PREVIOUS_TEST_RIGHT_PANEL";
export const SUBMIT_REFERENCE_CHECK_END =
	"[Candidate Testing] SUBMIT_REFERENCE_CHECK_END";
export const SUBMIT_REFERENCE_CHECK_START =
	"[Candidate Testing] SUBMIT_REFERENCE_CHECK_START";
export const SUBMIT_REFERENCE_CHECK_FAILED =
	"[Candidate Testing] SUBMIT_REFERENCE_CHECK_FAILED";
export const SET_ASSESSMENT_ID_FOR_REFERENCE_DETAILS =
	"[Candidate Testing] SET_ASSESSMENT_ID_FOR_REFERENCE_DETAILS";
export const SET_NEW_REFERRERS = "[Candidate Testing] SET_NEW_REFERRERS";
export const SET_REPORT_URL = "[Candidate Testing] SET_REPORT_URL";
export const SET_LINK_ID = "[Candidate Testing] SET_LINK_ID";		
export const SAVE_LINK_ID_PARAM = "[Candidate Testing] SAVE_LINK_ID_PARAM";		
export const GET_ASSESSMEST_INVITE_VERIFICATION_START = "[Candidate Testing] GET_ASSESSMEST_INVITE_VERIFICATION_START";		
export const GET_ASSESSMEST_INVITE_VERIFICATION = "[Candidate Testing] GET_ASSESSMEST_INVITE_VERIFICATION";		
export const GET_ASSESSMEST_INVITE_VERIFICATION_END = "[Candidate Testing] GET_ASSESSMEST_INVITE_VERIFICATION_END";		
export const GET_CANDIDATE_REFERRERS =
	"[Candidate Testing] GET_CANDIDATE_REFERRERS";
export const ADD_EXISTING_REFERRER =
	"[Candidate Testing] ADD_EXISTING_REFERRER";
export const REMOVE_EXISTING_REFERRER =
	"[Candidate Testing] REMOVE_EXISTING_REFERRER";
export const GET_ASSESSMEST_SESSIONS_STATS =
	"[Candidate Testing] GET_ASSESSMEST_SESSIONS_STATS";
export const DELETE_ASSESSMEST = "[Candidate Testing] DELETE_ASSESSMEST";
export const HANDLE_COMMENTS_CHANGE =
	"[Candidate Testing] HANDLE_COMMENTS_CHANGE";
export const SUBMIT_FEEDBACK = "[Candidate Testing] SUBMIT_FEEDBACK";
export const SUBMIT_ASSESSMENT_FEEDBACK =
	"[Candidate Testing] SUBMIT_ASSESSMENT_FEEDBACK";

export const setAssessmentId = (assessmentId) => {
	return {
		type: SET_ASSESSMENT_ID_TO_START_TEST,
		payload: assessmentId,
	};
};

export const setTestToResume = (test) => {
	return {
		type: SET_TEST_TO_RESUME,
		payload: test,
	};
};

export const getNextQuestion = (assessmentId) => {
	return async (dispatch) => {
		try {
			dispatch({
				type: GET_NEXT_QUESTION_START,
			});

			const request = await secure_instance({
				url: `v1/assessment_sessions/${assessmentId}/next`,
				method: "Get",
			});
			console.log(request, "next");
			// Changed from UNDER_EVALUATION to FINISHED
			// Since FINISHED now denotes that the immediate evaluation is finished
			if (request.data?.status === assessmentStatus.FINISHED) {
				dispatch({
					type: CANDIDATE_TEST_ENDED,
					payload: request.data,
				});
				console.log(request.data, "asdd");
			} else {
				dispatch({
					type: GET_NEXT_QUESTION_END,
					payload: request.data,
				});
			}
		} catch (e) {
			dispatch({
				type: GET_NEXT_QUESTION_FAILED,
			});
		}
	};
};

export const setTimeOut = (bool) => {
	return {
		type: SET_TIME_OUT,
		payload: bool,
	};
};

export const submitAnswer = (
	{ ques_id, response_type, response, video },
	assessmentId,
) => {
	return async (dispatch) => {
		const formData = new FormData();
		formData.append("ques_id", ques_id);
		formData.append("response_type", response_type);
		response
			? formData.append("response", response)
			: formData.append("video", video);
		try {
			dispatch({
				type: SUBMIT_ANSWER_START,
			});

			await secure_instance.request({
				url: `v1/assessment_sessions/${assessmentId}`,
				method: "Patch",
				data: formData,
			});

			dispatch({
				type: SUBMIT_ANSWER_END,
			});
		} catch (e) {
			dispatch({
				type: SUBMIT_ANSWER_FAILED,
			});
		}
	};
};

export const submitFeedback = (data) => {
	return async (dispatch) => {
		try {
			const request = await secure_instance.request({
				url: "v1/question_feedbacks/",
				method: "Post",
				data: data,
			});
			console.log(request);
			dispatch({
				type: SUBMIT_FEEDBACK,
			});
		} catch (error) {
			console.log(error);
		}
	};
};

export const submitAssessmentFeedback = (data, history, match) => {
	return async (dispatch) => {
		try {
			const request = await secure_instance.request({
				url: "v1/assessment_feedbacks/",
				method: "Post",
				data: data,
			});
			console.log(request);
			if (request.status === 201) {
				history.push(`${match.path}/`);
			}
			dispatch({
				type: SUBMIT_ASSESSMENT_FEEDBACK,
				// payload: request.data,
			});
		} catch (error) {
			console.log(error);
		}
	};
};

export const setAnswerSubmitted = (bool) => {
	return {
		type: SET_ANSWER_SUBMITTED,
		payload: bool,
	};
};

export const setTimerReset = (bool) => {
	return {
		type: SET_TIMER_RESET,
		payload: bool,
	};
};

export const handleChangeTextArea = (text) => {
	return {
		type: HANDLE_CHANGE_TEXT_AREA,
		payload: text,
	};
};

export const handleChangeComments = (text) => {
	return {
		type: HANDLE_COMMENTS_CHANGE,
		payload: text,
	};
};

export const resetTextArea = (text) => {
	return {
		type: RESET_TEXT_AREA,
		payload: text,
	};
};

export const resetCandidateTesting = () => {
	return {
		type: RESET_CANDIDATE_TESTING,
	};
};

export const setVideoBlob = (blob) => {
	return {
		type: SET_VIDEO_BLOB,
		payload: blob,
	};
};

export const setPendingTestRightPanel = (data) => {
	return {
		type: SET_PENDING_TEST_RIGHT_PANEL,
		payload: data,
	};
};

export const getCandidateUserProfile = (userId) => {
	return async (dispatch) => {
		try {
			const request = await secure_instance.request({
				url: `v1/user_profile/${userId}`,
				method: "Get",
			});

			dispatch({
				type: SET_CANDIDATE_USER_PROFILE,
				payload: request.data.data,
			});
		} catch (e) {
			//
		}
	};
};

export const setPreviousTestRightPanel = (test) => {
	console.log("test is " , test);
	return {
		type: SET_PREVIOUS_TEST_RIGHT_PANEL,
		payload: test,
	};
};

export const getSingleAssessmentSession = (assessmentId) => {
	return async (dispatch) => {

		try {
			const request = await secure_instance.request({
				url: `v1/assessment_sessions/${assessmentId}`,
				method: "Get",
			});

			console.log(request.data, "single");
			dispatch({
				type: SET_SINGLE_ASSESSMENT_SESSION,
				payload: request.data,
			});
		} catch (e) {
			//
		}
	};
};
export const getAssessmentReport = (assessmentId) => {
	return async (dispatch) => {
		try {
			const request = await instance.request({
				url: `v1/assessment_reports/${assessmentId}`,
				method: "Get",
			});
			// console.log( "request  data on hit report endpoint " ,request.data);
			// console.log( "status on hit report endpoint " ,request.status);
			dispatch({
				type: SET_ASSESSMENT_REPORT,
				payload: request ,
			});
		} catch (e) {
			//
		}
	};
};

export const getAssessmentSessions = (roleId) => {
	return async (dispatch) => {
		try {
			const request = await secure_instance.request({
				url: `v1/assessment_sessions/?role_id=${roleId}`,
				method: "Get",
			});

			console.log(request);
			dispatch({
				type: GET_ASSESSMEST_SESSIONS,
				payload: request.data,
			});
		} catch (e) {
			//
		}
	};
};

export const deleteAssessment = (assessmentId) => {
	return async (dispatch) => {
		try {
			await secure_instance.request({
				url: `v1/assessment_sessions/${assessmentId}`,
				method: "Delete",
			});

			dispatch({
				type: DELETE_ASSESSMEST,
				payload: assessmentId,
			});
		} catch (e) {
			//
		}
	};
};

export const getAssessmentSessionsStats = () => {
	return async (dispatch) => {
		try {
			const request = await secure_instance.request({
				url: `v1/assessment_sessions/stats`,
				method: "Get",
			});

			if (request.status === 200) {
				dispatch({
					type: GET_ASSESSMEST_SESSIONS_STATS,
					payload: request,
				});
			}
		} catch (e) {
			//
		}
	};
};
export const inviteVerification = (linkId) => {
	return async (dispatch) => {
		dispatch({
			type:GET_ASSESSMEST_INVITE_VERIFICATION_START,
		});
		try {
			const request = await instance.request({
				url: `v1/assessment_invites/${linkId}`,
				method: "Get",
			});

			dispatch({
				type: GET_ASSESSMEST_INVITE_VERIFICATION,
				payload: request,
			});
			
		} catch (e) {
			dispatch({
				type:GET_ASSESSMEST_INVITE_VERIFICATION_END ,
			});
			//
		}
	};
};
export const getReportKey = (reportId , expiry) => {
	return async (dispatch) => {
		try {
			const request = await secure_instance.request({
				url: "/v1/report_urls/",
				method: "Post",
				data: {
					report_id: reportId,
					expiry:expiry
				},
			});
			dispatch({
				type: SET_REPORT_URL,
				payload: request.data  ,
			});
		} catch (e) {
			//
		}
	};
};
export const linkIdParam = (param) => {
	return async (dispatch) => {
		dispatch({
			type:SAVE_LINK_ID_PARAM,
			payload:param ,
		});
	};
};
export const getLinkId = (roleId , expiry) => {
	return async (dispatch) => {
		try {
			const request = await secure_instance.request({
				url: "/v1/assessment_invites/",
				method: "Post",
				data: {
					role_id: roleId,
					expiry:expiry
				},
			});
			dispatch({
				type: SET_LINK_ID,
				payload: request.data  ,
			});
		} catch (e) {
			//
		}
	};
};

export const getInvitedUsers = (userIds) => {
	return async (dispatch) => {
		try {
			const request = await secure_instance.request({
				url: "v1/user/emails",
				method: "Post",
				data: {
					userIds,
				},
			});
			console.log(request.data);
			dispatch({
				type: SET_INVITED_USERS,
				payload: request.data,
			});
		} catch (e) {
			//
		}
	};
};

export const resetInvitedusers = () => {
	return {
		type: RESET_INVITED_USERS,
	};
};

export const getUserProfileOfIds = (userIds) => {
	return async (dispatch) => {
		try {
			const request = await secure_instance.request({
				url: "v1/user_profile/get-user-profiles",
				method: "Post",
				data: {
					user_ids: userIds,
				},
			});

			dispatch({
				type: SET_COMPLETED_USER_PROFILES,
				payload: request.data,
			});
		} catch (e) {
			//
		}
	};
};

export const resetCompletedUserProfiles = () => {
	return {
		type: RESET_COMPLETED_USER_PROFILES,
	};
};

export const setCandidateStatus = (assessmentId, status) => {
	return async (dispatch) => {
		try {
			await secure_instance.request({
				url: `v1/assessment_sessions/${assessmentId}/status`,
				method: "Post",
				data: {
					status,
				},
			});

			dispatch({
				type: SET_CANDIDATE_STATUS,
				payload: { assessmentId, status },
			});
		} catch (e) {
			//
		}
	};
};

export const resetPreviousTestRightPanel = () => {
	return {
		type: RESET_PREVIOUS_TEST_RIGHT_PANEL,
	};
};

export const submitReferenceCheck = (data) => {
	return async (dispatch) => {
		try {
			dispatch({
				type: SUBMIT_REFERENCE_CHECK_START,
			});

			const request = await secure_instance.request({
				url: "/v1/candidate_referrers/",
				method: "Post",
				data,
			});

			dispatch({
				type: SUBMIT_REFERENCE_CHECK_END,
				payload: request.data,
			});
		} catch (e) {
			dispatch({
				type: SUBMIT_REFERENCE_CHECK_FAILED,
			});
		}
	};
};

export const setAssessmentIdForReferenceDetails = (assessmentId) => {
	return {
		type: SET_ASSESSMENT_ID_FOR_REFERENCE_DETAILS,
		payload: assessmentId,
	};
};

export const setNewReferrers = (target) => {
	return {
		type: SET_NEW_REFERRERS,
		payload: target,
	};
};

export const getCandidateReferrers = (assessId) => {
	if (assessId) {
		return async (dispatch) => {
			try {
				const request = await secure_instance.request({
					url: `/v1/candidate_referrers/?assess_id=${assessId}`,
					method: "Get",
				});
	
				if (request.status === 200) {
					dispatch({
						type: GET_CANDIDATE_REFERRERS,
						payload: request.data,
					});
				}
			} catch (e) {
				//
			}
		};
	}
	else 
		return async (dispatch) => {
			try {
				const request = await secure_instance.request({
					url: "/v1/candidate_referrers/",
					method: "Get",
				});

				if (request.status === 200) {
					dispatch({
						type: GET_CANDIDATE_REFERRERS,
						payload: request.data,
					});
				}
			} catch (e) {
			//
			}
		};
};

export const addExistingReferrer = (id) => {
	return {
		type: ADD_EXISTING_REFERRER,
		payload: id,
	};
};

export const removeExistingReferrer = (id) => {
	return {
		type: REMOVE_EXISTING_REFERRER,
		payload: id,
	};
};
