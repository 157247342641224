import React from "react";
import MenuDropdown from "../../../components/onboarding/MenuDropdown";
import { Switch, Route, useRouteMatch, useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import Roles from "../../../components/onboarding/screens/SetupRoles/Roles";
import Qualification from "../../../components/onboarding/screens/SetupRoles/Qualification";
import Personality from "../../../components/onboarding/screens/SetupRoles/PersonalityAssesment";
import Reference from "../../../components/onboarding/screens/SetupRoles/ReferenceChecks";
import Result from "../../../components/onboarding/screens/SetupRoles/Result";

export default function CreateNewPositionWeb() {
	const history = useHistory();

	const { path } = useRouteMatch();
	const isPath = (p, exact) =>
		useRouteMatch({ path: `/dashboard/create-new-position${p}`, exact })
			? true
			: false;

	const isDashboardSubmitted = useSelector(
		(state) => state.setupRoles.isDashboardSubmitted,
	);

	const isQualificationSubmitted = useSelector(
		(state) => state.setupRoles.isQualificationSubmitted,
	);

	const isPersonalitySubmitted = useSelector(
		(state) => state.setupRoles.isPersonalitySubmitted,
	);

	const isReferenceCheckSubmitted = useSelector(
		(state) => state.setupRoles.isReferenceCheckSubmitted,
	);

	const isRoleSummarySubmitted = useSelector(
		(state) => state.setupRoles.isRoleSummarySubmitted,
	);

	return (
		<div className='d-flex'>
			<div
				className='onboarding_left_panel'
				style={{ width: "30%", padding: "25px 30px", marginRight: "20px" }}>
				<MenuDropdown
					label='Setup Role'
					selected={isPath("/setup-roles", false || isPath("", true))}
					done={isDashboardSubmitted}
					onClickDone={() =>
						history.push("/dashboard/create-new-position/setup-roles")
					}
				/>
				<MenuDropdown
					label='Qualification'
					selected={isPath("/qualifications", false)}
					done={isQualificationSubmitted}
					onClickDone={() =>
						history.push("/dashboard/create-new-position/qualifications")
					}
				/>
				<MenuDropdown
					label='Personality Assessment'
					selected={isPath("/personality-assessment", false)}
					done={isPersonalitySubmitted}
					onClickDone={() =>
						history.push(
							"/dashboard/create-new-position/personality-assessment",
						)
					}
				/>
				<MenuDropdown
					label='Reference Checks'
					selected={isPath("/reference-checks", false)}
					done={isReferenceCheckSubmitted}
					onClickDone={() =>
						history.push("/dashboard/create-new-position/reference-checks")
					}
				/>
				<MenuDropdown
					label='Role Summary'
					selected={isPath("/result", false)}
					done={isRoleSummarySubmitted}
					onClickDone={() =>
						history.push("/dashboard/create-new-position/result")
					}
				/>
			</div>
			<div className='onboarding_right_panel' style={{ width: "70%" }}>
				<Switch>
					<Route exact path={[`${path}`, `${path}/`, `${path}/setup-roles`]}>
						<Roles dashboard />
					</Route>
					<Route exact path={`${path}/qualifications`}>
						<Qualification dashboard />
					</Route>
					<Route exact path={`${path}/personality-assessment`}>
						<Personality dashboard />
					</Route>
					<Route path={`${path}/reference-checks`}>
						<Reference dashboard />
					</Route>
					<Route path={`${path}/result`}>
						<Result dashboard />
					</Route>
				</Switch>
			</div>
		</div>
	);
}
