import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {CopyToClipboard} from "react-copy-to-clipboard";
import { ReactComponent as Ellipse } from "../../../assets/icons/basic/Ellipse 256.svg";
import pending from "../../../assets/icons/basic/pending.png";
import {ReactComponent as Copy } from "../../../assets/icons/onboarding/Copy.svg";
import * as candidateTestingActions from "../../../redux/actions/Candidate/candidate-testing.actions";

export default function ReferenceCard(props) {
	const dispatch = useDispatch();

	const candidateReferrers = useSelector(
		(state) => state.candidateTesting.candidateReferrers,
	);
	const isAutoMail = useSelector((state) => state.candidateTesting.isAutoRefEmail);

	useEffect(() => {
		dispatch(candidateTestingActions.getCandidateReferrers(props.assessId));
	}, [dispatch , props]);
	return (
		<div
			className='invisible-scrollbar'
			style={{
				width: "100%",
				minHeight: "300px",
				maxHeight: "508px",
				borderRadius: "10px",
				padding: "10px 15px",
				backgroundColor: "white",
				overflowY: "auto",
			}}>
			<div
				className='d-flex'
				style={{ borderBottom: "1px solid #C8CBCD", paddingBlock: "10px" }}>
				<span className='b2'>References</span>
			</div>
			<div>
				{candidateReferrers.map((reference, key) => {
					return (
						<div key={key}>
							<div
								className='d-flex flex-column'
								style={{
									paddingBlock: "10px",
									borderBottom: "1px solid #C8CBCD",
								}}>
								<div className='d-flex align-items-center justify-content-between'>
									<div className='d-flex align-items-center'>
										<span className='b2'>{reference.name || "Daniel James"}</span>
										<div style={{ paddingInline: "10px" }}>
											<Ellipse />
										</div>
										<span className='description' style={{ color: "#96999C" }}>
											{isAutoMail && reference.designation || reference.relationship }	
										</span>  
										{isAutoMail &&  reference.organisation &&
									<div style={{ paddingInline: "10px" }}>
										<Ellipse />
									</div>}
										<span className='description' style={{ color: "#96999C" }}>
											{reference.organisation || reference.organization}
										</span>
									</div>
									{isAutoMail && !reference.reference?.data && <div style={{ cursor:"pointer",border:"1px solid #00B4D8" , boxSizing:"border-box" , borderRadius:"50px" , padding:"5px 10px" , marginRight:"17.5px"  , backgroundColor:"#00B4D8"}}>
										<img src= {pending}/>
										<span className="b3 " style={{paddingLeft:"6.88px" , color:"#FFFFFF"}}>Pending</span>
							
									</div>}
								</div>

								<div className='d-flex' style={{marginTop:"10px"}}>
									<span className='description'>{reference.email}</span>
									{!isAutoMail &&
									<CopyToClipboard text={reference.email}>
										<Copy style={{marginLeft:"15px" , cursor:"pointer"}}/>
									</CopyToClipboard>}
								</div>
								{reference.reference?.data.map((question, key) => {
									return (
										<div
											key={key}
											style={{ paddingTop: "10px" }}
											className='d-flex flex-column align-items-start text-justify'>
											<span className='description'>{question.question}</span>
											<div>
												<span
													className='description'
													style={{ color: "#56585A" }}>
													{question.response}
												</span>
											</div>
										</div>
									);
								})}
							</div>
						</div>
					);
				})}
			</div>
			
			
		</div>
	);
}
