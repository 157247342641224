import React, { useEffect, useState } from "react";
import Checkmark from "../../../assets/icons/onboarding/checkmark.png";

export default function TraitCard(props) {
	const { onClick, children } = props;
	const [selected, setSelected] = useState(false);

	const togglebtn = () => {
		setSelected(!selected);
		onClick(selected);
	};

	useEffect(() => {
		setSelected(props.selected);
	}, []);
	return props.completed ? (
		<div
			className='d-flex align-items-center justify-content-center flex-column'
			style={{
				position: "relative",
				minHeight: "250px",
				width: "180px",
				borderRadius: "12px",
				background: "white",
				margin: "5px",
				cursor: "pointer",
				textAlign: "center",
			}}>
			<img
				width='16px'
				src={Checkmark}
				style={{
					position: "absolute",
					right: "16px",
					top: "18px",
					display: "block",
				}}
			/>
			{children}
		</div>
	) : (
		<div
			onClick={() => togglebtn()}
			className='d-flex align-items-center justify-content-center flex-column traitCard'
			style={{
				position: "relative",
				minHeight: "250px",
				width: "180px",
				borderRadius: "12px",
				background: selected ? "#EAEDEE" : "white",
				margin: "5px",
				cursor: "pointer",
				textAlign: "center",
				padding:"0px 5px"
			}}>
			<img
				width='16px'
				src={Checkmark}
				style={{
					position: "absolute",
					right: "16px",
					top: "18px",
					display: selected ? "block" : "none",
				}}
			/>
			{children}
		</div>
	);
}
