import React from "react";
import Button from "./Button";
import Logo from "../../assets/icons/logo/Symbol.png";

export default function IntegrationsCard(props) {
	return (
		<div className='integrations-card' onClick={props.onClick}>
			<div className='w-100 d-flex justify-content-end'>
				<Button
					className='d-flex justify-content-center align-items-center'
					dashboardbtn
					label={props.label || "Message Channels"}
					style={{
						backgroundColor: "#EAEDEE",
						height: "fit-content",
						width: "fit-content",
						borderRadius: "5px",
						padding: "0px 5px",
						margin: "0px 0px",
					}}
					textClass='description'
				/>
			</div>
			<div style={{ padding: "17px 0px" }}>
				<img
					src={props.logo || Logo}
					alt=''
					style={{ width: "45px", height: "45px" }}
				/>
			</div>
			<div>
				<span className='b1'>{props.name || "Lever via Zapier"}</span>
			</div>
			<div>
				<span className='description'>
					{props.description ||
						"Keep your hiring moving with flexible tools for remote interviewing, collaborating and more Keep your hiring moving with flexible tools for remote interviewing, collaborating and more"}
				</span>
			</div>
		</div>
	);
}
